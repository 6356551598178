import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Grid from '@mui/material/Grid';
import InitialIntegrationModal from "../Connectors/Onboarding/InitialIntegrationModal";
import SummaryMetricsBox from "./SummaryMetricsBox";
import SummaryModal from "../SummaryModal/SummaryModal";
import {setCurrentType} from "../../store/alertsTabsSlice";
import FeedTable from "./FeedTable";
import {
	actions,
	appNameDisplay,
	flagAiRelevantStatus,
	flagDescription,
	moneySaved,
	referenceDescription,
	flagFirstOccurrenceTimestamp
} from "./FeedTableColumns";

function AlertFeed(props) {
	const dispatch = useDispatch();

  const apps = useSelector((state) => state.apps.filteredList);
	const subscribedApps = useSelector((state) => state.appsSubscriptions.list);

	const alerts = useSelector((state) => state.alerts.alerts);
	const resolvedAlerts = useSelector((state) => state.alerts.resolvedAlerts);
	const selectedTab = useSelector((state) => state.alertsTabs.selectedTab);
	const filters = useSelector((state) => state.alertsTabs.filters);

	useEffect(() => {
		return () => {
			dispatch(setCurrentType("All"))
		}
	}, [])

	const columns = useMemo(() => {
		if (selectedTab === "Resolved") {
			// user viewing resolved alerts (no actions)
			return [
				appNameDisplay(selectedTab, false, false),
				moneySaved(false, selectedTab, false),
				flagDescription(apps, subscribedApps, "", selectedTab, false, false, false, false),
				referenceDescription(false, selectedTab, false, false),
				flagFirstOccurrenceTimestamp(false),
			]
		}
		return [
			appNameDisplay(selectedTab, false, false),
			moneySaved(false, selectedTab, false),
			flagDescription(apps, subscribedApps, "", selectedTab, false, false, false, false),
			referenceDescription(false, selectedTab, false, false),
			flagFirstOccurrenceTimestamp(false),
			actions(false, "", selectedTab),
			flagAiRelevantStatus(false, "", selectedTab),
		]
	}, [selectedTab, apps, subscribedApps])

	return (
		<>
			<InitialIntegrationModal />
			<SummaryModal/>
			<Grid container spacing={2}>
				<SummaryMetricsBox/>
				<Grid item xs={12}>
					<FeedTable
						alerts={selectedTab === 'Resolved' ? filters[selectedTab](resolvedAlerts) : filters[selectedTab](alerts)}
						sysAdmin={false}
						currentTab={selectedTab}
						columns={columns}
					/>
				</Grid>
			</Grid>
		</>
	)
}

export default AlertFeed;