import { createSlice } from '@reduxjs/toolkit';

export const appsSubscriptionsSlice = createSlice({
  name: "appsSubscriptions",
  initialState: {
    list: [],
    isCaptured: false,
    isReady: false,
    addSubscriptionArgs: null,
    cancelSubscriptionArgs: null,
    customerSubToRemove: "",
  },
  reducers: {
    setAppsSubscriptions: (state, action) => {
      console.warn("Apps subscriptions:", action.payload)
      state.list = action.payload;
      state.isCaptured = true;
      state.isReady = true
    },
    refreshAppsSubscriptions: (state, action) => {
      console.log("refreshAppsSubscriptions")
      state.isCaptured = false;
    },
    setAddSubscriptionArgs: (state, action) => {
      console.log("setAddSubscriptionArgs", action.payload)
      state.addSubscriptionArgs = action.payload;
    },
    resetAddSubscriptionArgs: (state, action) => {
      console.log("resetAddSubscriptionArgs")
      state.addSubscriptionArgs = null;
    },
    setCancelSubscriptionAndCustomerSubArgs: (state, action) => {
      console.log("setCancelSubscriptionAndCustomerSubArgs", action.payload)
      state.cancelSubscriptionArgs = action.payload.cancelSubscriptionArgs;
      state.customerSubToRemove = action.payload.customerSubToRemove;
    },
    resetCancelSubscriptionArgs: (state, action) => {
      console.log("resetCancelSubscriptionArgs")
      state.cancelSubscriptionArgs = null;
    },
    resetCustomerSubToRemove: (state, action) => {
      console.log("resetCustomerSubToRemove")
      state.customerSubToRemove = "";
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setAppsSubscriptions,
  refreshAppsSubscriptions,
  setAddSubscriptionArgs,
  resetAddSubscriptionArgs,
  setCancelSubscriptionAndCustomerSubArgs,
  resetCancelSubscriptionArgs,
  resetCustomerSubToRemove
} = appsSubscriptionsSlice.actions;

export default appsSubscriptionsSlice.reducer;