import {useSelector} from "react-redux";
import React, {useState, useEffect} from "react";
import * as Constants from "../../../Constants";
import DirectRequest from "../../../API/requests/DirectRequest";
import ImpactBar from "../../ImpactBar/ImpactBar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ReplayIcon from "@mui/icons-material/Replay";
import SendIcon from '@mui/icons-material/Send';
import SearchIcon from '@mui/icons-material/Search';
import Box from "@mui/material/Box";
import { CustomAppTooltip } from "../TagEditor/TagEditor";
import { SelectApp } from "../TagEditor/TagEditor";
import Card from "@mui/material/Card";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python"; // imported python language
import "ace-builds/src-noconflict/theme-textmate"; // imported theme
import "ace-builds/src-noconflict/ext-language_tools";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Alert from '@mui/material/Alert';

const editorCardStyle = {
  boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  height: "100%",
}

const disabledCardStyle = {
  filter: "opacity(50%)",
  pointerEvents: "none",
}

const ScriptIDE = ({ script, handleScriptChange, updating }) => {
  return (
    <Grid item xs={12}>
      <Card sx={updating ? {...editorCardStyle, ...disabledCardStyle} : editorCardStyle}>
        <AceEditor
          mode="python"
          theme="textmate"
          onChange={handleScriptChange}
          value={script}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          showPrintMargin={false}
          style={{
            marginTop: "1%",
            marginBottom: "1%",
            marginRight: "1%",
            marginLeft: "1%",
            width: "98%",
          }}
        />
      </Card>
    </Grid>
  )
}

const findDapp = (inputString) => {
  if (typeof inputString !== 'string') return null;
  // Define the regex pattern
  // \b for word boundary, dapp_ followed by any alphanumeric characters and underscores
  const regex = /\bdapp_[A-Za-z0-9_]+/;

  // Use match to find the first occurrence
  const result = inputString ? inputString.match(regex) : null;

  // match returns an array if a match is found, null otherwise
  return result ? result[0] : null;
}

const checkAppAndScript = (selectedAppUuid, script) => {
  if (!selectedAppUuid || !script) {
    return true;
  } else {
    const scriptUuid = findDapp(script);
    return !((scriptUuid === selectedAppUuid) || !scriptUuid);
  }
}

const DisplayCheckMark = ({ setDisplayCheckMark }) => {
  useEffect(() => {
    const checkTimer = setTimeout(() => {
      setDisplayCheckMark(false);
    }, 3000)
    return () => clearTimeout(checkTimer);
  }, [])
  return (
    <Grid item>
      <Box sx={{height: "100%", display: "flex", alignItems: "center" }}>
        <CheckCircleOutlineIcon sx={{color: "green"}}/>
      </Box>
    </Grid>
  )
}

// almost a 1:1 copy of TagEditor.js's SelectAppBar - will probably want to modularize at some point - just need to move fast right now (Eric - 02/2024)
const SelectAppBar = ({ allApps, selectedAppUuid, handleReset, handleUpdate, script, updating, displayCheckMark, setDisplayCheckMark }) => {
  return (
    <Grid item xs={12}>
      <ImpactBar
        component={
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item>
              <SelectApp selectedAppUuid={selectedAppUuid} allApps={allApps} />
            </Grid>
            <Grid item>
              <Box sx={{height: "100%", display: "flex", alignItems: "center" }}>
                <CustomAppTooltip
                  title={selectedAppUuid ? <pre>{JSON.stringify(allApps.find(app => app.uuid === selectedAppUuid), null, 2)}</pre> : ""}
                >
                  <SearchIcon sx={!selectedAppUuid ? { fontSize: "2rem", fill: "grey" } : { fontSize: "2rem" }}/>
                </CustomAppTooltip>
              </Box>
            </Grid>
            <Grid item>
              <Button
                onClick={handleReset}
                sx={{ borderRadius: "0px !important"}}
                variant={"outlined"}
                startIcon={<ReplayIcon />}
                disabled={!selectedAppUuid}
              >
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant={"contained"}
                sx={{ borderRadius: "0px !important"}}
                startIcon={updating ? <CircularProgress size={"1rem"}/> : <SendIcon />}
                onClick={handleUpdate}
                disabled={updating ? updating : checkAppAndScript(selectedAppUuid, script)}
              >
                Update
              </Button>
            </Grid>
            {displayCheckMark && (
              <DisplayCheckMark setDisplayCheckMark={setDisplayCheckMark} />
            )}
          </Grid>
        }
      />
    </Grid>
  )
}

const MismatchedAppAlert = ({selectedAppUuid, script}) => {
  if (!script) return null;
  const scriptUuid = findDapp(script);
  return !scriptUuid || scriptUuid === selectedAppUuid ? null : (
    <Grid item xs={12}>
      <Alert severity="warning">The script uuid <strong>{scriptUuid}</strong> does not match the selected app uuid <strong>{selectedAppUuid}</strong></Alert>
    </Grid>
  )
}

export const ScriptEditor = () => {
  const allApps = useSelector((state) => state.appsSysAdmin.list);//dummyApps
  const selectedAppUuid = useSelector((state) => state.dashboardEditor.selectedAppUuid);
  const [script, setScript] = useState("");

  const [updateAppScriptArgs, setUpdateAppScriptArgs] = useState(null);
  const [updating, setUpdating] = useState(false);

  const [displayCheckMark, setDisplayCheckMark] = useState(false);

  const handleReset = () => {
    setScript("");
  };

  const handleScriptChange = (newScript) => {
    setScript(newScript);
  };

  const handleUpdate = () => {
    setUpdating(true)
    const updateBody = new FormData();
    const scriptFile = new File([script], "appScript.py", {
      type: "text/x-python",
    });
    updateBody.append("appScript", scriptFile);
    updateBody.append("devAppUuid", selectedAppUuid);
    const updateArgs = {
      url: Constants.SERVER_SYSADMIN_UPDATE_DEVAPP_SCRIPT_URL,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      body: updateBody,
    };
    setUpdateAppScriptArgs(updateArgs);
  }

  const handleSuccessfulUpdate = (res) => {
    console.log("successful update", res);
    setUpdating(false);
    setDisplayCheckMark(true);
  }

  useEffect(() => {
    console.log("selectedAppUuid", selectedAppUuid);
    console.log("script", script);
    const findDapp = (inputString) => {
      // Define the regex pattern
      // \b for word boundary, dapp_ followed by any alphanumeric characters and underscores
      const regex = /\bdapp_[A-Za-z0-9_]+/;

      // Use match to find the first occurrence
      const result = inputString.match(regex);

      // match returns an array if a match is found, null otherwise
      return result ? result[0] : null;
    }
    console.log("findDapp:", findDapp(script));
  }, [selectedAppUuid, script])

  return (
    <>
      <DirectRequest
        requestArgs={updateAppScriptArgs}
        afterProcess={handleSuccessfulUpdate}
        handleError={(err) => console.log("error updating app script", err)}
        handleCatchError={(err) => console.log("catch error updating app script", err)}
      />
      <Grid container spacing={2}>
        <SelectAppBar
          allApps={allApps}
          selectedAppUuid={selectedAppUuid}
          handleReset={handleReset}
          handleUpdate={handleUpdate}
          script={script}
          updating={updating}
          displayCheckMark={displayCheckMark}
          setDisplayCheckMark={setDisplayCheckMark}
        />
        <MismatchedAppAlert
          selectedAppUuid={selectedAppUuid}
          script={script}
        />
        <ScriptIDE
          script={script}
          handleScriptChange={handleScriptChange}
          updating={updating}
        />
      </Grid>
    </>
  )
}

export default ScriptEditor;