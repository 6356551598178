import { useSelector } from "react-redux";
import CircleIcon from "@mui/icons-material/Circle";
import Button from "@mui/material/Button";
import React from "react";
import { styled, keyframes } from '@mui/system';

const blink = keyframes`
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
`;

const withBlinkingAnimation = (Component, color) => styled(Component)(({ theme }) => ({
  color: color,
  animation: `${blink} 1s linear infinite`
}));

// use the HOC to create a blinking CircleIcon
const BlinkingCircleIcon = withBlinkingAnimation(CircleIcon, '#FFBF00'); // or use '#FFBF00' for a more specific amber color

const isSuccessStatus = (clusterState) => {
  const successStates = ["RUNNING", "DRIVER_HEALTHY", "RESIZING", "UPSIZE_COMPLETED"];
  return successStates.includes(clusterState);
};

const isFailureStatus = (clusterState) => {
  const failureStates = ["TERMINATED", "TERMINATING", "DRIVER_UNAVAILABLE", "SPARK_EXCEPTION", "DRIVER_NOT_RESPONDING", "DBFS_DOWN", "METASTORE_DOWN"];
  return failureStates.includes(clusterState);
};

export default function ClusterStatusChip() {
  const clusterState = useSelector((state) => state.clusterState.state);
  return (
    <>
      <Button
        sx={{ pointerEvents: "none" }}
        size="small"
        variant="outlined"
        startIcon={
          isSuccessStatus(clusterState) ? <CircleIcon color={"success"} /> :
          isFailureStatus(clusterState) ? <CircleIcon color={"error"} /> :
          <BlinkingCircleIcon />
        }
      >
        CLUSTER STATUS
      </Button>
    </>
  );
};
