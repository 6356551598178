import { createSlice } from '@reduxjs/toolkit';

/*
alertActiveMap: {
  "alertUuid": true
}
 - use this to "View alert" link or "Resolved" if message's alertUuid is not in alertActiveMap
alertMessageFeedCategoryMap: {
  "appNameDisplay": ["alertMessageUuid", "alertMessageUuid"]
}
 - use this to display number of messages per alert type in AlertMessages dropdown filter - will update whenever alertMessages are updated
*/


export const alertMessagesSlice = createSlice({
  name: "alertMessages",
  initialState: {
    list: [],
    isCaptured: false,
    alertActiveMap: null,
    alertMessagesFeedCategoryMap: null,
    currentType: "All",
    updateArgs: null,
    disableOnClick: false,
  },
  reducers: {
    setAlertMessages: (state, action) => {
      console.warn("setAlertMessages:", action.payload)
      state.list = action.payload;
      state.isCaptured = true;
      state.disableOnClick = false;
    },
    refreshAlertMessages: (state) => {
      // console.log("refreshAlertMessages");
      state.updateArgs = null;
      state.isCaptured = false;
    },
    setCurrentType: (state, action) => {
      // console.log("setCurrentType", action.payload)
      state.currentType = action.payload
    },
    setUpdateAlertMessagesArgs: (state, action) => {
      // console.log("setUpdateAlertMessagesArgs", action.payload)
      state.updateArgs = action.payload;
      state.disableOnClick = true;
    },
    setAlertActiveMap: (state, action) => {
      // console.log("setAlertActiveMap", action.payload)
      state.alertActiveMap = action.payload;
    },
    setAlertMessagesFeedCategoryMap: (state, action) => {
      // console.log("setAlertMessagesFeedCategoryMap", action.payload)
      state.alertMessagesFeedCategoryMap = action.payload;
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  setAlertMessages,
  refreshAlertMessages,
  setCurrentType,
  setUpdateAlertMessagesArgs,
  setAlertActiveMap,
  setAlertMessagesFeedCategoryMap,
} = alertMessagesSlice.actions;

export default alertMessagesSlice.reducer;