import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import React from "react";
import {useSelector} from "react-redux";

const getChecked = (newValue, paramValue) => {
  if (newValue !== null) {
    return newValue === "true";
  } else {
    return paramValue === "true";
  }
}

const getLabel = (newValue, paramValue) => {
  if (newValue === null) {
    return (
      <span>{paramValue}</span>
    )
  } else {
    return (
      <span>{newValue} - <i>edited</i></span>
    )
  }
}

export const BooleanInput = ({ index, handleChange }) => {
  const newValue = useSelector((state) => state.alertConfigsSysAdmin.alertConfigs[index].newValue);
  const paramValue = useSelector((state) => state.alertConfigsSysAdmin.alertConfigs[index].paramValue);
  const handleSwitchChange = (e) => {
    console.log("BooleanInput handleSwitchChange", e.target.checked);
    if (e.target.checked) {
      handleChange("true", paramValue)
    } else {
      handleChange("false", paramValue)
    }
  }
  console.log("BooleanInput config index:", index)
  return (
    <FormControlLabel
      control={
        <Switch checked={getChecked(newValue, paramValue)} onChange={handleSwitchChange}/>
      }
      label={getLabel(newValue, paramValue)}
    />
  )
}

export default BooleanInput