import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import Tour from "reactour";
import CircularProgress from "@mui/material/CircularProgress";
import { useScrollLock } from "../../helpers/useScrollLock";
import ClusterStatusChip from "./ClusterStatusChip";
import InfoIcon from '@mui/icons-material/Info';
import DownloadJournalEntryCSVButton from "../Dashboard/components/FDRecipeTableComponents/DisplayConfigActionsComponents/CreateEntryConfigComponents/DownloadJournalEntryCSVButton";

function TopBar(props) {
  const theme = useTheme();
  const [tourOpen, setTourOpen] = useState(false);
  const { lockScroll, unlockScroll } = useScrollLock();
  const themeLogo = useSelector((state) => state.themeLogo.logo);

  const toggleTour = (event) => {
    setTourOpen(!tourOpen);
  };

  useEffect(() => {
    if (tourOpen) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [tourOpen]);

  return (
    <React.Fragment>
      {(!props.hide_help || props.hide_help === false) && props.steps && (
        <Tour
          steps={props.steps}
          isOpen={tourOpen}
          onRequestClose={toggleTour}
          rounded={8}
          startAt={0}
        />
      )}
      <Grid
        container
        spacing={4}
        justifyContent={"space-between"}
        alignItems={"center"}
        wrap={"wrap-reverse"}
      >
        <Grid item>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems={"center"}>
                {props.recipe && !props.preview && themeLogo && (
                  <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={themeLogo ? URL.createObjectURL(themeLogo) : ""} alt={"Company logo"} style={{ objectFit: "contain", width: "150px" }}/>
                  </Grid>
                )}
                {props.icon && <Grid item>{props.icon}</Grid>}
                <Grid item>
                  {!props.recipe && (<Typography variant="h4">{props.top_message}</Typography>)}
                  {props.recipe && (
                    <Typography
                      variant="h4"
                      // sx={{ fontFamily: props.newTheme.typography.fontFamily }}
                    >
                      {props.top_message}
                    </Typography>
                  )}
                </Grid>
                {props.description && props.description !== undefined && <Grid item>
                  <Typography variant="h4">
                    <Tooltip title={props.description}>
                      <InfoIcon />
                    </Tooltip>
                  </Typography>
                </Grid>}
                {props.loading && props.loading !== false && <Grid item>
                  <CircularProgress size={"1.5rem"} />
                </Grid>}
              </Grid>
            </Grid>
            {props.bottom_message && (
              <Grid item xs={12}>
                <Typography
                  variant={"p"}
                  sx={{
                    fontSize: props.bottom_emphasis ? "1.5em" : "inherit",
                    color: props.bottom_emphasis && props.devView ? "white !important" : props.bottom_emphasis ? theme.palette.primary.main : "inherit",
                    fontWeight: props.bottom_emphasis ? "bold" : "inherit"
                  }}
                >
                  {props.bottom_message}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent={{"lg": "flex-end"}} spacing={2}>
            {props.customize && false && (
              <Grid item>
                  <Button
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.white.main,
                      "&:hover": {
                        color: theme.palette.white.main
                      } 
                    }}
                    onClick={() => { props.showCustomizationForm(true) }}
                    size="small"
                    variant="contained"
                    startIcon={<EmojiPeopleIcon />}
                  >
                    Schedule time with analyst
                  </Button>
              </Grid>
            )}
            {!props.hide_help && (
              <Grid item>
                  <Button
                    onClick={toggleTour}
                    sx={{
                      backgroundColor: theme.palette.secondary.main,
                      color: theme.typography.color,
                      "&:hover": {
                        color: theme.palette.white.main
                      } 
                    }}
                    size="small"
                    variant="contained"
                    startIcon={undefined}
                  >
                    Page tutorial
                  </Button>
              </Grid>
            )}
            {props.journal_entry_button && (
              <DownloadJournalEntryCSVButton preview={props.preview} companyUuid={props.company_uuid}/>
            )}
            {props.cancel_button && (
              <Grid item>
                  <Button
                    onClick={props.cancel_action}
                    size="small"
                    variant="contained"
                    startIcon={props.cancel_icon ? props.cancel_icon : <CloseIcon />}
                  >
                    {props.cancel_text ? props.cancel_text : "Close"}
                  </Button>
              </Grid>
            )}
            {props.clusterStatus && (
              <Grid item>
                <ClusterStatusChip />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
	);
}

export default TopBar;
