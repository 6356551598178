import React from "react";
import { useSelector, useDispatch } from "react-redux";
import DirectRequest from "./DirectRequest";
import * as Constants from "../../Constants";
import { setLoginNotificationSent } from "../../store/emailNotificationSlice";
import iso8601Timestamp from "../../helpers/iso8601Timestamp";

export default function LoginNotificationRequest() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector((state) => state.role.isInternal);
  const loginNotificationSent = useSelector((state) => state.emailNotification.loginNotificationSent);
  const frontendVersion = useSelector(state => state.appState.frontendVersion);
  const backendVersion = useSelector(state => state.appState.backendVersion);


  const getLoginNotificationArgs = (user) => {
    if (!isInternal) {
      const args = {
        url: Constants.SERVER_SEND_EMAIL,
        method: "POST",
        body: JSON.stringify({
          "body": `${user} logged in to ${window.location.origin}<br/><br/>Version: ${frontendVersion || ''} (${backendVersion || '0.0.000'})`,
          "subject": `${user} logged in (${window.location.origin} - ${iso8601Timestamp()})`,
          "from": "noreply@wiselayer.com",
          "toEmail": Constants.notificationList.join(","),
          "sendHTML": true
        })
      };
      return args;
    } else {
      dispatch(setLoginNotificationSent(true));
      return null;
    }
  };

  const handleEmailSent = () => {
    dispatch(setLoginNotificationSent(true));
  };

  const handleEmailError = (error) => {
    !Constants.USE_DUMMY_DATA && console.log("Error sending login notification email", error);
  };

  return (
    user && !loginNotificationSent && frontendVersion && backendVersion && (
        <DirectRequest requestArgs={getLoginNotificationArgs(user)} afterProcess={handleEmailSent} handleError={handleEmailError} handleCatchError={handleEmailError} />
    )
  )
}