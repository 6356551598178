import { createSlice } from '@reduxjs/toolkit';

export const alertsTabsSlice = createSlice({
  name: "alertsTabs",
  initialState: {
    tabs: {
      Main: 'Main',
      Unread: 'Unread',
      Archived: 'Archived',
      Irrelevant: 'Irrelevant',
      Resolved: 'Resolved',
    },
    selectedTab: 'Main',
    primaryFeed: true,
    disabled: false,
    currentType: "All",
    filters: {
      // Primary will only be used by AppsCategoriesManager to filter alerts that are in the primary feed for SummaryModal display info - everywhere else will check for inPrimaryFeed
      Primary: (alerts) => {
        return alerts.filter(alert => (alert.flagAiRelevantStatus === "NOT_SET" || alert.flagAiRelevantStatus === "RELEVANT") && !alert.flagArchive && alert.readyForCustomer && alert.inPrimaryFeed)
      },
      Main: (alerts) => {
        return alerts.filter(alert => (alert.flagAiRelevantStatus === "NOT_SET" || alert.flagAiRelevantStatus === "RELEVANT") && !alert.flagArchive && alert.readyForCustomer)
      },
      Unread: (alerts) => {
        return alerts.filter(alert => alert.flagAiRelevantStatus === "NOT_SET" && alert.readyForCustomer)
      },
      // Archived shelved in late 2023
      Archived: (alerts) => {
        return alerts.filter(alert => alert.flagArchive && alert.readyForCustomer)
      },
      Irrelevant: (alerts) => {
        return alerts.filter(alert => alert.flagAiRelevantStatus === "IRRELEVANT" && alert.readyForCustomer)
      },
      Resolved: (alerts) => {
        return alerts.filter(alert => alert.readyForCustomer)
      }
    }
  },
  reducers: {
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload
    },
    setTabsDisabled: (state, action) => {
      state.disabled = action.payload
    },
    setCurrentType: (state, action) => {
      state.currentType = action.payload
    },
    setPrimaryFeed: (state, action) => {
      state.primaryFeed = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedTab, setTabsDisabled, setCurrentType, setPrimaryFeed } = alertsTabsSlice.actions;

export default alertsTabsSlice.reducer;