import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {getTooltipTitle, getIcon, getIconButtonStyle} from "./ConfigActionUtils";
import React, {useMemo} from "react";
import { config_options } from "../../../../../store/dashboardConfigsSlice";

export const DefaultConfigActionButton = ({handleConfigUpdate, configAction, displayConfigTableRow, statusTrackerConfig, disableActions}) => {
  const newConfigValue = useMemo(() => {
    if (configAction === config_options.suppress_alert || configAction === config_options.hide_row) {
      return !displayConfigTableRow?.displayConfigOptions[configAction]
    } else if (configAction === config_options.status_tracker) {
      let currentStatus;
      let nextStatus;
      if (displayConfigTableRow && displayConfigTableRow.displayConfigOptions[configAction]) {
        currentStatus = displayConfigTableRow.displayConfigOptions[configAction];
      } else {
        currentStatus = statusTrackerConfig.defaultStatus;
      }
      const currentStatusIndex = statusTrackerConfig.allowedStatusList.indexOf(currentStatus);
      // if index === length - 1 -> nextStatus === allowedStatusList[0] otherwise allow
      nextStatus = currentStatusIndex === statusTrackerConfig.allowedStatusList.length - 1
        ? statusTrackerConfig.allowedStatusList[0]
        : statusTrackerConfig.allowedStatusList[currentStatusIndex + 1]
      return nextStatus;
    }
  }, [configAction, displayConfigTableRow, statusTrackerConfig])
  const handleClick = () => {
    if (configAction === config_options.create_entry || configAction === config_options.send_email) {
      // do nothing - for now
    } else {
      handleConfigUpdate(newConfigValue)
    }
  }
  return (
    <Tooltip title={getTooltipTitle(newConfigValue, configAction)} followCursor>
      <IconButton tabIndex={-1} sx={getIconButtonStyle(configAction)} onClick={handleClick} disabled={disableActions}>
        {getIcon(newConfigValue, configAction, disableActions)}
      </IconButton>
    </Tooltip>
  )
}

export default DefaultConfigActionButton;