import { createSlice } from "@reduxjs/toolkit";

export const previewAppSlice = createSlice({
  name: "previewApp",
  initialState: {
    tables: null,
    dashboardJson: null,
    commandUuid: null,
    alerts: [],
    error: {
      isErrored: false,
      message: "",
    },
    ready: false,
    goToPreview: false,
    loading: false,
  },
  reducers: {
    setPreviewCommandUuid: (state, action) => {
      console.log("setPreviewCommandUuid -> action.payload", action.payload)
      state.commandUuid = action.payload;
    },
    setPreviewData: (state, action) => {
      console.log("setPreviewData -> action.payload", action.payload)
      state.tables = action.payload.tables;
      state.dashboardJson = action.payload.dashboardJson;
      state.alerts = action.payload.alerts;
      state.ready = true;
      state.loading = false;
      state.startTime = null;
      state.goToPreview = true;
    },
    setPreviewError: (state, action) => {
      console.log("setPreviewError -> action.payload", action.payload)
      state.error = action.payload.error;
      state.loading = false;
      state.startTime = null;
    },
    resetPreviewApp: (state) => {
      console.log("resetPreviewApp -> resetting previewApp state");
      state.tables = null;
      state.dashboardJson = null;
      state.commandUuid = null;
      state.alerts = [];
      state.error = { isErrored: false, message: "" };
      state.ready = false;
      state.loading = false;
      state.startTime = null;
    },
    setLoading: (state, action) => {
      console.log("setLoading -> action.payload", action.payload);
      state.loading = action.payload;
      state.startTime = Date.now();
    },
    setGoToPreview: (state, action) => {
      console.log("setGoToPreview -> action.payload", action.payload);
      state.goToPreview = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPreviewCommandUuid,
  setPreviewData,
  setPreviewError,
  resetPreviewApp,
  setLoading,
  setGoToPreview,
} = previewAppSlice.actions;

export default previewAppSlice.reducer;
