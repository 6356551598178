import {useDispatch, useSelector} from "react-redux";
import { useRef, useEffect } from "react";
import { transformRows, filterRows, sortRows } from "./DashboardUtils";
import { setRows } from "../../../store/dashboardSlice";
import {setLoading} from "../../../store/dashboardFiltersSlice";

// Custom debounce function - accepts passed callback and delay -
function debounce(callback, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId); // Clear previous timeout
    }
    timeoutId = setTimeout(() => {
      callback(...args); // Execute the function after the delay
    }, delay);
  };
}

export const DashboardRowsManager = () => {
  const dispatch = useDispatch();
  const hide_row_toggle = useSelector((state) => state.dashboardFilters.displayConfigsToggleState.hide_row);
  const suppress_alert_toggle = useSelector((state) => state.dashboardFilters.displayConfigsToggleState.suppress_alert);

  const transformations = useSelector(state => state.dashboardTransform.transformations);

  const filters = useSelector(state => state.dashboardFilters.filters);

  const sortRules = useSelector(state => state.dashboardSort.rules);

  const tables = useSelector(state => state.dashboard.tables);
  const dashboardJson = useSelector(state => state.dashboard.dashboardJson);


  // needs to run a function I will supply that accepts the above filtering values and outputs a new rows object to the redux slice
  // - if any of the above values change, it needs to cancel it's previous filtering run and start a new one to save the user as many ms as possible
  const currentRunId = useRef(0); // Used to track and cancel previous runs

  // Function to handle filtering and updating rows
  const runFiltering = () => {
    currentRunId.current += 1;
    const runId = currentRunId.current;

    const transformedRows = transformRows(tables, transformations, dashboardJson, suppress_alert_toggle);
    const filteredRows = filterRows(transformedRows, filters, hide_row_toggle); // Your filtering function
    const sortedRows = sortRows(filteredRows, sortRules, dashboardJson);

    // If this run is still valid, dispatch the new rows
    if (runId === currentRunId.current) {
      dispatch(setRows(sortedRows));
      dispatch(setLoading(false));
    }
  };

  // Debounce to save the user as many ms as possible
  const debouncedRunFiltering = debounce(runFiltering, 300); // 300ms delay

  useEffect(() => {
    debouncedRunFiltering();

    // Cleanup: cancel any pending executions when the component unmounts or deps change
    return () => {
      // Since we don't store timeout in a ref, no need to explicitly cancel debounce here
    };
  }, [hide_row_toggle, suppress_alert_toggle, transformations, filters, sortRules, tables, dashboardJson]);

  return null; // No UI, just managing rows
}

export default DashboardRowsManager;