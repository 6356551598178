import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

// TODO: RecipeDynamicV2 runs these on its passed thisDevApp
//  we can do this with the preview notice as well
const getMonetary = (tags) => {
  const monetaryTag = tags.find(tag => tag.includes("monetary__"));
  if (monetaryTag) {
    return monetaryTag.replace(":", "").replace("monetary__", "");
  } else {
    return "";
  }
}
const getNextSteps = (tags) => {
  const nextStepsTag = tags.find(tag => tag.includes("next_steps__"));
  if (nextStepsTag) {
    return nextStepsTag.replace(":", "").replace("next_steps__", "")
  } else {
    return "";
  }
}

export const FDMonetaryAndNextSteps = ({ state }) => {
  const thisDevApp = state.thisDevApp;
  const tags = thisDevApp?.tags || [];
  const monetary = getMonetary(tags);
  const nextSteps = getNextSteps(tags);
  return monetary && nextSteps ? (
    <Grid item xs={12}>
      <Alert variant="outlined" severity="info">
        {monetary}: {nextSteps}
      </Alert>
    </Grid>
  ) : null;
}

export default FDMonetaryAndNextSteps;