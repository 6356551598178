import { form_fields, acct_fields, isValidAmount } from "./createEntryForm";
import CreateEntryInput from "./CreateEntryInput";
import { Grid, IconButton, Tooltip, Autocomplete, TextField, FormControl } from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import { useCallback, useMemo } from "react";

////////////////////////// Account ID
const modifyAccountIdValue = (formState, form_field, index, newValue) => {
  return {
    ...formState,
    [form_field]: [...formState[form_field].map((acctDict, i) => {
      if (i === index) {
        return {
          ...acctDict,
          [acct_fields.id]: newValue
        }
      } else {
        return acctDict;
      }
    })]
  }
}
// TODO: currently disabled - Eric 9/26
const AccountIdInput = ({accountId, setFormState, index, form_field}) => {
  const error = !accountId;
  const errorMessage = !accountId ? <span style={{ fontSize: "8px" }}>Account ID required</span> : "";

  const label = "Account ID";

  const handleUpdate = useCallback(
    (newValue) => {
      setFormState((prevFormState) => {
        const updatedFormState = modifyAccountIdValue(prevFormState, form_field, index, newValue);
        return updatedFormState;
      });
    },
    [index, form_field, setFormState] // Only re-create the function when these values change
  );

  return (
    <Grid item xs={2} sx={{ pointerEvents: "none" }}>
      <CreateEntryInput
        value={accountId}
        handleUpdate={handleUpdate}
        label={label}
        error={error}
        errorMessage={errorMessage}
      />
    </Grid>
  )
}

const AccountNameAutocomplete = ({accountName, accountNameOptions, handleUpdate, label, error, errorMessage, disabled}) => {
  return (
    <FormControl size="small" sx={{ width: "100%" }}>
      <Autocomplete
        value={accountName}
        disabled={disabled}
        disableClearable // Disables the clear icon
        clearOnEscape={false} // Prevents clearing the value on escape key press
        onChange={(event, newValue) => {
          handleUpdate(newValue);
        }}
        options={accountNameOptions}
        renderInput={(params) => (
          <TextField 
            {...params} 
            InputLabelProps={{
              shrink: true
            }} 
            InputProps={disabled ? {
              ...params.InputProps,
              endAdornment: null
            } : {...params.InputProps}}
            size="small"
            variant="standard" 
            label={label}
            error={error}
            helperText={errorMessage} 
          />
        )}
      />
    </FormControl>
  );
}

/////////////////////////// modify account name and matching account id
const modifyAccountNameValueV2 = (formState, form_field, index, newValue, newAccountId) => {
  return {
    ...formState,
    [form_field]: [...formState[form_field].map((acctDict, i) => {
      if (i === index) {
        return {
          ...acctDict,
          [acct_fields.acct]: newValue,
          [acct_fields.id]: newAccountId
        }
      } else {
        return acctDict;
      }
    })]
  }
}

////////////////////////// Account Name accountMappingConfig
const AccountNameInputV2 = ({accountName, setFormState, index, form_field, accountNameToIdMap}) => {
  const error = !accountName;
  const errorMessage = !accountName ? <span style={{ fontSize: "8px" }}>Account required</span> : "";

  const label = "Account";

  const accountNameOptions = useMemo(() => {
    if (accountNameToIdMap && (!accountName || accountNameToIdMap[accountName])) {
      return Object.keys(accountNameToIdMap);
    } else {
      console.log(`accountName: ${accountName} not found in accountNameToIdMap`, accountNameToIdMap);
      return [accountName];
    }
  }, [accountNameToIdMap, accountName])

  const disabled = useMemo(() => {
    if (accountNameToIdMap && (!accountName || accountNameToIdMap[accountName])) {
      return false;
    } else {
      // console.warn(`accountName: ${accountName} not found in accountNameToIdMap`, accountNameToIdMap);
      return true;
    }
  }, [accountNameToIdMap, accountName])

  const handleUpdate = useCallback(
    (newValue) => {
      setFormState((prevFormState) => {
        const newAccountId = accountNameToIdMap[newValue];
        const updatedFormState = modifyAccountNameValueV2(prevFormState, form_field, index, newValue, newAccountId);
        return updatedFormState;
      });
    },
    [index, form_field, setFormState] // Only re-create the function when these values change
  );

  return (
    <Grid item xs={6}>
      <AccountNameAutocomplete
        accountName={accountName}
        accountNameOptions={accountNameOptions}
        handleUpdate={handleUpdate}
        label={label}
        error={error}
        errorMessage={errorMessage}
        disabled={disabled}
      />
    </Grid>
  )
}

////////////////////////// Account Amount
const modifyAccountAmountValue = (formState, form_field, index, newValue) => {
  return {
    ...formState,
    [form_field]: [...formState[form_field].map((acctDict, i) => {
      if (i === index) {
        return {
          ...acctDict,
          [acct_fields.amt]: newValue
        }
      } else {
        return acctDict;
      }
    })]
  }
}

const AccountAmountInput = ({accountAmount, setFormState, index, form_field}) => {
  const error =  !isValidAmount(accountAmount);
  const errorMessage = !isValidAmount(accountAmount) ? <span style={{ fontSize: "8px" }}>Valid amount required</span> : "";

  const label = "Amount";
  // const startAdornment = "$"; // Eric - 10/8 -> commenting out until we can handle diff currencies

  const handleUpdate = useCallback(
    (newValue) => {
      setFormState((prevFormState) => {
        const updatedFormState = modifyAccountAmountValue(prevFormState, form_field, index, newValue);
        return updatedFormState;
      });
    },
    [index, form_field, setFormState] // Only re-create the function when these values change
  );

  return (
    <Grid item xs={5}>
      <CreateEntryInput
        value={accountAmount}
        handleUpdate={handleUpdate}
        label={label}
        error={error}
        errorMessage={errorMessage}
        // startAdornment={startAdornment} // see above
      />
    </Grid>
  )
}

////////////////////////// Remove Line
const removeLine = (formState, form_field, index) => {
  return {
    ...formState,
    [form_field]: [...formState[form_field].filter((_, i) => i !== index)]
  }
}

// TODO: currently disabled/hidden - Eric 9/26
const RemoveLineButton = ({form_field, setFormState, index}) => {
  const handleRemoveLine = () => {
    // slice index acctDict and set updated state
    setFormState((prevFormState) => {
      const updatedFormState = removeLine(prevFormState, form_field, index);
      return updatedFormState;
    })
  }
  return (
    <Grid item xs={1}>
      <Tooltip title={`Remove this ${form_field === form_fields.debitAccounts ? "debit" : "credit"} account line`} followCursor>
        <IconButton onClick={handleRemoveLine} sx={{ padding: "4px"}}>
          <RemoveIcon/>
        </IconButton>
      </Tooltip>
    </Grid>
  )
}

////////////////////////// All together:
export const AccountInput = ({acctDict, index, form_field, setFormState, accountNameToIdMap}) => {
  return (
    <>
      <AccountNameInputV2
        accountName={acctDict[acct_fields.acct]}
        setFormState={setFormState}
        index={index}
        form_field={form_field}
        accountNameToIdMap={accountNameToIdMap}
      />
      <AccountAmountInput
        accountAmount={acctDict[acct_fields.amt]}
        setFormState={setFormState}
        index={index}
        form_field={form_field}
      />
      <RemoveLineButton
        setFormState={setFormState}
        index={index}
        form_field={form_field}
      />
    </>
  )
}

export default AccountInput;