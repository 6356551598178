import Grid from "@material-ui/core/Grid";
import React from "react";
import ConfigInput from "./ConfigInputs/ConfigInput";
import DisplayNoteInput from "./ConfigInputs/DisplayNoteInput";
import {useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

export const Config = ({index}) => {
  const paramDisplayName = useSelector(state => state.alertConfigsSysAdmin.alertConfigs[index].paramDisplayName);
  const isUpdating = useSelector(state => state.alertConfigsSysAdmin.isUpdating);
  // const isRefreshing = useSelector(state => state.alertConfigsSysAdmin.isRefreshing);
  return (
    <Grid item xs={3} sx={{ height: "800px" }}>
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "relative",
        }}
      >
        {isUpdating && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              zIndex: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress/>
          </div>
        )}
        <Grid container spacing={2} sx={{height: "100%"}}>
          <Grid item xs={12}>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
                textWrap: "wrap",
                height: "54px",
                fontWeight: 500,
              }}
            >
              {paramDisplayName}
            </div>
          </Grid>
          <Grid item xs={12}>
            <ConfigInput
              index={index}
            />
          </Grid>
          <Grid item xs={12}>
            <DisplayNoteInput
              index={index}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  )
}

export default React.memo(Config);