import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";

const getLabel = (newValue, error, errorMessage) => {
  if (newValue === null) {
    return "Value"
  } else if (!error) {
    return (
      <span>Value - <i>edited</i></span>
    )
  } else {
    return (
      <span>Value - <i>{errorMessage}</i></span>
    )
  }
}

export const TextInput = ({ index, handleChange }) => {
  const newValue = useSelector((state) => state.alertConfigsSysAdmin.alertConfigs[index].newValue);
  const paramValue = useSelector((state) => state.alertConfigsSysAdmin.alertConfigs[index].paramValue);
  const error = useSelector((state) => state.alertConfigsSysAdmin.alertConfigs[index].error);
  const errorMessage = useSelector((state) => state.alertConfigsSysAdmin.alertConfigs[index].errorMessage);
  return (
    <TextField
      multiline
      rows={2}
      variant="outlined"
      label={getLabel(newValue, error, errorMessage)}
      value={newValue !== null ? newValue : paramValue}
      onChange={(e) => handleChange(e.target.value, paramValue)}
      InputLabelProps={{
        shrink: true, // Always show the label above the input
      }}
      sx={{
        width: "100%",//300, // Adjust width as needed
        '& .MuiInputBase-inputMultiline': {
          whiteSpace: 'pre-wrap', // Allows for normal wrapping at space or new lines
          overflowY: 'auto',      // Enable vertical scrolling
          wordBreak: 'break-all'  // Break words at any character (useful for long words)
        }
      }}
      error={error}
    />
  )
}

export default TextInput;