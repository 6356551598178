import React, {useEffect, useState} from "react";
import {setTabsSysAdminDisabled} from "../../store/alertsTabsSysAdminSlice";
import {setTabsDisabled} from "../../store/alertsTabsSlice";
import {useDispatch, useSelector} from "react-redux";
import {getRowClassNameForAlertFeed} from "./FeedTableUtils";
import FDGrid from "../FDGrid/FDGrid";
import Grid from "@mui/material/Grid";

const getNoRowsLabel = (sysAdmin, currentTab, alertsError, resolvedAlertsError, ) => {
  if (sysAdmin) {
    return `No ${(currentTab === "Main" || currentTab === "Unread") ? "active" : currentTab === "Resolved" ? "previous" : "removed"} alerts`;
  } else {
    if (currentTab === "Main" || currentTab === "Unread") {
      if (alertsError.isErrored) {
        return alertsError.errorMessage;
      } else {
        return "No active alerts";
      }
    } else if (currentTab === "Resolved") {
      if (resolvedAlertsError.isErrored) {
        return resolvedAlertsError.errorMessage;
      } else {
        return "No previous alerts";
      }
    } else {
      if (alertsError.isErrored) {
        return alertsError.errorMessage;
      } else {
        return "No removed alerts";
      }
    }
  }
};

export const FeedTableAlerts = (
  {
    sysAdmin,
    alerts,
    currentType,
    currentDate,
    currentFilterText,
    preview,
    columns,
  }
) => {
  const dispatch = useDispatch();
  const alertsError = useSelector((state) => state.alerts.alertsError);
  const resolvedAlertsError = useSelector((state) => state.alerts.resolvedAlertsError);
  const [rows, setRows] = useState([]);
  const disabled = useSelector((state) => sysAdmin ? state.alertsTabsSysAdmin.disabled : state.alertsTabs.disabled);

  const currentTab = useSelector((state) => sysAdmin ? state.alertsTabsSysAdmin.selectedTab : state.alertsTabs.selectedTab);
  const primaryFeed = useSelector((state) => sysAdmin ? state.alertsTabsSysAdmin.primaryFeed : state.alertsTabs.primaryFeed);

  useEffect(() => {
    // if preview - include all alerts
    if (preview) {
      setRows(alerts);
    // if not preview - filter alerts
    } else {
      const sortedAlerts = alerts;

      let new_rows = [];

      for(let i = 0; i < sortedAlerts.length; i++) {
        let include_row = true;
        if (primaryFeed && !sortedAlerts[i]["inPrimaryFeed"]) {
          include_row = false;
        }
        if(currentType !== "All" && sortedAlerts[i]["appNameDisplay"] !== currentType) {
          include_row = false;
        }
        if(currentDate !== undefined) {
          let parts = sortedAlerts[i]["flagFirstOccurrenceTimestamp"].split("/");
          let alert_date = new Date(new Date(parts[2], parts[0] - 1, parts[1]));
          let delta_date = currentDate === "Today"
            ? 1
            : currentDate === "7D"
              ? 7
              : currentDate === "14D"
                ? 14
                : currentDate === "30D"
                  ? 30
                  : 90;
          let date = new Date();
          date.setDate(date.getDate() - delta_date);
          include_row = (alert_date > date) ? include_row : false;
        }
        // Check against currentFilterText
        if (currentFilterText && include_row) { // Ensure we only check this if the row is still included
          // Convert both strings to lower case for case-insensitive comparison
          let alertText = sortedAlerts[i]["flagDescription"].toLowerCase();
          let searchText = currentFilterText.toLowerCase();

          // Check if the searchText is a substring of the alertText
          include_row = alertText.includes(searchText);
        }
        if(include_row === true) {
          new_rows.push(sortedAlerts[i]);
        }
      }
      setRows(new_rows);
    }
    // once new alerts are calculated and set to rows, enable tabs (alert filters/alert action buttons/inbox selection) - these are disabled while alerts are being updated / fetched
    dispatch(setTabsSysAdminDisabled(false))
    dispatch(setTabsDisabled(false))
  }, [alerts, currentType, currentDate, currentFilterText, primaryFeed, preview]);

  return (
    <Grid item xs={12} sx={disabled ? { pointerEvents: "none", cursor: "default" } : {}}>
      <FDGrid
        pageSize={100}
        rows={rows}
        columns={columns}
        getRowId={(row) => row.Id}
        autoHeight={true}
        localeText={{
          noRowsLabel: getNoRowsLabel(sysAdmin, currentTab, alertsError, resolvedAlertsError)
        }}
        initialState={{ pinnedColumns: sysAdmin ? { right: ["flagAiRelevantStatus", 'actions'] } : {}}}
        getRowClassName={(params) => getRowClassNameForAlertFeed(params.row, currentTab, sysAdmin, preview)}
      />
    </Grid>
  )
}

export default FeedTableAlerts;