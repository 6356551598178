import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TopBar from "../TopBar/TopBar";
import Category from "./Category";
import RequestCustomAlertForm from "./RequestCustomAlertForm"

function AppStore(props) {
  const location = useLocation();

  const userApps = useSelector((state) => state.apps.filteredList);
  const subscribedApps = useSelector((state) => state.appsSubscriptions.list);
  const categories = useSelector((state) => state.appsCategories.coverageCategories)

  return (
    <Grid container spacing={2} data-tut="reactour__Dashboard">
      <Grid item xs={12}>
        <TopBar
          hide_help={true}
          bottom_message={"WiseLayer's Wisest Notifications"}
          bottom_emphasis={true}
          devView={location.pathname !== "/notifications"}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {/* categories mapped here */}
          {userApps.length > 0 && (
            categories.filter(category => category !== "Efficiency (compliance & close)").map((category, index) => {
              return (
                <Category
                  key={index}
                  index={index}
                  apps={userApps}
                  subscribedApps={subscribedApps}
                  category={category}
                />
              )
            })
          )}
        </Grid>
      </Grid>
      <RequestCustomAlertForm/>
    </Grid>
  );
}

export default AppStore;