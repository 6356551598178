import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import new_logo from "../../assets/Wiselayer.png";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

const FooterComponent = styled("div")(({ theme }) => ({
  backgroundColor: "transparent",
  padding: "2em 2em",
  marginTop: "2em",
  "& img": {
    height: "48px",
  },
  borderTop: "1px solid " + theme.palette.border.light,

  "& a": {
    color: theme.typography.link.color,
    fontSize: "12px",
    lineHight: "18px",
  },
}));

const Version = () => {
  const backendVersion = useSelector(state => state.appState.backendVersion);
  const frontendVersion = useSelector(state => state.appState.frontendVersion);
  return (
    <Typography variant="footer" sx={{ color: "#ffffff" }}>Version:{" "}{frontendVersion ? frontendVersion : "0.0.000"}{" "}({backendVersion ? backendVersion : ""})</Typography>
  )
}

function Footer(props) {
  return (
    <FooterComponent ref={props.footerRef}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <img src={new_logo} alt={"WiseLayer"} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <RouterLink to="/">Home</RouterLink>
            </Grid>
            <Grid item xs={12}>
              <Version/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="footer">WiseLayer</Typography>
            </Grid>
            <Grid item xs={12}>
              <a href="mailto:support@wiselayer.com">support@wiselayer.com</a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FooterComponent>
  );
}

export default Footer;
