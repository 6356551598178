import { createSlice } from '@reduxjs/toolkit';

export const summaryNotificationsModalSlice = createSlice({
  name: "summaryNotificationsModal",
  initialState: {
    showModal: true,
  },
  reducers: {
    setShowModal: (state, action) => {
      state.showModal = action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const { setShowModal } = summaryNotificationsModalSlice.actions;

export default summaryNotificationsModalSlice.reducer;