import DirectRequest from './DirectRequest';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as Constants from "../../Constants";
import { setSubscriptions, setAlertConfigs, resetAlertConfigs, refreshAlertConfigs, setUpdateArgs } from '../../store/alertConfigsSysAdminSlice';

const SubscriptionsRequest = () => {
  const dispatch = useDispatch();
  const companyUuid = useSelector((state) => state.alertsSysAdmin.company.uuid);
  const subscriptionsCaptured = useSelector((state) => state.alertConfigsSysAdmin.subscriptionsCaptured);
  const subscriptionsArgs = { url: Constants.SERVER_GET_SYSADMIN_SUBBED_APPS_URL + companyUuid };

  useEffect(() => {
    if (companyUuid) {
      dispatch(resetAlertConfigs());
    }
  }, [companyUuid]);

  const handleSubscriptions = (res) => {
    dispatch(setSubscriptions({ companyUuid: companyUuid, subscriptions: res }));
  }

  return !subscriptionsCaptured && companyUuid ? (
    <DirectRequest
      requestArgs={subscriptionsArgs}
      afterProcess={handleSubscriptions}
      handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("get subscriptions error", err)}
      handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("get subscriptions catch error", err)}
    />
  ) : null;
}

const AlertConfigsRequest = () => {
  const dispatch = useDispatch();
  const companyUuid = useSelector(state => state.alertConfigsSysAdmin.companyUuid);
  const selectedAppUuid = useSelector(state => state.alertConfigsSysAdmin.selectedAppUuid);
  const alertConfigsCaptured = useSelector(state => state.alertConfigsSysAdmin.alertConfigsCaptured);
  const alertConfigsArgs = {
    url: Constants.SERVER_GET_SYSADMIN_ALERT_CONFIGS_FOR_APP_URL + companyUuid,
    method: "POST",
    body: JSON.stringify({
      developerAppUuid: selectedAppUuid
    })
  }

  useEffect(() => {
    if (selectedAppUuid && companyUuid) {
      dispatch(refreshAlertConfigs());
    }
  }, [selectedAppUuid, companyUuid]);

  const handleAlertConfigs = (res) => {
    const processedAlertConfigs = [...res.alerts]
      .sort((a, b) => a.paramDisplayName.localeCompare(b.paramDisplayName))
      .map(config => {
        return {
          ...config,
          newValue: null,
          newNote: null,
          error: false,
          errorMessage: ""
        }
      });
    dispatch(setAlertConfigs(processedAlertConfigs))
  }

  return !alertConfigsCaptured && selectedAppUuid && companyUuid ? (
    <DirectRequest
      requestArgs={alertConfigsArgs}
      afterProcess={handleAlertConfigs}
      handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("get alert configs error", err)}
      handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("get alert configs catch error", err)}
    />
  ) : null;
}

const UpdateAlertConfigsRequest = () => {
  const dispatch = useDispatch();
  const isUpdating = useSelector((state) => state.alertConfigsSysAdmin.isUpdating);
  const alertConfigsUpdateArgsArray = useSelector((state) => state.alertConfigsSysAdmin.updateArgs);

  const handleSuccessfulAlertConfigUpdate = (res) => {
    if (alertConfigsUpdateArgsArray.length === 1) {
      console.log("final alert config update request sent")
      dispatch(refreshAlertConfigs());
    } else {
      dispatch(setUpdateArgs(alertConfigsUpdateArgsArray.slice(1)))
    }
  }

  return isUpdating && alertConfigsUpdateArgsArray.length > 0 ? (
    <DirectRequest
      requestArgs={alertConfigsUpdateArgsArray[0]}
      afterProcess={handleSuccessfulAlertConfigUpdate}
      handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("update alert configs error", err)}
      handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("update alert configs catch error", err)}
    />
  ) : null;
}

export const AlertConfigsSysAdminRequest = () => {
  const isSysAdmin = useSelector((state) => state.role.isSysAdmin);
  return isSysAdmin ? (
    <>
      <SubscriptionsRequest/>
      <AlertConfigsRequest />
      <UpdateAlertConfigsRequest/>
    </>

  ) : null;
}

export default AlertConfigsSysAdminRequest;