import { useSelector, useDispatch } from "react-redux";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ShieldIcon from '@mui/icons-material/Shield';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FeedCategories from "./FeedCategories"
import {setAlertNotificationArgs} from "../../store/alertsNotificationSlice";
import {generateSummaryCategorySelectedNotificationArgs} from "../AlertFeed/FeedTableUtils";

const getNumberOfSummaryCategoryAlerts = (numberOfAlerts) => {
  if (!numberOfAlerts || numberOfAlerts < 100) {
    return numberOfAlerts || 0
  } else if (numberOfAlerts > 99) {
    return "99+"
  }
}

const summaryCategoryContainerStyleSelected = {
  paddingTop: "5px !important",
  paddingBottom: "5px !important",
  marginTop: "2px", // need to make a little vertical space so the box shadow doesn't overlap
  marginBottom: "2px", // need to make a little vertical space so the box shadow doesn't overlap
  borderRadius: "10px",
  boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.2)',
}

const summaryCategoryContainerStyleUnselected = {
  cursor: "pointer",
  paddingTop: "5px !important",
  paddingBottom: "5px !important",
  transition: 'box-shadow 0.2s ease-in-out',
  borderRadius: "10px",
  '&:hover': {
    boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.2)',
  },
}
const summaryCategoryItemStyle = { height: "2rem", paddingTop: "0px !important", paddingLeft: "0px !important" }

const getSummaryCategoryTextStyle = (category) => {
  const boldStyle = { fontSize: "1.25rem", fontWeight: 650, lineHeight: 1.6 }
  const parenStyle = { fontSize: "1.25rem", fontWeight: 550, lineHeight: 1.6 }
  // const italicStyle = { fontSize: "1.25rem", fontWeight: 550, lineHeight: 1.6, fontStyle: "italic" }
  // if (category === `Novel risks (Wisest "unknown unknowns")`) {
  //   // special case for "Novel risks (Wisest "unknown unknowns")" - 'Novel risks' is bold, 'Wisest "unknown unknowns"' is not
  //   // additionally, 'Wisest' needs to be italicized
  //   return (
  //     <Typography>
  //       <span style={boldStyle}>Novel risks</span>
  //       <span style={parenStyle}> (</span>
  //       <span style={italicStyle}>Wisest</span>
  //       <span style={parenStyle}> "unknown unknowns")</span>
  //     </Typography>
  //   );
  // } else {
    const openParenIndex = category.indexOf('(');
    const closeParenIndex = category.indexOf(')', openParenIndex);

    if (openParenIndex !== -1 && closeParenIndex !== -1) {
      // Text before the parentheses
      const categoryBefore = category.substring(0, openParenIndex);
      // Text inside the parentheses (including the parentheses themselves)
      const categoryInParentheses = category.substring(openParenIndex, closeParenIndex + 1);

      return (
        <Typography>
          <span style={boldStyle}>{categoryBefore}</span>
          <span style={parenStyle}>{categoryInParentheses}</span>
        </Typography>
      );
    } else {
      // Return the original text if no parentheses are found
      return <Typography><span style={boldStyle}>{category}</span></Typography>;
    }
  // }
}

const SelectedSummaryCategory = ({ category, selectedCategory, numberOfAlerts, handleSummaryCategorySelection }) => {
  return (
    <Grid item xs={12} container sx={summaryCategoryContainerStyleSelected}>
      <Grid item xs={12} container sx={{ cursor: "pointer"}} onClick={(e) => handleSummaryCategorySelection(category, selectedCategory)}>
        <Grid item xs={0.5} sx={summaryCategoryItemStyle} container alignItems={"center"}>
          <ExpandMoreIcon sx={{ fontSize: "1.2rem"}}/>
        </Grid>
        <Grid item xs={0.5} sx={summaryCategoryItemStyle} container alignItems={"center"}>
          <ShieldIcon sx={{ fill: numberOfAlerts > 0 ? "rgba(245, 215, 66, 1.0)" : "green"}}/>
        </Grid>
        <Grid item xs={1} sx={summaryCategoryItemStyle} container justifyContent={"center"}>
          <Typography variant={"h6"}>{getNumberOfSummaryCategoryAlerts(numberOfAlerts)}</Typography>
        </Grid>
        <Grid item xs={10} sx={summaryCategoryItemStyle} container alignItems={"center"}>
          <Typography variant={"h6"} sx={{ marginLeft: "10px"}}>{getSummaryCategoryTextStyle(category)}</Typography>
        </Grid>
      </Grid>
      <FeedCategories selectedCategory={selectedCategory}/>
    </Grid>
  )
}
const SummaryCategory = ({ category, selectedCategory, setSelectedCategory }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector((state) => state.role.isInternal);

  const primaryFeed = useSelector((state) => state.alertsTabs.primaryFeed);
  const summaryCategories = useSelector((state) => state.appsCategories.summaryCategories);
  const numberOfAlerts = primaryFeed ? summaryCategories[category].numberOfAlerts.primaryFeed : summaryCategories[category].numberOfAlerts.main;

  const handleSummaryCategorySelection = (category, selectedCategory) => {
    // console.log("handleCategorySelection - category:", category)
    if (category === selectedCategory) {
      setSelectedCategory(null)
    } else {
      setSelectedCategory(category)
      if (!isInternal) {
        const tempSummaryCategorySelectedNotificationArgs = generateSummaryCategorySelectedNotificationArgs(user, category)
        dispatch(setAlertNotificationArgs(tempSummaryCategorySelectedNotificationArgs))
      }
    }
  }

  return selectedCategory !== category ? (
    <>
      <Grid item xs={12} container alignItems={"center"} sx={selectedCategory !== category ? summaryCategoryContainerStyleUnselected : summaryCategoryContainerStyleSelected} onClick={(e) => handleSummaryCategorySelection(category, selectedCategory)}>
        <Grid item xs={0.5} sx={summaryCategoryItemStyle} container alignItems={"center"}>
          <ChevronRightIcon sx={{ fontSize: "1.2rem"}}/>
        </Grid>
        <Grid item xs={0.5} sx={summaryCategoryItemStyle} container alignItems={"center"}>
          <ShieldIcon sx={{ fill: numberOfAlerts > 0 ? "rgba(245, 215, 66, 1.0)" : "green"}}/>
        </Grid>
        <Grid item xs={1} sx={summaryCategoryItemStyle} container justifyContent={"center"}>
          <Typography variant={"h6"}>{getNumberOfSummaryCategoryAlerts(numberOfAlerts)}</Typography>
        </Grid>
        <Grid item xs={10} sx={summaryCategoryItemStyle} container alignItems={"center"}>
          <Typography variant={"h6"} sx={{ marginLeft: "10px"}}>{getSummaryCategoryTextStyle(category)}</Typography>
        </Grid>
      </Grid>
    </>
  ) : (
    <SelectedSummaryCategory category={category} selectedCategory={selectedCategory} numberOfAlerts={numberOfAlerts} handleSummaryCategorySelection={handleSummaryCategorySelection}/>
  )
}

export const SummaryOverview = ({ selectedCategory, setSelectedCategory }) => {
  const categories = useSelector((state) => state.appsCategories.coverageCategories)

  return (
    <>
      {categories.filter(category => category !== "Efficiency (compliance & close)").map((category) => (
        <SummaryCategory category={category} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} key={category}/>
      ))}
    </>
  )
}

export default SummaryOverview;