import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeToError,
  removeCommand,
  commandsSelectors,
} from "../../store/commandsSlice";
import DirectRequest from "../requests/DirectRequest";
import * as Constants from "../../Constants";

function GenericCommandHandler(props) {
  const dispatch = useDispatch();

  const [commandUuid, setCommandUuid] = useState();
  const [message, setMessage] = useState();
  const command = useSelector((selectorState) =>
    commandsSelectors.selectById(selectorState, commandUuid)
  );

  const [ssePingRequestArgs, setSsePingRequestArgs] = useState();

  useEffect(() => {
    if (!props.sse) {
      return;
    }
    props.sse.addEventListener(props.handlerName, function (m) {
      prepareGeneric(m);
      const ssePingBody = [JSON.parse(m.data).commandUuid];
      setSsePingRequestArgs({
        url: Constants.SERVER_PING_SSE_URL,
        method: "POST",
        body: JSON.stringify(ssePingBody),
      })

    });
  }, [props.sse]);

  useEffect(() => {
    finishGeneric();
  }, [command]);

  useEffect(() => {
    if (message) {
      console.log("SSE message ->", message);
    }
  }, [message]);

  // Get and set the commandUuid so we can try to find a matching command in the store
  function prepareGeneric(m) {
    setMessage(m);

    const dataJson = JSON.parse(m.data);
    // console.log(dataJson);
    setCommandUuid(dataJson.commandUuid);
  }
  // Once we've looked for the command, handle the result based on whether it exists or not
  function finishGeneric() {
    if (!message) {
      return;
    }

    const dataJson = JSON.parse(message.data);
    const commandStatus = dataJson?.status;

    if (!command) {
      // We couldn't find the command
      setCommandUuid(null);
      return;
    } else if (!commandStatus) {
      // Server response doesn't contain a status and is bad/malformed
      console.log("bad server response");
      console.log(dataJson);
      dispatch(changeToError(commandUuid, "Malformed server response"));
      return;
    } else if (commandStatus !== "succeeded") {
      // Server returned an error of some sort
      console.log("command with id", commandUuid, "returned error");
      let response = "Malformed server response";
      if (dataJson.statusMessage && dataJson.statusMessage !== "") response = dataJson.statusMessage;
      dispatch(changeToError(commandUuid, response));
    } else {
      props.finishHandler(commandUuid);
    }

    setCommandUuid(null);
    setMessage(null);
  }

  return (
    <>
      <DirectRequest
        requestArgs={ssePingRequestArgs}
        afterProcess={() => {}}
        handleError={() => console.log("SSE - GenericCommandHandler ping failed - server error")}
        handleCatchError={() => console.log("SSE - GenericCommandHandler ping failed - catch error")}
      />
    </>
  )//null;
}

export default GenericCommandHandler;
