import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from "@mui/material/FormControl";
import {useSelector} from "react-redux";

const getLabel = (newValue, error, errorMessage) => {
if (newValue === null) {
    return "Value"
  } else if (!error) {
    return (
      <span>Value - <i>edited</i></span>
    )
  } else {
    return (
      <span>Value - <i>{errorMessage}</i></span>
    )
  }
}

export const DateInput = ({ index, handleChange }) => {
  const newValue = useSelector((state) => state.alertConfigsSysAdmin.alertConfigs[index].newValue);
  const paramValue = useSelector((state) => state.alertConfigsSysAdmin.alertConfigs[index].paramValue);
  const error = useSelector((state) => state.alertConfigsSysAdmin.alertConfigs[index].error);
  const errorMessage = useSelector((state) => state.alertConfigsSysAdmin.alertConfigs[index].errorMessage);
  const handleDateSelection = (newValue) => {
    console.log("DateInput handleDateSelection newValue:", newValue);
    const formattedValue = newValue ? newValue.format("MM/DD/YYYY") : null;
    handleChange(formattedValue, paramValue);
  };
  return (
    <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          labelId={"alert-config-date-label_" + index}
          id={"alert-config-date_" + index}
          fullWidth
          label={getLabel(newValue, error, errorMessage)}
          inputFormat="MM/DD/YYYY"
          value={newValue !== null ? newValue : paramValue}
          onChange={handleDateSelection}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </FormControl>
  );
}

export default DateInput