import * as Constants from "../../Constants";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import iso8601Timestamp from "../../helpers/iso8601Timestamp";

export const viewAlertText = {
  APP: "in app",
  EMAIL: "email link",
  ALERT_MESSAGE: "alert message link"
}

export const viewAlertTypes = {
  APP: "APP",
  EMAIL: "EMAIL",
  ALERT_MESSAGE: "ALERT_MESSAGE"
}

export const generateAlertViewArgs = (alert, user, viewAlertType) => {
  const sysAdminLink = `${window.location.origin}/redirect/${alert.developerAppUuid}/${alert.uuid}/${alert.companyUuid}`//"redirect/:dashboardID/:alertID/:companyID"
  const alertViewArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `<strong>${user}</strong> viewed alert via ${viewAlertText[viewAlertType]} click on ${window.location.origin}<br/><br/>Alert:<br/>${alert.appNameDisplay}<br/>${alert.flagDescription}<br/><br/>SysAdmin Link:<br/>${sysAdminLink}<br/><br/>`,
      "subject": `Alert viewed via ${viewAlertText[viewAlertType]} click by ${user} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    }),
  }
  return alertViewArgs;
}

export const generateOrdersDashboardViewArgs = (user, thisDevApp) => {
  const ordersDashboardViewArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `<strong>${user}</strong> viewed ${thisDevApp.fullName} dashboard on ${window.location.origin}<br/><br/>App uuid:<br/>${thisDevApp.uuid}<br/><br/>`,
      "subject": `${thisDevApp.fullName} dashboard viewed by ${user} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    }),
  }
  return ordersDashboardViewArgs;
}

export const generateDeadLinkArgs = (user) => {
  const deadLinkArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `<strong>${user}</strong> clicked a dead alert link (the alert has been resolved since the link was sent to them) on ${window.location.origin}<br/><br/>Dead link:<br/>${window.location.href}<br/><br/>`,
      "subject": `Dead alert link viewed by ${user} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    }),
  }
  return deadLinkArgs;
}

export const generateDeadAlertMessageLinkArgs = (user) => {
  const deadAlertMessageLinkArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `<strong>${user}</strong> clicked a dead alert message link (the alert has been resolved since the link was sent to them) on ${window.location.origin}<br/><br/>Dead link:<br/>${window.location.href}<br/><br/>`,
      "subject": `Dead alert message link viewed by ${user} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    }),
  }
  return deadAlertMessageLinkArgs;
}

const retrievingText = "Retrieving details";
// below for case when user clicks alert link that has since been resolved (EmailRedirect) - the uuid changes when this occurs and we don't do dashboard view for resolved alerts - Eric 03/14/24
const resolvedText = "Congratulations, this alert has been resolved in its source system! You're being redirected";

export const RedirectLoader = ({resolved}) => {
  return (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: "30vh" }}>
        <Grid item xs={12} style={{textAlign: "center", alignItems: "center"}}>
          <Typography sx={{marginBottom: "1rem"}}>{!resolved ? retrievingText : resolvedText}<span className="bouncing-dots"><span
            className="dot" style={{ marginLeft: "2px"}}>.</span><span className="dot" style={{ marginLeft: "2px"}}>.</span><span className="dot" style={{ marginLeft: "2px"}}>.</span></span></Typography>
          <div className="lds-grid-small">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}