import { useDispatch } from 'react-redux';
import { updateConfigValue } from '../../../../../store/alertConfigsSysAdminSlice';
import { generateUpdate } from './configInputUtils';
import TextInput from "./TextInput";
import BooleanInput from "./BooleanInput";
import DateInput from "./DateInput";
import DropdownInput from "./DropdownInput";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";

export const ConfigInput = ({ index }) => {
  const dispatch = useDispatch();
  const type = useSelector((state) => state.alertConfigsSysAdmin.alertConfigs[index].alertConfigParamType);
  const handleChange = (newValue, paramValue) => {
    console.log("ConfigInput newValue:", newValue)
    dispatch(updateConfigValue(generateUpdate(newValue, paramValue, index, type)));
  }
  return (
    <Grid container alignItems={"center"} sx={{ width: "100%", height: "70px" }}>
      <Grid item xs={12}>
        {(type === "TEXT" || type === "INTEGER" || type === "FLOAT") && (
          <TextInput
            index={index}
            handleChange={handleChange}
          />
        )}
        {type === "BOOLEAN" && (
          <BooleanInput
            index={index}
            handleChange={handleChange}
          />
        )}
        {type === "DATE" && (
          <DateInput
            index={index}
            handleChange={handleChange}
          />
        )}
        {type === "DROPDOWN" && (
          <DropdownInput
            index={index}
            handleChange={handleChange}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default ConfigInput;