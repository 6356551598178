import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { SiteLanguage } from "../../../Constants";

export const FDPreviewAlert = ({ state, layout }) => {
  const [open, setOpen] = useState(true);
  return state.preview && open ? (
    <Grid item xs={12}>
      <Alert severity="info" action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            setOpen(false);
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>}>
        This is a preview of the {layout.title ? layout.title : ""}{" "}
        {SiteLanguage["Data app"].toLowerCase()} using dummy data
      </Alert>
    </Grid>
  ) : null;
}

export default FDPreviewAlert;