import { createSlice } from '@reduxjs/toolkit';

const initialDashboardSortState = {
  rules: {}
}

export const dashboardSortSlice = createSlice({
  name: "dashboardSort",
  initialState: initialDashboardSortState,
  reducers: {
    setUpSortRules: (state, action) => {
      console.log("dashboardSortSlice - setUpSort", action.payload);
      const { dashboardJson } = action.payload;
      const newRules = {};
      Object.entries(dashboardJson.components).forEach(([objectName, component]) => {
        if (component.type && component.type === "table" && component.sortConfig) {
          newRules[component.sourceTable] = component.sortConfig;
        }
      })
      state.rules = newRules;
    },
    resetDashboardSortSlice: (state, action) => {
      console.log("dashboardSortSlice - resetDashboardSortSlice")
      return initialDashboardSortState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUpSortRules,
  resetDashboardSortSlice
} = dashboardSortSlice.actions;

export default dashboardSortSlice.reducer;