import {useDispatch, useSelector} from "react-redux";
import {
  builders,
  dataSources,
  setActiveBuilder,
  setActiveDataSource,
  setSandboxDestination
} from "../../store/appBuilderSlice";
import AppBar from "../AppBar/AppBar";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import React, {useLayoutEffect, useState} from "react";
import {PreviewButton, ResetPreviewButton} from "./AppBuilderButtons";
import Typography from "@mui/material/Typography";


const BuilderSelection = () => {
  const dispatch = useDispatch();
  const activeBuilder = useSelector((state) => state.appBuilder.activeBuilder);

  const changeActiveBuilder = (builder) => {
    dispatch(setActiveBuilder({activeBuilder: builder}));
  };

  return (
    <AppBar
      builders={builders}
      functional={true}
      changeActiveBuilder={changeActiveBuilder}
      activeBuilder={activeBuilder}
      developerCreate
    />
  );
}

const DataSourceDropdown = ({ options, selectedOption, handleSelection }) => {
  const loading = useSelector((state) => state.previewApp.loading);
  const previewReady = useSelector((state) => state.previewApp.ready);
  const previewError = useSelector((state) => state.previewApp.error.isErrored);
  return (
    <FormControl sx={{ width: "15rem" }}>
      <InputLabel id="appbuilder-developer-select-label">
        Preview data source
      </InputLabel>
      <Select
        labelId="appbuilder-developer-select-label"
        id="appbuilder-developer-select"
        label="Preview data source"
        margin="dense"
        onChange={handleSelection}
        value={selectedOption ? selectedOption : ""}
        disabled={loading || previewReady || previewError}
      >
        {options.map((option, index) => {
          return (
            <MenuItem value={option} key={index}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

const DataSourceSelection = () => {
  const dispatch = useDispatch();
  const activeDataSource = useSelector((state) => state.appBuilder.activeDataSource);

  const changeActiveDataSource = (event) => {
    console.log("changeActiveDataSource", event.target.value)
    dispatch(setActiveDataSource({activeDataSource: event.target.value}));
  }

  return (
    <DataSourceDropdown
      options={dataSources}
      selectedOption={activeDataSource}
      handleSelection={changeActiveDataSource}
    />
  );
}

const SandboxDestinationDropdown = ({ activeDataSource, handleSelection, sandboxDestination}) => {
  const sandboxDestinations = useSelector((state) => state.appBuilder.sandboxDestinations);
  const loading = useSelector((state) => state.previewApp.loading);
  const previewReady = useSelector((state) => state.previewApp.ready);
  const previewError = useSelector((state) => state.previewApp.error.isErrored);
  console.log("sandboxDestinations", sandboxDestinations);
  console.log("activeDataSource", activeDataSource)
  return sandboxDestinations ? (
    <FormControl sx={{ width: "15rem" }}>
      <InputLabel id="appbuilder-developer-select-label">
        Preview data set
      </InputLabel>
      <Select
        labelId="appbuilder-developer-select-label"
        id="appbuilder-developer-select"
        label="Preview data set"
        margin="dense"
        onChange={handleSelection}
        value={sandboxDestination}
        disabled={loading || previewReady || previewError}
      >
        <MenuItem value={"Default"} key={"default"}>
          Default
        </MenuItem>
        {[...sandboxDestinations[activeDataSource]].sort((a, b) => a.localeCompare(b)).map((destination, index) => {
          return (
            <MenuItem value={destination} key={index}>
              {destination}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  ) : null;

}

const SandboxDestinationSelection = () => {
  const dispatch = useDispatch();
  const activeDataSource = useSelector((state) => state.appBuilder.activeDataSource);
  const sandboxDestination = useSelector((state) => state.appBuilder.sandboxDestination);

  const changeSandboxDestination = (event) => {
    dispatch(setSandboxDestination({sandboxDestination: event.target.value}));
  }

  return (
    <SandboxDestinationDropdown
      activeDataSource={activeDataSource}
      handleSelection={changeSandboxDestination}
      sandboxDestination={sandboxDestination}
    />
  );
}

const RunTimer = ({startTime}) => {
  const [duration, setDuration] = useState(Date.now() - startTime);
  useLayoutEffect(() => {
    const interval = setInterval(() => {
      setDuration(Date.now() - startTime);
    }, 1000);

    return () => clearInterval(interval);
  }, [])

  return (
    <>
      <Typography>{"Run duration: "}{duration / 60000 >= 1 ? `${Math.floor(duration / 60000)}m ` : ""}{`${Math.floor((duration % 60000) / 1000)}s`}</Typography>
    </>
  )
}

const PreviewRunTimer = () => {
  const startTime = useSelector((state) => state.previewApp.startTime);
  console.log("PreviewRunTimer -> startTime", startTime);
  return startTime ? (
    <RunTimer startTime={startTime} />
  ) : null
}

export const AppBuilderSettings = () => {
  return (
    <Grid container spacing={4} alignItems={"center"}>
      <Grid item>
        {/*  system selection */}
        <DataSourceSelection />
      </Grid>
      <Grid item>
        {/*  preview data source selection */}
        <SandboxDestinationSelection />
      </Grid>
      <Grid item>
        <PreviewButton />
      </Grid>
      <Grid item>
        <ResetPreviewButton />
      </Grid>
      <Grid item>
        <PreviewRunTimer />
      </Grid>
    </Grid>
  );

}

export default AppBuilderSettings;