import React, { useState, useEffect } from "react";
import DirectRequest from "../../API/requests/DirectRequest";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as Constants from "../../Constants";
import {SummaryEmailForm, SummaryEmailOptIn} from "../Settings/Account";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {useTheme} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

/*
customerMetadata res:
{
        "customerMetadata": {
            "uuid": "cdat_##################",
            "companyUuid": "comp_##################",
            "name": "comp_################_metadata",
            "createdBy": "name@domain.com",
            "dashboardTheme": null,
            "stripeCustomerUuid": null,
            "postSetupComplete": true,
            "alertNotifyUserEmail": "name@domain.com",
            "optOutOfAlertSummaryEmail": false,
            "selectedConnectorNames": [
                "quickbooks"
            ],
            "databricksDevPreviewJobId": null
        }
    }
*/

const DisplayProfitabilityToggle = ({selectedCompanyMetadata, refreshSelectedCompanyMetadata}) => {
  const [displayProfitabilityArgs, setDisplayProfitabilityArgs] = useState(null)
  const handleChange = () => {
    const tempArgs = {
      url: Constants.SERVER_SYSADMIN_POST_SET_DISPLAY_PROFITABILITY_URL + selectedCompanyMetadata.companyUuid,
      method: "POST",
      body: JSON.stringify({ displayProfitability: !selectedCompanyMetadata.displayProfitability })
    }
    setDisplayProfitabilityArgs(tempArgs);
  }

  const handleSuccess = () => {
    refreshSelectedCompanyMetadata();
    setDisplayProfitabilityArgs(null);
  }

  return (
    <>
      <DirectRequest
        requestArgs={displayProfitabilityArgs}
        afterProcess={handleSuccess}
        handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("error saving opt out of alert summary email")}
        handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("catch error saving opt out of alert summary email")}
      />
      <Grid item xs={12} container alignItems={"center"} spacing={1} sx={{ height: "65px"}}>
        <Grid item>
          Display profitability:
        </Grid>
        <Grid item>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch checked={selectedCompanyMetadata.displayProfitability} onChange={handleChange} />
              }
              label={selectedCompanyMetadata.displayProfitability ? "Enabled" : "Disabled"}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  )
}

const DisplayProfitabilityValue = ({selectedCompanyMetadata, refreshSelectedCompanyMetadata}) => {
  const theme = useTheme()
  const [formState, setFormState] = useState({
    setDisplayProfitabilityValueArgs: null,
    edit: false,
    input: ""
  })

  const handleInput = (e) => {
    setFormState(prevFormState => ({ ...prevFormState, input: e.target.value }))
  }

  const toggleEdit = () => {
    setFormState(prevFormState => ({ edit: !prevFormState.edit, input: "", setDisplayProfitabilityValueArgs: null }))
  }

  const handleSave = () => {
    const tempArgs = {
      url: Constants.SERVER_SYSADMIN_POST_SET_DISPLAY_PROFITABILITY_VALUE_URL + selectedCompanyMetadata.companyUuid,
      method: "POST",
      body: JSON.stringify({ displayProfitabilityValue: formState.input })
    }
    setFormState(prevFormState => ({ ...prevFormState, setDisplayProfitabilityValueArgs: tempArgs }))
  }

  const handlePostSave = (res) => {
    refreshSelectedCompanyMetadata();
    setFormState({
      setDisplayProfitabilityValueArgs: null,
      edit: false,
      input: ""
    })
  }

  return (
    <>
      <DirectRequest
        requestArgs={formState.setDisplayProfitabilityValueArgs}
        afterProcess={handlePostSave}
        handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("error saving alert summary email")}
        handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("catch error saving alert summary email")}
      />
      <Grid item xs={12} container alignItems={"center"} spacing={1} sx={{ height: "65px"}}>
        <Grid item>
          <Typography variant={"p"}>Display profitability value:&nbsp;&nbsp;<strong>{selectedCompanyMetadata.displayProfitabilityValue}</strong></Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={toggleEdit} size={"small"}>
            <EditIcon sx={formState.edit ? { color: theme.palette.primary.main } : {}}/>
          </IconButton>
        </Grid>
      </Grid>
      {formState.edit && (
        <Grid item xs={12} container alignItems={"center"} spacing={1}>
          <Grid item>
            <Typography variant={"p"}>Update display profitability value:</Typography>
          </Grid>
          <Grid item>
            <TextField
              size={"small"}
              onChange={handleInput}
            />
          </Grid>
          <Grid item>
            <Button size={"small"} variant={"contained"} onClick={handleSave}>Save</Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export const SysAdminAccount = ({selectedCompany}) => {
  const [selectedCompanyMetadataArgs, setSelectedCompanyMetadataArgs] = useState();
  const [selectedCompanyMetadata, setSelectedCompanyMetadata] = useState();

  useEffect(() => {
    if (selectedCompany && selectedCompany.uuid) {
      if (selectedCompany.name) console.log("selected company", selectedCompany.name);
      setSelectedCompanyMetadataArgs({ url: Constants.SERVER_GET_SYSADMIN_COMPANY_METADATA_URL + selectedCompany.uuid })
    }
  }, [selectedCompany])

  const handleSelectedCompanyMetadata = (res) => {
    console.log("selected company metadata", res);
    setSelectedCompanyMetadata(res.customerMetadata)
  }

  const refreshSelectedCompanyMetadata = () => {
    setSelectedCompanyMetadataArgs({ url: Constants.SERVER_GET_SYSADMIN_COMPANY_METADATA_URL + selectedCompany.uuid })
  }

  return (
    <>
      <DirectRequest
        requestArgs={selectedCompanyMetadataArgs}
        afterProcess={handleSelectedCompanyMetadata}
        handleError={(err) => console.log("error getting selected company metadata", err)}
        handleCatchError={(err) => console.log("catch error getting selected company metadata", err)}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant={"h6"}>Company account settings</Typography>
        </Grid>
        {selectedCompanyMetadata && (
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} sx={{ height: "65px"}} container alignItems={"center"} spacing={1}>
              <Grid item>
                <Typography variant={"p"}>Company:&nbsp;&nbsp;<strong>{selectedCompany.name}</strong></Typography>
              </Grid>
            </Grid>
            <SummaryEmailForm
              role={selectedCompanyMetadata}
              sysAdmin={true}
              refreshSelectedCompanyMetadata={refreshSelectedCompanyMetadata}
            />
            <SummaryEmailOptIn
              role={selectedCompanyMetadata}
              sysAdmin={true}
              refreshSelectedCompanyMetadata={refreshSelectedCompanyMetadata}
            />
            <DisplayProfitabilityToggle
              selectedCompanyMetadata={selectedCompanyMetadata}
              refreshSelectedCompanyMetadata={refreshSelectedCompanyMetadata}
            />
            <DisplayProfitabilityValue
              selectedCompanyMetadata={selectedCompanyMetadata}
              refreshSelectedCompanyMetadata={refreshSelectedCompanyMetadata}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default SysAdminAccount;