import React, { useState, useEffect, useRef, forwardRef } from 'react';
import DetailPanelGrid from "./DetailPanelGrid";
import CircularProgress from "@mui/material/CircularProgress";
import {useTheme} from '@mui/material/styles';

const DetailPanelLoader = ({row}) => {
  const theme = useTheme();
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.secondary.main,
        borderBottom: `1px solid rgba(224, 224, 224, 1)`, // Same border color
      }}
    >
      <CircularProgress/>
    </div>
  )
}

export const DetailPanel = ({ row, setDetailPanelHeights, parentGridRef, detailPanelDisplayNameOrder, detailPanelDisplayNameColumn }) => {
  const detailPanelRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  // generates identical columns as parent table by accessing its columns via prentGridRef - these are mapped over
  // then it uses a column's detail_config (if present) along with table's detailPanelDisplayNameOrder and detailPanelDisplayNameColumn to construct rows
  useEffect(() => {
    if (parentGridRef.current) {
      const columnState = parentGridRef.current.getAllColumns();
      const newColumns = [];
      const newColumnVisibilityModel = {};
      console.log("columnState", columnState);
      columnState.forEach(col => {
        newColumns.push({
          field: col.field,
          headerName: col.headerName,
          width: col.computedWidth,
          cellClassName: col.cellClassName ? col.cellClassName : ""
        })
        if (col.hidden) {
          newColumnVisibilityModel[col.field] = false;
        }
      })
      //console.log("DetailPanel - newColumns", newColumns);
      //console.log("DetailPanel - newColumnVisibilityModels", newColumnVisibilityModel);
      setColumns(newColumns);
      setColumnVisibilityModel(newColumnVisibilityModel);
      //console.log("columnState", columnState);
      //console.log("row", row);
      //console.log("detailPanelDisplayNameOrder", detailPanelDisplayNameOrder);
      const newRows = [];
      detailPanelDisplayNameOrder.forEach((displayName, index) => {
        const newRow = { row_index: index };
        columnState.forEach(column => {
          if (column.field === detailPanelDisplayNameColumn) {
            newRow[column.field] = displayName;
          } else {
            newRow[column.field] = "";
          }
        });
        columnState.forEach(column => {
          if (column.detail_config &&
              column.detail_config.displayName &&
              column.detail_config.displayName === displayName) {
            newRow[column.detail_config.putValueUnder] = row[column.field];
          }
        })
        console.log(`${displayName} - newRow`, newRow);
        newRows.push(newRow);
      })
      setRows(newRows);
    }
  }, [parentGridRef]);

  useEffect(() => {
    if (rows.length > 0 && detailPanelRef.current) {
      // console.log("detailPanelRef.current", detailPanelRef.current)
      setTimeout(() => {
        const height = detailPanelRef.current.getBoundingClientRect().height;
        // console.log("DetailPanel - height", height);
        setDetailPanelHeights(prevHeights => {
          const newHeights = {...prevHeights};
          newHeights[row.row_index] = height;
          return newHeights
        })
        setLoading(false);
      }, 1000)
    }
  }, [rows]);

  const HiddenDetailPanel = forwardRef(({ rows, columns, columnVisibilityModel }, ref) => {
    return (
      <div ref={ref} style={{
        position: 'absolute',
        visibility: 'hidden',
        zIndex: -10,
        height: 'auto',
        width: "100%",
        pointerEvents: "none",
        top: -10000,
        right: -10000
      }}>
        <DetailPanelGrid
          rows={rows}
          columns={columns}
          getRowId={row => row.row_index}
          columnVisibilityModel={columnVisibilityModel}
          // ... other props
        />
      </div>
    )
  });

  return (
    <>
      {loading && rows.length > 0 && <HiddenDetailPanel rows={rows} columns={columns} columnVisibilityModel={columnVisibilityModel} ref={detailPanelRef}/>}
      {loading && <DetailPanelLoader row={row}/>}
      {!loading && <DetailPanelGrid columns={columns} rows={rows} columnVisibilityModel={columnVisibilityModel}/>}
    </>
  );
};