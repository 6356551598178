import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../Constants";

// 2 properties:
// 1: hasSyncedAtLeastOnce: object - key: source name, value: boolean ( ex: { "quickbooks": true, "netsuite": false } )
// 2: syncedNonEmptyTables: array of strings (each string is a uuid corresponding to app reuiredSourceTables)

export const syncStatusSlice = createSlice({
  name: "syncStatus",
  initialState: {
    // hasSyncedAtLeastOnce: false,
    hasSyncedAtLeastOnce: {},
    hasReceivedSyncedNonEmptyTables: false,
    syncedNonEmptyTables: [],
  },
  reducers: {
    setSyncStatus: (state, action) => {
      state.hasSyncedAtLeastOnce = action.payload.hasSyncedAtLeastOnce;
      state.hasReceivedSyncedNonEmptyTables = true;
      state.syncedNonEmptyTables = action.payload.syncedNonEmptyTables;
    },
    setHasSyncedAtLeastOnce: (state, action) => {
      state.hasSyncedAtLeastOnce = action.payload;
    },
    setSyncedNonEmptyTables: (state, action) => {
      state.hasReceivedSyncedNonEmptyTables = true;
      state.syncedNonEmptyTables = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSyncStatus, setHasSyncedAtLeastOnce, setSyncedNonEmptyTables } = syncStatusSlice.actions;

export default syncStatusSlice.reducer;
