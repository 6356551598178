import { Tooltip, IconButton, Grid, Button } from "@mui/material";
import { form_fields } from "./createEntryForm";
import AddIcon from "@mui/icons-material/Add";

// TODO: currently disabled/hidden - Eric 9/26
export const AddLineButton = ({form_field, setFormState, addLine}) => {
  return (
    <>
      <Grid item xs={12}>
        <Tooltip title={`Add ${form_field === form_fields.debitAccounts ? "debit" : "credit"} account line`} followCursor>
          <IconButton onClick={() => addLine(form_field, setFormState)} sx={{ padding: "4px"}}>
            <AddIcon/>
          </IconButton>
        </Tooltip>
      </Grid>
    </>
  )
}

export default AddLineButton;