import React, { useState, useEffect } from 'react';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import { useTheme } from '@mui/material/styles';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { SiteLanguage } from "../../../Constants"

HighchartsExporting(Highcharts);
NoDataToDisplay(Highcharts);

function FDPie(props) {
	const theme = useTheme();
	const chartTheme = theme.palette.chartTheme;
	console.log("FDPie", chartTheme);
	Highcharts.setOptions({
		chart: {
			style: {
				fontFamily: theme.typography.fontFamily
			}
		},
		colors: (props.themeSample && props.themeColors)
			? props.themeColors
			: props.colors
			? props.colors
			: [chartTheme.chart1.color, chartTheme.chart2.color, chartTheme.chart3.color, chartTheme.chart4.color, chartTheme.chart5.color, chartTheme.chart6.color, chartTheme.chart7.color, chartTheme.chart8.color],
		xAxis: {
			labels: {
				style: {
					fontFamily: theme.typography.fontFamily,
					color: theme.typography.color,
				}
			}
		},
		yAxis: {
			labels: {
				style: {
					fontFamily: theme.typography.fontFamily,
					color: theme.typography.color,
				}
			}
		},
		legend: {
			itemStyle: {
				fontFamily: theme.typography.fontFamily,
				color: theme.typography.color,
			}
		},
		plotOptions: {
			series: {
				dataLabels: {
					style: {
						fontFamily: theme.typography.fontFamily,
						color: theme.typography.color,
					}
				}
			}
		},
		tooltip: {
			style: {
				fontFamily: theme.typography.fontFamily,
				color: theme.typography.color,
			}
		},
	});
	const [data, setData] = useState([]);
	
	const formatter = (props.formatting && props.formatting.currency ? "$" : "") + "{y:,." + (props.formatting && props.formatting.digits ? props.formatting.digits : 0) + "f}" + (props.formatting && props.formatting.percent ? "%" : "");

	const options = {
	    chart: {
	        type: 'pie',
	        height: props.height !== undefined ? props.height : 400
	    },
	    title: {
	        text: null
	    },
			tooltip: {
							pointFormat: props.themeSample ? () => {} : '{series.name}: <b>{point.percentage:.1f}%</b>'
			},
	    credits: {
	    	enabled: false
	    },
	    plotOptions: {
	        pie: {
	            allowPointSelect: true,
	            cursor: 'pointer',
	            dataLabels: {
	                enabled: props.labels ? props.labels : true,
	                format: props.themeSample ? '{point.name}' : '<b>{point.name}</b>: {point.percentage:.1f} %',
	            },
	            showInLegend: props.legend !== undefined ? props.legend : true,
	            size: 100,
	            innerSize: props.innerSize ? props.innerSize : "70%",
							point: props.themeSample ? {
								events: {
									legendItemClick: function (e) {
										e.preventDefault();
										console.log("legendItemClick", this.options.name);
										props.handleChartColorControlSelection(this.options.name);
									},
								}
							} : {},
	        }
	    },
			series: props.hasData ? [{
	        name: props.label,
	        colorByPoint: true,
	        data: data
	    }] : [],
			lang: {
				noData: SiteLanguage.MissingData
			},
			noData: {
				style: {
					fontWeight: theme.typography.p.fontWeight,
					fontSize: theme.typography.h6.fontSize,
					color: theme.typography.missingData.color,
				}
			},
	};

	console.log(options)
	useEffect(() => {
		setData(props.data);
	}, [props.data]);

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Typography variant={"h6"}>{props.title}</Typography>
			</Grid>
			<Grid item xs={12}>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
				/>
			</Grid>
		</Grid>
	)
}

export default FDPie;