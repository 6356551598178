

const appendVersionToAlertNotificationEmail = (payload, frontendVersion, backendVersion) => {
  const defaultFrontendVersion = frontendVersion || '0.0.000';
  const defaultBackendVersion = backendVersion || '';
  const parsedBody = JSON.parse(payload.body);
  const parsedBodyBody = parsedBody.body;
  // Append version information to the email 'body' of the args body
  const updatedBodyBody = `${parsedBodyBody}<br/><br/>Version: ${defaultFrontendVersion} (${defaultBackendVersion})`;
  // Append new email 'body' to the args body
  const updatedBody = JSON.stringify({
    ...parsedBody,
    body: updatedBodyBody
  });
  // Return the updated payload with the new body (and that body's newly appended body;)
  return {
    ...payload,
    body: updatedBody
  };
}

export const alertsNotificationMiddleware = store => next => action => {
  if (action.type === 'alertsNotification/setAlertNotificationArgs') {
    const state = store.getState();
    const { frontendVersion, backendVersion } = state.appState;

    const modifiedPayload = appendVersionToAlertNotificationEmail(action.payload, frontendVersion, backendVersion);

    const modifiedAction = {
      ...action,
      payload: modifiedPayload,
    };

    return next(modifiedAction);
  }

  return next(action);
};

export default alertsNotificationMiddleware;