import DirectRequest from "./DirectRequest";
import * as Constants from "../../Constants";
import { useDispatch, useSelector } from "react-redux";
import {resetSysAdminUpdateArgs, resetUserUpdateArgs} from "../../store/alertsUpdateSlice";
import {refreshAlerts} from "../../store/alertsSlice";
import {refreshSysAdminAlerts, refreshAllSysAdminAlerts} from "../../store/alertsSysAdminSlice";

const UserAlertsUpdateRequest = () => {
  const dispatch = useDispatch();
  const userAlertsUpdateArgs = useSelector((state) => state.alertsUpdate.userUpdateArgs);

  const handleUserAlertsUpdate = () => {
    console.log("UserAlertsUpdateRequest successful")
    dispatch(resetUserUpdateArgs())
    dispatch(refreshAlerts())
  }
  return userAlertsUpdateArgs ? (
    <DirectRequest
      requestArgs={userAlertsUpdateArgs}
      afterProcess={handleUserAlertsUpdate}
      handleError={(err) => !Constants.USE_DUMMY_DATA && console.log(err)}
      handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log(err)}
    />
  ) : null;
}

const SysAdminAlertsUpdateRequest = () => {
  const dispatch = useDispatch();
  const isSysAdmin = useSelector((state) => state.role.isSysAdmin);
  const sysAdminSelectedTab = useSelector((state) => state.alertsTabsSysAdmin.selectedTab);
  const sysAdminAlertsUpdateArgs = useSelector((state) => state.alertsUpdate.sysAdminUpdateArgs);

  const handleSysAdminAlertsUpdate = () => {
    console.log("SysAdminAlertsUpdateRequest successful")
    dispatch(resetSysAdminUpdateArgs())
    if (sysAdminSelectedTab === "Resolved") {
      console.log("refreshAllSysAdminAlerts")
      dispatch(refreshAllSysAdminAlerts());
    } else {
      console.log("refreshSysAdminAlerts")
      dispatch(refreshSysAdminAlerts());
    }
  }
  return isSysAdmin && sysAdminAlertsUpdateArgs ? (
    <DirectRequest
      requestArgs={sysAdminAlertsUpdateArgs}
      afterProcess={handleSysAdminAlertsUpdate}
      handleError={(err) => !Constants.USE_DUMMY_DATA && console.log(err)}
      handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log(err)}
    />
  ) : null;
}

export const AlertsUpdateRequest = () => {
  return (
    <>
      <UserAlertsUpdateRequest/>
      <SysAdminAlertsUpdateRequest/>
    </>
  )
}

export default AlertsUpdateRequest;