import {useDispatch} from "react-redux";
import {useTheme} from "@mui/material/styles";
import React, {useEffect, useRef, useState} from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import * as Constants from "../../Constants";
import DirectRequest from "../../API/requests/DirectRequest";
import {setSelectedIntegrations} from "../../store/selectedIntegrationsSlice";
import {setIntegrations} from "../../store/integrationMetadataSlice";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Modal from "@mui/material/Modal";

const DisconnectButton = ({ integration, connectedIntegrations }) => {
  const dispatch = useDispatch();

  const [disconnectArgs, setDisconnectArgs] = useState(null);
  const [integrationMetadataArgs, setIntegrationMetadataArgs] = useState(null);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleDisconnect = () => {
    setDisconnectArgs({
      url: Constants.SERVER_FIVETRAN_DISCONNECT_URL,
      method: "POST",
      body: JSON.stringify({
        fivetranConnectorId: connectedIntegrations.find((connectedIntegration) => connectedIntegration.sourceName === integration).fivetranConnectorId,
      }),
    })
  }

  const handleDisconnectClick = () => {
    handleToggle();
  }
  const handleDisconnectSubmit = () => {
    handleDisconnect();
    setOpen(false)
  }

  const handleAfterDisconnect = () => {
    setIntegrationMetadataArgs({ url: Constants.SERVER_GET_INTEGRATION_METADATA_URL });
  }

  const handleIntegrationMetadata = (res) => {
    dispatch(setIntegrations(res))
  }

  return (
    <>
      <DirectRequest
        requestArgs={disconnectArgs}
        afterProcess={handleAfterDisconnect}
        handleError={(err) => console.log("error disconnecting integration", err)}
        handleCatchError={(err) => console.log("catch error disconnecting integration", err)}
      />
      <DirectRequest
        requestArgs={integrationMetadataArgs}
        afterProcess={handleIntegrationMetadata}
        handleError={(err) => console.log("error getting integration metadata", err)}
        handleCatchError={(err) => console.log("catch error getting integration metadata", err)}
      />
      <Button onClick={handleDisconnectClick} ref={anchorRef} variant={"contained"} sx={{ width: "100%" }}>
        Connected
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom">
        <ClickAwayListener onClickAway={handleClose}>
          <Grid container spacing={1} justifyContent={"center"} alignItems={"center"} sx={{ marginTop: ".5rem", backgroundColor: "white !important", borderRadius: "10px", padding: "10px", boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.1)" }}>
            <Grid item xs={12} sx={{ paddingLeft: "0px !important", paddingTop: "0px !important" }}>
              <Button onClick={handleDisconnectSubmit} variant="outlined" size={"small"} sx={{ width: "100%" }}>Disconnect</Button>
            </Grid>
          </Grid>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

const ConnectButton = ({ integration }) => {
  const [connectUrlArgs, setConnectUrlArgs] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleConnect = () => {
    const tempConnectUrlArgs = {
      url: Constants.SERVER_FIVETRAN_CONNECT_CARD_URL,
      method: "POST",
      body: JSON.stringify({
        "sourceName": integration,
        "redirectUri": window.location.origin
      }),
    }
    setConnectUrlArgs(tempConnectUrlArgs);
    setLoading(true);
  }
  const handleConnectUrl = (res) => {
    if (res?.connect_card_uri) {
      window.location.href = res.connect_card_uri;
    }
  }

  return (
    <>
      <DirectRequest
        requestArgs={connectUrlArgs}
        afterProcess={handleConnectUrl}
        handleError={(err) => console.log("error getting connect url", err)}
        handleCatchError={(err) => console.log("catch error getting connect url", err)}
      />
      <Modal open={loading} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Grid container sx={{ width: "100%" }} justifyContent={"center"} alignItems={"center"}>
          <Grid item sx={{ backgroundColor: "white !important", position: "relative", borderRadius: "10px" }}>
            <Typography sx={{ padding: "20px"}}>You will be redirected momentarily<span className="bouncing-dots">
              <span className="dot" style={{ marginLeft: "2px"}}>.</span>
              <span className="dot" style={{ marginLeft: "2px"}}>.</span>
              <span className="dot" style={{ marginLeft: "2px"}}>.</span>
            </span></Typography>
          </Grid>
        </Grid>
      </Modal>
      <Button variant={"outlined"} sx={{ width: "100%" }} onClick={handleConnect}>Connect</Button>
    </>
  )
}

const IntegrationCard = ({ integration, connectedIntegrations, selectedIntegrations, onboarding }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  // ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ dynamic display logic ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼
  const cardHeaderRef = useRef(null);
  const cardActionsRef = useRef(null);
  const [combinedHeight, setCombinedHeight] = useState(0);

  useEffect(() => {
    const headerHeight = cardHeaderRef.current?.offsetHeight || 0;
    const actionsHeight = cardActionsRef.current?.offsetHeight || 0;
    const estimatedPadding = 0.10 * window.innerHeight; // 10% of view height
    setCombinedHeight(headerHeight + actionsHeight + estimatedPadding);
  }, []);
  // ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ dynamic display logic ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲


  // ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ system connected logic ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (!connectedIntegrations.find((connectedIntegration) => connectedIntegration.sourceName === integration)) {
      console.log(integration + " not connected")
      setConnected(false);
    } else {
      console.log(integration + " connected")
      setConnected(true);
    }
  }, [connectedIntegrations, integration])
  // ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ system connected logic ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲


  // ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ discard selected system logic ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼
  const [discardIntegrationArgs, setDiscardIntegrationArgs] = useState(null);

  const cancelSelection = () => {
    if (Constants.USE_DUMMY_DATA) {
      dispatch(setSelectedIntegrations(selectedIntegrations.filter((selectedIntegration) => selectedIntegration !== integration)))
    } else {
      // set args to remove selected integration
      const tempDiscardIntegrationsArgs = {
        method: "POST",
        url: Constants.SERVER_POST_SELECTED_CONNECTORS_URL,
        body: JSON.stringify({ selectedConnectorNames: selectedIntegrations.filter((selectedIntegration) => selectedIntegration !== integration) })
      }
      setDiscardIntegrationArgs(tempDiscardIntegrationsArgs)
    }
  }

  const handleSuccessfulDiscard = (res) => {
    if (res) {
      dispatch(setSelectedIntegrations(res.selectedConnectorNames))
    }
  }
  // ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ discard selected system logic ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲


  return (
    <>
      <DirectRequest
        requestArgs={discardIntegrationArgs}
        afterProcess={handleSuccessfulDiscard}
        handleError={(err) => console.log("error discarding integration", err)}
        handleCatchError={(err) => console.log("catch error discarding integration", err)}
      />
      <Grid item xs={12} md={6} lg={4} sx={{ height: "30vh", minHeight: "250px" }}>
        <Card
          sx={{
            display: 'flex',        // Make this a flex container
            flexDirection: 'column', // Set direction to column
            position: 'relative',
            borderRadius: "10px",
            boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.1)",
            border: "1px solid " + theme.palette.border.light,
            height: "100%"
          }}
        >
          {!connected && (
            <IconButton
              onClick={cancelSelection}
              sx={{
                position: 'absolute',
                top: '5px',
                right: '5px',
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <CardHeader
            ref={cardHeaderRef}
            title={
              <Typography variant={"h4"}>
                {Constants.supported_integrations.find((potentialIntegration) => potentialIntegration.name === integration).displayName}
              </Typography>
            }
          />
          <CardContent
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: `calc(30vh - ${combinedHeight}px)`
            }}
          >
            <img
              src={Constants.supported_integrations.find((potentialIntegration) => potentialIntegration.name === integration).logo}
              alt={Constants.supported_integrations.find((potentialIntegration) => potentialIntegration.name === integration).displayName + " logo"}
              style={{
                maxHeight: '100%',
                maxWidth: '100%',
                objectFit: 'contain'
              }}
            />
          </CardContent>
          <CardActions
            ref={cardActionsRef}
          >
            {!connected && (
              <ConnectButton integration={integration} />
            )}
            {connected && onboarding && (
              <Button
                variant={"contained"}
                sx={{
                  width: "100%",
                  pointerEvents: 'none'
                }}
              >
                Connected
              </Button>
            )}
            {connected && !onboarding && (
              <DisconnectButton integration={integration} connectedIntegrations={connectedIntegrations} />
            )}
          </CardActions>
        </Card>
      </Grid>
    </>
  )
};

export default IntegrationCard;