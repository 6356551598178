import DirectRequest from "./DirectRequest";
import { useSelector, useDispatch } from "react-redux";
import * as Constants from "../../Constants";
import { useState } from "react";
import { setSummaryMetrics } from "../../store/summaryMetricsSlice";

const dummy_summary = {
  "fullHistory": {
    "companyUuid": "comp_XXX",
    "summaryWindow": "fullHistory",
    "cellsProcessed": 111234569,
    "rowsProcessed": 111234569,
    "alertCount": 12,
    "minutesSaved": 5,
    "moneySaved": 1.23
  },
  "today": {
    "companyUuid": "comp_XXX",
    "summaryWindow": "today",
    "cellsProcessed": 5237903,
    "rowsProcessed": 5237903,
    "alertCount": 12,
    "minutesSaved": 5,
    "moneySaved": 1.23
  },
  "last7Days": {
    "companyUuid": "comp_XXX",
    "summaryWindow": "last7Days",
    "cellsProcessed": 11234569,
    "rowsProcessed": 11234569,
    "alertCount": 12,
    "minutesSaved": 5,
    "moneySaved": 1.23
  },
  "last30Days": {
    "companyUuid": "comp_XXX",
    "summaryWindow": "last30Days",
    "cellsProcessed": 1234,
    "rowsProcessed": 1234,
    "alertCount": 12,
    "minutesSaved": 5,
    "moneySaved": 1.23
  },
  "cellsProcessedTimeSeries": [
    {"2023-08-28T18:10:17.279+00:00": null},
    {"2023-09-18T18:10:17.279+00:00": 1000},
    {"2023-09-11T18:10:17.279+00:00": 500},
    {"2023-09-04T18:10:17.279+00:00": null},

  ]
}

const sortTimeSeries = (timeSeries) => {
  return timeSeries.sort((a, b) => {
    // Extract the timestamp strings
    const aDateStr = Object.keys(a)[0];
    const bDateStr = Object.keys(b)[0];

    // Convert timestamp strings to Date objects for comparison
    const aDate = new Date(aDateStr);
    const bDate = new Date(bDateStr);

    // Compare the two dates
    if (aDate < bDate) return -1;
    if (aDate > bDate) return 1;
    return 0;
  });
}

const processTimeSeries = (sortedTimeSeries) => {
  // initialize output
  const processedTimeSeries = [];

  // create flag
  let nullFound = false;

  // loop through reversed timeSeries
  sortedTimeSeries.reverse().forEach((window) => {
    const value = Object.values(window)[0];
    const timeStamp = Object.keys(window)[0];

    // if flag not tripped and value is null -> make value 0 and include in output
    if (!nullFound && value === null) {
      nullFound = true;
      processedTimeSeries.push({ [timeStamp]: 0 });
    }
    // if value is not null -> include in output
    else if (value !== null) {
      processedTimeSeries.push(window);
    }
  });

  // reverse and return
  return processedTimeSeries.reverse();
}


export const SummaryMetricsRequest = () => {
  const dispatch = useDispatch();
  const [summaryMetricsArgs, setSummaryMetricsArgs] = useState({ url: Constants.SERVER_GET_SUMMARY_METRICS_URL });
  const isCaptured = useSelector(state => state.summaryMetrics.isCaptured);
  const postSetupComplete = useSelector(state => state.role.postSetupComplete);

  const handleSummaryMetrics = (res) => {
    if (res && res.summary) {
      console.log("summary metrics", res.summary);
      dispatch(setSummaryMetrics(res.summary));
    }
  }

  return (
    <>
      {!isCaptured && postSetupComplete && (
        <DirectRequest
          requestArgs={summaryMetricsArgs}
          afterProcess={handleSummaryMetrics}
          handleError={(err) => console.log("error getting summary metrics", err)}
          handleCatchError={(err) => console.log("catch error getting summary metrics", err)}
        />
      )}
    </>
  )
}

export default SummaryMetricsRequest;