import {useTheme} from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";
import {
  setSelectedAppUuid,
  setUpdateArgs,
  refreshAlertConfigs, setAlertConfigs
  // setSelectedAlertConfigs
} from "../../../../store/alertConfigsSysAdminSlice";
import Grid from "@material-ui/core/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {getSourceTag, CustomAppTooltip} from "../../../AppBuilder/TagEditor/TagEditor";
import ImpactBar from "../../../ImpactBar/ImpactBar";
import React from "react";
import SearchIcon from '@mui/icons-material/Search';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import * as Constants from "../../../../Constants";
import ReplayIcon from "@mui/icons-material/Replay";
import CircularProgress from "@mui/material/CircularProgress";

const filterSubscribedApps = (apps, subscriptions) => {
  return apps.filter(app => subscriptions.some(sub => sub.developerAppUuid === app.uuid));
}

const getNumOfConfigs = (appUuid, alertConfigs) => {
  const numOfConfigs = alertConfigs.filter((config) => config.developerAppUuid === appUuid).length;
  return numOfConfigs < 1 ? "" : `(${numOfConfigs})`;
}

const AlertConfigAppSelect = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const selectedAppUuid = useSelector(state => state.alertConfigsSysAdmin.selectedAppUuid);
  const subscriptions = useSelector(state => state.alertConfigsSysAdmin.subscriptions);
  const appsSysAdmin = useSelector(state => state.appsSysAdmin.list);
  const isUpdating = useSelector(state => state.alertConfigsSysAdmin.isUpdating);

  const handleAppSelection = (event) => {
    console.log("selectedAppUuid", event.target.value);
    dispatch(setSelectedAppUuid(event.target.value));
  }

  return (
    <Grid item>
      <FormControl sx={{ minWidth: "450px", borderColor: theme.palette.primary.main }}>
        <InputLabel id={"alert-config-app-select-label"}>Select an app</InputLabel>
        <Select
          labelId={"alert-config-app-select-label"}
          label={"Select an app"}
          margin="dense"
          value={selectedAppUuid ? selectedAppUuid : ''}
          onChange={handleAppSelection}
          disabled={isUpdating}
        >
          {filterSubscribedApps(appsSysAdmin, subscriptions).map((app) => (
            <MenuItem key={app.uuid} value={app.uuid}>
              {app.name}&nbsp;<strong>{app.fullName}</strong>&nbsp;<i>{getSourceTag(app.tags)}</i>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )
}

const AppViewer = () => {
  const selectedAppUuid = useSelector(state => state.alertConfigsSysAdmin.selectedAppUuid);
  const appsSysAdmin = useSelector(state => state.appsSysAdmin.list);
  return (
    <Grid item>
      <Box sx={{height: "100%", display: "flex", alignItems: "center" }}>
        <CustomAppTooltip
          title={selectedAppUuid ? <pre style={{ wordBreak: 'break-word', whiteSpace: "pre-wrap", overflowWrap: 'break-word' }}>{JSON.stringify(appsSysAdmin.find(app => app.uuid === selectedAppUuid), null, 2)}</pre> : ""}
        >
          <SearchIcon sx={!selectedAppUuid ? { fontSize: "2rem", fill: "grey" } : { fontSize: "2rem" }}/>
        </CustomAppTooltip>
      </Box>
    </Grid>
  )
}

const fixFloat = (newParamValue) => {
  const hasDecimal = newParamValue.includes(".");
  if (hasDecimal) {
    const decimalIndex = newParamValue.indexOf(".");
    // if decimal is last character, append a 0 to the end and return
    // else return (decimal is not last character means there are numbers after)
    return decimalIndex === newParamValue.length - 1 ? newParamValue + "0" : newParamValue;
  } else {
    return newParamValue + ".0";
  }
}

const ResetAlertConfigsButton = () => {
  const dispatch = useDispatch();
  const selectedAppUuid = useSelector(state => state.alertConfigsSysAdmin.selectedAppUuid);
  const alertConfigs = useSelector(state => state.alertConfigsSysAdmin.alertConfigs);
  const isUpdating = useSelector(state => state.alertConfigsSysAdmin.isUpdating);

  const handleResetConfigs = () => {
    const resetAlertConfigs = alertConfigs.map(config => {
      return {
        ...config,
        newValue: null,
        newNote: null,
        error: false,
        errorMessage: ""
      }
    })
    dispatch(setAlertConfigs(resetAlertConfigs));
  }

  return (
    <Grid item>
      <Button
        onClick={handleResetConfigs}
        sx={{ borderRadius: "0px !important"}}
        variant={"outlined"}
        startIcon={<ReplayIcon />}
        disabled={isUpdating || !selectedAppUuid}
      >
        Reset
      </Button>
    </Grid>
  )
}

const UpdateConfigsButton = () => {
  const dispatch = useDispatch();
  const companyUuid = useSelector(state => state.alertsSysAdmin.company.uuid);
  const alertConfigs = useSelector(state => state.alertConfigsSysAdmin.alertConfigs);
  const isUpdating = useSelector(state => state.alertConfigsSysAdmin.isUpdating);

  const isEnabled = (configs) => {
    const valuesToUpdate = configs.some(config => config.newValue !== null || config.newNote !== null);
    const noErrors = configs.every(config => !config.error);
    return valuesToUpdate && noErrors;
  }
  const handleUpdateConfigs = () => {
    const configsUpdateArgs = alertConfigs.filter(config => config.newValue !== null || config.newNote !== null).map(config => {
      return {
        url: Constants.SERVER_POST_SYSADMIN_ALERT_CONFIG_UPDATE_URL + companyUuid,
        method: "POST",
        body: JSON.stringify({
          developerAppUuid: config.developerAppUuid,
          appName: config.appName,
          alertName: config.alertName,
          paramName: config.paramName,
          paramDisplayName: config.paramDisplayName,
          paramDisplayNote: config.newNote !== null ? config.newNote : config.paramDisplayNote,
          alertConfigParamType: config.alertConfigParamType,
          paramValue: config.newValue !== null ? config.alertConfigParamType === "FLOAT" ? fixFloat(config.newValue) : config.newValue : config.paramValue
        })
      }
    });
    console.log("configsUpdateArgs", configsUpdateArgs)
    dispatch(setUpdateArgs(configsUpdateArgs));
  }

  return (
    <Grid item>
      <Button
        color="primary"
        startIcon={isUpdating ? <CircularProgress size={"1rem"}/> : <SendIcon />}
        sx={{ borderRadius: "0px !important"}}
        variant="contained"
        onClick={handleUpdateConfigs}
        disabled={isUpdating || !isEnabled(alertConfigs)}
      >
        Update
      </Button>
    </Grid>
  )
}

export const AlertConfigsToolbar = () => {
  return (
    <Grid item xs={12}>
      <ImpactBar
        component={
          <>
            <Grid container spacing={2} alignItems={"center"}>
              <AlertConfigAppSelect/>
              <AppViewer/>
              <ResetAlertConfigsButton/>
              <UpdateConfigsButton/>
            </Grid>
          </>
        }
      />
    </Grid>
  )
}

export default AlertConfigsToolbar;