import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import React from "react";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  height: 5rem;
`;

export default function Dropzone(props) {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone(
    props.script
      ? { accept: { "text/*": [".py", ".pyc"] }, maxFiles: 1 }
      : { accept: { "img/png": [".png"] }, maxFiles: 1 }
  );

  // console.log("acceptedFiles:", acceptedFiles);
  if (props.script && acceptedFiles.length > 0)
    props.handleFileChange(acceptedFiles[0]);
  if (props.thumbnail && acceptedFiles.length > 0)
    props.handleThumbnailChange(acceptedFiles[0]);

  return (
    <div className="container">
      <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        {props.script && (
          <p>
            Drag and drop a .py file here, or click to select one from your file
            directory
          </p>
        )}
        {props.thumbnail && (
          <p>
            Drag and drop a .png file here, or click to select one from your
            file directory
          </p>
        )}
      </Container>
    </div>
  );
}
