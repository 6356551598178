import { createSlice } from "@reduxjs/toolkit";

export const mixpanelSlice = createSlice({
  name: "mixpanel",
  initialState: {
    initialized: false,
    identified: false,
  },
  reducers: {
    setMixpanelInitialized: (state, action) => {
      state.initialized = action.payload;
    },
    setMixpanelIdentified: (state, action) => {
      state.identified = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMixpanelInitialized, setMixpanelIdentified } = mixpanelSlice.actions;

export default mixpanelSlice.reducer;
