import DirectRequest from "../../API/requests/DirectRequest";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as Constants from "../../Constants";
import {SERVER_SYSADMIN_POST_GET_DEVAPP_VISIBILITY_URL} from "../../Constants";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

const dummyVisibility = {
  visibleToCompanyUuids: ["compUuid1", "compUuid2", "compUuid3"],
  hideFromCompanyUuids: ["compUuid4", "compUuid5", "compUuid6"],
}

const dummyCompanies = [
  // { uuid: "compUuid1", name: "Company 1" },
  // { uuid: "compUuid2", name: "Company 2" },
  // { uuid: "compUuid3", name: "Company 3" },
  // { uuid: "compUuid4", name: "Company 4" },
  // { uuid: "compUuid5", name: "Company 5" },
  // { uuid: "compUuid6", name: "Company 6" },
]

export const SysAdminAppVisibility = ({ companies }) => {
  const sysAdminApps = useSelector((state) => state.appsSysAdmin.list);
  const [selectedApp, setSelectedApp] = useState({});
  const [getAppVisibilityArgs, setGetAppVisibilityArgs] = useState(null);
  const [appVisibility, setAppVisibility] = useState({ visibleToCompanyUuids: [], hideFromCompanyUuids: [] });

  const getCompanyName = (companyUuid) => {
    const companyName = companies.find((company) => company.uuid === companyUuid)?.name;
    return ` - ${companyName ? companyName : "Company name not found"}`;
  }

  const handleAppSelection = (event) => {
    const selectedAppUuid = event.target.value;
    console.log("selectedAppUuid", selectedAppUuid)
    const selectedApp = sysAdminApps.find((sysAdminApp) => sysAdminApp.uuid === selectedAppUuid);
    console.log("selectedApp", selectedApp)
    setSelectedApp(selectedApp);
    setAppVisibility({ visibleToCompanyUuids: [], hideFromCompanyUuids: [] })
  }

  const handleAppVisibilityRes = (res) => {
    console.log("handleAppVisibilityRes", res);
    /*
    {
      visibleToCompanyUuids: ["compUuid1", "compUuid2", etc...],
      hideFromCompanyUuids: ["compUuid3", "compUuid4", etc...],
    }
    */
    if (res) {
      setAppVisibility(res);
    }
  }

  useEffect(() => {
    // need to set args
    if (selectedApp && selectedApp.uuid) {
      setGetAppVisibilityArgs({ url: Constants.SERVER_SYSADMIN_POST_GET_DEVAPP_VISIBILITY_URL, method: "POST", body: JSON.stringify({ developerAppUuid: selectedApp.uuid }) });
    }
  }, [selectedApp])

  useEffect(() => {
    if (Constants.USE_DUMMY_DATA) {
      setAppVisibility(dummyVisibility);
    }
  }, [])

  return (
    <>
      <DirectRequest
        requestArgs={getAppVisibilityArgs}
        afterProcess={handleAppVisibilityRes}
        handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("AlertFeed get subscribedApps error", err)}
        handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("AlertFeed get subscribedApps catch error", err)}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl size="small" sx={{ minWidth: "450px"}}>
            <Select
              labelId="app-select-label"
              id="app-select"
              displayEmpty
              value={selectedApp ? selectedApp.uuid : ''}
              onChange={handleAppSelection}
              renderValue={(selectedValue) => selectedApp && selectedApp.uuid ? selectedApp.uuid : "Select an app"}
            >
              {sysAdminApps.map((sysAdminApp, index) => (
                <MenuItem key={index} value={sysAdminApp.uuid}>
                  {sysAdminApp.name} runs - {sysAdminApp.uuid}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Visible to:</Typography>
          </Grid>
          {appVisibility.visibleToCompanyUuids?.map((companyUuid, index) => (
            <Grid item xs={12} key={index}>
              <Typography>{companyUuid}{getCompanyName(companyUuid)}</Typography>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={6} container spacing={1}>
          <Grid item>
            <Typography variant="h6">Hidden from:</Typography>
          </Grid>
          {appVisibility.hideFromCompanyUuids?.map((companyUuid, index) => (
            <Grid item xs={12} key={index}>
              <Typography>{companyUuid}{getCompanyName(companyUuid)}</Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  )
};

export default SysAdminAppVisibility;