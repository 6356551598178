import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { addCommand, removeCommand } from "../../store/commandsSlice";
import { changeToReady } from "../../store/customerSubsSlice";
import DirectRequest from "../requests/DirectRequest";
import GenericDelayedResponseHandler from "./GenericDelayedResponseHandler";
import * as Constants from "../../Constants";
import {refreshAppsSubscriptions} from "../../store/appsSubscriptionsSlice";

function ListenForAppRun(props) {
  const dispatch = useDispatch();

  const [markSetupCompleteArgs, setMarkSetupCompleteArgs] = useState();

  function finishListenForAppRun(response, commandUuid) {
    // TODO: what happens if an error is returned?
    if (response.status === "succeeded") {
      const customerSubscriptionUuid = response.response.customerSubscriptionUuid;
      console.log("app run completed for " + customerSubscriptionUuid);
      const tempArgs = {
        url: Constants.SERVER_CUS_MARK_SETUP_COMPLETE_URL,
        method: "POST",
        body: JSON.stringify({
          customerSubscriptionUuid: customerSubscriptionUuid
        })
      };
      setMarkSetupCompleteArgs(tempArgs);


      dispatch(removeCommand(commandUuid));
    }
  }

  function afterMarkedComplete(res) {
    // dispatch(changeToReady(res.uuid)); subscribed_apps_refactor => will now just refresh subscribedApps
    dispatch(refreshAppsSubscriptions());
  }

  function afterListenForAppRunStart(res) {
    // Just wait
  }

  const handleListenError = (data) => {
    // Todo: implement this
    console.log("Error occurred while waiting for app to run");
    console.log(data);
  }

  const handleListenCatchError = (error) => {
    // Todo: implement this
    console.log("Error occurred while communicating with server");
    console.log(error);
  }

  return (
    <Fragment>
      <DirectRequest
        requestArgs={markSetupCompleteArgs}
        afterProcess={afterMarkedComplete}
        handleError={handleListenError}
        handleCatchError={handleListenCatchError}
      />
      <GenericDelayedResponseHandler
        sse={props.sse}
        handlerName={Constants.CommandType.listenForAppRun}
        finishResponse={finishListenForAppRun}
      />
    </Fragment>
  );
}

export default ListenForAppRun;
