import React, { useEffect, useState } from 'react';
import DirectRequest from "../../../API/requests/DirectRequest";
import * as Constants from "../../../Constants";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import {refreshAppsSubscriptions} from "../../../store/appsSubscriptionsSlice";

const SubscribeToOneApp = ({ app, setSubscriptionStatus }) => {
  const [subscriptionArgs, setSubscriptionArgs] = useState(null);

  useEffect(() => {
    if (!subscriptionArgs) {
      const tempSubscriptionArgs = {
        url: Constants.SERVER_CUS_APP_BUY_URL,
        method: "POST",
        body: JSON.stringify({
          "developerAppUuid": app.uuid,
          "stripeSubscriptionUuid": null,
          "isTrial": false,
          "isActive": true
        })
      };
      setSubscriptionArgs(tempSubscriptionArgs);
    }
  }, [subscriptionArgs, app])

  const handleSubscription = (res) => {
    setSubscriptionStatus((subscriptionStatus) => {
      const tempSubscriptionStatus = { ...subscriptionStatus };
      tempSubscriptionStatus[app.uuid] = true;
      return tempSubscriptionStatus;
    })
  }

  return (
    <>
      <DirectRequest
        requestArgs={subscriptionArgs}
        afterProcess={handleSubscription}
        handleError={(err) => console.log(`error creating subscription for developer app ${app.uuid}`, err)}
        handleCatchError={(err) => console.log(`catch error creating subscription for developer app ${app.uuid}`, err)}
      />
    </>
  )
};

const SubscribeToAllApps = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apps = useSelector((state) => state.apps.filteredList);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  useEffect(() => {
    if (apps.length > 0) {
      const tempSubscriptionStatus = {};
      apps.forEach((app) => {
        tempSubscriptionStatus[app.uuid] = false;
      })
      setSubscriptionStatus(tempSubscriptionStatus);
    }
  }, [apps])

  useEffect(() => {
    if (subscriptionStatus) {
      const allSubscribed = Object.values(subscriptionStatus).every((status) => status);
      if (allSubscribed) {
        dispatch(refreshAppsSubscriptions());
        navigate("/notifications")
      }
    }
  }, [subscriptionStatus])

  return (
    <>
      {subscriptionStatus && apps.map((app) =>  (
        <SubscribeToOneApp key={`${app.uuid}_subscriber`} app={app} setSubscriptionStatus={setSubscriptionStatus} />
      ))}
    </>
  )
}

export default SubscribeToAllApps;