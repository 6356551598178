import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AppRedirect from "./AppRedirect";
import EmailRedirect from "./EmailRedirect";
import SysAdminRedirect from "./SysAdminRedirect";

export const Redirect = () => {
  const isSysAdmin = useSelector((state) => state.role.isSysAdmin);
  const page_params = useParams();
  const dashboardID = page_params.dashboardID;
  const alertID = page_params.alertID;
  const companyID = page_params.companyID;
  // console.log("Redirect - sysadmin:", isSysAdmin, "dashboardID", dashboardID, "alertID:", alertID, "companyID:", companyID);
  return (
    <>
      {(isSysAdmin && companyID) ? <SysAdminRedirect dashboardID={dashboardID} alertID={alertID} companyID={companyID} /> : alertID ? <EmailRedirect alertID={alertID} /> : <AppRedirect dashboardID={dashboardID} />}
    </>
  )
}

export default Redirect;