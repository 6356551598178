import { createSlice } from '@reduxjs/toolkit';

export const alertsSysAdminSlice = createSlice({
  name: "alertsSysAdmin",
  initialState: {
    company: { uuid: '' },
    isCaptured: false,
    alerts: [],
    resolvedAlerts: [],
    isErrored: false,
    errorMessage: "",
    isReady: false,
  },
  reducers: {
    setCompany: (state, action) => {
      state.company = action.payload
      state.alerts = []
      state.resolvedAlerts = []
      state.isCaptured = false
      state.isReady = false
    },
    setSysAdminAlerts: (state, action) => {
      state.alerts = action.payload.alerts
      state.resolvedAlerts =  action.payload.resolvedAlerts
      state.isCaptured = true
      state.isReady = true
    },
    refreshSysAdminAlerts: (state, action) => {
      state.isCaptured = false
    },
    refreshAllSysAdminAlerts: (state, action) => {
      state.isCaptured = false
      state.isReady = false
    },
    setSysAdminActiveAlerts: (state, action) => {
      state.alerts = action.payload.alerts
      state.isCaptured = true
    },
    setSysAdminAlertsError: (state, action) => {
      state.isErrored = true
      state.errorMessage = action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setCompany,
  setSysAdminAlerts,
  refreshSysAdminAlerts,
  refreshAllSysAdminAlerts,
  setSysAdminActiveAlerts,
  setSysAdminAlertsError } = alertsSysAdminSlice.actions;

export default alertsSysAdminSlice.reducer;