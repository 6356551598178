import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import DirectRequest from "../../API/requests/DirectRequest";
import FDGrid from "../FDGrid/FDGrid";
import Grid from "@mui/material/Grid";
import * as Constants from "../../Constants";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PendingIcon from '@mui/icons-material/Pending';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from "@mui/material/Tooltip";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import {SERVER_SYSADMIN_POST_GET_DATABRICKS_RUNS_URL} from "../../Constants";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

/*
public class DatabricksJobRunInfo {

    Long jobId;
    Long runId;
    String startTime;
    String jobUrl;
    String runUrl;
    String jobName;
    Integer runDuration;
    DatabricksJobRunStatus runStatus;
    String companyUuid;
    String developerAppUuid;
    Map<String, String> runParameters;

    Timestamp createdOn;

}

runStatus = SUCCEEDED || FAILED || PENDING || RUNNING || TIMEOUT
*/

const dummy_runs = [
  {
    jobId: "12345",
    runId: "12345",
    startTime: Date.now(),
    jobUrl: "TODO",
    runUrl: "TODO",
    jobName: "TODO",
    runDuration: "116632",
    runStatus: "RUNNING",
    companyUuid: "TODO",
    developerAppUuid: "TODO",
    runParameters: {},
    createdOn: "TODO",
    alertRowCount: "15",
    otherTablesRowCount: "20",
  },
  {
    jobId: "123098",
    runId: "12098",
    startTime: Date.now(),
    jobUrl: "TODO",
    runUrl: "TODO",
    jobName: "TODO",
    runDuration: "118312",
    runStatus: "FAILED",
    companyUuid: "TODO",
    developerAppUuid: "TODO",
    runParameters: {},
    createdOn: "TODO",
    alertRowCount: "15",
    otherTablesRowCount: "20",
  },
  {
    jobId: "123098",
    runId: "12098",
    startTime: Date.now(),
    jobUrl: "TODO",
    runUrl: "TODO",
    jobName: "TODO",
    runDuration: "69814",
    runStatus: "PENDING",
    companyUuid: "TODO",
    developerAppUuid: "TODO",
    runParameters: {},
    createdOn: "TODO",
    alertRowCount: "15",
    otherTablesRowCount: "20",
  },
  {
    jobId: "123098",
    runId: "12098",
    startTime: Date.now(),
    jobUrl: "TODO",
    runUrl: "TODO",
    jobName: "TODO",
    runDuration: "136082",
    runStatus: "TIMEDOUT",
    companyUuid: "TODO",
    developerAppUuid: "TODO",
    runParameters: {
      "job_id": "799780266748620",
      "run_id": "614500326777024",
      "schemaPath": "awsdev_catalog.awsdev_awsdev_testsand_yisrb_quickbooks",
      "webhookUrl": "https://dev-use1.fruitiondata.net/databricks/public/webhook",
      "customerUuid": "comp_86fof91czhpw88srgr9tyisrb",
      "parent_run_id": "{{parent_run_id}}",
      "developerAppUuid": "dapp_b5gqcfygpeom67mghu6zqxheg"
    },
    createdOn: "TODO",
    alertRowCount: "15",
    otherTablesRowCount: "20",
  },
  {
    jobId: "123098",
    runId: "12098",
    startTime: Date.now(),
    jobUrl: "TODO",
    runUrl: "TODO",
    jobName: "TODO",
    runDuration: "57827",
    runStatus: "SUCCESS",
    companyUuid: "TODO",
    developerAppUuid: "TODO",
    runParameters: { testValue: "test", otherTestValue: "test" },
    createdOn: "TODO",
    alertRowCount: "15",
    otherTablesRowCount: "20",
  }
]

const RunTimer = ({startTime}) => {
  const [duration, setDuration] = useState(Date.now() - startTime);
  useLayoutEffect(() => {
    const interval = setInterval(() => {
      setDuration(Date.now() - startTime);
    }, 1000);

    return () => clearInterval(interval);
  }, [])

  return (
    <>
      <Typography>{duration / 60000 >= 1 ? `${Math.floor(duration / 60000)}m ` : ""}{`${Math.floor((duration % 60000) / 1000)}s`}</Typography>
    </>
  )
}

const RunDuration = ({runStatus, runDuration, startTime}) => {
  if (runStatus === "PENDING" || runStatus === "RUNNING") {
    return <RunTimer startTime={startTime} />
  } else {
    return <Typography>{runDuration / 60000 >= 1 ? `${Math.floor(runDuration / 60000)}m ` : ""}{`${Math.floor((runDuration % 60000) / 1000)}s`}</Typography>
  }
}

////PENDING, - PendingIcon
//// RUNNING, - running
// SKIPPED, - HighlightOffIcon
// BLOCKED, - HighlightOffIcon
// WAITING_FOR_RETRY, - PendingIcon
// QUEUED, - PendingIcon
// SUCCESS, - CheckCircleOutlineIcon
// FAILED, - HighlightOffIcon
// TIMEDOUT, - HourglassBottomIcon
// CANCELED, - HighlightOffIcon
// MAXIMUM_CONCURRENT_RUNS_REACHED, - HighlightOffIcon
// EXCLUDED, - HighlightOffIcon
// SUCCESS_WITH_FAILURES, - HighlightOffIcon
// UPSTREAM_FAILED, - HighlightOffIcon
// UPSTREAM_CANCELED; - HighlightOffIcon
export const RunStatus = ({runStatus}) => {
  const runStatusIcons = {
    "SUCCESS": <CheckCircleOutlineIcon sx={{color: "green"}}/>,
    "FAILED": <HighlightOffIcon sx={{color: "#9c0606"}}/>,
    "PENDING": <PendingIcon sx={{color: "gray"}}/>,
    "RUNNING": <CircularProgress size={20}/>,
    "TIMEDOUT": <HourglassBottomIcon sx={{color: "#ffd000"}}/>,
    "SKIPPED": <HighlightOffIcon sx={{color: "#9c0606"}}/>,
    "BLOCKED": <HighlightOffIcon sx={{color: "#9c0606"}}/>,
    "WAITING_FOR_RETRY": <PendingIcon sx={{color: "gray"}}/>,
    "QUEUED": <PendingIcon sx={{color: "gray"}}/>,
    "CANCELED": <HighlightOffIcon sx={{color: "#9c0606"}}/>,
    "MAXIMUM_CONCURRENT_RUNS_REACHED": <HighlightOffIcon sx={{color: "#9c0606"}}/>,
    "EXCLUDED": <HighlightOffIcon sx={{color: "#9c0606"}}/>,
    "SUCCESS_WITH_FAILURES": <HighlightOffIcon sx={{color: "#9c0606"}}/>,
    "UPSTREAM_FAILED": <HighlightOffIcon sx={{color: "#9c0606"}}/>,
    "UPSTREAM_CANCELED": <HighlightOffIcon sx={{color: "#9c0606"}}/>,
  }
  return (
    <Typography component="div" noWrap sx={{ display: "flex", alignItems: "center" }}>
      {runStatusIcons[runStatus] ? runStatusIcons[runStatus] : <PendingIcon sx={{color: "gray"}}/>}
      <Box component="span" sx={runStatus === "RUNNING" ? { marginLeft: "10px"} : { marginLeft: "6px"}}>
        {runStatus}
      </Box>
    </Typography>
  )
}

const openDatabricksRun = (runUrl) => {
  window.open(runUrl, "_blank");
}

export const StartTime = ({startTime, runUrl}) => {
  const theme = useTheme();
  const [hover, setHover] = useState(false);
  const toggleHover = () => setHover(prevHover => !prevHover);
  return (
    <Typography
      variant={"link"}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      sx={hover ? {
        textDecoration: "underline",
        cursor: "pointer",
        color: theme.palette.primary.main,
      } : {}}
      onClick={(e) => openDatabricksRun(runUrl)}
    >
      {new Date(startTime).toLocaleString()}
    </Typography>
  )
}

const getRunParametersTooltip = (runParameters) => {
  return runParameters && Object.keys(runParameters).length > 0 ? (
    <>
      {Object.keys(runParameters).map((key, index) => {
        return (
          <Typography key={index}><strong>{key}:</strong> {runParameters[key]}</Typography>
        )
      })}
    </>
  ) : "No parameters"
}

const RunParameters = ({runParameters}) => {
  return (
    <Tooltip title={getRunParametersTooltip(runParameters)} followCursor>
      <SearchIcon/>
    </Tooltip>
  )
}

const getAppUuidTooltip = (app) => {
  return app ? (
    <>
      <Typography><strong>name:</strong> {app.name}</Typography>
      <Typography><span style={{ visibility: "hidden" }}>I</span></Typography>
      <Typography><strong>description:</strong> {app.description}</Typography>
      <Typography><span style={{ visibility: "hidden" }}>I</span></Typography>
      <Typography><strong>requiredSourceTables:</strong></Typography>
      {app.requiredSourceTables.map((table, index) => {
        return (
          <Typography key={index}>- {table.uuid}</Typography>
        )
      })}
      <Typography><span style={{ visibility: "hidden" }}>I</span></Typography>
      <Typography><strong>tags:</strong></Typography>
      {app.tags.map((tag, index) => {
        return (
          <Typography key={index}>- {tag}</Typography>
        )
      })}
    </>
  ) : "App not found"
}

const AppUuid = ({appUuid}) => {
  const sysAdminApps = useSelector(state => state.appsSysAdmin.list);
  const app = sysAdminApps.find((app) => app.uuid === appUuid);
  return (
    <Tooltip title={getAppUuidTooltip(app)} followCursor>
      <Typography>{app?.name} - {appUuid}</Typography>
    </Tooltip>
  )
}


const columns = [
  {
    "field": "startTime",
    "headerName": "Start time",
    "width": 150,
    "renderCell": (params) => <StartTime startTime={params.value} runUrl={params.row.runUrl} />,
  },
  {
    "field": "developerAppUuid",
    "headerName": "App name / uuid",
    "width": 350,
    "renderCell": (params) => <AppUuid appUuid={params.value} />,
  },
  {
    "field": "runId",
    "headerName": "Run ID",
    "width": 150,
  },
  {
    "field": "runDuration",
    "headerName": "Duration",
    "width": 100,
    "renderCell": params => <RunDuration runStatus={params.row.runStatus} runDuration={params.value} startTime={params.row.startTime} />,
  },
  {
    "field": "runStatus",
    "headerName": "Status",
    "width": 130,
    "renderCell": params => <RunStatus runStatus={params.value} />
  },
  {
    "field": "alertRowCount",
    "headerName": "Alert rows",
    "width": 90,
  },
  {
    "field": "otherTablesRowCount",
    "headerName": "Table rows",
    "width": 90,
  },
  {
    'field': "runParameters",
    "headerName": "Params",
    "width": 70,
    "align": "center",
    "renderCell": params => <RunParameters runParameters={params.value} />,
  }
]

export const cleanDatabricksRuns = (runs) => {
  return runs.sort((a, b) => b.startTime - a.startTime).map((run, index) => {
    return {
      ...run,  // Preserve original properties
      Id: index, // Add Id property
    };
  })
}

const filterByAppUuid = (runs, uuid) => {
  return !uuid ? runs : runs.filter((run) => run.developerAppUuid === uuid);
}

export const SysAdminDatabricksRuns = ({selectedCompany}) => {
  const [databricksRunsArgs, setDatabricksRunsArgs] = useState(null);
  const [databricksRuns, setDatabricksRuns] = useState([]);
  const sysAdminApps = useSelector(state => state.appsSysAdmin.list);
  const [selectedApp, setSelectedApp] = useState({ uuid: '' });
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (selectedCompany && selectedCompany.uuid) {
      setDatabricksRunsArgs({ url: Constants.SERVER_SYSADMIN_POST_GET_DATABRICKS_RUNS_URL + selectedCompany.uuid, method: "POST", body: JSON.stringify({}) })
    }
  }, [selectedCompany])

  const handleDatabricksRuns = (res) => {
    console.log("handleDatabricksRuns -> res", res)
    if (res && res.databricksJobRunInfoList) {
      setDatabricksRuns(cleanDatabricksRuns(res.databricksJobRunInfoList));
    }
  }

  const handleAppSelection = (event) => {
    const selectedAppUuid = event.target.value;
    if (selectedAppUuid) {
      const selectedApp = sysAdminApps.find(app => app.uuid === selectedAppUuid);
      setSelectedApp(selectedApp);
    } else {
      setSelectedApp({ uuid: '' });
    }
  }

  const refreshDatabricksRuns = () => {
    setDatabricksRunsArgs({ url: Constants.SERVER_SYSADMIN_POST_GET_DATABRICKS_RUNS_URL + selectedCompany.uuid, method: "POST", body: JSON.stringify({}) })
  }

  useEffect(() => {
    setRows(filterByAppUuid(databricksRuns, selectedApp.uuid));
  }, [selectedApp, databricksRuns])

  useEffect(() => {
    if (Constants.USE_DUMMY_DATA) {
      setDatabricksRuns(cleanDatabricksRuns(dummy_runs));
    }
  }, [])

  return (
    <>
      <DirectRequest
        requestArgs={databricksRunsArgs}
        afterProcess={handleDatabricksRuns}
        handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("error getting databricks runs", err)}
        handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("catch error getting databricks runs", err)}
      />
      <Grid container spacing={1}>
        <Grid container item xs={12} alignItems={"center"} spacing={1}>
          <Grid item>
            <Typography variant={"h6"}>Databricks runs</Typography>
          </Grid>
          <Grid item>
            <Tooltip title={"Refresh"} followCursor>
              <IconButton
                onClick={refreshDatabricksRuns}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <FormControl size="small" sx={{ minWidth: "450px"}}>
              <Select
                labelId="app-select-label"
                id="app-select"
                displayEmpty
                value={selectedApp ? selectedApp.uuid : ''}
                onChange={handleAppSelection}
                renderValue={(selectedValue) => selectedApp && selectedApp.uuid ? selectedApp.uuid : "All app runs"}
              >
                <MenuItem key={"default"} value={''}>
                  <em>All app runs</em>
                </MenuItem>
                {sysAdminApps.map((sysAdminApp, index) => (
                  <MenuItem key={index} value={sysAdminApp.uuid}>
                    {sysAdminApp.name} runs - {sysAdminApp.uuid}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FDGrid
            pageSize={100}
            rows={rows}
            columns={columns}
            getRowId={(row) => row.Id}
            autoHeight={true}
            localeText={{
              noRowsLabel: 'No Databricks runs'
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default SysAdminDatabricksRuns;