import { useSelector, useDispatch } from "react-redux";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ShieldIcon from '@mui/icons-material/Shield';
import {setCurrentType, setSelectedTab} from "../../store/alertsTabsSlice";
import {setShowModal} from "../../store/summaryNotificationsModalSlice";
import { generateAlertFeedSelectedNotificationArgs } from "../AlertFeed/FeedTableUtils";
import { setAlertNotificationArgs } from "../../store/alertsNotificationSlice";

const sortFeedCategories = (feedCategories, numberOfAlertsByFeedCategory, primaryFeed) => {
  // Making a shallow copy of the feedCategories array and sorting the copy
   return [...feedCategories].sort((a, b) => {
    const alertsDifference = primaryFeed
      ? numberOfAlertsByFeedCategory[b].primaryFeed - numberOfAlertsByFeedCategory[a].primaryFeed
      : numberOfAlertsByFeedCategory[b].main - numberOfAlertsByFeedCategory[a].main;
    if (alertsDifference !== 0) {
      return alertsDifference;
    } else {
      return a.localeCompare(b);
    }
  });
}

const getNumberOfFeedCategoryAlerts = (numberOfAlerts) => {
  if (!numberOfAlerts || numberOfAlerts < 100) {
    return numberOfAlerts || 0
  } else if (numberOfAlerts > 99) {
    return "99+"
  }
}

const getFeedCategoryStyle = (feedCategory, numberOfAlertsByFeedCategory, primaryFeed) => {
  if (primaryFeed
    ? numberOfAlertsByFeedCategory[feedCategory].primaryFeed > 0
    : numberOfAlertsByFeedCategory[feedCategory].main > 0
  ) {
    return {
      fontWeight: "bold",
      textDecoration: "underline",
      cursor: "pointer",
    };
  } else {
    return {};
  }
}

const generateFeedCategoriesForSummaryCategory = (selectedCategory, summaryCategories, numberOfAlertsByFeedCategory, goToAlertFeed, primaryFeed) => {
  const handleAlertTypeClick = (feedCategory) => {
    console.log("handleAlertTypeClick", feedCategory);
    // (e) => numberOfAlertsByFeedCategory[feedCategory] > 0 ? goToAlertFeed(feedCategory) : null
    const primaryFeedValue = numberOfAlertsByFeedCategory[feedCategory].primaryFeed
    const mainValue = numberOfAlertsByFeedCategory[feedCategory].main
    if (primaryFeed) {
      if (primaryFeedValue > 0) {
        goToAlertFeed(feedCategory)
      }
    } else {
      if (mainValue > 0) {
        goToAlertFeed(feedCategory)
      }
    }
  }
  return (
    <>
      {sortFeedCategories(summaryCategories[selectedCategory].feedCategories, numberOfAlertsByFeedCategory, primaryFeed).map((feedCategory) => (
        <Grid item xs={6} key={feedCategory} sx={{ height: "20px" }} container alignItems={"center"}>
          <Grid item xs={0.5} container justifyContent={"center"} alignItems={"center"}>
            <ShieldIcon sx={{
              fill: ((primaryFeed && numberOfAlertsByFeedCategory[feedCategory].primaryFeed > 0) || (!primaryFeed && numberOfAlertsByFeedCategory[feedCategory].main > 0))
                ? "rgba(245, 215, 66, 1.0)"
                : "green",
              fontSize: "11px"
            }}/>
          </Grid>
          <Grid item xs={1} container justifyContent={"center"}>
            <Typography>
              {getNumberOfFeedCategoryAlerts(primaryFeed
                ? numberOfAlertsByFeedCategory[feedCategory].primaryFeed
                : numberOfAlertsByFeedCategory[feedCategory].main
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <span
                style={getFeedCategoryStyle(feedCategory, numberOfAlertsByFeedCategory, primaryFeed)}
                onClick={(e) => handleAlertTypeClick(feedCategory)}
              >
                {feedCategory}
              </span>
            </Typography>
          </Grid>
        </Grid>
        ))}
    </>
  )
}


export const FeedCategories = ({ selectedCategory }) => {
  const dispatch = useDispatch();
  const primaryFeed = useSelector((state) => state.alertsTabs.primaryFeed);
  const summaryCategories = useSelector((state) => state.appsCategories.summaryCategories);
  const numberOfAlertsByFeedCategory = useSelector((state) => state.appsCategories.numberOfAlertsByFeedCategory);

  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector((state) => state.role.isInternal);

  const goToAlertFeed = (feedCategory) => {
    dispatch(setCurrentType(feedCategory));
    dispatch(setSelectedTab('Main'));
    //dispatch(setPrimaryFeed(true));
    dispatch(setShowModal(false));
    if (!isInternal) {
      const tempAlertFeedSelectedNotificationArgs = generateAlertFeedSelectedNotificationArgs(user, feedCategory)
      dispatch(setAlertNotificationArgs(tempAlertFeedSelectedNotificationArgs))
    }
  }

  return (
    <Grid id={"feed_categories"} item xs={12} container sx={{ paddingTop: "12px", paddingBottom: "12px"}}>
      {generateFeedCategoriesForSummaryCategory(selectedCategory, summaryCategories, numberOfAlertsByFeedCategory, goToAlertFeed, primaryFeed)}
    </Grid>
  )
}

export default FeedCategories;