import React, { useState, useEffect } from 'react';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import HighchartsMap from "highcharts/modules/map";
import { useTheme } from '@mui/material/styles';
import {SiteLanguage} from "../../../Constants";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";

const convertHexToRGBA = (hexCode, opacity = 1) => {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
        opacity = opacity / 100;
    }
    //console.log(`rgba(${r},${g},${b},${opacity})`);
    return `rgba(${r},${g},${b},${opacity})`;
};

HighchartsExporting(Highcharts);
HighchartsHeatmap(Highcharts);
HighchartsMap(Highcharts);
NoDataToDisplay(Highcharts);

function FDMap(props) {
    const [topology, setTopology] = useState(undefined);
    //const [data, setData] = useState(props.data);
	  const theme = useTheme();

    const chartTheme = theme.palette.chartTheme;
    const mapMaxColor = convertHexToRGBA(chartTheme.chart1.color);
    const mapMidColor = convertHexToRGBA(chartTheme.chart1.color, 0.5);
    const mapMinColor = convertHexToRGBA(chartTheme.chart1.color, 0.1);
    Highcharts.setOptions({
        colors: props.colors
          ? props.colors
          : [chartTheme.chart1.color, chartTheme.chart2.color, chartTheme.chart3.color, chartTheme.chart4.color, chartTheme.chart5.color, chartTheme.chart6.color, chartTheme.chart7.color, chartTheme.chart8.color],
        xAxis: {
            labels: {
                style: {
                    fontFamily: theme.typography.fontFamily,
                    color: theme.typography.color,
                }
            }
        },
        yAxis: {
            labels: {
                style: {
                    fontFamily: theme.typography.fontFamily,
                    color: theme.typography.color,
                }
            }
        },
        legend: {
            itemStyle: {
                fontFamily: theme.typography.fontFamily,
                color: theme.typography.color,
            }
        },
        plotOptions: {
            series: {
                dataLabels: {
                    style: {
                        fontFamily: theme.typography.fontFamily,
                        color: theme.typography.color,
                    }
                }
            },
        },
        tooltip: {
            style: {
                fontFamily: theme.typography.fontFamily,
                color: theme.typography.color,
            }
        },
        chart: {
            map: {
                color: chartTheme.chart1.color,
            }
        }
    });

    const formatter = "{point.postal-code}: "  + (props.formatting && props.formatting.currency ? "$" : "") + "{point.value:,." + (props.formatting && props.formatting.digits ? props.formatting.digits : 0) + "f}" + (props.formatting && props.formatting.percent ? "%" : "") + (props.formatting && props.formatting.suffix ? props.formatting.suffix : "");

    let options = {
        chart: {
            map: topology,
            borderWidth: 1,
        },

        title: {
            text: null
        },

        subtitle: {
            text: null
        },

        mapNavigation: {
            enabled: true,
            enableButtons: true
        },

        xAxis: {
            labels: {
                enabled: false
            }
        },

        credits: {
            enabled: false
        },

        tooltip: {
            pointFormat: formatter
        },

        legend: {
            layout: 'vertical',
            borderWidth: 0,
            verticalAlign: 'middle',
            align: "right",
            x: -10,
        },

        colorAxis: {
            min: 1,
            type: 'logarithmic',
            minColor: mapMinColor,//'#EEEEFF',
            maxColor: mapMaxColor,//'#000022',
            stops: [
                [0, mapMinColor/*'#EFEFFF'*/],
                [0.67, mapMidColor/*'#4444FF'*/],
                [1, mapMaxColor/*'#000022'*/]
            ]
        },

        series: props.hasData ? [
            {
              name: 'USA',
              keys: ['code', 'value'],
              joinBy: ['postal-code', 'code'],
              dataLabels: {
                enabled: true,
                format: '{point.properties.postal-code}'
              },
              data: props.data
            }
        ] : [],
        lang: {
            noData: SiteLanguage.MissingData
        },
        noData: {
            style: {
                fontWeight: theme.typography.p.fontWeight,
                fontSize: theme.typography.h6.fontSize,
                color: theme.typography.missingData.color,
            }
        },
    }

	useEffect(() => {
        const url = "https://code.highcharts.com/mapdata/countries/us/us-all.topo.json";

        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const mapData = await response.json();
                setTopology(mapData);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
	}, []);

    useEffect(() => {

    }, [props.data]);


	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
          <Typography variant="h6">{props.title}</Typography>
      </Grid>
			<Grid item xs={12}>
				{topology && <HighchartsReact
					constructorType ={'mapChart'}
				    highcharts={Highcharts}
				    options={options}
				  />}
			</Grid>
	    </Grid>
	)
}

export default FDMap;