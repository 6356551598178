import React from "react";
//import { useDispatch } from 'react-redux';
//import { addCommand } from "../../store/commandsSlice";
import GenericRequest from "./GenericRequest";

function DirectRequest(props) {
  //const dispatch = useDispatch();

  function processDirectResponse(result) {
    // TODO: remove ternary, backend will be altered to provide json dict

    const data = result.data ? result.data : result;
    if (props.afterProcess) {
      props.afterProcess(data);
    }
  }

  function processDirectResponseException(result) {
    if (props.handleError) {
      props.handleError(result);
    }
  }

  function processDirectResponseCatchError(error) {
    if (props.handleCatchError) {
      props.handleCatchError(error);
    }
  }

  return (
    <GenericRequest
      processResponse={processDirectResponse}
      processException={processDirectResponseException}
      processCatchError={processDirectResponseCatchError}
      timeout={props.timeout}
      retries={props.retries}
      errorMessageWhitelist={props.errorMessageWhitelist}
      {...props}
    />
  );
}

export default DirectRequest;
