import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import Grid from '@mui/material/Grid';
import TopBar from "../TopBar/TopBar";
import { setCurrentType } from "../../store/alertMessagesSlice";
import AlertMessagesFilters from "./AlertMessagesFilters";
import AlertMessagesTable from "./AlertMessagesTable";
import {
  appNameDisplay,
  message,
  createdBy,
  createdOn,
  alertLink,
  actions
} from "./AlertMessagesColumns";

export const AlertMessages = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setCurrentType("All"))
    }
  }, [])

  const columns = [
    appNameDisplay(),
    message(),
    createdBy(),
    createdOn(),
    alertLink(false),
    actions(false)
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TopBar
          hide_help={true}
          bottom_message={"AI analyst messages"}
          bottom_emphasis={true}
        />
      </Grid>
      <Grid item xs={12}>
        <AlertMessagesFilters/>
      </Grid>
      <Grid item xs={12}>
        <AlertMessagesTable columns={columns}/>
      </Grid>
    </Grid>
  )
}

export default AlertMessages;