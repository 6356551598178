import {createSlice} from "@reduxjs/toolkit";

export const messages_to_show = {
  ALERT: "ALERT",
  GENERAL: "GENERAL",
}

export const create_alert_message_types = {
  alertUuid: "alertUuid",
  developerAppUuid: "developerAppUuid",
  message: "message",
  alertLink: "alertLink",
  createdBy: "createdBy",
  createdOn: "createdOn",
  appNameDisplay: "appNameDisplay"
}

export const getCreateAlertMessageInputLabel = (type, error) => {
  if (!error) {
    return (
      <span>{type}</span>
    )
  } else {
    return (
      <span>{type} - <i>{error}</i></span>
    )
  }
}

export const validateCreateAlertMessageInput = (value, type, company) => {
  if (!value) return "";
  // return obj or if string is not empty use as error boolean and attach to label?
  if (type === create_alert_message_types.createdOn) {
    // ensure iso8601 timestamp
    // if not return "please use a valid ISO 8601 timestamp"
    const dateRegexp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    const validISO8601 = value.match(dateRegexp)
    if (!validISO8601) {
      return "does not match ISO8601 format"
    }
  }
  if (type === create_alert_message_types.alertUuid) {
    // ensure ralt__ uuid prefix
    // if not return "please enter a valid alert uuid"
    const correctPrefix = value.includes("ralt_");
    if (!correctPrefix) {
      return "invalid uuid prefix"
    }
  }
  if (type === create_alert_message_types.developerAppUuid) {
    // ensure dapp__ prefix
    // if not return "please enter a valid developer app uuid"
    const correctPrefix = value.includes("dapp_");
    if (!correctPrefix) {
      return "invalid uuid prefix"
    }
  }
  if (type === create_alert_message_types.createdBy) {
    // ensure email
    const regex = /.*@.*\..*/;
    const validEmail = regex.test(value);
    if (!validEmail) {
      return "invalid email"
    }
    // ensure domain matches company name
    const sameDomain = value.includes(company.name);
    if (!sameDomain) {
      return "wrong domain"
    }
    // message depending on which
  }
  return "";
}

export const validateCreateAlertMessage = (formValues, company) => {
  let valid = true;
  Object.values(create_alert_message_types).forEach(type => {
    if (!formValues[type]) valid = false;
    if (validateCreateAlertMessageInput(formValues[type], type, company) !== "") valid = false;
  });
  return valid;
}

const createAlertMessageState = {
  showForm: false,
  alertUuid: "",
  developerAppUuid: "",
  message: "",
  alertLink: "",
  createdBy: "",
  createdOn: "",
}

export const alertMessagesSysAdminSlice = createSlice({
  name: "alertMessagesSysAdmin",
  initialState: {
    list: [],
    isCaptured: false,
    alertActiveMap: null,
    alertMessagesFeedCategoryMap: null,
    currentType: "All",
    updateArgs: null,
    disableOnClick: false,
    messagesToShow: messages_to_show.ALERT,
    createAlertMessage: createAlertMessageState
  },
  reducers: {
    setAlertMessages: (state, action) => {
      console.warn("setAlertMessages - sysAdmin:", action.payload)
      state.list = action.payload;
      state.isCaptured = true;
      state.disableOnClick = false;
      state.createAlertMessage = createAlertMessageState;
    },
    refreshAlertMessages: (state) => {
      // console.log("refreshAlertMessages - sysAdmin");
      state.updateArgs = null;
      state.isCaptured = false;
    },
    setCurrentType: (state, action) => {
      // console.log("setCurrentType - sysAdmin", action.payload)
      state.currentType = action.payload
    },
    setUpdateAlertMessagesArgs: (state, action) => {
      // console.log("setUpdateAlertMessagesArgs - sysAdmin", action.payload)
      state.updateArgs = action.payload;
      state.disableOnClick = true;
    },
    setAlertActiveMap: (state, action) => {
      // console.log("setAlertActiveMap - sysAdmin", action.payload)
      state.alertActiveMap = action.payload;
    },
    setAlertMessagesFeedCategoryMap: (state, action) => {
      // console.log("setAlertMessagesFeedCategoryMap - sysAdmin", action.payload)
      state.alertMessagesFeedCategoryMap = action.payload;
    },
    resetAlertMessagesSysAdminState: (state) => {
      // console.log("resetAlertMessagesSysAdminState - sysAdmin");
      state.list = [];
      state.isCaptured = false;
      state.alertActiveMap = null;
      state.alertMessagesFeedCategoryMap = null;
      state.currentType = "All";
      state.updateArgs = null;
      state.disableOnClick = false;
      state.messagesToShow = messages_to_show.ALERT;
      state.createAlertMessage = createAlertMessageState;
    },
    setMessagesToShow: (state, action) => {
      // console.log("setMessagesToShow", action.payload)
      state.messagesToShow = action.payload;
    },
    setCreateAlertMessage: (state, action) => {
      // console.log("setCreateAlertMessage", action.payload);
      state.createAlertMessage[action.payload.type] = action.payload.value;
    },
    resetCreateAlertMessage: (state) => {
      // console.log("resetCreateAlertMessage")
      state.createAlertMessage = createAlertMessageState;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setAlertMessages,
  refreshAlertMessages,
  setCurrentType,
  setUpdateAlertMessagesArgs,
  setAlertActiveMap,
  setAlertMessagesFeedCategoryMap,
  resetAlertMessagesSysAdminState,
  setMessagesToShow,
  setCreateAlertMessage,
  resetCreateAlertMessage
} = alertMessagesSysAdminSlice.actions;

export default alertMessagesSysAdminSlice.reducer;