import { createSlice } from '@reduxjs/toolkit';

export const appStateSlice = createSlice({
  name: "appState",
  initialState: {
    isReady: false,
    backendVersion: null,
    frontendVersion: null,
  },
  reducers: {
    setIsReady: (state) => {
      state.isReady = true;
    },
    setBackendVersion: (state, action) => {
      console.log("Server version:", action.payload)
      state.backendVersion = action.payload;
    },
    setFrontendVersion: (state, action) => {
      console.log("Frontend version", action.payload)
      state.frontendVersion = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsReady, setBackendVersion, setFrontendVersion } = appStateSlice.actions;

export default appStateSlice.reducer;