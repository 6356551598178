import React, {useEffect, useState} from "react";
import * as Constants from "../../Constants";
import DirectRequest from "../../API/requests/DirectRequest";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FDGrid from "../FDGrid/FDGrid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";

const dummyUserLogins = [
  {
    "eventId": 1,
    "companyUuid": "special_company_uuid",
    "httpSessionId": "special_session_id",
    "username": "jack@awsdev_testsandbox.com",
    "attributes": null,
    "createdOn": "2024-02-15T18:37:04.012+00:00",
    "createdBy": "system_async"
  }
]

const cleanUserLogins = (userLogins) => {
  return userLogins.map((userLogin, index) => {
    return {...userLogin, Id: index }
  })
}

const columns = [
  {
    "field": 'username',
    "headerName": 'Username',
    "width": 350
  },
  {
    "field": 'createdOn',
    "headerName": 'Created On',
    "width": 200,
    "renderCell": (params) => {
      return new Date(params.value).toLocaleString();
    }
  },
]

export const SysAdminUserLogins = ({ selectedCompany }) => {
  const [selectedCompanyUserLoginsArgs, setSelectedCompanyUserLoginsArgs] = useState();
  const [selectedCompanyUserLogins, setSelectedCompanyUserLogins] = useState([]);
  const [showInternal, setShowInternal] = useState(false);

  useEffect(() => {
    if (selectedCompany && selectedCompany.uuid) {
      if (selectedCompany.name) console.log("selected company", selectedCompany.name);
      setSelectedCompanyUserLoginsArgs({
        url: Constants.SERVER_SYSADMIN_POST_GET_USER_LOGINS_URL + selectedCompany.uuid,
        method: "POST",
        body: JSON.stringify({ includeInternalUsers: showInternal })
      });
      setSelectedCompanyUserLogins([]);
    }
  }, [selectedCompany, showInternal])

  const handleSelectedCompanyUserLogins = (res) => {
    console.log("handleSelectedCompanyUserLogins", res);
    if (res && res.userLoginEventList) {
      setSelectedCompanyUserLogins(cleanUserLogins(res.userLoginEventList));
    }
  }
  const handleShowInternal = () => {
    setShowInternal(!showInternal);
  }

  return (
    <>
      <DirectRequest
        requestArgs={selectedCompanyUserLoginsArgs}
        afterProcess={handleSelectedCompanyUserLogins}
      />
      <Grid container spacing={1}>
        {selectedCompany && selectedCompany.uuid && selectedCompany.name && (
          <Grid item xs={12} container spacing={3} alignItems={"center"}>
            <Grid item>
              <Typography variant={"h6"}>{selectedCompany.name + " logins"}</Typography>
            </Grid>
            <Grid item></Grid>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch checked={showInternal} onChange={handleShowInternal} />
                  }
                  label={showInternal ? "Show internal users enabled" : "Show internal users disabled"}
                />
              </FormGroup>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <FDGrid
            pageSize={100}
            rows={selectedCompanyUserLogins}
            columns={columns}
            getRowId={(row) => row.Id}
            autoHeight={true}
            localeText={{
              noRowsLabel: 'No user logins'
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default SysAdminUserLogins;