import React, {useEffect, useState} from 'react';
import { useSelector } from "react-redux";
import DirectRequest from "../../API/requests/DirectRequest";
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TopBar from "../TopBar/TopBar";
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { SiteLanguage, SERVER_SEND_EMAIL } from "../../Constants";
import Button from '@mui/material/Button';


function RequestCustomAlertForm(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [body, setBody] = useState("");
  const role = useSelector((state) => state.role);
  const [customAlertArgs, setCustomAlertArgs] = useState();
  const [openForm, setOpenForm] = useState(false);


  const steps = [{
    selector: "[data-tut='reactour__description']",
    content: `Enter a description of the custom notification you want here`,
    position: "bottom"
  }, {
    selector: "[data-tut='reactour__submit']",
    content: `Submit the request to our team here`,
    position: "bottom"
  }, {
    selector: "[data-tut='reactour__cancel']",
    content: `You can cancel the request here`,
    position: "bottom"
  },];

  const handleSubmit = () => {
    const requestArgs = {
      url: SERVER_SEND_EMAIL,
      method: "POST",
      body: JSON.stringify({
        "body": `From user: ${role.email}<br/>Custom notification request: ${body}<br/>`,
        "subject": `${role.email} requested a custom notification`,
        "from": "info@wiselayer.com",
        "toEmail": "requests@wiselayer.com",
        "sendHTML": true
      })
    }
    console.log(requestArgs);
    setCustomAlertArgs(requestArgs);
  }

  const afterProcess = () => {
    setOpenForm(false);
  }

  return (
    <React.Fragment>
      <DirectRequest requestArgs={customAlertArgs} afterProcess={afterProcess} />
      {!openForm && (
        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography variant="h6">
                Don't see the notification you are looking for?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button className="dark" variant="contained" onClick={() => setOpenForm(true)}>
                Request a custom notification
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      {openForm && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TopBar top_message={`Request a custom alert`}
                      hide_help={true} steps={steps}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ width: "100%"}}>
                <TextField
                  // label={"Request"}
                  multiline
                  rows={4}
                  variant="outlined"
                  data-tut="reactour__description"
                  onChange={(event) => setBody(event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "1em" }}>
              <Button data-tut="reactour__submit" onClick={handleSubmit} variant="contained" disabled={body === ""}>Send request</Button>
              <Button data-tut={"reactour__cancel"} onClick={() => setOpenForm(false)} variant="outlined" sx={{ marginLeft: "1em", color: theme.palette.bad.main, borderColor: theme.palette.bad.main }}>Cancel</Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

export default RequestCustomAlertForm;