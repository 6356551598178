import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import * as Constants from "../../Constants";
import {useDispatch, useSelector} from "react-redux";
import DirectRequest from "../../API/requests/DirectRequest";
import { setSelectedTab, setPrimaryFeed } from "../../store/alertsTabsSlice";
import { setAlertNotificationArgs } from "../../store/alertsNotificationSlice";
import {generateAlertViewArgs, viewAlertTypes, RedirectLoader, generateDeadLinkArgs} from "./redirectUtils";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

// if alertID is present, use the new EmailRedirect component - state will be determined based on alert state
export const EmailRedirect = ({ alertID }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector((state) => state.role.isInternal);

  const apps = useSelector((state) => state.apps.filteredList);
  const subscribedApps = useSelector((state) => state.appsSubscriptions.list);

  const userAlerts = useSelector((state) => state.alerts.alerts);
  const userResolvedAlerts = useSelector((state) => state.alerts.resolvedAlerts);

  const [tempAlert, setTempAlert] = useState(null);
  const [resolved, setResolved] = useState(false);
  const [deadLinkArgs, setDeadLinkArgs] = useState(null);

  // first, figure out whether it's an alert or a resolved alert, and set the selectedTab accordingly
  useEffect(() => {
    if (userAlerts.find(alert => alert.uuid === alertID)) {
      console.log("alert found in userAlerts")
      if (userAlerts.find(alert => alert.uuid === alertID).flagAiRelevantStatus === "IRRELEVANT") {
        dispatch(setSelectedTab('Irrelevant'))
      } else {
        dispatch(setSelectedTab('Main'))
      }
      if (!userAlerts.find(alert => alert.uuid === alertID).inPrimaryFeed) dispatch(setPrimaryFeed(false))
      setTempAlert(userAlerts.find(alert => alert.uuid === alertID))
    } else if (userResolvedAlerts.find(alert => alert.uuid === alertID)) {
      console.log("alert found in userResolvedAlerts")
      dispatch(setSelectedTab('Resolved'))
      if (!userResolvedAlerts.find(alert => alert.uuid === alertID).inPrimaryFeed) dispatch(setPrimaryFeed(false))
      setTempAlert(userResolvedAlerts.find(alert => alert.uuid === alertID))
    } else {
      console.log("alert not found in userAlerts or userResolvedAlerts - redirecting to home page")
      setResolved(true)
      setDeadLinkArgs(generateDeadLinkArgs(user));
      setTimeout(() => {
        navigate("/")
      }, 3000)
    }
  }, [userAlerts, userResolvedAlerts, alertID, user])

  // next, assemble required state for RecipeDynamic's handleViewArgs/FDAlertFeed -> then navigate to the dashboard
  useEffect(() => {
    if (apps.length > 0 && tempAlert && subscribedApps.length > 0) {
      console.log("apps", apps, "tempAlert", tempAlert, "subscribedApps", subscribedApps)
      const thisDevApp = apps.find(app => app.uuid === tempAlert.developerAppUuid)
      const customerSubscriptionUuid = subscribedApps.find(app => app.developerAppUuid === tempAlert.developerAppUuid).uuid;
      const tempState = {
        customerSubscriptionUuid: customerSubscriptionUuid,
        viewApp: true,
        flagFilterComponentName: tempAlert.flagFilterComponentName,
        flagFilterInput: tempAlert.flagFilterInput,
        thisAlert: tempAlert,
        thisDevApp: thisDevApp,
        companyUuid: "",
        currentTab: 'Main',// doesn't matter - only consumed in Redirect's AppRedirect when a user sent here via FeedTableButton's OpenDashboardButton - this payload will be only consumed by RecipeDynamic
        readyForCustomer: false,
        emailLink: true,
      }
      navigate(`/dashboard/${tempAlert.developerAppUuid}`, { state: { ...tempState, uuid: tempAlert.developerAppUuid } });
      if (!isInternal) {
        const alertViewArgs = generateAlertViewArgs(tempAlert, user, viewAlertTypes.EMAIL);
        dispatch(setAlertNotificationArgs(alertViewArgs));
      }
    }
  }, [apps, tempAlert, subscribedApps, isInternal, user])

  const cancelAction = () => {
    navigate("/")
  }

  return (
    <>
      {!isInternal && (
        <DirectRequest
          requestArgs={deadLinkArgs}
          afterProcess={() => console.log("dead link notification sent")}
          handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("Redirect - email link - dead link notification error", err)}
          handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("Redirect - email link - dead link notification catch error", err)}
        />
      )}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={4}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Grid item>
              <Button
                onClick={cancelAction}
                size="small"
                variant="contained"
                startIcon={<ChevronLeftIcon />}
              >
                Alerts
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <RedirectLoader resolved={resolved} />
        </Grid>
      </Grid>
    </>
  )
}

export default EmailRedirect;