import {
  AppNameDisplayCell,
  FeedTableButtonPicker,
  FeedTableActionsButtonPicker,
  MoneySavedCell,
  AiMessageCell,
  OpenDashboardButton,
  SysAdminMessageButton,
  LastActionTakenCell,
  FlagOverrideAndShowAnywayCell,
  ReferenceDescriptionCell
} from "./FeedTableButtons";
import {formatMoneySaved} from "./FeedTableUtils";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// This file contains the columns assembled by AlertFeed, SysAdminAlertFeed, and FDAlertFeed for the FeedTable component.

export const appNameDisplay = ( currentTab, sysAdmin, preview ) => {
  return {
    field: "appNameDisplay",
    headerName: "Alert type",
    width: 200,
    // sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <AppNameDisplayCell row={params.row} currentTab={currentTab} sysAdmin={sysAdmin} preview={preview} />
      )
    }
  }
}

export const flagDescription = (apps, subscribedApps, companyUuid, currentTab, readyForCustomer, dashboardView, sysAdmin, preview) => {
  return {
    field: "flagDescription",
    headerName: "Details",
    minWidth: 360,//450 before date_patch
    flex: 1,
    // sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <OpenDashboardButton
          row={params.row}
          apps={apps}
          subscribedApps={subscribedApps}
          companyUuid={companyUuid}
          currentTab={currentTab}
          readyForCustomer={readyForCustomer}
          dashboardView={dashboardView}
          sysAdmin={sysAdmin}
          preview={preview}
        />
      )
    }
  }
}

const flagFirstOccurrenceSortComparator = (value1, value2) => {
  // console.log("value1", value1)
  // console.log("value2", value2)
  // console.log("value1.api.state.sorting.sortModel", value1.api.state.sorting.sortModel)
  // console.log("value2.api.state.sorting.sortModel", value2.api.state.sorting.sortModel)
  const sortModel = value1.api.state.sorting.sortModel;
  const flagFirstOccurrenceTimestampSortModel = sortModel.find((sortModel) => sortModel.field === "flagFirstOccurrenceTimestamp");
  //console.log("flagFirstOccurrenceTimestampSortModel", flagFirstOccurrenceTimestampSortModel)
  const sortDirection = flagFirstOccurrenceTimestampSortModel ? flagFirstOccurrenceTimestampSortModel.sort : "asc";
  // sort by date
  const aDate = value1.row.flagFirstOccurrenceTimestamp.split('/');
  const bDate = value2.row.flagFirstOccurrenceTimestamp.split('/');
  const dateCompare = bDate[2] - aDate[2] || bDate[0] - aDate[0] || bDate[1] - aDate[1];

  if (dateCompare !== 0) {
    // If dates are different
    return dateCompare;
  } else {
    // If dates are the same, sort by moneySaved
    const moneySavedCompare = parseFloat(value2.row.moneySaved) - parseFloat(value1.row.moneySaved);
    if (moneySavedCompare !== 0) {
      // NOTE: due to how MUI DataGrid applies sorts, if order is desc we flip the comparison to make sure our moneyCompare, appNameCompare, and flagDescriptionCompare
      //   which are used as tie-breakers to ensure list stability when dateCompare is the same, are applied in the correct order
      return sortDirection === "desc" ? -moneySavedCompare : moneySavedCompare;
    } else {
      // If moneySaved amounts are the same, sort by appName
      const appNameCompare = value1.row.appNameDisplay.localeCompare(value2.row.appNameDisplay);
      if (appNameCompare !== 0) {
        // If appName are different
        return sortDirection === "desc" ? -appNameCompare : appNameCompare;
      } else {
        // If appName are the same, sort by flagDescription
        const flagDescriptionCompare = value1.row.flagDescription.localeCompare(value2.row.flagDescription);
        //return value1.row.flagDescription.localeCompare(value2.flagDescription);
        return sortDirection === "desc" ? -flagDescriptionCompare : flagDescriptionCompare;
      }
    }
  }
};

export const flagFirstOccurrenceTimestamp = (sysAdmin) => {
  return {
    field: "flagFirstOccurrenceTimestamp",
    headerName: "Discovered",
    align: "center",
    width: 90,
    // sortable: true,
    disableColumnMenu: true,
    valueGetter: (params) => params,// Passes full params so sortComparator can access sortModel state
    valueFormatter: (params) => {
      // console.log("valueGetter params", params)
      return params.value.row.flagFirstOccurrenceTimestamp
    },
    sortComparator: flagFirstOccurrenceSortComparator,
    sortingOrder: sysAdmin ? ["desc", null] : ["asc", "desc", null],
    renderCell: (params) => {
      return (
        `${params.row.flagFirstOccurrenceTimestamp}`
      )
    },
  }
}

export const moneySaved = (sysAdmin, currentTab, preview) => {
  return {
    field: "moneySaved",
    headerName: "$ est.",
    width: 70,
    type: "number",
    // sortable: false,
    disableColumnMenu: true,
    sortComparator: (v1, v2) => v2 - v1,
    renderCell: (params) => {
      return (
        <MoneySavedCell
          row={params.row}
          sysAdmin={sysAdmin}
          currentTab={currentTab}
          preview={preview}
        />
      )
    },
    valueFormatter: (params) => {
      return formatMoneySaved(params.api.getRow(params.id).inPrimaryFeed, sysAdmin, params.value);
    },
  }
}

export const referenceDescription = (sysAdmin, currentTab, preview, dashboardView) => {
  return {
    field: "referenceDescription",
    headerName: "Reference",
    width: dashboardView ? 250 : 150,
    // sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <ReferenceDescriptionCell
          row={params.row}
          sysAdmin={sysAdmin}
          currentTab={currentTab}
          preview={preview}
        />
      )
    },
  }

}

export const flagAiRelevantStatus = (sysAdmin, companyUuid, currentTab) => {
  return {
    field: "flagAiRelevantStatus",
    headerName: "",
    align: "center",
    headerAlign: "center",
    width: 70,
    disableExport: !sysAdmin,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <FeedTableButtonPicker
          row={params.row}
          sysAdmin={sysAdmin}
          companyUuid={companyUuid}
          currentTab={currentTab}
        />
      )
    }

  }
}

export const actions = (sysAdmin, companyUuid, currentTab) => {
  return {
    field: "actions",
    headerName: "Actions",
    renderCell: (params) => {
      return (
        <FeedTableActionsButtonPicker
          row={params.row}
          sysAdmin={sysAdmin}
          companyUuid={companyUuid}
          currentTab={currentTab}
        />
      )
    },
    align: "center",
    headerAlign: "center",
    width: !sysAdmin ? 130 : 140,
    disableExport: !sysAdmin,
    sortable: false,
    disableColumnMenu: true,
  }
}
/*
sysAdmin columns:
*/
export const flagAiMessage = () => {
  return {
    field: "flagAiMessage",
    headerName: "Message",
    width: 400,
    // sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <AiMessageCell row={params.row} />
      )
    }

  }
}

export const flagAiMessageSysadmin = () => {
  return {
    field: "flagAiMessageSysadmin",
    headerName: "SysAdmin message",
    width: 200,
    disableColumnMenu: true,
    cellClassName: "bordered-left",
    renderCell: (params) => {
      return (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      )
    }
  }
}

export const lastActionTaken = () => {
  return {
    field: "lastActionTaken",
    headerName: "Last action",
    width: 200,
    // sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <LastActionTakenCell row={params.row} />
      )
    },
    cellClassName: "bordered-left"
  }
}

export const flagRowId = () => {
  return {
    field: "flagRowId",
    headerName: "RowId",
    width: 60,
  }
}

export const flagOverrideAndShowAnyway = () => {
  return {
    field: "flagOverrideAndShowAnyway",
    headerName: "Overridden",
    headerAlign: "center",
    align: "center",
    width: 100,
    renderCell: (params) => {
      return (
        <FlagOverrideAndShowAnywayCell
          row={params.row}
        />
      )
    },
    sortable: false,
    disableColumnMenu: true,
  }
}

/* Original columns from first FeedTable iteration: */
/*
  const columns = useMemo(() => {
    return [
      {
        'field': "appNameDisplay",
        "headerName": "Alert type",
        "width": 200,
        "renderCell": (params) => {
          return (
            <span style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              textWrap: "wrap",
              fontWeight: getFontWeightForRenderCell(params.row, currentTab, sysAdmin, preview),
            }}>{params.value}</span>
          )
        },
        "sortable": false,
        "disableColumnMenu": true,
      },
      {
        'field': "flagDescription",
        "headerName": "Details (AI-generated)",
        "renderCell": (params) => {
          return (
            <Typography
              variant="link"
              sx={{
                ...(!dashboardView && {
                  color: theme.palette.primary.main,
                  "&:hover": { cursor: "pointer" },
                }),
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                textWrap: "wrap",
                fontWeight: getFontWeightForRenderCell(params.row, currentTab, sysAdmin, preview),
              }}
              onClick={!dashboardView ? () => openDashboard(params.row) : null}
            >
              {params.row["flagDescription"]}
            </Typography>
          )
        },
        "width": 570,
        "sortable": false,
        "disableColumnMenu": true,
      }, {
        'field': "flagFirstOccurrenceTimestamp",
        "headerName": "Discovered",
        "align": "center",
        "width": 90,
        "sortComparator": discoveredOnSortComparator,
        "sortable": false,
        "disableColumnMenu": true,
      }, {
        'field': "moneySaved",
        "headerName": "$ est.",
        'width': 70,
        'renderCell': (params) => {
          return (
            <Typography sx={{ fontWeight: getFontWeightForRenderCell(params.row, currentTab, sysAdmin, preview) }}>{sysAdmin ? params.row.moneySaved : getValue(params.row.moneySaved)}</Typography>
          )
        },
        'valueFormatter': (params) => {
          return sysAdmin ? params.value : getValue(params.value);
        },
        "sortable": false,
        "disableColumnMenu": true,
      }].concat(!preview ? [{
      "field": "flagAiRelevantStatus",
      "headerName": "Actions",
      "renderCell": (params) => {
        return (
          <MemoizedFeedTableButtonPicker row={params.row} handleUpdateAlert={handleUpdateAlert} sysAdmin={sysAdmin} disabled={disabled} currentTab={currentTab} />
        )
      },
      "align": "center",
      "headerAlign": "center",
      "width": 100,
      "disableExport": !sysAdmin,
      "sortable": false,
      "disableColumnMenu": true,
    }, {
      "field": "actions",
      "headerName": "",
      "renderCell": (params) => {
        return (
          <>
            {currentTab !== "Resolved" && (<MemoizedFeedTableActionsButtonPicker row={params.row} handleUpdateAlert={handleUpdateAlert} handleSysAdminOverride={handleSysAdminOverride} handleSysAdminReadyForCustomer={handleSysAdminReadyForCustomer} sysAdmin={sysAdmin} disabled={disabled} />)}
          </>
        )
      },
      "align": "center",
      "headerAlign": "center",
      "width": !sysAdmin ? 120 : 80,
      "disableExport": !sysAdmin,
      "sortable": false,
      "disableColumnMenu": true,
    }] : []).concat(sysAdmin ? [{
      "field": "flagAiMessage",
      "headerName": "Message",
      "width": 400,
      "renderCell": (params) => {
        // NOTE: updates to flagAiRelevantStatus by SysAdmins used to set flagAiMessage to "SysAdmin: No message provided" if no message was provided
        // this is no longer the case, but to improve SysAdmin experience on preexisting alerts this will prevent display of "SysAdmin: No message provided"
        return (
          <>
            {params.row.flagAiMessage && params.row.flagAiMessage !== "SysAdmin: No message provided" && (
              <Tooltip title={params.row.flagAiMessage} followCursor>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    textWrap: "wrap",
                  }}
                >{params.row.flagAiMessage}</Typography>
              </Tooltip>
            )}
          </>
        )
      },
      "sortable": false,
      "disableColumnMenu": true,
    }, {
      "field": "flagAiMessageSysadmin",
      "headerName": "SysAdmin message",
      "width": 200,
      "renderCell": (params) => {
        return (
          <SysAdminMessageButton row={params.row} handleSysAdminMessage={handleSysAdminMessage} disabled={disabled} currentTab={currentTab}/>
        )
      },
      "sortable": false,
      "disableColumnMenu": true,
      "cellClassName": "bordered-left"
    },{
      "field": "lastActionTaken",
      "headerName": "Last action",
      "width": 200,
      "renderCell": (params) => {
        return (
          <>
            {params.row.lastActionTaken && (
              <Tooltip title={params.row.lastActionTaken} followCursor>
                <Typography>{params.row.lastActionTaken}</Typography>
              </Tooltip>
            )}
          </>
        )
      },
      "sortable": false,
      "disableColumnMenu": true,
      "cellClassName": "bordered-left"
    }, {
      'field': "flagRowId",
      'headerName': "RowId",
      'width': 60,
    }]:[]).concat(sysAdmin && currentTab === "Main" ? [{
      "field": "flagOverrideAndShowAnyway",
      "headerName": "Overridden",
      "headerAlign": "center",
      "align": "center",
      "width": 100,
      "renderCell": (params) => {
        return (
          <>
            {params.row.flagOverrideAndShowAnyway && <CircleIcon sx={{ color: theme.palette.primary.main }}/>}
          </>
        )
      },
      "sortable": false,
      "disableColumnMenu": true,
    }] : []);
  }, [preview, sysAdmin, currentTab, dashboardView, theme.palette.primary.main, openDashboard, handleUpdateAlert, disabled, handleSysAdminOverride, handleSysAdminReadyForCustomer, handleSysAdminMessage]);
  */