import { createSlice } from '@reduxjs/toolkit';

export const appsSlice = createSlice({
  name: "apps",
  initialState: {
    list: [],
    filteredList: [],
    sources: [],
    isCaptured: false,
    isCalculated: false,
    appsError: {
      isErrored: false,
      errorMessage: "",
    },
    sourcesError: {
      isErrored: false,
      errorMessage: "",
    },
  },
  reducers: {
    setAppsAndSources: (state, action) => {
      state.list = action.payload.list;
      state.sources = action.payload.sources;
      state.isCaptured = true;
    },
    setFilteredList: (state, action) => {
      console.warn("filtered apps", action.payload);
      state.filteredList = action.payload;
      state.isCalculated = true;
    },
    setAppsError: (state, action) => {
      state.appsError.isErrored = true;
      state.appsError.errorMessage = action.payload;
    },
    setSourcesError: (state, action) => {
      state.sourcesError.isErrored = true;
      state.sourcesError.errorMessage = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setAppsAndSources,
  setFilteredList,
  setAppsError,
  setSourcesError,
} = appsSlice.actions;

export default appsSlice.reducer;