import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TagEditor from "../TagEditor/TagEditor";
import ScriptEditor from "../ScriptEditor/ScriptEditor";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import RequiredSourceTableEditor from "../RequiredSourceTableEditor/RequiredSourceTableEditor";
import UnsavedChangesModal from "./UnsavedChangesModal";
import {setActiveTool, setModalState, resetDashboardEditor} from "../../../store/dashboardEditorSlice";
import SummaryCategoriesEditor from "../SummaryCategoryEditor/SummaryCategoryEditor";

export const DashboardEditor = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const activeTool = useSelector((state) => state.dashboardEditor.activeTool);
  const isDirty = useSelector((state) => state.dashboardEditor.isDirty);

  const handleActiveToolSelection = (tool) => {
    if (isDirty) {
      dispatch(setModalState({ open: true, intendedTool: tool }))
    } else {
      dispatch(setActiveTool(tool));
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetDashboardEditor())
    }
  }, [])

  return (
    <Grid container spacing={2}>
      <UnsavedChangesModal />
      <Grid item xs={12} container spacing={2}>
        <Grid item>
          <Typography
            variant="h4"
            sx={activeTool === "Tags" ? { color: theme.palette.primary.main, borderBottom: "2px solid " + theme.palette.primary.main, paddingBottom: "3px"} : { cursor: "pointer" }}
            onClick={activeTool === "Tags" ? null : () => handleActiveToolSelection("Tags")}
          >
            Tags
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            sx={activeTool === "Script" ? { color: theme.palette.primary.main, borderBottom: "2px solid " + theme.palette.primary.main, paddingBottom: "3px"} : { cursor: "pointer" }}
            onClick={activeTool === "Script" ? null : () => handleActiveToolSelection("Script")}
          >
            Script
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            sx={activeTool === "Source Tables" ? { color: theme.palette.primary.main, borderBottom: "2px solid " + theme.palette.primary.main, paddingBottom: "3px"} : { cursor: "pointer" }}
            onClick={activeTool === "Source Tables" ? null : () => handleActiveToolSelection("Source Tables")}
          >
            Source Tables
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            sx={activeTool === "Summary Categories" ? { color: theme.palette.primary.main, borderBottom: "2px solid " + theme.palette.primary.main, paddingBottom: "3px"} : { cursor: "pointer" }}
            onClick={activeTool === "Summary Categories" ? null : () => handleActiveToolSelection("Summary Categories")}
          >
            Summary Categories
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {activeTool === "Tags" ? <TagEditor /> : activeTool === "Script" ? <ScriptEditor /> : activeTool === "Source Tables" ? <RequiredSourceTableEditor /> : <SummaryCategoriesEditor />}
      </Grid>
    </Grid>
  )
}

export default DashboardEditor