import { useDispatch, useSelector } from "react-redux";
import { setRole, setIsErrored } from "../../store/roleSlice";
import DirectRequest from "./DirectRequest";
import { setClusterState } from "../../store/clusterStateSlice";
import { setSelectedIntegrations } from "../../store/selectedIntegrationsSlice";
import { setShowModal } from "../../store/summaryNotificationsModalSlice";

export default function RoleRequest() {
  const dispatch = useDispatch();
  const roleCaptured = useSelector((state) => state.role.isCaptured);

  const handleRoleRes = (res) => {
    if (res) {
      const flatRes = { ...res, ...res.customerMetadata } // probably need to check for restrictedWhitelist here
      dispatch(setRole(flatRes));
      if (flatRes.selectedConnectorNames) dispatch(setSelectedIntegrations(flatRes.selectedConnectorNames));
      if (res.clusterState && res.clusterState.state) dispatch(setClusterState({ state: res.clusterState.state }));
      if (flatRes.roles.includes("ROLE_RL_RESTRICTED_USER") && flatRes.restricted_user_allowed_apps) {
        dispatch(setShowModal(false));
      }
    }
  };

  const handleRoleError = (err) => {
    console.error("RoleRequest error", err)
    dispatch(setIsErrored())
  }


  return (
    <>
      {!roleCaptured && (<DirectRequest requestArgs={{}} afterProcess={handleRoleRes} handleError={handleRoleError} handleCatchError={handleRoleError}/>)}
    </>
  );
}