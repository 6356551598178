import {useDispatch} from "react-redux";
import * as Constants from "../../Constants";
import {changeToListening} from "../../store/customerSubsSlice";
import AsyncRequest from "./AsyncRequest";
import React from "react";

export const CustomerSubListenerRequest = ({ customerSubscription }) => {
  const dispatch = useDispatch();

  const listenForDataSyncArgs = {
    url: Constants.SERVER_LISTEN_FOR_DATA_SYNC_URL,
    method: "POST",
    body: JSON.stringify({
      customerSubscriptionUuid: customerSubscription.uuid
    })
  }

  const handleAfterListenerCreation = (res) => {
    console.log("CustomerSubListenerRequest -- listenForDataSync created for " + customerSubscription.uuid)
    dispatch(changeToListening(customerSubscription.uuid));
  };

  const handleListenerCreationError = (err) => {
    console.log("CustomerSubListenerRequest handleListenerCreationError - err", err);
  }

  return (
    <AsyncRequest
      requestArgs={listenForDataSyncArgs}
      afterProcess={handleAfterListenerCreation}
      handleError={handleListenerCreationError}
      handleCatchError={handleListenerCreationError}
    />
  );
}

export default CustomerSubListenerRequest;