import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Dropzone from "./Dropzone";
import AceEditor from "react-ace";
import { setScript, setFile } from "../../store/appBuilderSlice";

import "ace-builds/src-noconflict/mode-python"; // imported python language
import "ace-builds/src-noconflict/theme-textmate"; // imported theme
import "ace-builds/src-noconflict/ext-language_tools";

const editorCardStyle = {
  boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  height: "100%",
}

const uploadCardStyle = {
  boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  width: "100%",
}

const disabledCardStyle = {
  filter: "opacity(50%)",
  pointerEvents: "none",
}

const getCardStyle = (activeBuilder, loading, previewReady, previewError) => {
  if (loading || previewReady || previewError) {
    return { ...disabledCardStyle, ...(activeBuilder === "Editor" ? editorCardStyle : uploadCardStyle)};
  } else {
    return activeBuilder === "Editor" ? editorCardStyle : uploadCardStyle;
  }
}


export const AppBuilderActiveBuilder = () => {
  const dispatch = useDispatch();
  const activeBuilder = useSelector(state => state.appBuilder.activeBuilder);
  const script = useSelector(state => state.appBuilder.script);
  const file = useSelector(state => state.appBuilder.selectedFile);

  const loading = useSelector((state) => state.previewApp.loading);
  const previewReady = useSelector((state) => state.previewApp.ready);
  const previewError = useSelector((state) => state.previewApp.error.isErrored);

  const onChange = (newValue) => {
    dispatch(setScript({ script: newValue }));
  };

  const handleFileChange = (newFile) => {
    dispatch(setFile({ selectedFile: newFile }));
  };

  return (
    <>
      {activeBuilder === "Editor" && (
        <Card sx={getCardStyle(activeBuilder, loading, previewReady, previewError)}>
          <AceEditor
            mode="python"
            theme="textmate"
            onChange={onChange}
            value={script}
            name="UNIQUE_ID_OF_DIV"
            editorProps={{ $blockScrolling: true }}
            showPrintMargin={false}
            style={{
              marginTop: "1%",
              marginBottom: "1%",
              marginRight: "1%",
              marginLeft: "1%",
              width: "98%",
            }}
          />
        </Card>
      )}
      {activeBuilder === "Upload" && (
        <Card sx={getCardStyle(activeBuilder, loading, previewReady, previewError)}>
          <Grid container spacing={1} sx={{ mb: "2rem" }}>
            <Grid item xs={12}>
              <Dropzone script handleFileChange={handleFileChange} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="bold" sx={{ ml: "2rem", mr: "2rem" }}>
                Selected file:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {file && (
                <Typography
                  variant="p"
                  sx={{
                    ml: "2rem",
                    mr: "2rem",
                    fontWeight: "bold",
                  }}
                >
                  {file.path} - {file.size} bytes
                </Typography>
              )}
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  )
}

export default AppBuilderActiveBuilder;