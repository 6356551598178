import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import { setIsOnboarding } from "../../../store/selectedIntegrationsSlice";
import IntegrationCard from "../IntegrationCard";
import AddIntegrationCard from "../AddIntegrationCard";
import Button from "@mui/material/Button";
import * as Constants from "../../../Constants";
import SubscribeToAllApps from "./SubscribeToAllApps";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import AddchartIcon from "@mui/icons-material/Addchart";
import Tooltip from "@mui/material/Tooltip";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';


const SysAdminActionButtons = () => {
  const navigate = useNavigate();
  const isSysAdmin = useSelector((state) => state.role.isSysAdmin);

  const goToBuilder = () => {
    navigate("/build_dashboard")
  }

  const goToControls = () => {
    navigate("/sysadmin_controls")
  }

  const actions = [
    { icon: <SupervisedUserCircleIcon sx={{ color: "white !important"}} />, title: 'Go to company controls', onClick: goToControls },
    { icon: <AddchartIcon sx={{ color: "white !important"}} />, title: 'Go to dashboard builder', onClick: goToBuilder },
  ]

  return isSysAdmin ? (
    <Box
      sx={{
        position: 'absolute',
        top: '-50px',
        right: '0px',
      }}
    >
      <Grid container spacing={1}>
        {actions.map((action) => (
          <Grid item key={action.title}>
            <Tooltip title={action.title} followCursor>
              <IconButton
                onClick={action.onClick}
                sx={{
                  backgroundColor: "rgba(0,0,0,0.2) !important",
                  "&:hover": {
                    backgroundColor: "rgba(0,0,0,0.4) !important",
                  },
                }}
              >
                {action.icon}
              </IconButton>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Box>
  ) : null
}


export const IntegrationModal = ({ open, selectedIntegrations, connectedIntegrations, disabled }) => {
  const dispatch = useDispatch();
  const [subscribeToAllApps, setSubscribeToAllApps] = useState(false);

  const completeSetup = () => {
    dispatch(setIsOnboarding(false))
    setSubscribeToAllApps(true)
  }

  const logout = (event) => {
    const logoutUrl = Constants.SERVER_LOGOUT_URL;
    dispatch({ type: "RESET" });
    localStorage.setItem("isLoggingOut", "true");
    if (localStorage.getItem("previewPercent")) {
      localStorage.removeItem("previewPercent");
      localStorage.removeItem("previewPercentTimestamp");
    }
    window.open(logoutUrl, "_self");
  };

  return (
    <>
      {subscribeToAllApps && (
        <SubscribeToAllApps/>
      )}
      <Modal open={open}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "80%",
              maxHeight: "90%",
              borderRadius: "10px",
              backgroundColor: "white !important",
              display: "flex", // To make flex work in the direction
              flexDirection: "column", // Flex direction to be column
              position: 'relative' // Added relative positioning
            }}
          >
            <SysAdminActionButtons/>
            <Box
              sx={{
                display: "flex", // Ensures the content is in the center
                alignItems: "center",
                justifyContent: "center",
                padding: "1rem", // Give some space around
                boxShadow: "0 5px 10px rgba(0,0,0,0.1)", // Upward shadow
              }}
            >
              <Grid item>
                <Typography variant={"h4"}>
                  Welcome! Please select which systems you'd like to connect.
                </Typography>
              </Grid>
            </Box>
            <Box
              sx={{
                overflowY: "auto", // Ensures inner box scrolls if the content overflows
                flexGrow: 1, // Takes up all the available space
              }}
            >
              <Grid container spacing={2} justifyContent={"center"} sx={{ padding: "20px"}}>
                {selectedIntegrations.map((integration) => (
                  <IntegrationCard
                    integration={integration}
                    connectedIntegrations={connectedIntegrations}
                    selectedIntegrations={selectedIntegrations}
                    key={`${integration}_connect_card`}
                    onboarding={true}
                  />
                ))}
                <AddIntegrationCard selectedIntegrations={selectedIntegrations} />
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex", // Ensures the content is in the center
                alignItems: "center",
                justifyContent: "center",
                padding: "1rem", // Give some space around
                boxShadow: "0 -5px 10px rgba(0,0,0,0.1)", // Upward shadow
                position: 'relative',
              }}
            >
              <Button
                variant={"contained"}
                onClick={completeSetup}
                disabled={disabled || subscribeToAllApps}
                startIcon={subscribeToAllApps ? <CircularProgress size={"1rem"} /> : null}
                aria-label='complete-setup'
              >
                Complete setup
              </Button>
              <Button
                sx={{ position: "absolute", right: "1rem" }}
                variant={"outlined"}
                onClick={logout}
                startIcon={<LogoutIcon />}
              >
                Logout
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export const InitialIntegrationModal = () => {
  const dispatch = useDispatch();

  const subscribedApps = useSelector((state) => state.appsSubscriptions.list);
  const isCaptured = useSelector((state) => state.appsSubscriptions.isCaptured);

  const connectedIntegrations = useSelector((state) => state.integrationMetadata.integrations)

  const selectedIntegrations = useSelector((state) => state.selectedIntegrations.integrations);
  const isOnboarding = useSelector((state) => state.selectedIntegrations.isOnboarding);

  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);

  // checks selectedIntegrations and subscribedApps (if either is length === 0, sets isOnboarding to true
  // - this will happen until user clicks the complete setup button which subscribes user to all apps
  useEffect(() => {
    if (isCaptured && (selectedIntegrations.length === 0 || subscribedApps.length === 0)) {
      dispatch(setIsOnboarding(true))
    }
  }, [selectedIntegrations, subscribedApps, isCaptured]);

  // checks isOnboarding (if true, sets open to true to display connector selection menu)
  useEffect(() => {
    if (isOnboarding) {
      setOpen(true);
    }
  }, [isOnboarding]);

  useEffect(() => {
    if (selectedIntegrations.length === 0) return;
    let tempDisabled = false;
    selectedIntegrations.forEach((selectedIntegration) => {
      if (!connectedIntegrations.find((connectedIntegration) => connectedIntegration.sourceName === selectedIntegration)) {
        tempDisabled = true;
      }
    });
    setDisabled(tempDisabled);
  }, [connectedIntegrations, selectedIntegrations])

  return (
    <Grid container>
      <IntegrationModal
        open={open}
        selectedIntegrations={selectedIntegrations}
        connectedIntegrations={connectedIntegrations}
        disabled={disabled}
        subscribedApps={subscribedApps}
      />
    </Grid>
  )
}

export default InitialIntegrationModal;
