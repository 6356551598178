import { createSlice } from '@reduxjs/toolkit';

const alertConfigsInitialState = {
  companyUuid: "",
  selectedAppUuid: "",
  alertConfigsCaptured: false,
  subscriptionsCaptured: false,
  alertConfigs: [],
  subscriptions: [],
  isUpdating: false,
  updateArgs: [],
}

export const alertConfigsSysAdminSlice = createSlice({
  name: "alertConfigsSysAdmin",
  initialState: alertConfigsInitialState,
  reducers: {
    setSubscriptions: (state, action) => {
      console.warn("alertConfigsSysAdmin - setSubscriptions", action.payload); // subscriptions request will dispatch
      state.companyUuid = action.payload.companyUuid;
      state.subscriptions = action.payload.subscriptions;
      state.subscriptionsCaptured = true;
    },
    setAlertConfigs: (state, action) => {
      console.warn("alertConfigsSysAdmin - setAlertConfigs", action.payload); // alert configs request will dispatch
      state.alertConfigs = action.payload;
      state.alertConfigsCaptured = true;
      state.isUpdating = false;
    },
    setSelectedAppUuid: (state, action) => {
      console.log("setSelectedAppUuid", action.payload); // app select handleChange will dispatch
      state.selectedAppUuid = action.payload;
      state.alertConfigs = [];
      state.alertConfigsCaptured = false;
    },
    refreshAlertConfigs: (state) => {
      console.log("refreshAlertConfigs"); // update request will dispatch once all updateArgs sent
      state.alertConfigsCaptured = false;
      state.updateArgs = [];
      // state.isUpdating = false;
    },
    resetAlertConfigs: () => alertConfigsInitialState,
    updateConfigValue: (state, action) => {
      console.log("updateConfigValue", action.payload) // input onChange will dispatch
      const { index, newValue, error, errorMessage } = action.payload;
      state.alertConfigs[index].newValue = newValue;
      state.alertConfigs[index].error = error;
      state.alertConfigs[index].errorMessage = errorMessage;
    },
    updateConfigDisplayNote: (state, action) => {
      console.log("updateConfigDisplayNote", action.payload) // display note onChange will dispatch
      const { index, newNote } = action.payload
      state.alertConfigs[index].newNote = newNote;
    },
    setUpdateArgs: (state, action) => {
      console.log("setAlertConfigsUpdateArgs", action.payload) // update button will dispatch
      state.updateArgs = action.payload;
      state.isUpdating = true
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSubscriptions,
  setAlertConfigs,
  setSelectedAppUuid,
  refreshAlertConfigs,
  resetAlertConfigs,
  updateConfigValue,
  updateConfigDisplayNote,
  setUpdateArgs
} = alertConfigsSysAdminSlice.actions;

export default alertConfigsSysAdminSlice.reducer;