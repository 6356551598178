import React from 'react';
import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.secondary.main,
	padding: "1em 2em",
	marginLeft: "-2em",
	marginRight: "-2em",
	marginTop: "1em",
	borderTop: "1px solid " + theme.palette.border.light,
	borderBottom: "1px solid " + theme.palette.border.light,
}));

function ImpactBar(props) {
	
	return (
		<StyledDiv>
			{props.component}
		</StyledDiv>
	)
}

export default ImpactBar;