import { Grid, Button } from "@mui/material"
import DownloadIcon from '@mui/icons-material/Download';
import {useDispatch, useSelector} from "react-redux";
import { useState, useMemo } from "react";
import { dashboardConfigsSelectors } from "../../../../../../store/dashboardConfigsSlice";
import { config_options } from "../../../../../../store/dashboardConfigsSlice";
import DirectRequest from "../../../../../../API/requests/DirectRequest";
import * as Constants from "../../../../../../Constants";
import Papa from 'papaparse';
import { generateExportNotificationArgs } from "../../../../../AlertFeed/FeedTableUtils";
import iso8601Timestamp from "../../../../../../helpers/iso8601Timestamp";
import { setAlertNotificationArgs } from "../../../../../../store/alertsNotificationSlice";

export const DownloadJournalEntryCSVButton = ({ preview, companyUuid }) => {
  // uuid for app
  // entries to exclude - displayConfigRows w/ createEntry where status is also done -> not status tracker done but override column done
  const dispatch = useDispatch();
  const isInternal = useSelector((state) => state.role.isInternal);
	const user = useSelector((state) => state.role.name);
  
  const dashboardUuid = useSelector(state => state.dashboard.uuid);
  const dashboardJson = useSelector((state) => state.dashboard.dashboardJson);
  const tables = useSelector((state) => state.dashboard.tables);
  const displayConfigs = useSelector((state) => dashboardConfigsSelectors.selectAll(state));
  const showButton = useMemo(() => {
    // console.log("showButton - dashboardJson", dashboardJson);
    let show = false;
    Object.values(dashboardJson.components).forEach((component) => {
      if (component.type === "table" && component.createEntryConfig) {
        show = true;
      }
    })
    return show;
  }, [dashboardJson])

  const [downloadCSVArgs, setDownloadCSVArgs] = useState(null);

  const generateDownloadCSVArgs = () => {
    /*
      // url will be dependant on companyUuid (user vs sysadmin w/ companyUuid)
      body: {
        uuid: appUuid
        entriesToExclude: [...] // list of displayConfigTableRow.displayConfigOptions.create_entry where createEntryConfig.overrideColumnName is falsey
      }
    */
   const entriesToExclude = [];
   // get all displayConfigs for this app
   console.log("generateDownloadCSVArgs - displayConfigs", displayConfigs)
   displayConfigs.forEach((displayConfig) => {
    // get each displayConfig
    // find it's objectName
    const objectName = displayConfig.objectName;
    const sourceTable = displayConfig.sourceTable;
    // get component definition for objectName
    const component = dashboardJson.components[objectName];
    // get statusTrackerConfig
    const statusTrackerConfig = component.statusTrackerConfig;
    // ok, now loop through each displayConfigTableRows
    displayConfig.displayConfigTableRows.forEach((displayConfigTableRow) => {
      // if displayConfigTableRow.create_entry
      const createdEntry = displayConfigTableRow.displayConfigOptions[config_options.create_entry];
      if (createdEntry) {
        //  get table (displayConfig.sourceTable or objectName.sourceTable - they are the same)  
        const table = tables[sourceTable];
        const columns = table.data[0];
        const rows = table.data.slice(1);
        let excluded = false;
        rows.forEach((row) => {
          if (excluded) return;
          // find matching table row
          const matchingTableRow = Object.entries(displayConfigTableRow.primaryKeyValueMap).every(([primaryKey, value]) => row[columns.indexOf(primaryKey)] === value);
          if (matchingTableRow) {
            // check statusTrackerConfig.overrideColName of row -> if truthy and row.create_entry -> add row.create_entry to entriesToExclude
            const overridden = row[columns.indexOf(statusTrackerConfig.overrideColName)];
            if (overridden) {
              console.log(`overridden row found for created entry ${createdEntry}`, row);
              excluded = true;
              entriesToExclude.push(createdEntry);
            }
          }
        })  
      }
    })
   })
   // great, now we have entries to exclude
   // construct rest of args - these are "global" to the dashboard 
   // 'destination' and 'entryType' are read from rows off one of the accrual tables
   // 'entryPeriod' is read from one of the createEntryConfigs
   let argsTable;
   let createEntryConfig;
   displayConfigs.forEach((displayConfig) => {
    if (argsTable) return;
    const tempArgsTable = tables[displayConfig.sourceTable]?.data;
    // console.log("tempArgsTable", tempArgsTable);
    if (tempArgsTable && tempArgsTable.length > 1) {
      argsTable = tempArgsTable;
      createEntryConfig = dashboardJson.components[displayConfig.objectName].createEntryConfig;
    }
   })
  //  console.log("argsTable", argsTable);
  //  console.log("createEntryConfig", createEntryConfig);

   if (!argsTable) {
    console.warn("No accrual rows for download CSV args")
   } 
   if (!createEntryConfig) {
    console.warn("No createEntryConfig for download CSV args")
   }
   if (!argsTable || !createEntryConfig) {
    return;
   } 
   
   const destinationIndex = argsTable[0].indexOf(createEntryConfig.destinationColumn);
   const destination = argsTable[1][destinationIndex];

   const entryTypeIndex = argsTable[0].indexOf(createEntryConfig.entryTypeColumn);
   const entryType = argsTable[1][entryTypeIndex];

   const tempArgs = {
    url: companyUuid ? Constants.SERVER_SYSADMIN_POST_GET_JOURNAL_ENTRIES_URL + companyUuid : Constants.SERVER_POST_GET_JOURNAL_ENTRIES_URL,
    method: "POST",
    body: JSON.stringify({
      entriesToExclude: entriesToExclude,
      destination: destination,
      entryType: entryType,
      entryPeriod: createEntryConfig.entryPeriod,
      developerAppUuid: dashboardUuid
      /*
      destinationColumn, entryTypeColumn, postingPeriodColumn, postingTranDateColumn, addReversalFlagColumn, reversalPostingPeriodIdColumn, entryPeriod

      "destination": String,      -> createEntryConfig.destinationColumn -> check 1st row
	    "entryType": String,        -> createEntryConfig.entryTypeColumn   -> check 1st row
	    "entryPeriod": String,      -> createEntryConfig.entryPeriod       -> string value from createEntryConfig
	    "entriesToExclude": List<String>,     -> constructed above
	    "resultFilename": String (optional, defaults to entry_import.csv) -> WILL EVENTUALLY COME IN CREATE ENTRY CONFIG
      */
    })
   }
   // setArgs to state
   setDownloadCSVArgs(tempArgs);
  }

  const handleCSV = (res) => {
    console.log("handleCSV - res", res);
    // res = array of arrays
    // use PapaParse to turn it into a csv and download it to client machine
    // Convert the array of arrays (res) into CSV format using PapaParse
    const csv = Papa.unparse(res.file);

    // Create a Blob from the CSV string
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    // Create a link element to trigger the file download
    const link = document.createElement('a');

    // Check if the download attribute is supported by the browser
    if (link.download !== undefined) {
      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Set the href attribute of the link to the Blob URL
      link.setAttribute('href', url);

      // Set the download attribute to the desired file name
      link.setAttribute('download', 'journal_entries.csv');// TODO: name of export??

      // Append the link to the document (required for Firefox)
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the document after the download starts
      document.body.removeChild(link);
    }
    setDownloadCSVArgs(null);
    // send notification
    if (!companyUuid && !isInternal) {
      dispatch(setAlertNotificationArgs(generateExportNotificationArgs('journal_entries.csv', user, csv)));
    }
  }
  
  return showButton ? (
    <>
    {/* direct request here */}
      <DirectRequest
        requestArgs={downloadCSVArgs}
        afterProcess={handleCSV}
      />
      <Grid item>
        <Button
          onClick={generateDownloadCSVArgs}
          size="small"
          variant="contained"
          disabled={preview}
          startIcon={<DownloadIcon />}
        >
          Download Journal Entries CSV
        </Button>
      </Grid>
    </>
  ) : null;
}

export default DownloadJournalEntryCSVButton;