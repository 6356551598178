import { createSlice } from '@reduxjs/toolkit';

const initialDashboardState = {
  companyUuid: "",// new 9/4
  getDataArgs: null,
  getDataError: {
    isErrored: false,
    message: null,
  },
  configsReady: false,
  dashboardJson: null,
  tables: null,
  rows: null,// new 9/4
  uuid: "",
}

export const getDataArgsPayload = (getDataArgs, companyUuid, uuid) => {
  return {
    getDataArgs: getDataArgs,
    companyUuid: companyUuid ?? "",
    uuid: uuid,
  }
}

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialDashboardState,
  reducers: {
    setUuid: (state, action) => {
      console.log("setUuid", action.payload);
      state.uuid = action.payload;
    },
    setGetDataArgs: (state, action) => {
      console.log("setGetDataArgs", action.payload);
      const { getDataArgs, companyUuid, uuid } = action.payload;
      state.getDataArgs = getDataArgs;
      state.companyUuid = companyUuid;
      state.uuid = uuid;
    },
    setGetDataError: (state, action) => {
      // console.log("setGetDataError", action.payload);
      state.getDataError = {
        isErrored: true,
        message: action.payload
      };
    },
    setDashboardData: (state, action) => {
      console.log("setDashboardData", action.payload);
      console.warn("dashboardJson", action.payload.dashboardJson);
      console.warn("tables", action.payload.tables);
      state.dashboardJson = action.payload.dashboardJson;
      state.tables = action.payload.tables;
      state.getDataArgs = null;
    },
    setConfigsReady: (state) => {
      console.log("setConfigsReady");
      state.configsReady = true;
    },
    setDashboardPreviewData: (state, action) => {
      console.log("setDashboardPreviewData", action.payload);
      console.warn("preview dashboardJson", action.payload.dashboardJson);
      console.warn("preview tables", action.payload.tables);
      state.dashboardJson = action.payload.dashboardJson;
      state.tables = action.payload.tables;
      state.configsReady = true
    },
    setRows: (state, action) => {
      console.log("setRows", action.payload);
      state.rows = action.payload;
    },
    resetDashboardSlice: (state) => {
      console.log("resetDashboardSlice");
      return initialDashboardState;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setGetDataArgs,
  setGetDataError,
  setDashboardData,
  setConfigsReady,
  setDashboardPreviewData,
  setRows,
  resetDashboardSlice
} = dashboardSlice.actions;

export default dashboardSlice.reducer;