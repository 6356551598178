import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useSelector, useDispatch} from "react-redux";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import SearchIcon from '@mui/icons-material/Search';
import {ReactComponent as OutlinedShieldIcon} from "../../assets/outlined_shield.svg";
import { setShowModal } from "../../store/summaryNotificationsModalSlice"
import {generateOpenedSummaryModalNotificationArgs} from "./FeedTableUtils";
import {setAlertNotificationArgs} from "../../store/alertsNotificationSlice";

const getMetricHeaderSpanStyle = (theme) => {
  return {
    backgroundColor: theme.palette.secondary.main,
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.6)",
    padding: "2px 4px",
    borderRadius: "4px"
  }
}

const Status = () => {
  const theme = useTheme();
  return (
    <Grid item xs={2}>
      <>
        <Typography variant={"p"}><span style={getMetricHeaderSpanStyle(theme)}>Status</span></Typography>
        <Typography sx={{fontSize: "10px !important", display: "flex", alignItems: "center", paddingLeft: "2px" }}>{<CircleIcon
          sx={{fill: "green", fontSize: "14px !important" }} />}&nbsp;AI monitoring:&nbsp;<span style={{ fontSize: "11px", fontWeight: 500 }}>Live</span></Typography>
      </>
    </Grid>
  )
}

const addCommasToNum = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getDataPointsToday = (summaryMetrics) => {
  if (summaryMetrics.today.rowsProcessed === 0) {
    return addCommasToNum(summaryMetrics?.last7Days?.rowsProcessed);
  } else {
    return addCommasToNum(summaryMetrics?.today?.rowsProcessed)
  }
};

const getDataPointsFullHistory = (summaryMetrics) => {
  return addCommasToNum(summaryMetrics?.fullHistory?.rowsProcessed);
}

const DataPointsAnalyzed = ({ vwSub1070 }) => {
  const theme = useTheme();
  const summaryMetrics = useSelector((state) => state.summaryMetrics.summary);
  return (
    <Grid item xs={!vwSub1070 ? 5 : 7} container spacing={1}>
      <Grid item xs={12}>
        <Typography variant={"p"}><span style={getMetricHeaderSpanStyle(theme)}>Your data points analyzed</span></Typography>
      </Grid>
      <Grid item xs={12} container spacing={1} sx={{ paddingTop: "0px !important", paddingLeft: "10px !important"}}>
        <Grid item xs={6}>
          <Typography component="div" noWrap sx={{ display: "flex", alignItems: "center" }}>
            <SearchIcon sx={{ fontSize: "14px !important" }} />&nbsp;
            <Box component="span" sx={{ fontSize: '11px', fontWeight: 500 }}>
              {getDataPointsToday(summaryMetrics)}
            </Box>
            &nbsp;
            <Box component="span" sx={{ fontSize: '10px', fontWeight: 600, color: "rgba(0, 0, 0, 0.6)"  }}>
              <i>past 24 hours</i>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography component="div" noWrap sx={{ display: "flex", alignItems: "center" }}>
            <SearchIcon sx={{ fontSize: "14px !important" }} />&nbsp;
            <Box component="span" sx={{ fontSize: '11px', fontWeight: 500 }}>
              {getDataPointsFullHistory(summaryMetrics)}
            </Box>
            &nbsp;
            <Box component="span" sx={{ fontSize: '10px', fontWeight: 600, color: "rgba(0, 0, 0, 0.6)" }}>
              <i>cumulative</i>
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const TotalToDateValue = () => {
  const displayProfitabilityValue = useSelector((state) => state.role.displayProfitabilityValue);
  // const displayValue = totalProfitabilityToDate.toLocaleString('en-US', {
  //   minimumFractionDigits: 0,
  //   maximumFractionDigits: 0,
  // });
  // console.log("displayValue", displayValue)
  return (
    <Typography>
      <span
        style={{
          fontSize: '16px',
          fontWeight: 500,
        }}
      >
        ${displayProfitabilityValue}&nbsp;
      </span>
      <span
        style={{
          fontSize: '12px',
          fontWeight: 600,
          color: "rgba(0, 0, 0, 0.6)"
        }}
      >
        <i>total to date</i>
      </span>
    </Typography>
  )
}

const TotalActiveValue = () => {
  const alerts = useSelector((state) => state.alerts.alerts);
  const mainFilter = useSelector((state) => state.alertsTabs.filters.Main);
  const primaryFilter = useSelector((state) => state.alertsTabs.filters.Primary);
  const primaryFeed = useSelector((state) => state.alertsTabs.primaryFeed);
  const alertsToTotal = primaryFeed ? primaryFilter(alerts) : mainFilter(alerts);
  // console.log("alertsToTotal", alertsToTotal)
  let totalValue = 0;
  alertsToTotal.forEach((alert) => {
    totalValue += alert.moneySaved;
  });
  // console.log("totalValue", totalValue)
  const formattedTotalValue = totalValue.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <Typography>
        <span
          style={{
            fontSize: '16px',
            fontWeight: 500,
          }}
        >
          ${formattedTotalValue}&nbsp;
        </span>
      <span
        style={{
          fontSize: '12px',
          fontWeight: 600,
          color: "rgba(0, 0, 0, 0.6)"
        }}
      >
          <i>active in feed</i>
        </span>
    </Typography>
  )
}

const ProfitabilityMetrics = ({ vwSub1070 }) => {
  const theme = useTheme();
  return (
    <Grid item xs={!vwSub1070 ? 5 : 7} container spacing={1}>
      <Grid item xs={12}>
        <Typography variant={"p"}><span style={getMetricHeaderSpanStyle(theme)}>WiseLayer attributed profit</span></Typography>
      </Grid>
      <Grid item xs={6} sx={{ paddingTop: "1px !important", paddingLeft: "10px !important"}}>
        <TotalToDateValue />
      </Grid>
      <Grid item xs={6} sx={{ paddingTop: "1px !important"}}>
        <TotalActiveValue />
      </Grid>
    </Grid>
  )
}

const ProtectedAreas = ({ vwSub860 }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector(state => state.role.name);
  const isInternal = useSelector(state => state.role.isInternal);
  const handleProtectedAreaClick = () => {
    dispatch(setShowModal(true));
    if (!isInternal) {
      const tempOpenedSummaryModalNotificationArgs = generateOpenedSummaryModalNotificationArgs(user)
      dispatch(setAlertNotificationArgs(tempOpenedSummaryModalNotificationArgs))
    }
  }
  return (
    <Grid item xs={!vwSub860 ? 4 : 12} container spacing={1}>
      <Grid item xs={12}>
        <Typography variant={"p"}><span style={getMetricHeaderSpanStyle(theme)}>AI-monitored protections</span></Typography>
      </Grid>
      <Grid item xs={12} sx={{paddingTop: "0px !important", paddingLeft: "10px !important"}}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Typography
            sx={{
              fontSize: "10px !important",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              '&:hover': {
                color: `${theme.palette.primary.main} !important`,
                '& svg': {
                  fill: `${theme.palette.primary.main} !important`
                }
              }
            }}
            onClick={handleProtectedAreaClick}
          >
            <OutlinedShieldIcon style={{ fill: "black" }} />&nbsp; <u>Click to see summary</u>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export const SummaryMetricsBox = () => {
  const theme = useTheme();
  const vwSub860 = useMediaQuery(theme.breakpoints.down(860));
  const vwSub1070 = useMediaQuery(theme.breakpoints.down(1070));

  const isCaptured = useSelector((state) => state.summaryMetrics.isCaptured);

  const isRestricted = useSelector((state) => state.role.isRestricted);
  const displayProfitability = useSelector((state) => state.role.displayProfitability);

  return (isCaptured && !isRestricted) ? (
    <Grid item xs={12} container sx={{ paddingTop: "8px !important" }}>
      {!vwSub860 && displayProfitability && (
        <ProfitabilityMetrics vwSub1070={vwSub1070} />
      )}
      {!vwSub860 && !displayProfitability && (
        <DataPointsAnalyzed vwSub1070={vwSub1070} />
      )}
      <ProtectedAreas vwSub860={vwSub860} />
    </Grid>
  ) : null
}

export default SummaryMetricsBox;