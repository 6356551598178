import { createSlice } from '@reduxjs/toolkit';

export const monitorPendingAppsSlice = createSlice({
  name: "monitorPendingApps",
  initialState: {
    isMonitoring: false,
  },
  reducers: {
    setIsMonitoring: (state) => {
      console.log("setIsMonitoring triggered");
      state.isMonitoring = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsMonitoring } = monitorPendingAppsSlice.actions;

export default monitorPendingAppsSlice.reducer;