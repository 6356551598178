import { createSlice /*, current*/ } from "@reduxjs/toolkit";

export const initialState = {
	fontFamily: "Montserrat",
	text: { color: "#000527"},
	link: {color: "#0012B8"},
	good: {color: "#1E4620"},
	bad: {color: "#5F2120"},
	chart1: {color:"#0012B8"},
	chart2: {color:"#59CEFF"},
	chart3: {color:"#146CF1"},
	chart4: {color:"#000957"},
	chart5: {color:"#0E24EE"},
	chart6: {color:"#2196F3"},
	chart7: {color:"#6D6E71"},
	chart8: {color: "#414042"},
	logo: null,
};

export const themeSlice = createSlice({
	name: "theme",
	initialState,
	reducers: {
		setTheme: (state, action) => {
			if (action.payload.fontFamily) state.fontFamily = action.payload.fontFamily;
			if (action.payload.text) state.text = action.payload.text;
			if (action.payload.link) state.link = action.payload.link;
			if (action.payload.good) state.good = action.payload.good;
			if (action.payload.bad) state.bad = action.payload.bad;
			if (action.payload.chart1) state.chart1 = action.payload.chart1;
			if (action.payload.chart2) state.chart2 = action.payload.chart2;
			if (action.payload.chart3) state.chart3 = action.payload.chart3;
			if (action.payload.chart4) state.chart4 = action.payload.chart4;
			if (action.payload.chart5) state.chart5 = action.payload.chart5;
			if (action.payload.chart6) state.chart6 = action.payload.chart6;
			if (action.payload.chart7) state.chart7 = action.payload.chart7;
			if (action.payload.chart8) state.chart8 = action.payload.chart8;
		},
	},
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;
