import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { commandsSelectors, removeCommand } from "../../store/commandsSlice";
import { resetPreviewApp, setLoading } from "../../store/previewAppSlice";
import { useNavigate } from "react-router-dom";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ReplayIcon from "@mui/icons-material/Replay";
import { setScript } from "../../store/appBuilderSlice";

/*
Ok - we need the following buttons

1: preview app / see preview

2: reset preview

preview app / see preview:
- once a script or file has been attached, this button should be enabled - requesting a preview should switch appBuilderSlice.loading to true
- when appBuilderSlice.loading is true, the button should be disabled and a spinner should be displayed
- when previewAppSlice.commmandUuid is not null and previewAppSlice.tables and previewAppSlice.dashboardJson are not null, this button should display "see preview" and be enabled


reset preview:
- always enabled - wipes previewAppSlice.tables and previewAppSlice.dashboardJson and sets previewAppSlice.commandUuid to null
*/

const getStartIcon = (previewReady, previewError) => {
  if (previewReady) {
    return <QueryStatsIcon />;
  } else if (!previewError) {
    return <CircularProgress size="1.25rem" />;
  } else {
    return <ErrorOutlineIcon />;
  }
};

const SeePreviewButton = ({ loading }) => {
  const navigate = useNavigate();
  // only enabled if previewApp.tables and previewApp.dashboardJson are not null
  const previewReady = useSelector((state) => state.previewApp.ready);
  const previewError = useSelector((state) => state.previewApp.error.isErrored);

  const seePreview = () => {
    navigate("/preview_dashboard", {
      state: { preview: true, developer: true },
    });
  }

  return (
    <Button
      variant="contained"
      color="primary"
      size={"large"}
      disabled={loading || !previewReady || previewError}
      sx={{ borderRadius: "0px !important" }}
      onClick={seePreview}
      startIcon={getStartIcon(previewReady, previewError)}
    >
      SEE PREVIEW
    </Button>
  )
};

const RequestPreviewButton = () => {
  const dispatch = useDispatch();
  const script = useSelector(state => state.appBuilder.script);
  const file = useSelector(state => state.appBuilder.selectedFile);
  const requestPreview = () => {
    dispatch(setLoading(true))
  }

  return (
    // <Button variant={currentDate === "90D" ? "contained" : "outlined"} sx={{ borderRadius: "0px !important" }} onClick={alert_filter.bind(this, "date", "90D")}>90D</Button>
    <Button
      variant="contained"
      color="primary"
      size={"large"}
      disabled={(!script && !file)}
      sx={{ borderRadius: "0px !important" }}
      onClick={requestPreview}
      startIcon={<QueryStatsIcon />}
    >
      PREVIEW APP
    </Button>
  )
};

export const PreviewButton = () => {
  const previewAppCommand = useSelector(state => state.previewApp.commandUuid);
  const loading = useSelector(state => state.previewApp.loading);

  const pickPreviewButton = (previewAppCommand, loading) => {
    if (loading || previewAppCommand) { // loading = request in progress (async request or SSE response not yet received), previewCommand not null after async request
      return (
        <SeePreviewButton
          loading={loading}
        />
      )
    } else {
      return (
        <RequestPreviewButton />
      )
    }
  }

  return (
    <>
      {pickPreviewButton(previewAppCommand, loading)}
    </>
  )
}

export const ResetPreviewButton = () => {
  const dispatch = useDispatch();
  const previewAppCommand = useSelector(state => state.previewApp.commandUuid);
  const loading = useSelector(state => state.previewApp.loading);

  const resetPreview = () => {
    dispatch(resetPreviewApp());
    dispatch(removeCommand(previewAppCommand));
    dispatch(setScript({ script: "" }));
  }

  return (
    <Button
      variant="outlined"
      color="primary"
      size={"large"}
      disabled={loading}
      sx={{ borderRadius: "0px !important" }}
      onClick={resetPreview}
      startIcon={<ReplayIcon/>}
    >
      RESET PREVIEW
    </Button>
  )
}

export const AppBuilderButtons = () => {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <PreviewButton />
      </Grid>
      <Grid item>
        <ResetPreviewButton />
      </Grid>
    </Grid>
  )
}

export default AppBuilderButtons;