const checkIfValidInteger = (value) => {
  return /^-?\d+$/.test(value);
}

const checkIfValidFloat = (value) => {
  return /^((-)?(0|([1-9][0-9]*))(\.[0-9]*)?)$/.test(value);
}

const validateDate = ( input ) => {
  const date = new Date( input );
  input = input.split( '/' );
  return date.getMonth() + 1 === +input[0] &&
    date.getDate() === +input[1] &&
    date.getFullYear() === +input[2];
}

const checkString = (newValue, paramValue, index) => {
  if (newValue === paramValue) {
    return {
      newValue: null,
      index: index,
      error: false,
      errorMessage: ''
    }
  } else {
    return {
      newValue: newValue,
      index: index,
      error: false,
      errorMessage: ''
    }
  }
}

const checkDropdown = (newValue, paramValue, index) => {
  if (newValue === paramValue) {
    return {
      newValue: null,
      index: index,
      error: false,
      errorMessage: ''
    }
  } else {
    return {
      newValue: newValue,
      index: index,
      error: false,
      errorMessage: ''
    }
  }
}

const checkInteger = (newValue, paramValue, index) => {
  if (newValue === paramValue) {
    return {
      newValue: null,
      index: index,
      error: false,
      errorMessage: ''
    }
  } else if (checkIfValidInteger(newValue)) {
    return {
      newValue: newValue,
      index: index,
      error: false,
      errorMessage: ''
    }
  } else {
    return {
      newValue: newValue,
      index: index,
      error: true,
      errorMessage: 'Please enter a valid integer'
    }
  }
}

const checkFloat = (newValue, paramValue, index) => {
  if (newValue === paramValue) {
    return {
      newValue: null,
      index: index,
      error: false,
      errorMessage: ''
    }
  } else if (checkIfValidInteger(newValue) || checkIfValidFloat(newValue)) {
    return {
      newValue: newValue,
      index: index,
      error: false,
      errorMessage: ''
    }
  } else {
    return {
      newValue: newValue,
      index: index,
      error: true,
      errorMessage: 'Please enter a valid float'
    }
  }
}

const checkBoolean = (newValue, paramValue, index) => {
  // values will be string: "true" or "false"
  if (newValue === paramValue) {
    return {
      newValue: null,
      index: index,
      error: false,
      errorMessage: ''
    }
  } else {
    return {
      newValue: newValue,
      index: index,
      error: false,
      errorMessage: ''
    }
  }
}

const checkDate = (newValue, paramValue, index) => {
  if (newValue === paramValue) {
    return {
      newValue: null,
      index: index,
      error: false,
      errorMessage: ''
    }
  } else if (validateDate(newValue)) {
    return {
      newValue: newValue,
      index: index,
      error: false,
      errorMessage: ''
    }
  } else {
    return {
      newValue: newValue,
      index: index,
      error: true,
      errorMessage: 'Please enter a valid date'
    }
  }
}

export const generateUpdate = (newValue, paramValue, index, type) => {
  // config.alertConfigParamType: one of string: TEXT, DROPDOWN, INTEGER, FLOAT, BOOLEAN, DATE
  // will need to return the following:
  // {
  //   newValue: newValue,
  //   index: index,
  //   error: true || false,
  //   errorMessage: 'some message' || ''
  // }
  if (newValue === null) {
    return {
      newValue: null,
      index: index,
      error: false,
      errorMessage: ''
    }
  } else if (type === "TEXT") {
    return checkString(newValue, paramValue, index)
  } else if (type === "DROPDOWN") {
    return checkDropdown(newValue, paramValue, index)
  } else if (type === "INTEGER") {
    return checkInteger(newValue, paramValue, index)
  } else if (type === "FLOAT") {
    return checkFloat(newValue, paramValue, index)
  } else if (type === "BOOLEAN") {
    return checkBoolean(newValue, paramValue, index)
  } else if (type === "DATE") {
    return checkDate(newValue, paramValue, index)
  }
};