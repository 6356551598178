import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useMemo, useState} from "react";
import * as Constants from "../../Constants";
import DirectRequest from "../../API/requests/DirectRequest";
import Grid from "@mui/material/Grid";
import {setSysAdminCurrentType} from "../../store/alertsTabsSysAdminSlice";
import FeedTable from "../AlertFeed/FeedTable";
import {
  actions,
  appNameDisplay,
  flagAiMessage,
  flagAiMessageSysadmin,
  flagAiRelevantStatus,
  flagDescription, flagFirstOccurrenceTimestamp,
  flagOverrideAndShowAnyway,
  flagRowId,
  lastActionTaken,
  moneySaved,
  referenceDescription
} from "../AlertFeed/FeedTableColumns";


const SysAdminAlertFeed = ({selectedCompany}) => {
  const dispatch = useDispatch();

  const selectedCompanyAlerts = useSelector((state) => state.alertsSysAdmin.alerts);
  const selectedCompanyResolvedAlerts = useSelector((state) => state.alertsSysAdmin.resolvedAlerts);
  const selectedTab = useSelector((state) => state.alertsTabsSysAdmin.selectedTab);
  const filters = useSelector((state) => state.alertsTabsSysAdmin.filters);
  const userFilters = useSelector((state) => state.alertsTabsSysAdmin.userFilters);
  const readyForCustomer = useSelector((state) => state.alertsTabsSysAdmin.readyForCustomer);

  const [selectedCompanySubscribedAppsArgs, setSelectedCompanySubscribedAppsArgs] = useState(null);
  const [selectedCompanySubscribedApps, setSelectedCompanySubscribedApps] = useState([]);

  const sysAdminApps = useSelector((state) => state.appsSysAdmin.list);

  useEffect(() => {
    if (selectedCompany && selectedCompany.uuid) {
      if (selectedCompany.name) console.log("selected company", selectedCompany.name);
      setSelectedCompanySubscribedAppsArgs({ url: Constants.SERVER_SYSADMIN_CUS_SUBBED_APPS_URL + selectedCompany.uuid });
    }
  }, [selectedCompany])

  const handleSelectedCompanySubscribedApps = (res) => {
    console.warn("selected company subscriptions:", res);
    if (res) {
      setSelectedCompanySubscribedApps(res);
    }
  }

  useEffect(() => {
    return () => {
      dispatch(setSysAdminCurrentType("All"))
    }
  }, [])

  const columns = useMemo(() => {
    return [
      appNameDisplay(selectedTab, true, false),
      moneySaved(true, selectedTab, false),
      flagDescription(sysAdminApps, selectedCompanySubscribedApps, selectedCompany.uuid, selectedTab, readyForCustomer, false, true, false),
      referenceDescription(true, selectedTab, false, false),
      flagFirstOccurrenceTimestamp(true),
      actions(true, selectedCompany.uuid, selectedTab),
      flagAiRelevantStatus(true, selectedCompany.uuid, selectedTab),
      flagAiMessage(),
      flagAiMessageSysadmin(),
      lastActionTaken(),
      flagRowId(),
    ].concat(selectedTab === 'Main' || selectedTab === 'Unread' ? [flagOverrideAndShowAnyway()] : []);
  }, [selectedTab, sysAdminApps, selectedCompanySubscribedApps, selectedCompany.uuid, readyForCustomer])

  return (
    <>
      <DirectRequest
        requestArgs={selectedCompanySubscribedAppsArgs}
        afterProcess={handleSelectedCompanySubscribedApps}
        handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("SysAdminAlertControls get selected company subscribedApps error", err)}
        handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("SysAdminAlertControls get selected company subscribedApps catch error", err)}
      />
      <Grid container spacing={1}>
        <FeedTable
          alerts={
            selectedTab === 'Resolved' && readyForCustomer
              ? userFilters[selectedTab](selectedCompanyResolvedAlerts)
              : selectedTab === 'Resolved' && !readyForCustomer
                ? selectedCompanyResolvedAlerts
                : readyForCustomer
                  ? userFilters[selectedTab](selectedCompanyAlerts)
                  : filters[selectedTab](selectedCompanyAlerts)
          }
          sysAdmin={true}
          dashboardView={false}
          preview={false}
          currentTab={selectedTab}
          columns={columns}
        />
      </Grid>
    </>
  )
}

export default SysAdminAlertFeed;