import { createSlice } from '@reduxjs/toolkit';

export const selectedIntegrationsSlice = createSlice({
  name: "selectedIntegrations",
  initialState: {
    integrations: [],
    isOnboarding: false,
  },
  reducers: {
    setSelectedIntegrations: (state, action) => {
      state.integrations = action.payload;
    },
    setIsOnboarding: (state, action) => {
      state.isOnboarding = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedIntegrations, setIsOnboarding } = selectedIntegrationsSlice.actions;

export default selectedIntegrationsSlice.reducer;