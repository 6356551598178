import { dashboardConfigsSelectors } from "../../../store/dashboardConfigsSlice";
import {useSelector, useDispatch} from "react-redux";
import {useEffect} from 'react';
import {setDisplayConfigFilters, displayConfigFilterPayload} from "../../../store/dashboardFiltersSlice";
import {displayConfigTransformPayload, setDisplayConfigTransforms} from "../../../store/dashboardTransformSlice";

const ConfigTableRowsManager = ({configUuid}) => {
  const dispatch = useDispatch();
  const displayConfigTableRows = useSelector((state) => dashboardConfigsSelectors.selectById(state, configUuid).displayConfigTableRows);
  const sourceTable = useSelector((state) => dashboardConfigsSelectors.selectById(state, configUuid).sourceTable);
  // console.log("ConfigTableRowsManager ", configUuid, " sourceTable: ", sourceTable);
  /*
  ::: displayConfigTableRow :::
  String uuid;
  String companyUuid;
  String createdBy;
  Map<String, Object> primaryKeyValueMap;
  Map<String, Map<String, Object>> linkedTableForeignValueMaps;
  Map<String, Object> displayConfigOptions;

  primaryKeyValueMap: {
    'columnNameA': valueA,
    'columnNameB': valueB,
    ...
  }
  linkedTableForeignValueMaps: {
    'foreignTableA': {
      'columnNameA': valueA,
      'columnNameB': valueB,
      ...
    },
    'foreignTableB': { ... },
    ...
  }
  displayConfigOptions: { suppress_alert: true, hide_row: false, "status": "some status" }
  we attach configUuid to the corresponding dashboardJson component when we get_or_create
  - this is how we'll know what table to apply the suppress_alert logic to via getCellClassName and which config to update via DisplayConfigActions
  - filter will be used for hidden - it will be mainTable (linkedTableForeignValueMaps will be additionalTables
  */
  useEffect(() => {
    dispatch(setDisplayConfigFilters(displayConfigFilterPayload(configUuid, displayConfigTableRows, sourceTable)));
    dispatch(setDisplayConfigTransforms(displayConfigTransformPayload(configUuid, displayConfigTableRows, sourceTable)));
  }, [displayConfigTableRows])
  return null;
}

export const DashboardConfigsManager = () => {
  // needs to check all dashboardConfig's displayConfigTableRows for their values -
  const displayConfigsUuids = useSelector((state) => dashboardConfigsSelectors.selectIds(state));
  return (
    <>
      {displayConfigsUuids.map((configUuid, index) => (
        <ConfigTableRowsManager key={"config_manager_" + index} configUuid={configUuid} />
      ))}
    </>
  )
}

export default DashboardConfigsManager;