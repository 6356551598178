import { createSlice } from "@reduxjs/toolkit";

export const initialClusterState = {
  state: null,
};

export const clusterStateSlice = createSlice({
  name: "clusterState",
  initialState: initialClusterState,
  reducers: {
    setClusterState: (state, action) => {
      if (action.payload.state) state.state = action.payload.state;
    },
  },
});

export const { setClusterState } = clusterStateSlice.actions;
export default clusterStateSlice.reducer;