import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { removeCommand } from "../../store/commandsSlice";
import { setSyncStatus } from "../../store/syncStatusSlice";
import DirectRequest from "../requests/DirectRequest";
import GenericDelayedResponseHandler from "./GenericDelayedResponseHandler";
import * as Constants from "../../Constants";

function ListenForCompleteDataSync(props) {
  const dispatch = useDispatch();
  const [syncedNonEmptyTablesArgs, setSyncedNonEmptyTablesArgs] = useState();
  const [sourcesArgs, setSourcesArgs] = useState();
  const [syncedNonEmptyTables, setSyncedNonEmptyTables] = useState();

  // -------------------- Delayed Response Handler --------------------
  function finishListenForCompleteDataSync(response, commandUuid) {
    // TODO: what happens if an error is returned?
    if (response.status === "succeeded") {
      console.log("Complete data sync finished");
      const tempArgs = {
        url: Constants.SERVER_GET_SYNCED_NON_EMPTY_TABLES_URL,
      };
      setSyncedNonEmptyTablesArgs(tempArgs);
      dispatch(removeCommand(commandUuid));
    }
  }

  // -------------------- Direct Request Response Handlers --------------------
  function handleSyncedNonEmptyTables(res) {
    console.log("ListenForCompleteDataSync handleSyncedNonEmptyTables -> res", res);
    setSourcesArgs({ url: Constants.SERVER_SOURCE_STATUS_URL });
    setSyncedNonEmptyTables(res);
  }

  function handleSources(res) {
    console.log("ListenForCompleteDataSync handleSources -> res", res);
    const payload = {};
    res.forEach((source) => {
      payload[source.sourceName] = source.hasSyncedAtLeastOnce;
    });
    dispatch(setSyncStatus({ hasSyncedAtLeastOnce: payload, syncedNonEmptyTables: syncedNonEmptyTables }));
  }


  // ----------------- Error Handlers -----------------
  const handleListenError = (data) => {
    // Todo: implement this
    console.log("Server error occurred while fetching syncedNonEmptyTables in ListenForCompleteDataSync");
    console.log(data);
  }

  const handleListenCatchError = (error) => {
    // Todo: implement this
    console.log("Client error occurred while fetching syncedNonEmptyTables in ListenForCompleteDataSync");
    console.log(error);
  }

  const handleSourcesError = (data) => {
    // Todo: implement this
    console.log("Server error occurred while fetching updated sources in ListenForCompleteDataSync");
    console.log(data);
  }

  const handleSourcesCatchError = (error) => {
    // Todo: implement this
    console.log("Client error occurred while fetching updated sources in ListenForCompleteDataSync");
    console.log(error);
  }

  return (
    <Fragment>
      <DirectRequest
        requestArgs={syncedNonEmptyTablesArgs}
        afterProcess={handleSyncedNonEmptyTables}
        handleError={handleListenError}
        handleCatchError={handleListenCatchError}
      />
      <DirectRequest
        requestArgs={sourcesArgs}
        afterProcess={handleSources}
        handleError={handleSourcesError}
        handleCatchError={handleSourcesCatchError}
      />
      <GenericDelayedResponseHandler
        sse={props.sse}
        handlerName={Constants.CommandType.listenForDataSyncAllTables}
        finishResponse={finishListenForCompleteDataSync}
      />
    </Fragment>
  );
}

export default ListenForCompleteDataSync;
