import { acct_fields, form_fields, newAccountDict, newCreateEntryForm } from "./createEntryForm";
import { Grid, Typography, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AccountInput from "./AccountInput";
import JournalEntryMemoInput from "./JournalEntryMemoInput";
import AddLineButton from "./AddLineButton";
import Attachments from "./Attachments";
import UpdateButtons from "./UpdateButtons";

////////////////////////////////////////// START ENTRY FORM CREATION
export const generateAccounts = (idsArr, acctsArr, amtsArr) => {
  const tempAccounts = [];
  acctsArr.forEach((acctName, index) => {
    const acctId = idsArr[index];
    const acctAmt = amtsArr[index];
    const accountDict = newAccountDict(acctId, acctName, acctAmt);
    tempAccounts.push(accountDict);
  })
  return tempAccounts;
}

export const populateNewCreateEntryForm = (createEntryConfig, newFormState, row, tables) => {
  console.log("newCreateEntryForm - createEntryConfig", createEntryConfig)
  const { 
    debitAccountIdListColumn, 
    debitAccountNameListColumn, 
    debitAccountAmountListColumn, 
    creditAccountIdListColumn, 
    creditAccountNameListColumn, 
    creditAccountAmountListColumn, 
    jeMemoTextColumn,
    referenceInfoColumn,
    accountMappingConfig 
  } = createEntryConfig;
  const debitAcctsIds = row[debitAccountIdListColumn]?.split(";") ?? [];
  // console.log("debitAcctsIds", debitAcctsIds)
  const debitAccts = row[debitAccountNameListColumn]?.split(";") ?? [];
  // console.log("debitAccts", debitAccts)
  const debitAmts = row[debitAccountAmountListColumn]?.split(";") ?? [];
  // console.log("debitAmts", debitAmts)
  const creditAcctsIds = row[creditAccountIdListColumn]?.split(";") ?? [];
  // console.log("creditAcctsIds", creditAcctsIds)
  const creditAccts = row[creditAccountNameListColumn]?.split(";") ?? [];
  // console.log("creditAccts", creditAccts)
  const creditAmts = row[creditAccountAmountListColumn]?.split(";") ?? [];
  // console.log("creditAmts", creditAmts)
  const referenceInfo = row[referenceInfoColumn] ?? "";
  const memoText = row[jeMemoTextColumn] ?? "";
  newFormState[form_fields.debitAccounts] = generateAccounts(debitAcctsIds, debitAccts, debitAmts);
  newFormState[form_fields.creditAccounts] = generateAccounts(creditAcctsIds, creditAccts, creditAmts);
  newFormState[form_fields.journalEntryMemo] = memoText;
  newFormState[form_fields.referenceInfo] = referenceInfo;

  // generate accountNameToIdMap
  if (accountMappingConfig) {
    /*
    "accountMappingConfig": {
              "accountMappingTableName": "account_mapping_table",
			        "accountMappingIdColumnName": "account_id",
			        "accountMappingNameColumnName": "account_name"
            }
    */
    console.log("accountMappingConfig", accountMappingConfig)
    const table = tables[accountMappingConfig.accountMappingTableName]?.data;
    if (table) {
      // console.log("table", table);
      const accountNameIndex = table[0].indexOf(accountMappingConfig.accountMappingNameColumnName);
      const accountIdIndex = table[0].indexOf(accountMappingConfig.accountMappingIdColumnName);
      // console.log("accountNameIndex", accountNameIndex);
      // console.log("accountIdIndex", accountIdIndex);
      if (accountIdIndex !== -1 && accountNameIndex !== -1) { 
        const rowData = table.slice(1);
        if (rowData.length > 0) {
          const accountNameToIdMap = {};
          rowData.forEach(row => {
            accountNameToIdMap[row[accountNameIndex]] = row[accountIdIndex];
          })
          newFormState[form_fields.accountNameToIdMap] = accountNameToIdMap;
        }
      }
    }
  }
}

export const generateNewCreateEntryForm = (createEntryConfig, row, tables) => {
  // populate form with createEntryConfig
  const newFormState = newCreateEntryForm();
  populateNewCreateEntryForm(createEntryConfig, newFormState, row, tables);
  return newFormState;
}

export const populateSubmittedCreateEntryForm = (createEntryConfig, submittedEntry, newFormState, row, tables) => {
  /*
  {
    "file": {
        "uuid": "erpe_1KOMNARSQCR1Z4F8K5XZAF5GS",
        "companyUuid": "comp_86FOF91CZHPW88SRGR9TYISRB",
        "developerAppUuid": "dapp_DM631F0DMN8Q3C4MJKAYZY3Y4",
        "destination": "netsuite_suiteanalytics",
        "entryType": "journal_entry",
        "entryPeriod": "entryPeriod",
        "entryArgs": {
            "entryType": "journal_entry",
            "journalLineDebitAmounts": "10;10",
            "reversalPostingPeriodId": "11",
            "journalLineDebitAccountNames": "100245 acct_245;100247 acct_247",
            "addReversalFlag": true,
            "destination": "netsuite_suiteanalytics",
            "journalMemo": "Accruing Expense",
            "journalLineCreditAccountNames": "1001 acct_1",
            "journalLineCreditAmounts": "20",
            "journalLineDebitAccountIds": "10245;10247",
            "entryPeriod": "entryPeriod",
            "postingPeriod": "10",
            "tranDate": "2024-01-01",
            "journalLineCreditAccountIds": "101"
        },
        "attachmentLinks": [],
        "message": null,
        "createdOn": "2024-10-08T14:36:52.019+00:00",
        "modifiedOn": null,
        "createdBy": "jack@awsdev_testsandbox.com",
        "hasBeenEntered": false
    }
}
  */
  const {
    referenceInfoColumn,
    accountMappingConfig 
  } = createEntryConfig;
  const {
    entryArgs
  } = submittedEntry;
  const debitAcctsIds = entryArgs.journalLineDebitAccountIds?.split(";") ?? [];
  const debitAccts = entryArgs.journalLineDebitAccountNames?.split(";") ?? [];
  const debitAmts = entryArgs.journalLineDebitAmounts?.split(";") ?? [];
  const creditAcctsIds = entryArgs.journalLineCreditAccountIds?.split(";") ?? [];
  const creditAccts = entryArgs.journalLineCreditAccountNames?.split(";") ?? [];
  const creditAmts = entryArgs.journalLineCreditAmounts?.split(";") ?? [];
  const referenceInfo = row[referenceInfoColumn] ?? "";
  const memoText = entryArgs.journalMemo ?? "";
  newFormState[form_fields.previouslySubmitted] = true;
  newFormState[form_fields.debitAccounts] = generateAccounts(debitAcctsIds, debitAccts, debitAmts);
  newFormState[form_fields.creditAccounts] = generateAccounts(creditAcctsIds, creditAccts, creditAmts);
  newFormState[form_fields.journalEntryMemo] = memoText;
  newFormState[form_fields.referenceInfo] = referenceInfo;

  if (accountMappingConfig) {
    const table = tables[accountMappingConfig.accountMappingTableName]?.data;
    if (table) {
      const accountNameIndex = table[0].indexOf(accountMappingConfig.accountMappingNameColumnName);
      const accountIdIndex = table[0].indexOf(accountMappingConfig.accountMappingIdColumnName);
      if (accountIdIndex !== -1 && accountNameIndex !== -1) { 
        const rowData = table.slice(1);
        if (rowData.length > 0) {
          const accountNameToIdMap = {};
          rowData.forEach(row => {
            accountNameToIdMap[row[accountNameIndex]] = row[accountIdIndex];
          })
          newFormState[form_fields.accountNameToIdMap] = accountNameToIdMap;
        }
      }
    }
  }
}

export const generateSubmittedCreateEntryForm = (createEntryConfig, submittedEntry, row, tables) => {
  const newFormState = newCreateEntryForm();
  populateSubmittedCreateEntryForm(createEntryConfig, submittedEntry, newFormState, row, tables);
  return newFormState;
}

const addLine = (form_field, setFormState) => {
  // add empty newAcctDict to form_field
  const newAccount = newAccountDict("", "", "");
  setFormState((prevFormState) => {
    return {
      ...prevFormState, // Spread the previous state
      [form_field]: [...prevFormState[form_field], newAccount] // Append the new account to the form_field array
    };
  })
}
////////////////////////////////////////// END ENTRY FORM CREATION


export const renderForm = (formState, setFormState, handleSubmit, handleDelete, handleClose, createEntryConfig) => {
  console.log("renderForm - formState", formState);
  return (
    <Grid container spacing={1} sx={{ padding: "10px", position: "relative"}} alignItems="flex-start">
      <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
        <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>{formState[form_fields.previouslySubmitted] ? "Update " : "Create "}journal entry</Typography>
        <IconButton onClick={handleClose} sx={{ padding: "4px" }}>
          <CloseIcon/>
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Typography>{formState[form_fields.referenceInfo]}</Typography>
      </Grid>
      <Grid item xs={12}>
        {/* purely for spacing */}
      </Grid>
      <Grid item xs={12}>
        {/* purely for spacing */}
      </Grid>
      <Grid container item xs={8} spacing={1}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 500}}>Debit accounts</Typography>
        </Grid>
        {formState[form_fields.debitAccounts].map((debitAcctDict, index) => {
          return (
            <AccountInput
              key={index + "_account_input"}
              acctDict={debitAcctDict}
              index={index}
              form_field={form_fields.debitAccounts}
              setFormState={setFormState}
              accountNameToIdMap={formState[form_fields.accountNameToIdMap]}
            />
          )
        })}
        <AddLineButton
          form_field={form_fields.debitAccounts}
          setFormState={setFormState}
          addLine={addLine}
        />
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 500}}>Credit accounts</Typography>
        </Grid>
        {formState[form_fields.creditAccounts].map((creditAcctDict, index) => {
          return (
            <AccountInput
              key={index + "_account_input"}
              acctDict={creditAcctDict}
              index={index}
              form_field={form_fields.creditAccounts}
              setFormState={setFormState}
              accountNameToIdMap={formState[form_fields.accountNameToIdMap]}
            />
          )
        })}
        <AddLineButton
          form_field={form_fields.creditAccounts}
          setFormState={setFormState}
          addLine={addLine}
        />
      </Grid>
      <Grid container item xs={4} spacing={1}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 500}}>Journal entry memo</Typography>
        </Grid>
        <JournalEntryMemoInput
          memo={formState[form_fields.journalEntryMemo]}
          setFormState={setFormState}
        />
        <Grid item xs={12}>
          {/* purely for spacing */}
        </Grid>
        {/* Eric 9/26 - currently hidden, awaiting server solution for attachments */}
        {/* <Grid item xs={12}>
          <Typography sx={{ fontWeight: 500}}>Attachments</Typography>
        </Grid>
        <Attachments
          attachedFiles={formState[form_fields.attachedFiles]}
          setFormState={setFormState}
        /> */}
      </Grid>
      <Grid item xs={12}>
        {/* purely for spacing */}
      </Grid>
      <Grid item xs={12}>
        {/* purely for spacing */}
      </Grid>
      <UpdateButtons
        formState={formState}
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
      />
    </Grid>
  )
}