import { createSlice } from '@reduxjs/toolkit';

export const alertSyncsSlice = createSlice({
  name: "alertSyncs",
  initialState: {
    syncMap: {},
  },
  reducers: {
    setAlertSyncs: (state, action) => {
      console.warn("setAlertSyncs", action.payload)
      state.syncMap = action.payload;
    },

  },
});

// Action creators are generated for each case reducer function
export const { setAlertSyncs } = alertSyncsSlice.actions;

export default alertSyncsSlice.reducer;