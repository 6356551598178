import { createSlice /*, current*/ } from "@reduxjs/toolkit";
import * as Constants from "../Constants";

// stripeCustomerUuid:
// initialState once included: stripeCustomerUuid
// this was gathered via RoleRequest and stored in the role slice
// stripeCustomerUuid AND stripeProductUuid (a devApp property) is used for the get_working_subscription endpoint

const initialState = {
  isCaptured: false,
  name: "",
  email: "",
  companyUuid: "",
  isAdmin: false,
  isSysAdmin: false,
  isDeveloper: false,
  isInternal: false,
  isRestricted: false,
  restrictedWhitelist: [],
  companyDomain: "",
  postSetupComplete: false,
  alertNotifyUserEmail: "",
  optOutOfAlertSummaryEmail: false,
  isErrored: false,
  closeBanner: true,
  displayProfitability: false,
  displayProfitabilityValue: "",
  // new for accruals.ai
  isAccrualsUser: false
};

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.name = action.payload.username;
      state.email = action.payload.email;
      if (action.payload.roles.includes("ROLE_RL_ADMIN") || action.payload.roles.includes("ROLE_RL_SYSADMIN")) {
        state.isAdmin = true;
      }
      if (action.payload.roles.includes("ROLE_RL_SYSADMIN")) {
        state.isSysAdmin = true;
      }
      if (action.payload.roles.includes("ROLE_RL_DEVELOPER")) {
        state.isDeveloper = true;
      }
      if (action.payload.roles.includes("ROLE_RL_INTERNAL_USER") || Constants.user_blacklist[action.payload.username]) {
        state.isInternal = true;
      }
      if (action.payload.roles.includes("ROLE_RL_RESTRICTED_USER") && action.payload.restricted_user_allowed_apps) {
        state.isRestricted = true;
        state.restrictedWhitelist = action.payload.restricted_user_allowed_apps;
      }
      // new for accruals.ai
      if (action.payload.roles.includes("ROLE_RL_ACCRUALS_USER")) {
        state.isAccrualsUser = true;
      }
      // totalProfitabilityToDate logic
      // action.payload.roles.forEach((role) => {
      //   if (role.includes("ROLE_RL_PROFITABILITY_USER")) {
      //     //dispatch(setShowModal(true));
      //     const profitabilityValue = role.split("_")[4];
      //     if (profitabilityValue) {
      //       console.log("roleSlice setRole - profitabilityValue:", profitabilityValue);
      //       state.totalProfitabilityToDate = Number(profitabilityValue);
      //     }
      //   }
      // });
      // end totalProfitabilityToDate logic
      if (action.payload.displayProfitability && action.payload.displayProfitabilityValue) {
        state.displayProfitability = true;
        state.displayProfitabilityValue = action.payload.displayProfitabilityValue;
      }
      if (action.payload.companyUuid) state.companyUuid = action.payload.companyUuid;
      state.isCaptured = true;
      if (action.payload.companyDomain) {
        state.companyDomain = action.payload.companyDomain;
      }
      if (action.payload.postSetupComplete) {
        state.postSetupComplete = action.payload.postSetupComplete;
      }
      if (action.payload.alertNotifyUserEmail) {
        state.alertNotifyUserEmail = action.payload.alertNotifyUserEmail;
      }
      if (action.payload.optOutOfAlertSummaryEmail) {
        state.optOutOfAlertSummaryEmail = action.payload.optOutOfAlertSummaryEmail;
      }
    },
    resetRole: (state) => {
      state.isCaptured = false;
      state.name = initialState.name;
      state.email = initialState.email;
      state.companyUuid = initialState.companyUuid;
      state.isAdmin = initialState.isAdmin;
      state.isSysAdmin = initialState.isSysAdmin;
      state.isDeveloper = initialState.isDeveloper;
      state.isInternal = initialState.isInternal;
      state.isRestricted = initialState.isRestricted;
      state.restrictedWhitelist = initialState.restrictedWhitelist;
      state.companyDomain = initialState.companyDomain;
      state.postSetupComplete = initialState.postSetupComplete;
      state.alertNotifyUserEmail = initialState.alertNotifyUserEmail;
      state.closeBanner = initialState.closeBanner;
      state.displayProfitability = initialState.displayProfitability;
      state.displayProfitabilityValue = initialState.displayProfitabilityValue;
    },
    setPostSetupComplete: (state) => {
      state.postSetupComplete = true;
    },
    setAlertNotifyUserEmail: (state, action) => {
      state.alertNotifyUserEmail = action.payload.alertNotifyUserEmail;
    },
    setOptOutOfAlertSummaryEmail: (state, action) => {
      state.optOutOfAlertSummaryEmail = action.payload;
    },
    setIsErrored: (state) => {
      state.isErrored = true;
    },
    setCloseBanner: (state) => {
      state.closeBanner = true;
    }
  },
});

export const {
  setRole,
  resetRole,
  setPostSetupComplete,
  setAlertNotifyUserEmail,
  setOptOutOfAlertSummaryEmail,
  setIsErrored,
  setCloseBanner
} = roleSlice.actions;
export default roleSlice.reducer;
