import { createSlice } from '@reduxjs/toolkit';

export const alertsTabsSysAdminSlice = createSlice({
  name: "alertsTabsSysAdmin",
  initialState: {
    tabs: {
      // Primary: 'Primary',
      Main: 'Main',
      Unread: 'Unread',
      Archived: 'Archived',
      Irrelevant: 'Irrelevant',
      Resolved: 'Resolved',
    },
    selectedTab: 'Main',
    primaryFeed: false,
    disabled: false,
    readyForCustomer: false,
    currentType: "All",
    filters: {
      Primary: (alerts) => {
        return alerts.filter(alert => (alert.flagAiRelevantStatus === "NOT_SET" || alert.flagAiRelevantStatus === "RELEVANT") && !alert.flagArchive && alert.inPrimaryFeed)
      },
      Main: (alerts) => {
        return alerts.filter(alert => (alert.flagAiRelevantStatus === "NOT_SET" || alert.flagAiRelevantStatus === "RELEVANT") && !alert.flagArchive)
      },
      Unread: (alerts) => {
        return alerts.filter(alert => alert.flagAiRelevantStatus === "NOT_SET")
      },
      Archived: (alerts) => {
        return alerts.filter(alert => alert.flagArchive)
      },
      Irrelevant: (alerts) => {
        return alerts.filter(alert => alert.flagAiRelevantStatus === "IRRELEVANT")
      },
      // There's no resolved filter here because SysAdmins just view all resolved alerts for a company in alertsSysAdminSlice - nothing to filter for
    },
    userFilters: {
      Primary: (alerts) => {
        return alerts.filter(alert => (alert.flagAiRelevantStatus === "NOT_SET" || alert.flagAiRelevantStatus === "RELEVANT") && !alert.flagArchive && alert.readyForCustomer && alert.inPrimaryFeed)
      },
      Main: (alerts) => {
        return alerts.filter(alert => (alert.flagAiRelevantStatus === "NOT_SET" || alert.flagAiRelevantStatus === "RELEVANT") && !alert.flagArchive && alert.readyForCustomer)
      },
      Unread: (alerts) => {
        return alerts.filter(alert => alert.flagAiRelevantStatus === "NOT_SET" && alert.readyForCustomer)
      },
      Archived: (alerts) => {
        return alerts.filter(alert => alert.flagArchive && alert.readyForCustomer)
      },
      Irrelevant: (alerts) => {
        return alerts.filter(alert => alert.flagAiRelevantStatus === "IRRELEVANT" && alert.readyForCustomer)
      },
      Resolved: (alerts) => {
        return alerts.filter(alert => alert.readyForCustomer)
      },
    },
  },
  reducers: {
    setSelectedTab: (state, action) => {
      console.log("setSelectedTab", action.payload)
      state.selectedTab = action.payload
    },
    setReadyForCustomer: (state, action) => {
      console.log("setReadyForCustomer", action.payload)
      state.readyForCustomer = action.payload
    },
    setTabsSysAdminDisabled: (state, action) => {
      state.disabled = action.payload
    },
    setSysAdminCurrentType: (state, action) => {
      state.currentType = action.payload
    },
    setSysAdminPrimaryFeed: (state, action) => {
      state.primaryFeed = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSelectedTab,
  setReadyForCustomer,
  setTabsSysAdminDisabled,
  setSysAdminCurrentType,
  setSysAdminPrimaryFeed
} = alertsTabsSysAdminSlice.actions;

export default alertsTabsSysAdminSlice.reducer;