import TopBar from "../TopBar/TopBar";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import IntegrationCard from "./IntegrationCard";
import AddIntegrationCard from "./AddIntegrationCard";

const Integrations = (props) => {
  const selectedIntegrations = useSelector((state) => state.selectedIntegrations.integrations);
  const connectedIntegrations = useSelector((state) => state.integrationMetadata.integrations);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TopBar
            top_message={"Manage your connections"}
            hide_help={true}
          />
        </Grid>
        <Grid item xs={12} container spacing={2} sx={{ padding: "20px"}}>
          {selectedIntegrations.map((integration) => (
            <IntegrationCard
              integration={integration}
              connectedIntegrations={connectedIntegrations}
              selectedIntegrations={selectedIntegrations}
              key={`${integration}_connect_card`}
              onboarding={false}
            />
          ))}
          <AddIntegrationCard selectedIntegrations={selectedIntegrations} />
        </Grid>
      </Grid>
    </>
  )
};

export default Integrations;