import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import * as Constants from "../../Constants";
import {customerSubsSelectors} from "../../store/customerSubsSlice";
import {setCancelSubscriptionAndCustomerSubArgs, setAddSubscriptionArgs} from "../../store/appsSubscriptionsSlice";

const SubscribedLabel = ({customerSubscription}) => {
  const customerSubSynced = useSelector((state) => customerSubsSelectors.selectById(state, customerSubscription?.uuid));

  const getLabel = (customerSubscription, customerSubSynced) => {
    if (!customerSubscription || !customerSubSynced) {
      return "Enabled";
    } else if (customerSubscription.isSetupComplete || (customerSubSynced && customerSubSynced.isReady)) {
      return "Enabled";
    } else if (customerSubSynced && !customerSubSynced.isReady) {
      return !customerSubSynced.isSynced ? "Enabled - Syncing..." : "Enabled - Populating...";
    }
  }

  return (
    <>
      {getLabel(customerSubscription, customerSubSynced)}
    </>
  );
};

export default function AppCardCustomerButtonPickerV2({ app, subscribed, customerSubscription }) {
  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    setEnabled(!!subscribed);
  }, [subscribed]);

  const createSubscription = () => {
    const tempCreateSubscriptionArgs = {
      url: Constants.SERVER_CUS_APP_BUY_URL,
      method: "POST",
      body: JSON.stringify({
        "developerAppUuid": app.uuid,
        "stripeSubscriptionUuid": null,
        "isTrial": false,
        "isActive": true
      })
    };
    dispatch(setAddSubscriptionArgs(tempCreateSubscriptionArgs));
  }

  const cancelSubscription = () => {
    const tempCancelSubscriptionArgs = {
      url: Constants.SERVER_CUS_APP_CANCEL_URL,
      method: "POST",
      body: JSON.stringify({
        "customerSubscriptionUuid": customerSubscription.uuid
      })
    };
    dispatch(setCancelSubscriptionAndCustomerSubArgs({
      cancelSubscriptionArgs: tempCancelSubscriptionArgs,
      customerSubToRemove: customerSubscription.uuid
    }));
  }

  const handleStartSubscription = () => {
    setEnabled(true);
    createSubscription();
  }

  const handleCancelSubscription = () => {
    setEnabled(false);
    cancelSubscription();
  }

  // Switch handler
  const handleChange = () => {
    // creating or updating subscription will need to fire a refreshSubscribedApps - this will trigger props.subscribed to be recalculated causing enabled to update in local state
    if (!subscribed) {
      handleStartSubscription();
    } else {
      handleCancelSubscription();
    }
  }

  return (
    <Grid item xs={12}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch checked={enabled} onChange={handleChange} />
          }
          label={enabled ? <SubscribedLabel customerSubscription={customerSubscription}/> : "Disabled"}
        />
      </FormGroup>
    </Grid>
  )
}