import DirectRequest from "./DirectRequest";
import React, { useState } from "react";
import * as Constants from "../../Constants";
import { useDispatch } from "react-redux";
import { setHasSyncedAtLeastOnce } from "../../store/syncStatusSlice";

export default function SourcesRequest() {
  const dispatch = useDispatch();
  const [sourcesArgs, setSourcesArgs] = useState({ url: Constants.SERVER_SOURCE_STATUS_URL });
  const handleSourcesRes = (res) => {
    if (res) {
      const payload = {};
      res.forEach((source) => {
        payload[source.sourceName] = source.hasSyncedAtLeastOnce;
      });
      dispatch(setHasSyncedAtLeastOnce(payload));
    }
  };
  return (
    <>
      <DirectRequest requestArgs={sourcesArgs} afterProcess={handleSourcesRes}/>
    </>
  )
}