import Grid from "@material-ui/core/Grid";
import { useSelector } from 'react-redux';
import React from 'react';
import AlertConfigsToolbar from './components/AlertConfigsToolbar';
import AlertConfigs from './components/AlertConfigs';

export const SysAdminAlertConfigs = () => {
  const subscriptionsCaptured = useSelector(state => state.alertConfigsSysAdmin.subscriptionsCaptured);
  return subscriptionsCaptured ? (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <AlertConfigsToolbar/>
      </Grid>
      <Grid item xs={12}>
        <AlertConfigs/>
      </Grid>
    </Grid>
  ) : null;
}

export default SysAdminAlertConfigs;