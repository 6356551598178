import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeRequestError } from "../../store/requestErrorsSlice";
import DirectRequest from "./DirectRequest";
import * as Constants from "../../Constants";
import iso8601Timestamp from "../../helpers/iso8601Timestamp";

const REQUEST_LIMIT_DURATION = 1000;

function ErrorLoggingWrapper(props) {
  const dispatch = useDispatch();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const requestErrors = useSelector((state) => state.requestErrors);
  const role = useSelector((state) => state.role);
  const [errorLogArgs, setErrorLogArgs] = useState();
  const frontendVersion = useSelector(state => state.appState.frontendVersion);
  const backendVersion = useSelector(state => state.appState.backendVersion);

  const getServerExceptionBody = (error, url) => {
    return `Server exception:<br/>Affected user email: ${role.name}<br/>Request URL: ${url}<br/>Error code: ${error.errorCode}<br/>Error message: ${error.errorMessage}<br/><br/>Version: ${frontendVersion || ''} (${backendVersion || '0.0.000'})`;
  };

  const getClientErrorBody = (error, url) => {
    const error_stack = error.stack.replace(/(?:\r\n|\r|\n)/g, "<br/>");
    return `Client error:<br/>Affected user email: ${role.name}<br/>Request URL: ${url}<br/>Error name: ${error.name}<br/>Error message: ${error.message}<br/><br/>${error_stack}<br/><br/>Version: ${frontendVersion || ''} (${backendVersion || '0.0.000'})`;
  };

  const getParagonErrorBody = (error, url) => {
    const error_stack = error.stack.replace(/(?:\r\n|\r|\n)/g, "<br/>");
    return `Paragon error:<br/>Affected user email: ${role.name}<br/>Request URL: ${url}<br/>Error name: ${error.name}<br/>Error message: ${error.message}<br/><br/>${error_stack}`;
  };

  const getBody = (currentError) => {
    if (currentError.logType === "server_exception") {
      return getServerExceptionBody(currentError.error, currentError.url);
    } else if (currentError.logType === "client_error") {
      return getClientErrorBody(currentError.error, currentError.url);
    } else if (currentError.logType === "paragon_authentication_error") {// TODO: terminate - haven't used paragon for a long long time
      return getParagonErrorBody(currentError.error, currentError.url);
    }
  };

  const getSubject = (logType) => {
    if (logType === "server_exception") {
      return `${role.name} frontend server exception (${Constants.SERVER_BASE_URL} - ${iso8601Timestamp()})`;
    } else if (logType === "client_error") {
      return `${role.name} frontend client error (${Constants.SERVER_BASE_URL} - ${iso8601Timestamp()})`;
    } /*else if (logType === "paragon_authentication_error") {
      return `Paragon Authentication Error`;
    }*/
  };

  const throttle = () => {
    setTimeout(() => {
      setRequestInProgress(false);
    }, REQUEST_LIMIT_DURATION);
  };

  function sendError() {
    if (!role.isInternal) {
      // console.log("Error logged ->", requestErrors.requestErrors[0]);
      setRequestInProgress(true);
      if (process.env.NODE_ENV !== "development" || Constants.TEST_ERROR_LOG) {
        const currentError = requestErrors.requestErrors[0];
        setErrorLogArgs({
          url: Constants.SERVER_SEND_EMAIL,
          method: "POST",
          body: JSON.stringify({
            body: getBody(currentError),
            subject: getSubject(currentError.logType),
            from: "noreply@wiselayer.com",
            toEmail: Constants.notificationList.join(","),
            sendHTML: true,
          }),
        });
      }
      dispatch(removeRequestError());
      if (
        Constants.USE_DUMMY_DATA ||
        (process.env.NODE_ENV === "development" && !Constants.TEST_ERROR_LOG)
      ) {
        throttle();
      }
    } else {
      dispatch(removeRequestError());
    }
  }

  useEffect(() => {
    if (requestErrors?.requestErrors?.length === 0) return;

    if (!requestInProgress) {
      sendError();
    }
  }, [requestErrors, requestInProgress]);

  return (
    <DirectRequest
      requestArgs={errorLogArgs}
      afterProcess={throttle}
      errorLogger
    />
  );
}

export default ErrorLoggingWrapper;
