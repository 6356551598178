import {useDispatch, useSelector} from "react-redux";
import React, {useRef, useState} from "react";
import convertISO8601ToMMDDYYYY from "../../helpers/convertISO8601ToMMDDYYYY";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import * as Constants from "../../Constants";
import { setUpdateAlertMessagesArgs } from "../../store/alertMessagesSlice";
import { setUpdateAlertMessagesArgs as setSysAdminUpdateAlertMessages } from "../../store/alertMessagesSysAdminSlice";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {setAlertNotificationArgs} from "../../store/alertsNotificationSlice";
import iso8601Timestamp from "../../helpers/iso8601Timestamp";

const GetAlertTypeCell = ({ alertType }) => {
  return (
    <span style={{
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: "2",
      WebkitBoxOrient: "vertical",
      textWrap: "wrap",
    }}>{alertType || ""}</span>
  )
}

export const appNameDisplay = () => {
  return {
    field: 'appNameDisplay',
    headerName: 'Alert type',
    renderCell: (params) => {
      return (<GetAlertTypeCell alertType={params.value} />)
    },
    width: 200,
    sortable: false,
    disableColumnMenu: true,
  }
}

const GetMessageCell = ({ message, isActive }) => {
  return (
    <span style={{
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: "2",
      WebkitBoxOrient: "vertical",
      textWrap: "wrap",
      fontWeight: isActive ? 600 : "inherit",
    }}>{message}</span>
  )
}

export const message = () => {
  return {
    field: 'message',
    headerName: 'Message',
    renderCell: (params) => {
      return (<GetMessageCell message={params.row.message} isActive={params.row.isActive} />)
    },
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
  }
}

export const createdBy = () => {
  return {
    field: 'createdBy',
    headerName: 'Created by',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
  }
}

const createdOnComparator = (v1, v2) => {
  // console.log("v1", v1)
  const sortModel = v1.api.state.sorting.sortModel;
  const createdOnSortModel = sortModel.find((sortModel) => sortModel.field === "createdOn");
  const sortDirection = createdOnSortModel ? createdOnSortModel.sort : "asc";
  const aDate = convertISO8601ToMMDDYYYY(v1.value).split('/');
  const bDate = convertISO8601ToMMDDYYYY(v2.value).split('/');
  const dateCompare = Number(bDate[2]) - Number(aDate[2]) || Number(bDate[0]) - Number(aDate[0]) || Number(bDate[1]) - Number(aDate[1]);
  if (dateCompare !== 0) {
    return dateCompare;
  } else {
    const appNameCompare = v1.row.appNameDisplay.localeCompare(v2.row.appNameDisplay);
    if (appNameCompare !== 0) {
      return sortDirection === "desc" ? -appNameCompare : appNameCompare;
    } else {
      const messageCompare = v1.row.message.localeCompare(v2.row.message);
      if (messageCompare !== 0) {
        return sortDirection === "desc" ? -messageCompare : messageCompare;
      } else {
        // if messages are the same, compare uuid
        return v1.row.uuid.localeCompare(v2.row.uuid);
      }
    }
  }
}

const CreatedOnCell = ({ createdOn }) => {
  return (
    <>
      {convertISO8601ToMMDDYYYY(createdOn) || ""}
    </>
  )
}

export const createdOn = () => {
  return {
    field: 'createdOn',
    headerName: 'Created on',
    renderCell: (params) => {
      return (<CreatedOnCell createdOn={params.row.createdOn} />)
    },
    width: 90,
    sortingOrder: ["desc", null],
    sortComparator: createdOnComparator,
    disableColumnMenu: true,
    valueGetter: (params) => params,// Passes full params so sortComparator can access sortModel state
    valueFormatter: (params) => {
      // console.log("valueGetter params", params)
      return convertISO8601ToMMDDYYYY(params.value.row.createdOn)
    },
  }
}

const AlertLinkCell = ({ sysAdmin, resolved, alertLink }) => {
  const theme = useTheme();
  const companyUuid = useSelector(state => state.alertsSysAdmin.company.uuid);
  const handleAlertLinkClick = () => {
    if (sysAdmin) {
      console.log("handleAlertLinkClick - sysAdmin", sysAdmin, " alertLink", alertLink, " companyUuid", companyUuid);
      window.open(`${alertLink}/${companyUuid}`, '_blank');
    } else {
      console.log("handleAlertLinkClick", alertLink);
      window.open(alertLink, '_blank');
    }
  }
  return resolved ? (
    <i>Resolved</i>
  ) : (
    <span
      style={{
        textDecoration: "underline",
        color: theme.palette.primary.main,
        cursor: "pointer",
      }}
      onClick={handleAlertLinkClick}
    >
      View alert
    </span>
  )
}

export const alertLink = (sysAdmin) => {
  return {
    field: 'alertLink',
    headerName: 'Alert link',
    renderCell: (params) => {
      return (<AlertLinkCell sysAdmin={sysAdmin} resolved={params.row.resolved} alertLink={params.row.alertLink} />)
    },
    width: 80,
    sortable: false,
    disableColumnMenu: true,
    disableExport: true,
  }
}

const iconButtonStyle = {
  padding: "4px",
}

const generateDeleteAlertMessageArgs = (uuid) => {
  return {
    url: Constants.SERVER_SYSADMIN_POST_DELETE_ALERT_MESSAGE_URL,
    method: 'POST',
    body: JSON.stringify({
      uuid: uuid
    })
  }
}

const SysAdminDeleteCell = ({ uuid }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const disableOnClick = useSelector(state => state.alertMessagesSysAdmin.disableOnClick)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleDeleteClick = () => {
    if (disableOnClick) return;
    handleToggle();
  }

  const handleDeleteSubmit = () => {
    const tempSysAdminDeleteAlertMessageArgs = generateDeleteAlertMessageArgs(uuid);
    dispatch(setSysAdminUpdateAlertMessages(tempSysAdminDeleteAlertMessageArgs))
    setOpen(false)
  }

  return (
    <>
      <Tooltip title={"Delete alert message"} followCursor={true} enterDelay={300} enterNextDelay={500}>
        <IconButton tabIndex={-1} onClick={handleDeleteClick} sx={iconButtonStyle}>
          <DeleteForeverIcon ref={anchorRef} alt={"delete"} sx={{color: `${open ? theme.palette.primary.main : "inherit"}`}}/>
        </IconButton>
      </Tooltip>
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom" sx={{ zIndex: 1 }}>
        <ClickAwayListener onClickAway={handleClose}>
          <Grid container spacing={1} sx={{ marginTop: ".5rem", /*width: "300px",*/ backgroundColor: "white !important", borderRadius: "10px", padding: "10px", boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.1)" }}>
            <Grid item xs={12} sx={{ padding: "0px !important"}}>
              <Button onClick={handleDeleteSubmit} variant="contained" size={"small"} sx={{ width: "100%" }}>Confirm deletion</Button>
            </Grid>
          </Grid>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

const generateChangeIsActiveArgs = (uuid, isActive, companyUuid) => {
  return {
    url: Constants.SERVER_POST_CHANGE_ALERT_MESSAGE_IS_ACTIVE_URL,
    method: 'POST',
    body: JSON.stringify({
      uuid: uuid,
      isActive: !isActive,
      companyUuid: companyUuid
    })
  }
}

const generateChangeIsActiveNotificationArgs = (user, alertMessage) => {
  const sysAdminLink = `${alertMessage.alertLink}/${alertMessage.companyUuid}`;
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `Alert AI message ${alertMessage.isActive ? "deactivated" : "activated"} by <strong>${user}</strong> on ${window.location.origin}<br/><br/>Uuid:<br/>${alertMessage.uuid}<br/><br/>Message:<br/>${alertMessage.message}<br/><br/>Alert type:</br>${alertMessage.appNameDisplay}</br></br>SysAdmin Link:<br/>${sysAdminLink}<br/><br/>`,
      "subject": `Alert AI message ${alertMessage.isActive ? "deactivated" : "activated"} by ${user} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}

const ChangeIsActiveButton = ({ alertMessage, sysAdmin }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector((state) => state.role.isInternal);
  const disableOnClick = useSelector(state => sysAdmin ? state.alertMessagesSysAdmin.disableOnClick : state.alertMessages.disableOnClick);
  const handleChangeIsActiveClick = () => {
    if (disableOnClick) return;
    const tempChangeIsActiveArgs = generateChangeIsActiveArgs(alertMessage.uuid, alertMessage.isActive, alertMessage.companyUuid);
    dispatch(sysAdmin ? setSysAdminUpdateAlertMessages(tempChangeIsActiveArgs) : setUpdateAlertMessagesArgs(tempChangeIsActiveArgs));
    if (!isInternal && !sysAdmin) {
      const tempAlertMessageUpdateNotificationArgs = generateChangeIsActiveNotificationArgs(user, alertMessage);
      dispatch(setAlertNotificationArgs(tempAlertMessageUpdateNotificationArgs));
    }
  }

  return (
    <>
      <Tooltip title={alertMessage.isActive ? "Deactivate" : "Activate"} followCursor={true} enterDelay={300} enterNextDelay={500}>
        <IconButton tabIndex={-1} onClick={handleChangeIsActiveClick} sx={iconButtonStyle}>
          {alertMessage.isActive && <RadioButtonCheckedIcon alt="Active" sx={{ color: theme.palette.primary.main }} />}
          {!alertMessage.isActive && <RadioButtonUncheckedIcon alt="Inactive" />}
        </IconButton>
      </Tooltip>
      {alertMessage.isActive && "Active"}
      {!alertMessage.isActive && "Inactive"}
    </>
  )
}

export const actions = (sysAdmin) => {
  return {
    field: 'isActive',
    headerName: 'Status',
    headerAlign: "center",
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    disableExport: true,
    renderCell: (params) => {
      return (
        <ChangeIsActiveButton alertMessage={params.row} sysAdmin={sysAdmin} />
      )
    }
  }
}

export const sysAdminActions = () => {
  return {
    field: 'uuid',
    headerName: 'Delete',
    align: "center",
    headerAlign: "center",
    width: 70,
    sortable: false,
    disableColumnMenu: true,
    disableExport: true,
    renderCell: (params) => {
      return (
        <SysAdminDeleteCell uuid={params.row.uuid} />
      )
    }
  }
}