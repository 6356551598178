import {styled} from "@mui/material/styles";
import {DataGridPro} from "@mui/x-data-grid-pro";
import React from "react";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

const StyledDetailPanelProGrid = styled(DataGridPro)(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "18px",
  border: "none",
  backgroundColor: theme.palette.secondary.main,
  '& .MuiDataGrid-row .MuiDataGrid-detailPanelToggleCell': {
    display: 'none', // Hides the cell
    visibility: 'hidden', // Ensures it is invisible
    pointerEvents: 'none', // Disables pointer events
  },
  '& .MuiDataGrid-row:last-child': {
    borderBottom: `1px solid rgba(224, 224, 224, 1)`, // Same border color
  },
  "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader": {
    display: "none",
    height: "0px",
  },
  "& .MuiDataGrid-virtualScroller": {
    marginTop: "0 !important",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 500,
  },
  "& .MuiDataGrid-iconSeparator": {
    //display: "none",
  },
  "& .MuiButton-root": {
    marginRight: "1em",
  },
  "& .MuiDataGrid-footerContainer": {
    justifyContent: "center",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: "0px !important",
    //backgroundColor: "transparent",
    color: theme.palette.color,
    opacity: 1,
  },
  "& .MuiPaginationItem-root.Mui-selected": {
    borderRadius: "0px !important",
    backgroundColor: theme.palette.secondary.main,
  },
  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  // forced scrollbar below - Non-standard (incompatible with firefox)
  "* ::-webkit-scrollbar": {
    //"-webkit-appearance": "none",
    WebkitAppearance: "none",
    width: "7px",
    height: "7px"
  },
  "* ::-webkit-scrollbar-thumb": {
    borderRadius: "4px",
    backgroundColor: theme.palette.primary.main,
  },
  "& .alert--active": {
    backgroundColor: theme.palette.secondary.main,
  },
  "& .alert--new": {
    fontWeight: 600,
  },
  "& .sysadmin-subscribed-apps-sync-before-run": {
    border: "1px solid red", // Apply a red border
    boxSizing: "border-box" // Ensure the border is included in the row's total width and height
  },
  "& .Mui-hovered.MuiDataGrid-detailPanelToggleCell": {
    zIndex: "20 !important",
  },
  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none"
  }
}));

const collapse = () => {
  return <ArrowCircleUpIcon sx={{ zIndex: 20 }} />;
};
//console.log(props)

const expand = () => {
  return <ArrowCircleDownIcon />;
};

export const DetailPanelGrid = ({rows, columns, columnVisibilityModel}) => {
  // console.log("DetailPanelGrid - rows", rows)
  // console.log("DetailPanelGrid - columns", columns)
  return (
    <div style={{width: "100%" }}>
      <StyledDetailPanelProGrid
        columnVisibilityModel = {columnVisibilityModel}
        autoHeight
        getRowId={row => row.row_index}
        rows={rows}
        columns={columns}
        //pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
        hideFooterRowCount={true}
        disableColumnFilter
        disableColumnSelector
        hideFooter
        disableSelectionOnClick
        disableColumnMenu={true}
        pagination={false}
        components={{
          DetailPanelExpandIcon: expand,
          DetailPanelCollapseIcon: collapse,
        }}
        density={"compact"}
        disableVirtualization={process.env.TEST ? true : false}
        getDetailPanelHeight={(row) => 0}
        getDetailPanelContent={(row) => <>Placeholder</>}
      />
    </div>
  )
}

export default DetailPanelGrid;