import React from "react";
import { FDUrl } from "./FDUrl"; // this file gets replaced during production build for different environments
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Quickbooks_Logo from "./assets/logos/Quickbooks.png";
import Netsuite_Logo from "./assets/logos/Netsuite-Logo-hi-res.png";
import Brex_Logo from "./assets/logos/Brex_Logo.png";
import Salesforce_Logo from "./assets/logos/salesforce.png";
import Sage_Logo from "./assets/logos/sage-logo.png";
import Coupa_Logo from "./assets/logos/coupa_logo.png";
import Acumatica_Logo from "./assets/logos/acumatica_logo.png";
import Deltek_Logo from "./assets/logos/deltek_logo.png";
import Workday_Logo from "./assets/logos/Workday-Logo.jpeg";
import ADP_Logo from "./assets/logos/ADP-logo.png";
import MSD365_Logo from "./assets/logos/Dynamics_365_logo_PNG1.png";

export const CommandState = {
  Pending: "Pending",
  Ready: "Ready",
  Error: "Error",
};

export const CommandType = {
  CreateSource: "CreateSource",
  DiscoverSchema: "DiscoverSchema",
  FinalizeConnection: "FinalizeConnection",
  ValidateModel: "ValidateModel",
  clusterState: "clusterState",
  runMarketplaceApp: "runMarketplaceApp",
  listenForDataSync: "listenForDataSync",
  listenForAppRun: "listenForAppRun",
  listenForDataSyncAllTables: "listenForDataSyncAllTables",
};

export const SiteLanguage = {
  Domains: "Models",
  Domain: "Model",
  Connectors: "Sources",
  Connector: "Source",
  Tables: "Tables",
  Table: "Table",
  Fields: "Fields",
  Field: "Field",
  Joins: "Links",
  Join: "Link",
  Keys: "Keys",
  Key: "Key",
  Owner: "Admin",
  Owners: "Admin",
  Maintainers: "Editors",
  Maintainer: "Editor",
  Viewers: "Readers",
  Viewer: "Reader",
  String: "Categorical",
  Integer: "Numerical",
  Formulas: "Formulas",
  Formula: "Formula",
  "Data app": "Analytics app",
  "Data apps": "Analytics apps",
  MissingData: "No data",
  CancelText: {
    ReturnToStore: "Return to App store",
  },
  CancelIcon: {
    ReturnToStore: <ChevronLeftIcon />,
  },
};

export const user_blacklist = {
  // jack End-To-End test accounts
  // "jack@awsdev_testsandbox.com": true,
  "jack@awsdev_testprod.com": true,
  "jack@awssandbox_testsandbox.com": true,
  "jack@awssandbox_testprod.com": true,
  "jack@prod_testsandbox.com": true,
  "eric@prod_testsandbox.com": true,
  "jack@prod_testprod.com": true,
  // team most common test accounts
  "jack@fruitiondata.com": true,
  "josh@adheretech.com": true,
  "josh@wiselayerdemo.com": true,
  "josh@wiselayerdemo3.com": true,
  "vik@uniqueio.com": true,
  "vik@fruitiondata.com": true,
  "vik@wiselayer.com": true,
  "eric@fruitiondatadev.com": true,
  "junaid@abbasi.com": true,
  "junaid@wiselayer.com": true,
  "junaid@prod_testsandbox.com": true,
  "eric@ericdebug.com": true,
  "eric@ericdebugprod.com": true,
  "fruitiondata@previewdata.com": true,
  "jack@anothercompany.com": true,
  "josh@wiselayer.com": true,
}

export const notificationList = [
  "vik@wiselayer.com",
  "josh@wiselayer.com",
  "jack@wiselayer.com",
  "junaid@wiselayer.com",
  "eric@wiselayer.com"
];

// name === originSourceName on source definition objects from /api/sources/definitions/list
// && name === sourceName on fivetran connected sources (aka integrations) objects from /fivetran/integrations
export const supported_integrations = [
  { name: "acumatica", displayName: "Acumatica", logo: Acumatica_Logo },
  { name: "brex", displayName: "Brex", logo: Brex_Logo },
  { name: "coupa", displayName: "Coupa", logo: Coupa_Logo },
  { name: "netsuite_suiteanalytics", displayName: "NetSuite", logo: Netsuite_Logo },
  { name: "quickbooks", displayName: "QuickBooks", logo: Quickbooks_Logo },
  { name: "salesforce", displayName: "Salesforce", logo: Salesforce_Logo },
  { name: "sage_intacct", displayName: "Sage Intacct", logo: Sage_Logo },
  // { name: "deltek", displayName: "Deltek", logo: Deltek_Logo },
  // { name: "workday", displayName: "Workday", logo: Workday_Logo },
  // { name: "adp", displayName: "ADP", logo: ADP_Logo },
  // { name: "msd365", displayName: "Dynamics 365", logo: MSD365_Logo },
]

export const module_dashboard_tags = {
  module__accruals: "module__accruals" 
}

export const stripe_checkout_link_expiration_time_ms = 480000; // 8 minutes
export const stripe_customer_portal_expiration_time_ms = 240000; // 4 minutes

export const SERVER_BASE_URL = FDUrl;

export const SERVER_LOGIN_URL = SERVER_BASE_URL + "/oauthlogin";
export const SERVER_LOGOUT_URL = SERVER_BASE_URL + "/logout";
export const SERVER_AUTHENTICATION_URL = SERVER_BASE_URL + "/profile";
export const SERVER_SSE_URL = SERVER_BASE_URL + "/sse";
export const SERVER_COMMAND_RESPONSES_URL = SERVER_BASE_URL + "/responses";
export const SERVER_USER_ROLES = SERVER_BASE_URL + "/test/user_roles";
export const SERVER_SOURCE_DEFINITIONS_URL =
  SERVER_BASE_URL + "/api/sources/definitions/list";
export const SERVER_SOURCE_CREATE_URL = SERVER_BASE_URL + "/api/sources/create";
export const SERVER_SOURCE_TABLES_URL = SERVER_BASE_URL + "/api/sources/tables";
export const SERVER_SOURCE_STATUS_URL = SERVER_BASE_URL + "/api/sources/list";
export const SERVER_SOURCE_FINALIZE_URL =
  SERVER_BASE_URL + "/api/sources/finalize";

export const SERVER_MODEL_LIST_URL = SERVER_BASE_URL + "/api/model/list";
export const SERVER_MODEL_VALIDATE_URL =
  SERVER_BASE_URL + "/api/model/validate";

export const SERVER_MODEL_CREATE_URL = SERVER_BASE_URL + "/api/model/create";

export const SERVER_MODEL_REMEDIATE = SERVER_BASE_URL + "/api/model/remediate";

export const SERVER_QUALITY_URL = SERVER_BASE_URL + "/quality";

export const SERVER_AGENTS_URL = SERVER_BASE_URL + "/admin/agents";

export const SERVER_CREATE_AGENT_URL = SERVER_BASE_URL + "/sysadmin/agent";

export const SERVER_CREATE_AGENT_COMPANIES_URL =
  SERVER_BASE_URL + "/sysadmin/companies";

export const SERVER_DEV_APPS_URL = SERVER_BASE_URL + "/api/devapp/list/"; // NOTE -> MUST attach developer's companyUuid to end of this url
export const SERVER_DEV_APP_PREVIEW_URL =
  SERVER_BASE_URL + "/api/devapp/preview"; // NOTE -> aync (returns commandUuid)
export const SERVER_DEV_APP_SAVE_URL = SERVER_BASE_URL + "/api/devapp/save"; // NOTE -> used for saving new apps and updating old apps

export const SERVER_CUS_APPS_URL = SERVER_BASE_URL + "/api/devapp/list_visible";
export const SERVER_CUS_SUBBED_APPS_URL =
  SERVER_BASE_URL + "/api/customersub/list";
export const SERVER_CUS_SUBBED_APP_URL =
  SERVER_BASE_URL + "/api/customersub/getData";

export const SERVER_CUS_APP_PREVIEW_URL =
  SERVER_BASE_URL + "/api/customersub/preview"; // NOTE -> async (returns commandUuid)
export const SERVER_CUS_APP_BUY_URL = SERVER_BASE_URL + "/api/customersub/save";
export const SERVER_SYSADMIN_CUS_APP_BUY_URL = SERVER_BASE_URL + "/api/customersub/save/";// + companyUuid
export const SERVER_CUS_APP_CANCEL_URL = SERVER_BASE_URL + "/api/customersub/cancel";
export const SERVER_SYSADMIN_CUS_APP_CANCEL_URL = SERVER_BASE_URL + "/api/customersub/cancel/";// + companyUuid
export const SERVER_DEV_APP_SOURCES =
  SERVER_BASE_URL + "/api/sources/definitions/list/appbuilder";

export const SERVER_SEND_EMAIL = SERVER_BASE_URL + "/sendemail";

export const SERVER_CUSTOMER_PORTAL_URL = SERVER_BASE_URL + "/customerportal";
export const SERVER_CHECKOUT_URL = SERVER_BASE_URL + "/checkoutinfo";

export const SERVER_SAVE_COMPANY_THEME = SERVER_BASE_URL + "/api/company/save_theme";

export const SERVER_APPBUILDER_DOCS = SERVER_BASE_URL + "/appbuilder_docs/index.html";

export const SERVER_LISTEN_FOR_DATA_SYNC_URL = SERVER_BASE_URL + "/api/customersub/listen_for_sync";
export const SERVER_LISTEN_FOR_APP_RUN_URL = SERVER_BASE_URL + "/api/customersub/listen_for_run";
export const SERVER_CUS_MARK_SETUP_COMPLETE_URL = SERVER_BASE_URL + "/api/customersub/mark_setup_complete";

export const SERVER_STRIPE_CREATE_CUSTOMER_URL = SERVER_BASE_URL + "/api/payment/create_customer";
export const SERVER_STRIPE_HAS_DONE_TRIAL_URL = SERVER_BASE_URL + "/api/payment/has_done_trial";
export const SERVER_STRIPE_GET_TRIAL_CHECKOUT_URL = SERVER_BASE_URL + "/api/payment/get_trial_checkout_link";
export const SERVER_STRIPE_GET_CHECKOUT_URL = SERVER_BASE_URL + "/api/payment/get_checkout_link";
export const SERVER_STRIPE_GET_WORKING_SUBSCRIPTION_URL = SERVER_BASE_URL + "/api/payment/get_working_subscription";
export const SERVER_STRIPE_GET_REMAINING_TRIAL_DAYS_URL = SERVER_BASE_URL + "/api/payment/get_remaining_trial_days";
export const SERVER_STRIPE_GET_CUSTOMER_PORTAL_URL = SERVER_BASE_URL + "/api/payment/customer_portal";
export const SERVER_STRIPE_SAVE_CUSTOMER_UUID_URL = SERVER_BASE_URL + "/api/company/save_stripe_customer_uuid";
export const SERVER_STRIPE_GET_STANDARD_CHECKOUT_URL = SERVER_BASE_URL + "/api/payment/get_standard_checkout_link";
export const SERVER_GET_SYNCED_NON_EMPTY_TABLES_URL = SERVER_BASE_URL + "/api/customersub/synced_non_empty_tables";
export const SERVER_VERSION_URL = SERVER_BASE_URL + "/test/version";
export const SERVER_GET_COMPANY_THEME_LOGO = SERVER_BASE_URL + "/api/company/get_company_logo";
export const SERVER_SAVE_COMPANY_THEME_LOGO = SERVER_BASE_URL + "/api/company/upload_company_logo";
export const SERVER_DELETE_COMPANY_THEME_LOGO = SERVER_BASE_URL + "/api/company/delete_company_logo";
export const SERVER_LISTEN_FOR_COMPLETE_DATA_SYNC_URL = SERVER_BASE_URL + "/paragon/listen_for_sync_all_tables";
export const SERVER_GET_SYNC_HISTORY_URL = SERVER_BASE_URL + "/admin/sync_history";
export const SERVER_PING_SSE_URL = SERVER_BASE_URL + "/sse/message_ack";
export const SERVER_GET_INTEGRATION_METADATA_URL = SERVER_BASE_URL + "/fivetran/integrations";
export const SERVER_GET_SYSADMIN_INTEGRATION_METADATA_URL = SERVER_BASE_URL + "/api/fivetran/connectors/list/";// + companyUuid
export const SERVER_SYSADMIN_GET_CONNECTOR_SYNCED_TABLES_URL = SERVER_BASE_URL + "/api/fivetran/connectors/";// + companyUuid
export const SERVER_FIVETRAN_CONNECT_CARD_URL = SERVER_BASE_URL + "/fivetran/onconnect";
export const SERVER_FIVETRAN_DISCONNECT_URL = SERVER_BASE_URL + "/fivetran/disconnect";
export const SERVER_SYSADMIN_GET_COMPANIES_URL = SERVER_BASE_URL + "/sysadmin/companies";
export const SERVER_SYSADMIN_GET_COMPANY_ALERTS_URL = SERVER_BASE_URL + "/api/alerts/list/";// + companyUuid
export const SERVER_SYSADMIN_POST_COMPANY_ALERT_OVERRIDE_URL = SERVER_BASE_URL + "/api/alerts/override/";// + companyUuid
export const SERVER_SYSADMIN_POST_COMPANY_ALERT_RELEASE_URL = SERVER_BASE_URL + "/api/alerts/release/";// + companyUuid
export const SERVER_GET_ALERTS_URL = SERVER_BASE_URL + "/api/alerts/list";
export const SERVER_GET_RELEASED_ALERTS_URL = SERVER_BASE_URL + "/api/alerts/list_released";
export const SERVER_USER_POST_ALERT_UPDATE_URL = SERVER_BASE_URL + "/api/alerts/update";
export const SERVER_SYSADMIN_POST_COMPANY_ALERT_UPDATE_URL = SERVER_BASE_URL + "/api/alerts/update/";// + companyUuid
export const SERVER_GET_RESOLVED_ALERTS_URL = SERVER_BASE_URL + "/api/alerts/list_resolved";
export const SERVER_GET_RELEASED_RESOLVED_ALERTS_URL = SERVER_BASE_URL + "/api/alerts/list_resolved_released";
export const SERVER_SYSADMIN_GET_COMPANY_RESOLVED_ALERTS_URL = SERVER_BASE_URL + "/api/alerts/list_resolved/";// + companyUuid

export const SERVER_GET_ALERT_CONFIGS_URL = SERVER_BASE_URL + "/api/alerts/alert_config/list";
export const SERVER_GET_SYSADMIN_ALERT_CONFIGS_URL = SERVER_BASE_URL + "/api/alerts/alert_config/list/";// + companyUuid
export const SERVER_POST_ALERT_CONFIG_UPDATE_URL = SERVER_BASE_URL + "/api/alerts/alert_config/update";
export const SERVER_POST_SYSADMIN_ALERT_CONFIG_UPDATE_URL = SERVER_BASE_URL + "/api/alerts/alert_config/update/";// + companyUuid
export const SERVER_POST_ALERT_CONFIG_RESET_URL = SERVER_BASE_URL + "/api/alerts/alert_config/reset";
export const SERVER_POST_SYSADMIN_ALERT_CONFIG_RESET_URL = SERVER_BASE_URL + "/api/alerts/alert_config/reset/";// + companyUuid
export const SERVER_GET_SYSADMIN_SUBBED_APPS_URL = SERVER_BASE_URL + "/api/customersub/list/";// + companyUuid
export const SERVER_POST_SELECTED_CONNECTORS_URL = SERVER_BASE_URL + "/api/company/set_selected_connectors";
export const SERVER_GET_SUMMARY_METRICS_URL = SERVER_BASE_URL + "/api/alerts/summary_metrics";
export const SERVER_GET_SYSADMIN_SUMMARY_METRICS_URL = SERVER_BASE_URL + "/api/alerts/summary_metrics/";// + companyUuid
export const SERVER_GET_RUN_POST_SETUP_URL = SERVER_BASE_URL + "/api/company/run_post_setup";
export const SERVER_SYSADMIN_CUS_SUBBED_APP_URL = SERVER_BASE_URL + "/api/customersub/getData/";// + companyUuid
export const SERVER_SYSADMIN_CUS_SUBBED_APPS_URL = SERVER_BASE_URL + "/api/customersub/list/";// + companyUuid
export const SERVER_POST_ALERT_SUMMARY_EMAIL_URL = SERVER_BASE_URL + "/api/company/save_alert_notify_email";
export const SERVER_POST_SYSADMIN_ALERT_SUMMARY_EMAIL_URL = SERVER_BASE_URL + "/api/company/save_alert_notify_email/";// + companyUuid
export const SERVER_POST_OPT_OUT_ALERT_SUMMARY_EMAIL_URL = SERVER_BASE_URL + "/api/alerts/disable_summary_email";
export const SERVER_POST_SYSADMIN_OPT_OUT_ALERT_SUMMARY_EMAIL_URL = SERVER_BASE_URL + "/api/alerts/disable_summary_email/";// + companyUuid
export const SERVER_GET_SYSADMIN_AI_MESSAGES_URL = SERVER_BASE_URL + "/api/alerts/ai_messages/";// + companyUuid
export const SERVER_GET_SYSADMIN_GENERAL_AI_MESSAGES_URL = SERVER_BASE_URL + "/api/alerts/general_ai_message/list/";// + companyUuid
export const SERVER_POST_GENERAL_AI_MESSAGE_URL = SERVER_BASE_URL + "/api/alerts/general_ai_message/post";
export const SERVER_GET_SYSADMIN_COMPANY_METADATA_URL = SERVER_BASE_URL + "/sysadmin/customer_metadata/";// + companyUuid
export const SERVER_DEVAPP_POST_GET_SANDBOX_DESTINATION_URL = SERVER_BASE_URL + "/api/devapp/sandbox";
export const SERVER_SYSADMIN_POST_GET_DATABRICKS_RUNS_URL = SERVER_BASE_URL + "/sysadmin/get_job_runs/";// + companyUuid;
export const SERVER_SYSADMIN_POST_GET_DATABRICKS_RUNS_BY_APP_URL = SERVER_BASE_URL + "/sysadmin/get_job_runs_by_app/";// + companyUuid;
export const SERVER_SYSADMIN_POST_GET_DEVAPP_VISIBILITY_URL = SERVER_BASE_URL + "/api/devapp/get_visible";
export const SERVER_SYSADMIN_GET_AI_MESSAGE_ALERTS_URL = SERVER_BASE_URL + "/api/alerts/ai_messages/";// + companyUuid
export const SERVER_SYSADMIN_UPDATE_DEVAPP_URL = SERVER_BASE_URL + "/api/devapp/update/admin";
export const SERVER_SYSADMIN_UPDATE_DEVAPP_SCRIPT_URL = SERVER_BASE_URL + "/api/devapp/update_script";
export const SERVER_SYSADMIN_POST_GET_ALERT_SYNCS_URL = SERVER_BASE_URL + "/sysadmin/get_alert_syncs_by_app/";// + companyUuid
export const SERVER_SYSADMIN_POST_GET_USER_LOGINS_URL = SERVER_BASE_URL + "/sysadmin/get_login_events/";// + companyUuid
export const SERVER_SYSADMIN_POST_GET_PREPROCESS_JOB_RUNS_URL = SERVER_BASE_URL + "/sysadmin/get_job_runs_preprocess/";// + companyUuid
export const SERVER_SYSADMIN_POST_COMPANY_ALERT_PRIMARY_FEED_URL = SERVER_BASE_URL + "/api/alerts/primary/";// + companyUuid
export const SERVER_GET_ALERT_MESSAGES_URL = SERVER_BASE_URL + "/api/alert_messages/get_all_for_company";
export const SERVER_POST_ALERT_MESSAGE_URL = SERVER_BASE_URL + "/api/alert_messages/create_one";
export const SERVER_SYSADMIN_POST_GET_ALERT_MESSAGES_URL = SERVER_BASE_URL + "/api/alert_messages/get_all_for_company_sysadmin";
export const SERVER_SYSADMIN_POST_ALERT_MESSAGE_URL = SERVER_BASE_URL + "/api/alert_messages/create_one_sysadmin"
export const SERVER_SYSADMIN_POST_DELETE_ALERT_MESSAGE_URL = SERVER_BASE_URL + "/api/alert_messages/delete_one";
export const SERVER_POST_CHANGE_ALERT_MESSAGE_IS_ACTIVE_URL = SERVER_BASE_URL + "/api/alert_messages/set_is_active";
export const SERVER_SYSADMIN_POST_GET_ALERT_MESSAGE_BY_UUID_URL = SERVER_BASE_URL + "/api/alert_messages/get_one";
export const SERVER_GET_SYSADMIN_ALERT_CONFIGS_FOR_APP_URL = SERVER_BASE_URL + "/api/alerts/alert_config/get_for_app/";// + companyUuid
export const SERVER_GET_ALERT_SYNCS_BY_APP_URL = SERVER_BASE_URL + "/api/alerts/get_alert_syncs_by_app";
export const SERVER_SYSADMIN_POST_SET_DISPLAY_PROFITABILITY_URL = SERVER_BASE_URL + "/api/company/set_display_profitability/";// + companyUuid
export const SERVER_SYSADMIN_POST_SET_DISPLAY_PROFITABILITY_VALUE_URL = SERVER_BASE_URL + "/api/company/set_display_profitability_value/";// + companyUuid
export const SERVER_POST_DISPLAY_CONFIG_GET_OR_CREATE_URL = SERVER_BASE_URL + "/api/display_config/get_or_create_table";
export const SERVER_SYSADMIN_POST_DISPLAY_CONFIG_GET_OR_CREATE_URL = SERVER_POST_DISPLAY_CONFIG_GET_OR_CREATE_URL + "/";// + companyUuid
export const SERVER_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROW_URL = SERVER_BASE_URL + "/api/display_config/create_or_modify_row";
export const SERVER_SYSADMIN_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROW_URL = SERVER_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROW_URL + "/";// + companyUuid
export const SERVER_POST_GET_JOURNAL_ENTRIES_URL = SERVER_BASE_URL + "/api/display_config/get_import_file";
export const SERVER_SYSADMIN_POST_GET_JOURNAL_ENTRIES_URL = SERVER_POST_GET_JOURNAL_ENTRIES_URL + "/";// + companyUuid
export const SERVER_POST_GET_JOURNAL_ENTRY_URL = SERVER_BASE_URL + "/api/display_config/get_erp_entry";
export const SERVER_SYSADMIN_POST_GET_JOURNAL_ENTRY_URL = SERVER_POST_GET_JOURNAL_ENTRY_URL + "/";// + companyUuid

export const defaultRequestOptions = {
  url: SERVER_AUTHENTICATION_URL,

  method: "GET",
  headers: { "Content-Type": "application/json" },
  mode: "cors",
  credentials: "include",
};

export const requestErrorTypes = {
  network: "network",
  server: "server",
}

export const renderHTML = (rawHTML) =>
  React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

export const DEFAULT_SYNC_FREQUENCY_HRS = 24;

export const SOURCE_TABLE_SEPARATOR = "___";

// export const USE_DUMMY_DATA = true;
export const USE_DUMMY_DATA =
  !(
    process.env.REACT_APP_PROD &&
    process.env.REACT_APP_PROD.toLowerCase() === "true"
  ) &&
  process.env.REACT_APP_DUMMY &&
  process.env.REACT_APP_DUMMY.toLowerCase() === "true";

export const TEST_ERROR_LOG =
  !(
    process.env.REACT_APP_PROD &&
    process.env.REACT_APP_PROD.toLowerCase() === "true"
  ) &&
  process.env.REACT_APP_TEST_ERROR_LOG &&
  process.env.REACT_APP_TEST_ERROR_LOG.toLowerCase() === "true";

export const SEND_DISCOVER_SCHEMA = true;
