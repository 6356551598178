import React, { useState, useEffect } from 'react';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import { useTheme } from '@mui/material/styles';
import {SiteLanguage} from "../../../Constants";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";

HighchartsExporting(Highcharts);
HighchartsHeatmap(Highcharts);
NoDataToDisplay(Highcharts);

function FDHeat(props) {
	const [rows, setRows] = useState([]);
	const theme = useTheme();
	// const chartTheme = theme.palette.chartTheme;
	Highcharts.setOptions({
		// colors: props.colors
		// 	? props.colors
		// 	: [chartTheme.chart1.color, chartTheme.chart2.color, chartTheme.chart3.color, chartTheme.chart4.color, chartTheme.chart5.color, chartTheme.chart6.color, chartTheme.chart7.color, chartTheme.chart8.color],
		xAxis: {
			labels: {
				style: {
					fontFamily: theme.typography.fontFamily,
					color: theme.typography.color,
				}
			}
		},
		yAxis: {
			labels: {
				style: {
					fontFamily: theme.typography.fontFamily,
					color: theme.typography.color,
				}
			}
		},
		legend: {
			itemStyle: {
				fontFamily: theme.typography.fontFamily,
				color: theme.typography.color,
			}
		},
		plotOptions: {
			series: {
				dataLabels: {
					style: {
						fontFamily: theme.typography.fontFamily,
						color: theme.typography.color,
					}
				}
			}
		},
		tooltip: {
			style: {
				fontFamily: theme.typography.fontFamily,
				color: theme.typography.color,
			}
		},
	});

	const formatter = (props.formatting && props.formatting.currency ? "$" : "") + "{point.value:,." + (props.formatting && props.formatting.digits ? props.formatting.digits : 0) + "f}" + (props.formatting && props.formatting.percent ? "%" : "");
	const labelFormatter = (props.formatting && props.formatting.currency ? "$" : "") + "{name:,." + (props.formatting && props.formatting.digits ? props.formatting.digits : 0) + "f}" + (props.formatting && props.formatting.percent ? "%" : "")

	const getPointCategoryName = (point, dimension) => {
	    var series = point.series,
	        isY = dimension === 'y',
	        axis = series[isY ? 'yAxis' : 'xAxis'];
	    return axis.categories[point[isY ? 'y' : 'x']];
	}

	useEffect(() => {
		let data = [];
		for(var i = 0; i < props.data.length; i++) {
			for(var j = 0; j < props.data[i].length; j++) {
				data.push([i, j, props.data[i][j]])
			}
		}
		setRows(data);
	}, props.data);

	const options = {

	    chart: {
	        type: 'heatmap',
	        //marginTop: 40,
	        //marginBottom: 80,
	        height: props.height !== undefined ? props.height : 400,
	        marginRight: 100,
	        plotBorderWidth: 1
	    },
	    title: {
	        text: null
	    },
	    xAxis: {
	        categories: props.x_catagories.map((row) => { return row.label })
	    },
	    yAxis: {
	        categories: props.y_catagories.map((row) => { return row.label }),
	        title: null,
	        reversed: true
	    },
	    colorAxis: {
	        min: 0,
	        minColor: '#FFFFFF',
	        maxColor: theme.palette.primary.main,
	    },
	    legend: {
	        align: 'right',
	        layout: 'vertical',
	        margin: 0,
	        verticalAlign: 'top',
	        y: 25,
	        //labelFormat: labelFormatter,
	        symbolHeight: props.height !== undefined ? props.height * 0.7 : 280,
	    },
	    credits: {
	    	enabled: false
	    },
	    tooltip: {
	        enabled: false
	    },
	    series: props.hasData ? [{
	        name: props.label,
	        borderWidth: 1,
	        data: rows,
	        dataLabels: {
	            enabled: true,
	            color: '#000000',
	           	format: formatter,
	        }
	    }] : [],
	    responsive: {
	        rules: [{
	            condition: {
	                maxWidth: 500
	            },
	            chartOptions: {
	                yAxis: {
	                    labels: {
	                        formatter: function () {
	                            return this.value.charAt(0);
	                        }
	                    }
	                }
	            }
	        }]
	    },
		lang: {
			noData: SiteLanguage.MissingData
		},
		noData: {
			style: {
				fontWeight: theme.typography.p.fontWeight,
				fontSize: theme.typography.h6.fontSize,
				color: theme.typography.missingData.color,
			}
		},

	}




	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h6">{props.title}</Typography>
			</Grid>
			<Grid item xs={12}>
				<HighchartsReact
				    highcharts={Highcharts}
				    options={options}
				  />
			</Grid>
	    </Grid>
	)
}

export default FDHeat;