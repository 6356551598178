export default function formatNum(num, args={}) {
	let max = args.max ? args.max : 0;
	let min = Math.min(args.min ? args.min: 0,max);
	let prefix = args.prefix ? args.prefix : "";
	let suffix = args.suffix ? args.suffix : "";
	let divisor = args.divisor ? args.divisor : 1;
	let percent = args.percent ? 100 : 1;

	return !isNaN(num) ? (prefix + ((Number(num) / divisor) * (percent)).toLocaleString(undefined, {
		minimumFractionDigits: min,
		maximumFractionDigits: max,
	}) + suffix) : "-"
}