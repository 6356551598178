import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Constants from "../../Constants";
import { setPreviewCommandUuid, setPreviewError } from "../../store/previewAppSlice";
import AsyncRequest from "./AsyncRequest";

/*
PreviewAppRequest - This guy needs to watch appBuilder.loading - if true, it needs to generate preview args and return the res.commandUuid from it's async request to previewApp.commandUuid
If something goes wrong - it needs to set previewApp.error to true and setLoading to false
*/

const PreviewAppRequest = () => {
  const dispatch = useDispatch();
  const isSysAdmin = useSelector(state => state.role.isSysAdmin);
  const loading = useSelector(state => state.previewApp.loading);
  const script = useSelector(state => state.appBuilder.script);
  const file = useSelector(state => state.appBuilder.selectedFile);
  const activeDataSource = useSelector(state => state.appBuilder.activeDataSource);
  const sandboxDestination = useSelector(state => state.appBuilder.sandboxDestination);
  const [previewReqArgs, setPreviewReqArgs] = useState(null);

  const handlePreviewResponse = (res) => {
    console.log("PreviewAppRequest handlePreviewResponse -> res", res);
    dispatch(setPreviewCommandUuid(res.commandUuid));
    setPreviewReqArgs(null);
  }

  const handlePreviewError = (err, errType) => {
    console.log("PreviewAppRequest handlePreviewError -> err", err);
    dispatch(setPreviewError({ error: { isErrored: true, message: `There was a ${errType} error requesting your preview` } }));
    setPreviewReqArgs(null);
  }

  useEffect(() => {
    if (loading) {
      console.log("PreviewAppRequest generating args");
      const previewReqBody = new FormData();
      if (file) {
        previewReqBody.append("appScript", file);
      } else {
        const scriptFile = new File([script], "appScript.py", {
          type: "text/x-python",
        });
        previewReqBody.append("appScript", scriptFile);
      }
      const formSources = []; // holdover from previous iteration - noted on 12/20/2023
      const formTables = []; // holdover from previous iteration - noted on 12/20/2023
      previewReqBody.append("appSourceTables", JSON.stringify(formSources));
      previewReqBody.append("requiredSources", JSON.stringify(formTables));
      previewReqBody.append("appName", "");
      previewReqBody.append("appFullName", "");
      previewReqBody.append("appCategory", "");
      previewReqBody.append("price", "");
      previewReqBody.append("description", "");
      previewReqBody.append("appThumbnail", "null");
      previewReqBody.append("forceJobRemake", false);
      previewReqBody.append("dataSource", activeDataSource.toLowerCase());
      previewReqBody.append("sandboxChoice", sandboxDestination);
      console.log("preview app request body:");
      for (const value of previewReqBody.entries()) {
        console.log(value[0] + ": ", value[1]);
      }
      const previewArgs = {
        url: Constants.SERVER_DEV_APP_PREVIEW_URL,
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        body: previewReqBody,
        commandType: Constants.CommandType.runMarketplaceApp,
        sourceName: null,
      };
      console.log("previewArgs", previewArgs);
      setPreviewReqArgs(previewArgs);
    }
  }, [loading, script, file, activeDataSource, sandboxDestination])

  return !isSysAdmin || !loading ? null : (
    <AsyncRequest
      requestArgs={previewReqArgs}
      afterProcess={handlePreviewResponse}
      handleError={(err) => handlePreviewError(err, "server")}
      handleCatchError={(err) => handlePreviewError(err, "network")}
    />
  )
}

export default PreviewAppRequest;