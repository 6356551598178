import React from "react";
// const prod_url = "https://api.fruitiondata.com";
// const dev_url = "https://dev-use1.fruitiondata.net";
//const local_url = "http://localclient.fruitiondata.net:8080"; // <- Eric's fix local_url
// const local_url = "http://localhost:8080"; // <- OG local_url
// const sandbox_url = "https://sandbox-use1.fruitiondata.net";
// const getEnv = () => {
//   if (
//     process.env.REACT_APP_LOCAL &&
//     process.env.REACT_APP_LOCAL.toLowerCase() === "true"
//   ) {
//     return local_url;
//   } else {
//     return dev_url;
//   }
// };

export const FDUrl = process.env.REACT_APP_FDURL;
