import DirectRequest from "./DirectRequest";
import {useDispatch, useSelector} from "react-redux";
import {useState, Fragment} from "react";
import {setConfigsReady, setDashboardData, setGetDataError} from "../../store/dashboardSlice";
import {
  addConfigs,
  dashboardConfigsSelectors,
  resetConfig,
  updateConfigTableRows
} from "../../store/dashboardConfigsSlice";
import * as Constants from "../../Constants";
import {setUpFilters} from "../../store/dashboardFiltersSlice";
import {setUpSortRules} from "../../store/dashboardSortSlice";
import {setUpTransforms} from "../../store/dashboardTransformSlice";

export const GetDashboardAndConfigsRequest = () => {
  const dispatch = useDispatch();
  const getDataArgs = useSelector(state => state.dashboard.getDataArgs);
  const companyUuid = useSelector(state => state.dashboard.companyUuid);

  const [tempDashboardData, setTempDashboardData] = useState(null);
  const [displayConfigsArgs, setDisplayConfigsArgs] = useState([]);
  const [displayConfigs, setDisplayConfigs] = useState([]);

  const resetLocalState = () => {
    setTempDashboardData(null);
    setDisplayConfigsArgs([]);
    setDisplayConfigs([]);
  }

  const handleDashboardData = (res) => {
    const dashboardJson = JSON.parse(res.dashboardJson);
    const tables = res.tables;
    // find each component with displayConfigEnabled
    const tempDisplayConfigsArgs = [];
    Object.entries(dashboardJson.components).forEach(([objectName, component]) => {
      if (component.displayConfigEnabled) {
        // create args -> push to tempDisplayConfigArgs
        // developerAppUuid: str
        // sourceTableName: str
        // objectName: str
        // primaryKeyFieldList: List<str>
        // linkedTableForeignFieldMap: Map<str, List<str>>
        const tempBody = {
          developerAppUuid: res.appUuid,
          sourceTableName: component.sourceTable,
          objectName: objectName,
          primaryKeyFieldList: component.primaryKeyFieldList,
        }
        if (component.linkedTableForeignFieldMap) {
          tempBody["linkedTableForeignFieldMap"] = component.linkedTableForeignFieldMap
        }
        const tempDisplayConfigArgs = {
          url: companyUuid ? Constants.SERVER_SYSADMIN_POST_DISPLAY_CONFIG_GET_OR_CREATE_URL + companyUuid : Constants.SERVER_POST_DISPLAY_CONFIG_GET_OR_CREATE_URL,
          method: "POST",
          body: JSON.stringify(tempBody)
        }
        tempDisplayConfigsArgs.push(tempDisplayConfigArgs);
      }
    })
    if (tempDisplayConfigsArgs.length === 0) {
      console.log("no display configs for app" + res.appName);
      dispatch(setDashboardData({
        dashboardJson: dashboardJson,
        tables: tables
      }));

      dispatch(setUpTransforms({ tables: tables, dashboardJson: dashboardJson }))
      dispatch(setUpFilters({ tables: tables }));
      dispatch(setUpSortRules({ dashboardJson: dashboardJson }));

      dispatch(setConfigsReady());
      resetLocalState();
    } else {
      setTempDashboardData({
        dashboardJson: dashboardJson,
        tables: tables
      })
      setDisplayConfigsArgs(tempDisplayConfigsArgs);
    }
    console.warn("layout", JSON.parse(res.dashboardJson));
    console.warn("tables", res.tables);
  }

  const handleDashboardDataError = (err) => {
    console.log("getData error", err);
    console.log("getData args", getDataArgs);
    dispatch(setGetDataError("There was an error retrieving your dashboard"))
  }

  const handleDisplayConfig = (res) => {
    console.log("handleDisplayConfig res:", res);
    if (displayConfigsArgs.length === 1) {
      console.log("displayConfigsArgs.length === 1")
      // last displayConfig - dispatch each in displayConfigs and current res to dashboardConfigSlice
      // wipe local state and dispatch tempDashboardSTate to dashboardSlice
      const sourceTable = JSON.parse(displayConfigsArgs[0].body).sourceTableName;
      const displayConfigWithSourceTable = {
        ...res.displayConfigTable,
        sourceTable: sourceTable
      }

      const allConfigs = [...displayConfigs, displayConfigWithSourceTable];
      const dashboardData = {...tempDashboardData}
      allConfigs.forEach((displayConfig) => {
        /*
        String uuid;
        String companyUuid;
        String createdBy;
        String developerAppUuid;
        String objectName;
        List<String> primaryKeyFieldList;
        List<DisplayConfigTableRowDTO> displayConfigTableRows;
        Map<String, List<String>> linkedTableForeignFieldMap;
         */
        // give the component its config uuid so it's actions buttons know which config to update
        // and its table knows which config displayConfigTableRows to monitor
        dashboardData['dashboardJson']['components'][displayConfig.objectName]['displayConfigUuid'] = displayConfig.uuid;
      })
      // console.log("dashboardData", dashboardData)

      //const allConfigs = [...displayConfigs, displayConfigWithSourceTable];
      dispatch(addConfigs(allConfigs));
      dispatch(setDashboardData(dashboardData));

      dispatch(setUpTransforms(dashboardData));
      dispatch(setUpFilters(dashboardData));
      dispatch(setUpSortRules(dashboardData));

      dispatch(setConfigsReady());
      // wipe local state
      resetLocalState();
    } else {
      // push the res into displayConfigs - slice displayConfigsArgs
      // add back sourceTableName to the config
      const sourceTable = JSON.parse(displayConfigsArgs[0].body).sourceTableName;
      const displayConfigWithSourceTable = {
        ...res.displayConfigTable,
        sourceTable: sourceTable
      }
      setDisplayConfigs((prevState) => [...prevState, displayConfigWithSourceTable]);
      setDisplayConfigsArgs((prevState) => prevState.slice(1));
    }
  }

  return (
    <>
      <DirectRequest
        requestArgs={getDataArgs}
        afterProcess={handleDashboardData}
        handleError={handleDashboardDataError}// TODO: setGetDataError
        handleCatchError={handleDashboardDataError}
      />
      {displayConfigsArgs.length > 0 && (
        <DirectRequest
          requestArgs={displayConfigsArgs[0]}
          afterProcess={handleDisplayConfig}
          handleError={() => {}}// TODO: how to handle error here?
          handleCatchError={() => {}}
        />
      )}
    </>
  )
}

const DashboardConfigUpdateHandler = ({ configUuid }) => {
  const dispatch = useDispatch();
  // - each handler needs to watch for its displayConfig's updateArgs - needs to fire the request - needs to get updated config - needs to pass them to config
  const configUpdateArgs = useSelector((state) => dashboardConfigsSelectors.selectById(state, configUuid).updateArgs);

  const handleConfigUpdate = (res) => {
    console.log(configUuid + " displayConfig update res", res)
    // will get whole config back - need to update config displayConfigTableRows - no need for refresh
    const newDisplayConfigTableRows = res.displayConfigTable.displayConfigTableRows || [];
    dispatch(updateConfigTableRows(configUuid, newDisplayConfigTableRows));
  }

  return (
    <DirectRequest
      requestArgs={configUpdateArgs}
      afterProcess={handleConfigUpdate}
      handleError={() => {}}// TODO: how to handle error here?
      handleCatchError={() => {}}
    />
  )
}

const DashboardConfigResetHandler = ({ configUuid }) => {
  const dispatch = useDispatch();
  // - each handler needs to watch for its displayConfig's resetArgs - needs to fire the request - once all resets fired get config table rows - pass them to config
  const configResetArgs = useSelector((state) => dashboardConfigsSelectors.selectById(state, configUuid).resetArgs);
  const handleConfigRowReset = (res) => {
    // check if last reset
    if (configResetArgs.length === 1) {
      console.log("all displayConfigTableRows reset for displayConfig", configUuid);
      // get res.displayConfig
      const newDisplayConfigTableRows = res.displayConfigTable.displayConfigTableRows || [];
      dispatch(updateConfigTableRows(configUuid, newDisplayConfigTableRows));
    } else {
      // resetArgs = resetArgs.slice(1);
      dispatch(resetConfig(configUuid, configResetArgs.slice(1)));
    }
  }
  return configResetArgs ? (
    <DirectRequest
      requestArgs={configResetArgs[0]}
      afterProcess={handleConfigRowReset}
      handleError={() => {}}// TODO: how to handle error here?
      handleCatchError={() => {}}
    />
  ) : null

}

const DashboardConfigsUpdateRequest = () => {
  // needs to map over display configs and spawn a handler for each
  // - each handler needs to watch for its displayConfig's updateArgs - needs to fire the request - needs to get updated config - needs to pass them to config
  const displayConfigs = useSelector((state) => dashboardConfigsSelectors.selectIds(state));
  return (
    <>
      {displayConfigs.map(configUuid => {
        return (
          <Fragment key={configUuid + "_update_request"}>
            <DashboardConfigUpdateHandler key={configUuid + "_update_handler"} configUuid={configUuid} />
            <DashboardConfigResetHandler key={configUuid + "_reset_handler"} configUuid={configUuid} />
          </Fragment>
        )
      })}
    </>
  )
}

export const DashboardRequest = () => {
  return (
    <>
      <GetDashboardAndConfigsRequest/>
      <DashboardConfigsUpdateRequest/>
    </>
  )
}

export default DashboardRequest;