import { createSlice /*, current*/ } from "@reduxjs/toolkit";

export const initialLogoState = {
  logo: null,
};

export const themeLogoSlice = createSlice({
  name: "themeLogo",
  initialState: initialLogoState,
  reducers: {
    setThemeLogo: (state, action) => {
      if (action.payload.logo) state.logo = action.payload.logo;
    },
    resetThemeLogo: (state) => {
      state.logo = null;
    },
  },
});

export const { setThemeLogo, resetThemeLogo } = themeLogoSlice.actions;
export default themeLogoSlice.reducer;
