import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ImpactBar from "../../ImpactBar/ImpactBar";
import Button from "@mui/material/Button";
import TagInputs from "./TagInputs";
import ReplayIcon from "@mui/icons-material/Replay";
import SendIcon from '@mui/icons-material/Send';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import * as Constants from "../../../Constants";
import DirectRequest from "../../../API/requests/DirectRequest";
import {refreshAppsSysAdmin} from "../../../store/appsSysAdminSlice";
import { styled } from "@mui/material/styles";
import {setSelectedAppUuid, setIsDirty, setModalState} from "../../../store/dashboardEditorSlice";

export const getSourceTag = (tags) => {
  const sourceDisplayNames = Constants.supported_integrations.map((integration) => integration.displayName.toLowerCase()).concat(["sage"]);
  return tags.find((tag) => sourceDisplayNames.includes(tag.toLowerCase())) || "source tag not found";
}

// also used in SysAdminSubscribedApps.js
export const CustomAppTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
  },
});

const checkTags = (selectedAppUuid, allApps, tags) => {
  const thisApp = allApps.find(app => app.uuid === selectedAppUuid)
  if (!thisApp) {
    // console.log("checkTags -> no 'thisApp' - allApps:", allApps, "selectedAppUuid:", selectedAppUuid)
    return true;
  }
  // then check if tags are the same
  if (thisApp.tags.join("") === tags.join("")) {
    // console.log("checkTags -> tags are the same:", thisApp.tags, tags)
    return true;
  }
  // then check if tags is an empty array
  if (tags.length === 0) {
    // console.log("checkTags -> tags is an empty array:", tags)
    return true;
  }
  // then check if any tags are empty
  let disabled = false;
  tags.forEach((tag, index) => {
    if (!tag) {
      // tag is an empty string
      // console.log("checkTags -> tag is an empty string:", tag)
      disabled = true;
    }
  })
  // console.log("checkTags -> disabled:", disabled)
  return disabled;
}

// this component is also being used by ScriptEditor's SelectAppBar
export const SelectApp = ({ selectedAppUuid, allApps }) => {
  const dispatch = useDispatch();
  const isDirty = useSelector((state) => state.dashboardEditor.isDirty);
  const handleSelection = (event) => {
    if (isDirty) {
      dispatch(setModalState({ open: true, intendedSelection: event.target.value }))
    } else {
      dispatch(setSelectedAppUuid(event.target.value));
    }
  }

  return (
    <FormControl sx={{ minWidth: "350px", width: "700px" }}>
      <InputLabel id={"app-editor-select-label"}><i>App</i></InputLabel>
      <Select
        id="app-editor-select"
        labelId={"app-editor-select-label"}
        margin="dense"
        onChange={handleSelection}
        value={selectedAppUuid || ""}
        label={"App"}
      >
        {allApps.map((app, index) => {
          return (
            <MenuItem value={app.uuid} key={index}>
              {app.uuid} {app.fullName} {getSourceTag(app.tags)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

const SelectAppBar = ({ allApps, handleReset, handleUpdate, selectedAppUuid, tags }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    let isDirty = false;
    if (selectedAppUuid) {
      const thisApp = allApps.find(app => app.uuid === selectedAppUuid);
      if (tags.some(tag => !tag)) {
        isDirty = true;
      }
      if (thisApp) {
        if (thisApp.tags.join("") !== tags.join("")) {
          isDirty = true;
        }
      }
    }
    dispatch(setIsDirty(isDirty));
  }, [selectedAppUuid, allApps, tags])
  return (
    <Grid item xs={12}>
      <ImpactBar
        component={
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item>
              <SelectApp selectedAppUuid={selectedAppUuid} allApps={allApps} />
            </Grid>
            <Grid item>
              <Box sx={{height: "100%", display: "flex", alignItems: "center" }}>
                <CustomAppTooltip
                  title={selectedAppUuid ? <pre>{JSON.stringify(allApps.find(app => app.uuid === selectedAppUuid), null, 2)}</pre> : ""}
                >
                  <SearchIcon sx={!selectedAppUuid ? { fontSize: "2rem", fill: "grey" } : { fontSize: "2rem" }}/>
                </CustomAppTooltip>
              </Box>
            </Grid>
            <Grid item>
              <Button
                onClick={handleReset}
                sx={{ borderRadius: "0px !important"}}
                variant={"outlined"}
                startIcon={<ReplayIcon />}
                disabled={!selectedAppUuid}
              >
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant={"contained"}
                sx={{ borderRadius: "0px !important"}}
                startIcon={<SendIcon />}
                onClick={handleUpdate}
                disabled={checkTags(selectedAppUuid, allApps, tags)}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        }
      />
    </Grid>
  )
}

export const TagEditor = () => {
  const dispatch = useDispatch();
  const allApps = useSelector((state) => state.appsSysAdmin.list);//dummyApps
  const selectedAppUuid = useSelector((state) => state.dashboardEditor.selectedAppUuid);
  const [tags, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);

  const [updateAppTagsArgs, setUpdateAppTagsArgs] = useState(null);

  const handleReset = () => {
    setTags(JSON.parse(JSON.stringify(allApps.find((app) => app.uuid === selectedAppUuid).tags)));
  };

  const handleUpdate = () => {
    const body = {
      developerAppUuid: selectedAppUuid,
      tags: tags
    }
    const updateRequestArgs = {
      url: Constants.SERVER_SYSADMIN_UPDATE_DEVAPP_URL,
      method: "POST",
      body: JSON.stringify(body)
    }
    setUpdateAppTagsArgs(updateRequestArgs);
  }

  const refreshSysAdminApps = () => {
    dispatch(refreshAppsSysAdmin());
  }

  useEffect(() => {
    const tagsList = [...new Set(allApps.map((app) => app.tags).flat())].sort((a, b) => a.localeCompare(b));
    setAllTags(tagsList);
  }, [allApps])

  useEffect(() => {
    if (selectedAppUuid) setTags(JSON.parse(JSON.stringify(allApps.find((app) => app.uuid === selectedAppUuid).tags)));
  }, [allApps, selectedAppUuid])

  return (
    <>
      <DirectRequest
        requestArgs={updateAppTagsArgs}
        afterProcess={refreshSysAdminApps}
        handleError={(err) => console.log("error updating app tags", err)}
        handleCatchError={(err) => console.log("catch error updating app tags", err)}
      />
      <Grid container spacing={2}>
        <SelectAppBar
          allApps={allApps}
          handleReset={handleReset}
          handleUpdate={handleUpdate}
          selectedAppUuid={selectedAppUuid}
          tags={tags}
        />
        <TagInputs
          allTags={allTags}
          tags={tags}
          setTags={setTags}
          selectedAppUuid={selectedAppUuid}
          allApps={allApps}
        />
      </Grid>
    </>
  );
}

export default TagEditor;