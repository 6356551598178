import React, { useState, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from "react-redux";
import {setCurrentType} from "../../store/alertsTabsSlice";
import {setSysAdminCurrentType} from "../../store/alertsTabsSysAdminSlice";
import FeedTableAlerts from "./FeedTableAlerts";
import FeedTableFilters from "./FeedTableFilters";

function FeedTable(
  {
    alerts,
    sysAdmin = false,
    dashboardView = false,
    preview = false,
    columns
  }) {
  const dispatch = useDispatch();
  const currentType = useSelector((state) => !sysAdmin ? state.alertsTabs.currentType : state.alertsTabsSysAdmin.currentType)
  const [currentDate, setCurrentDate] = useState(undefined);
  const [currentFilterText, setCurrentFilterText] = useState("");

  const alert_filter = useCallback((type, value = undefined, event) => {
    // console.log("alert_filter", type, value, event);
    if (type === "type") {
      value = event?.target.dataset.value || value;
      if (sysAdmin) {
        dispatch(setSysAdminCurrentType(value ? value : currentType));
      } else {
        dispatch(setCurrentType(value ? value : currentType));
      }
    } else if (type === "date") {
      setCurrentDate(currentDate === undefined || currentDate !== value ? value : undefined);
    } else if (type === "search") {
      // console.log("search", value);
      setCurrentFilterText(value);
    }
  }, [sysAdmin, currentType, currentDate, dispatch]);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <FeedTableFilters
          alert_filter={alert_filter}
          currentDate={currentDate}
          currentType={currentType}
          dashboardView={dashboardView}
          sysAdmin={sysAdmin}
        />
        <FeedTableAlerts
          sysAdmin={sysAdmin}
          alerts={alerts}
          currentType={currentType}
          currentDate={currentDate}
          currentFilterText={currentFilterText}
          preview={preview}
          columns={columns}
        />
      </Grid>
    </React.Fragment>
  )
}

export default FeedTable;
