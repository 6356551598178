import {useDispatch, useSelector} from "react-redux";
import {config_options, dashboardConfigsSelectors, updateConfig} from "../../../../../store/dashboardConfigsSlice";
import {generateDisplayConfigUpdateArgs, generateDisplayConfigUpdateNotification} from "../DisplayConfigUpdateUtils"
import {setAlertNotificationArgs} from "../../../../../store/alertsNotificationSlice";
import React from "react";
import DefaultConfigActionButton from "./DefaultConfigActionButton";
import CreateEntryConfigActionButton from "./CreateEntryConfigComponents/CreateEntryConfigActionButton";

const configActionsButtonPicker = (configAction, row, handleConfigUpdate, statusTrackerConfig, createEntryConfig, disableActions, displayConfigTableRow) => {
  if (configAction === config_options.create_entry) {
    return (
      <CreateEntryConfigActionButton
        //handleConfigUpdate, configAction, displayConfigTableRow, statusTrackerConfig, disableActions
        handleConfigUpdate={handleConfigUpdate}
        configAction={configAction}
        displayConfigTableRow={displayConfigTableRow}
        createEntryConfig={createEntryConfig}
        statusTrackerConfig={statusTrackerConfig}
        disableActions={disableActions}
        row={row}
      />
    )
  } else {
    return (
      <DefaultConfigActionButton
        handleConfigUpdate={handleConfigUpdate}
        configAction={configAction}
        displayConfigTableRow={displayConfigTableRow}
        statusTrackerConfig={statusTrackerConfig}
        disableActions={disableActions}
      />
    )
  }
}

export const ConfigActionsButton = ({configAction, row, displayConfigUuid, statusTrackerConfig, createEntryConfig, disableActions}) => {
  const dispatch = useDispatch();
  const dashboardUuid = useSelector(state => state.dashboard.uuid)
  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector((state) => state.role.isInternal);
  const companyUuid = useSelector(state => state.dashboard.companyUuid);

  const primaryKeyFieldList = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.primaryKeyFieldList);
  const linkedTableForeignFieldMap = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.linkedTableForeignFieldMap);
  const displayConfigTableRows = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.displayConfigTableRows);
  const displayConfigTableRow = displayConfigTableRows?.find((displayConfigTableRow) => Object.entries(displayConfigTableRow.primaryKeyValueMap).every(([field, value]) => row[field] === value));

  const handleConfigUpdate = (newConfigValue) => {
    const configUpdateArgs = generateDisplayConfigUpdateArgs(companyUuid, newConfigValue, configAction, row, displayConfigUuid, primaryKeyFieldList, linkedTableForeignFieldMap);
    // console.log("configUpdateArgs", configUpdateArgs);
    if (!isInternal && !companyUuid) {// companyUuid means sysAdmin viewing dashboard
      const configUpdateNotification = generateDisplayConfigUpdateNotification(newConfigValue, configAction, row, user, displayConfigUuid, dashboardUuid);
      console.log("configUpdateNotification", configUpdateNotification);
      dispatch(setAlertNotificationArgs(configUpdateNotification));
    }
    dispatch(updateConfig(displayConfigUuid, configUpdateArgs));
  }

  return (configActionsButtonPicker(configAction, row, handleConfigUpdate, statusTrackerConfig, createEntryConfig, disableActions, displayConfigTableRow))
}

export default ConfigActionsButton