import Grid from "@mui/material/Grid";
import ImpactBar from "../ImpactBar/ImpactBar";
import AppBuilderActiveBuilder from "./AppBuilderActiveBuilder";
import AppBuilderErrorAlert from "./AppBuilderErrorAlert";
import AppBuilderSettings from "./AppBuilderSettings";
import TopBar from "../TopBar/TopBar";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setGoToPreview } from "../../store/previewAppSlice";

const AppBuilderGoToPreviewManager = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goToPreview = useSelector(state => state.previewApp.goToPreview);
  useEffect(() => {
    if (goToPreview) {
      navigate("/preview_dashboard", {
        state: { preview: true, developer: true },
      });
      dispatch(setGoToPreview(false));
    }
  }, [goToPreview]);
  return null;
}

export const AppBuilder = () => {
  return (
    <>
      <AppBuilderGoToPreviewManager/>
      <Grid container spacing={3}>
        <AppBuilderErrorAlert />
        <Grid item xs={12}>
          <TopBar
            top_message={"App builder"}
            hide_help={true}
            clusterStatus={true}
          />
        </Grid>
        <Grid item xs={12}>
          <ImpactBar component={<AppBuilderSettings />} />
        </Grid>
        <Grid item xs={12}>
          <AppBuilderActiveBuilder />
        </Grid>
      </Grid>
    </>
  );
}

export default AppBuilder;