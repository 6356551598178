import DirectRequest from "./DirectRequest";
import * as Constants from "../../Constants";
import { useDispatch, useSelector } from "react-redux";
import {resetAlertNotificationArgs, setAlertNotificationArgs, resetTextSearches} from "../../store/alertsNotificationSlice";
import {useEffect} from "react";
import { generateDescriptionFilterNotificationArgs } from "../../components/AlertFeed/FeedTableUtils";

const DescriptionFilterNotificationManager = () => {
  const dispatch = useDispatch();
  const textSearches = useSelector((state) => state.alertsNotification.textSearches);
  const user = useSelector(state => state.role.name);

  useEffect(() => {
    let timer;
    if (textSearches.length > 0) {
      timer = setTimeout(() => {
        dispatch(setAlertNotificationArgs(generateDescriptionFilterNotificationArgs(textSearches, user)))
        dispatch(resetTextSearches())
      }, 30000);
    }
    return () => clearTimeout(timer);
  }, [textSearches, user])

  return null;
}

const NotificationRequest = () => {
  const dispatch = useDispatch();
  const alertNotificationArgs = useSelector((state) => state.alertsNotification.args);

  const handleSuccessfulNotification = () => {
    console.log("AlertNotificationRequest successful")
    dispatch(resetAlertNotificationArgs())
  }

  return alertNotificationArgs ? (
    <DirectRequest
      requestArgs={alertNotificationArgs}
      afterProcess={handleSuccessfulNotification}
      handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("AlertNotificationRequest server error", err)}
      handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("AlertNotificationRequest client error", err)}
    />
  ) : null
}


export const AlertsNotificationRequest = () => {
  return (
    <>
      <DescriptionFilterNotificationManager/>
      <NotificationRequest/>
    </>
  )
}

export default AlertsNotificationRequest;