import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {GeneralAiButton} from "./FeedTableButtons";
import {useSelector, useDispatch} from "react-redux";
import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from "react";
import ImpactBar from "../ImpactBar/ImpactBar";
import { setPrimaryFeed } from "../../store/alertsTabsSlice";
import { setSysAdminPrimaryFeed } from "../../store/alertsTabsSysAdminSlice";
import { generateAlertTypeFilterNotificationArgs, generatePrimaryFeedToggleNotificationArgs } from "./FeedTableUtils";
import {setAlertNotificationArgs, setTextSearches} from "../../store/alertsNotificationSlice";
import * as Constants from "../../Constants";
import DirectRequest from "../../API/requests/DirectRequest";

const PrimaryFeedToggle = ({ sysAdmin }) => {
  const dispatch = useDispatch();
  const primaryFeed = useSelector((state) => sysAdmin ? state.alertsTabsSysAdmin.primaryFeed : state.alertsTabs.primaryFeed);

  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector((state) => state.role.isInternal);

  const handlePrimaryFeedToggle = () => {
    if (sysAdmin) {
      dispatch(setSysAdminPrimaryFeed(!primaryFeed));
    } else {
      dispatch(setPrimaryFeed(!primaryFeed));
      if (!isInternal) {
        const value = !primaryFeed ? "Highest value" : "All"
        const tempPrimaryFeedToggleNotificationArgs = generatePrimaryFeedToggleNotificationArgs(value, user)
        dispatch(setAlertNotificationArgs(tempPrimaryFeedToggleNotificationArgs))
      }
    }
  }

  return (
    <ButtonGroup aria-label="outlined primary button group">
      <Button disableRipple variant={primaryFeed ? "contained" : "outlined"} size={"small"} sx={{ borderRadius: "0px !important" }} onClick={handlePrimaryFeedToggle}>Highest value only</Button>
      <Button disableRipple variant={!primaryFeed ? "contained" : "outlined"} size={"small"} sx={{ borderRadius: "0px !important" }} onClick={handlePrimaryFeedToggle}>All</Button>
    </ButtonGroup>
  )
}

const NumberOfAlertsForFeedCategory = ({ feedCategory }) => {
  const currentTab = useSelector((state) => state.alertsTabs.selectedTab);// NumberOfAlertsForFeedCategory only shows when !sysAdmin so just use user reference
  const filters = useSelector((state) => state.alertsTabs.filters);
  const alerts = useSelector(state => currentTab === 'Resolved' ? state.alerts.resolvedAlerts : state.alerts.alerts)//currentTab = 'Resolved' ?
  const primaryFeed = useSelector((state) => state.alertsTabs.primaryFeed);
  const numberOfAlertsByFeedCategory = primaryFeed
    ? filters[currentTab](alerts)?.filter(alert => alert.appNameDisplay === feedCategory && alert.flagAiRelevantStatus === "NOT_SET" && alert.inPrimaryFeed)?.length
    : filters[currentTab](alerts)?.filter(alert => alert.appNameDisplay === feedCategory && alert.flagAiRelevantStatus === "NOT_SET")?.length
  if (numberOfAlertsByFeedCategory && (currentTab === "Main" || currentTab === "Unread")) {
    return (
      <Typography variant="p" sx={{ pointerEvents: 'none'/* this ensures parent MenuItem element receives click event*/ }}>&nbsp;({numberOfAlertsByFeedCategory})</Typography>
    )
  } else {
    return null;
  }
}

const AlertTypeFilter = ({ alert_filter, currentType }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector((state) => state.role.isInternal);

  const allFeedCats = useSelector((state) => state.appsCategories.feedCategories);

  const primaryFeed = useSelector((state) => state.alertsTabs.primaryFeed);
  const filters = useSelector((state) => state.alertsTabs.filters);
  const currentTab = useSelector((state) => state.alertsTabs.selectedTab);
  const alerts = useSelector(state => currentTab === 'Resolved' ? state.alerts.resolvedAlerts : state.alerts.alerts)
  const primaryFeedCats = [...new Set(filters[currentTab](alerts)?.filter(alert => alert.inPrimaryFeed)?.map(alert => alert.appNameDisplay))].sort();
  const finalizedFeedCats = primaryFeed ? primaryFeedCats : allFeedCats;

  const handleAlertTypeSelection = (event) => {
    const value = event?.target.dataset.value;
    if (value && !isInternal) {
      const tempAlertTypeFilterNotificationArgs = generateAlertTypeFilterNotificationArgs(value, user)
      dispatch(setAlertNotificationArgs(tempAlertTypeFilterNotificationArgs))
    }
    alert_filter("type", undefined, event);
  }

  useEffect(() => {
    if (!finalizedFeedCats.includes(currentType) && currentType !== "All") {
      alert_filter("type", "All")
    }
  }, [finalizedFeedCats, currentType])

  return (
    <FormControl size="small" >
      <InputLabel id="alert-type-select-label">Alert type</InputLabel>
      <Select
        labelId="alert-type-select-label"
        id="alert-type-select"
        value={currentType}
        variant="outlined"
        onClick={handleAlertTypeSelection}
        label={"Alert type"}
        sx={{ minWidth: "150px", borderColor: theme.palette.primary.main }}
      >
        <MenuItem value={"All"} key={"cat_all"}>All</MenuItem>
        {finalizedFeedCats.map((cat, index) => {
          return (
            <MenuItem index={"cat-" + index} value={cat} key={`cat__${cat}__${index}`}>{cat}<NumberOfAlertsForFeedCategory feedCategory={cat} /></MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

const SysAdminAlertTypeFilter = ({ alert_filter, currentType }) => {
  const theme = useTheme();
  const feedCatsSysAdmin = useSelector((state) => state.appsSysAdmin.feedCategories);
  return (
    <FormControl size="small" >
      <InputLabel id="alert-type-select-label">Alert type</InputLabel>
      <Select
        labelId="alert-type-select-label"
        id="alert-type-select"
        value={currentType}
        variant="outlined"
        onClick={alert_filter.bind(this, "type", undefined)}
        label={"Alert type"}
        sx={{ minWidth: "150px", borderColor: theme.palette.primary.main }}
      >
        <MenuItem value={"All"} key={"cat_all"}>All</MenuItem>
        {feedCatsSysAdmin.map((cat, index) => {
          return (
            <MenuItem index={"cat-" + index} value={cat} key={`cat__${cat}__${index}`}>{cat}</MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

const DescriptionFilter = ({ alert_filter }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');

  const isInternal = useSelector((state) => state.role.isInternal);

  // Debounce input value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 500); // Adjust debounce time as needed

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  // Call alert_filter whenever debouncedValue changes
  useEffect(() => {
    alert_filter("search", debouncedValue);
    if (debouncedValue && !isInternal) {
      dispatch(setTextSearches(debouncedValue))
    }
  }, [debouncedValue, alert_filter, isInternal]);

  return (
    <FormControl size="small" >
      <TextField
        id="details-search"
        size="small"
        variant="outlined"
        label="Search by details"
        value={inputValue}
        onChange={(event) => setInputValue(event.target.value)}
      />
    </FormControl>
  );
}

const getSourceTag = (tags) => {
  const sourceDisplayNames = Constants.supported_integrations.map((integration) => integration.displayName.toLowerCase()).concat(["sage"]);
  return tags.find((tag) => sourceDisplayNames.includes(tag.toLowerCase())) || "source tag not found";
}

const SysAdminLinkConfigAppSelect = () => {
  const theme = useTheme();
  const sysAdminApps = useSelector((state) => state.appsSysAdmin.list);
  const selectedCompany = useSelector((state) => state.alertsSysAdmin.company);
  const [selectedCompanySubscribedAppsArgs, setSelectedCompanySubscribedAppsArgs] = useState(null);
  const [selectedCompanySubscribedApps, setSelectedCompanySubscribedApps] = useState([]);

  useEffect(() => {
    if (selectedCompany && selectedCompany.uuid) {
      // if (selectedCompany.name) console.log("selected company", selectedCompany.name);
      setSelectedCompanySubscribedAppsArgs({ url: Constants.SERVER_SYSADMIN_CUS_SUBBED_APPS_URL + selectedCompany.uuid });
      // setOrderFlowButtonConfig(null);
      setSelectedCompanySubscribedApps([]);
    }
  }, [selectedCompany])

  const handleSelectedCompanySubscribedApps = (res) => {
    setSelectedCompanySubscribedApps(res);
  }

  // menu items will be all subscribedApps with link_config_sysadmin__
  const linkConfigSysAdminApps = sysAdminApps.filter((sysAdminApp) => {
    let linkConfigFlag = false;
    sysAdminApp.tags.forEach((tag) => {
      if (tag.includes("link_config_sysadmin__")) {
        linkConfigFlag = true
      }
    })
    let subscribedAppFlag;
    subscribedAppFlag = selectedCompanySubscribedApps.some((subbedApp) => subbedApp.developerAppUuid === sysAdminApp.uuid);
    return linkConfigFlag && subscribedAppFlag;
  })

  const getLinkConfigSysAdminTitle = (tags) => {
    const linkConfigTag = tags.find(tag => tag.includes("link_config_sysadmin__"));
    return linkConfigTag.split("__")[1].split("_").join(" ");
  }

  const handleLinkConfigSysAdminClick = (event) => {
    const appUuid = event.target.value;
    console.log("handleLinkConfigSysAdminClick -> appUuid", appUuid);
    const companyUuid = selectedCompany.uuid;
    if (appUuid === undefined || !companyUuid) return;
    const thisDevApp = linkConfigSysAdminApps.find((app) => app.uuid === appUuid);
    if (!thisDevApp) return;
    const customerSubscriptionUuid = selectedCompanySubscribedApps.find(subbedApp => subbedApp.developerAppUuid === thisDevApp.uuid)?.uuid;
    if (!customerSubscriptionUuid) return;
    localStorage.setItem("dashboardState", JSON.stringify({
      companyUuid: companyUuid,
      customerSubscriptionUuid: customerSubscriptionUuid,
      viewApp: true,
      thisDevApp: thisDevApp,
    }));
    window.open(`${window.location.origin}/redirect/${thisDevApp.uuid}`, '_blank');
  }

  return (
    <>
      <DirectRequest
        requestArgs={selectedCompanySubscribedAppsArgs}
        afterProcess={handleSelectedCompanySubscribedApps}
        handleError={() => {}}
        handleCatchError={() => {}}
      />
      <FormControl size="small" >
        <InputLabel id="link-config-sysadmin-select-label">Link config apps</InputLabel>
        <Select
          labelId="link-config-sysadmin-select-label"
          id="link-config-sysadmin-select"
          value={""}
          placeholder={""}
          variant="outlined"
          onChange={handleLinkConfigSysAdminClick}
          label={"Link config apps"}
          sx={{ minWidth: "150px", borderColor: theme.palette.primary.main }}
        >
          {linkConfigSysAdminApps.map((app, index) => {
            return (
              <MenuItem value={app.uuid} key={"link_config_sysadmin_app_" + index}><strong>{getLinkConfigSysAdminTitle(app.tags)}</strong>&nbsp;{app.uuid}&nbsp;{app.fullName}&nbsp;<i>{getSourceTag(app.tags)}</i></MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </>
  )
}

const Filters = ({ alert_filter, currentDate, currentType, sysAdmin }) => {
  return (
    <Grid container spacing={2} alignItems={"center"}>
      {/* March 28, 2024 - Eric: shelving the Discovered on filter input for now - Primary feed toggle will take it's place */}
      {/*<Grid item>*/}
      {/*  <Typography variant="p">Discovered</Typography>*/}
      {/*</Grid>*/}
      {/*<Grid item>*/}
      {/*  <ButtonGroup aria-label="outlined primary button group">*/}
      {/*    <Button variant={currentDate === "Today" ? "contained" : "outlined"} sx={{ borderRadius: "0px !important" }} onClick={alert_filter.bind(this, "date", "Today")}>Today</Button>*/}
      {/*    <Button variant={currentDate === "7D" ? "contained" : "outlined"} sx={{ borderRadius: "0px !important" }} onClick={alert_filter.bind(this, "date", "7D")}>7D</Button>*/}
      {/*    <Button variant={currentDate === "14D" ? "contained" : "outlined"} sx={{ borderRadius: "0px !important" }} onClick={alert_filter.bind(this, "date", "14D")}>14D</Button>*/}
      {/*    <Button variant={currentDate === "30D" ? "contained" : "outlined"} sx={{ borderRadius: "0px !important" }} onClick={alert_filter.bind(this, "date", "30D")}>30D</Button>*/}
      {/*    <Button variant={currentDate === "90D" ? "contained" : "outlined"} sx={{ borderRadius: "0px !important" }} onClick={alert_filter.bind(this, "date", "90D")}>90D</Button>*/}
      {/*  </ButtonGroup>*/}
      {/*</Grid>*/}
      <Grid item>
        <PrimaryFeedToggle sysAdmin={sysAdmin} />
      </Grid>
      <Grid item></Grid>
      <Grid item>
        {!sysAdmin && (
          <AlertTypeFilter alert_filter={alert_filter} currentType={currentType} />
        )}
        {sysAdmin && (
          <SysAdminAlertTypeFilter alert_filter={alert_filter} currentType={currentType} />
        )}
      </Grid>
      <Grid item></Grid>
      <Grid item>
        <DescriptionFilter alert_filter={alert_filter}/>
      </Grid>
      {!sysAdmin && (
        <>
          <Grid item></Grid>
          <Grid item>
            <GeneralAiButton currentType={currentType} currentDate={currentDate} />
          </Grid>
        </>
      )}
      {sysAdmin && (
        <>
          <Grid item></Grid>
          <Grid item>
            <SysAdminLinkConfigAppSelect/>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export const FeedTableFilters = ({
    alert_filter,
    currentDate,
    currentType,
    dashboardView,
    sysAdmin,
  }) => {
  const disabled = useSelector((state) => sysAdmin ? state.alertsTabsSysAdmin.disabled : state.alertsTabs.disabled);
  return dashboardView ? null : (
    <Grid item xs={12} sx={disabled ? { pointerEvents: "none", cursor: "default" } : {}}>
      <ImpactBar
        component={
          <Filters
            alert_filter={alert_filter}
            currentDate={currentDate}
            currentType={currentType}
            sysAdmin={sysAdmin}
          />
        }
      />
    </Grid>
  )
}

export default FeedTableFilters;