import { createSlice } from '@reduxjs/toolkit';

export const alertsUpdateSlice = createSlice({
  name: "alertsUpdate",
  initialState: {
    userUpdateArgs: null,
    sysAdminUpdateArgs: null,
  },
  reducers: {
    setUserUpdateArgs: (state, action) => {
      // console.log("setUserUpdateArgs", action.payload)
      state.userUpdateArgs = action.payload
    },
    resetUserUpdateArgs: (state) => {
      // console.log("resetUserUpdateArgs")
      state.userUpdateArgs = null
    },
    setSysAdminUpdateArgs: (state, action) => {
      // console.log("setSysAdminUpdateArgs", action.payload)
      state.sysAdminUpdateArgs = action.payload
    },
    resetSysAdminUpdateArgs: (state) => {
      // console.log("resetSysAdminUpdateArgs")
      state.sysAdminUpdateArgs = null
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserUpdateArgs,
  setSysAdminUpdateArgs,
  resetUserUpdateArgs,
  resetSysAdminUpdateArgs
} = alertsUpdateSlice.actions;

export default alertsUpdateSlice.reducer;