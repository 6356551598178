import { createSlice } from '@reduxjs/toolkit';
/*
Risks to finances/org (fraud, scams, black swans)
Profitability outflows (expenses & payments)
Profitability inflows (topline & receipts)
Efficiency (compliance & close)
Before 6/3:
"Profitability inflows (top-line & receipts)",
"Profitability outflows (expenses & payments)",
"Novel risks & efficiencies",
"Efficiency (compliance & close)"// not mapped over for display
 */
export const appsCategoriesSlice = createSlice({
  name: "appsCategories",
  initialState: {
    coverageCategories: [
      "Revenue & top-line (profit-growth by inflows)",
      "Expenses & payments (profit-growth by outflows)",
      "Efficiencies & more (profit-growth by productivity)",
      "Efficiency (compliance & close)"// not mapped over for display
    ],
    summaryCategories: {},
    feedCategories: [],
    numberOfAlertsByFeedCategory: {}
  },
  reducers: {
    setAppsCategories: (state, action) => {
      state.summaryCategories = action.payload.summaryCategories
      state.feedCategories = action.payload.feedCategories
      state.numberOfAlertsByFeedCategory = action.payload.numberOfAlertsByFeedCategory
    }
  },
});

// Action creators are generated for each case reducer function
export const { setAppsCategories } = appsCategoriesSlice.actions;

export default appsCategoriesSlice.reducer;