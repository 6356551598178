import { createSlice } from '@reduxjs/toolkit';

export const initialSubscriptionsSlice = createSlice({
  name: "initialsSubscriptions",
  initialState: {
    subscriptionUuids: [],
  },
  reducers: {
    addSubscriptionUuid: (state, action) => {
      // console.log("addInitialSubscriptionUuid -> action.payload", action.payload);
      state.subscriptionUuids = state.subscriptionUuids.concat([action.payload]);
    },
    removeSubscriptionUuid: (state, action) => {
      // console.log("removeInitialSubscriptionUuid -> action.payload", action.payload);
      const index = state.subscriptionUuids.indexOf(action.payload);
      state.subscriptionUuids = state.subscriptionUuids.slice(0, index).concat(state.subscriptionUuids.slice(index + 1));
    },
    addAllSubscriptionUuids: (state, action) => {
      // console.log("addAllSubscriptionUuids -> action.payload", action.payload);
      state.subscriptionUuids = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addSubscriptionUuid, removeSubscriptionUuid, addAllSubscriptionUuids } = initialSubscriptionsSlice.actions;

export default initialSubscriptionsSlice.reducer;