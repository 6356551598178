import { useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import ConfigActionsButton from "./DisplayConfigActionsComponents/ConfigActionsButton";

const ConfigAction = (
  {configAction, row, displayConfigUuid, statusTrackerConfig, createEntryConfig, disableActions, sysAdminConfigAction}
) => {
  const companyUuid = useSelector(state => state.dashboard.companyUuid);
  // if sysAdminConfigAction && disableActions -> preview
  // if sysAdmin && companyUuid -> live sysadmin viewing
  const showConfigAction = useMemo(() => {
    if (!sysAdminConfigAction) {
      return true;
    } else {
      return !!(companyUuid || disableActions);
    }
  }, [sysAdminConfigAction, disableActions, companyUuid]);

  return showConfigAction ? (
    //pickConfigAction(configAction, row, displayConfigUuid, statusTrackerConfig, disableActions)
    <ConfigActionsButton
      configAction={configAction}
      row={row}
      displayConfigUuid={displayConfigUuid}
      statusTrackerConfig={statusTrackerConfig}
      createEntryConfig={createEntryConfig}
      disableActions={disableActions}
    />
  ) : null;
}

export const DisplayConfigActions = (
  {column, row, displayConfigUuid, displayConfigActionList, displayConfigSysAdminActionList, statusTrackerConfig, createEntryConfig, disableActions}
) => {
  // this needs to be dynamic
  // "status_tracker" needs to spit out a StatusTrackerButton
  // "status_tracker" will currently render a change status button which cycles to final status -
  return (
    <div>
      {displayConfigActionList.map((configAction, index) => {
        return (
          <ConfigAction
            key={index + "_config_action"}
            configAction={configAction}
            row={row}
            displayConfigUuid={displayConfigUuid}
            statusTrackerConfig={statusTrackerConfig}
            createEntryConfig={createEntryConfig}
            disableActions={disableActions}
            sysAdminConfigAction={false}
          />
        )
      })}
      {displayConfigSysAdminActionList?.map((configAction, index) => {
        return (
          <ConfigAction
            key={index + "_sys_admin_config_action"}
            configAction={configAction}
            row={row}
            displayConfigUuid={displayConfigUuid}
            statusTrackerConfig={statusTrackerConfig}
            createEntryConfig={createEntryConfig}
            disableActions={disableActions}
            sysAdminConfigAction={true}
          />
        )
      })}
    </div>
  )
}

export default DisplayConfigActions;