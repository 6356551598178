import { useState, useEffect } from "react";
import DirectRequest from "./DirectRequest";
import * as Constants from "../../Constants";
import { useDispatch, useSelector } from "react-redux";
import { setAlerts, setActiveAlerts, setAlertsError, setResolvedAlertsError } from "../../store/alertsSlice";

/*
export const SERVER_GET_RESOLVED_ALERTS_URL = SERVER_BASE_URL + "/api/alerts/list_resolved";
export const SERVER_SYSADMIN_GET_COMPANY_RESOLVED_ALERTS_URL = SERVER_BASE_URL + "/api/alerts/list_resolved/";// + companyUuid
*/

export const cleanAlerts = (alerts) => {
  return alerts.map((alert, index) => {
    return {
      ...alert,  // Preserve original properties
      Id: index, // Add Id property
    };
  })
}

export const sortAlerts = (alerts) => {
  // sorts alerts by DATE -> MONEY SAVED -> APP NAME (aka: 'alert type') -> DESCRIPTION
  return [...alerts].sort((a, b) => {
    // NOTE: 04/11/2024 - Eric: we're no longer displaying date - so we're no longer sorting by date
    // // sort by date
    // const aDate = a.flagFirstOccurrenceTimestamp.split('/');
    // const bDate = b.flagFirstOccurrenceTimestamp.split('/');
    // const dateCompare = bDate[2] - aDate[2] || bDate[0] - aDate[0] || bDate[1] - aDate[1];
    //
    // if (dateCompare !== 0) {
    //   // If dates are different
    //   return dateCompare;
    // } else {
      // If dates are the same, sort by moneySaved
      const moneySavedCompare = parseFloat(b.moneySaved) - parseFloat(a.moneySaved);
      if (moneySavedCompare !== 0) {
        return moneySavedCompare;
      } else {
        // If moneySaved amounts are the same, sort by appName
        const appNameCompare = a.appNameDisplay.localeCompare(b.appNameDisplay);
        if (appNameCompare !== 0) {
          // If appName are different
          return appNameCompare;
        } else {
          // If appName are the same, sort by flagDescription
          return a.flagDescription.localeCompare(b.flagDescription);
        }
      }
    // }
  });
};

const InitialAlertsRequest = () => {
  const dispatch = useDispatch();
  const isCaptured = useSelector((state) => state.alerts.isCaptured);

  const isRestricted = useSelector((state) => state.role.isRestricted);
  const restrictedWhitelist = useSelector((state) => state.role.restrictedWhitelist);

  const [alertsArgs, setAlertsArgs] = useState({
    url: Constants.SERVER_GET_RELEASED_ALERTS_URL
  });
  const [alertsDict, setAlertsDict] = useState({alerts: [], isCaptured: false});

  const [resolvedAlertsArgs, setResolvedAlertsArgs] = useState({
    url: Constants.SERVER_GET_RELEASED_RESOLVED_ALERTS_URL
  });
  const [resolvedAlertsDict, setResolvedAlertsDict] = useState({alerts: [], isCaptured: false});

  const postSetupComplete = useSelector((state) => state.role.postSetupComplete);

  useEffect(() => {
    if (alertsDict.isCaptured && resolvedAlertsDict.isCaptured) {
      console.warn("alerts", alertsDict.alerts, "resolvedAlerts", resolvedAlertsDict.alerts);
      dispatch(setAlerts({alerts: alertsDict.alerts, resolvedAlerts: resolvedAlertsDict.alerts}));
    }
  }, [alertsDict, resolvedAlertsDict]);

  useEffect(() => {
    if (isCaptured) {
      // console.log("resetting local AlertsRequest state");
      setAlertsDict({alerts: [], isCaptured: false});
      setResolvedAlertsDict({alerts: [], isCaptured: false});
    }
  }, [isCaptured])

  const handleAlerts = (res) => {
    if (res && res.alerts && res.alerts.length > 0) {
      const alerts = isRestricted ? res.alerts.filter(alert => restrictedWhitelist.includes(alert.developerAppUuid)) : res.alerts;
      const cleanedAlerts = sortAlerts(cleanAlerts(alerts));
      setAlertsDict({alerts: cleanedAlerts, isCaptured: true});
    } else {
      setAlertsDict({alerts: [], isCaptured: true});
    }
  }

  const handleResolvedAlerts = (res) => {
    if (res && res.alerts && res.alerts.length > 0) {
      const resolvedAlerts = isRestricted ? res.alerts.filter(alert => restrictedWhitelist.includes(alert.developerAppUuid)) : res.alerts;
      const cleanedAlerts = sortAlerts(cleanAlerts(resolvedAlerts));
      // console.log("InitialAlertsRequest's handleResolvedAlerts - isRestricted", isRestricted, "restrictedWhitelist", restrictedWhitelist, "cleanedAlerts", cleanedAlerts);
      setResolvedAlertsDict({alerts: cleanedAlerts, isCaptured: true});
    } else {
      setResolvedAlertsDict({alerts: [], isCaptured: true});
    }
  };

  const handleAlertsError = (errType, err) => {
    console.log("AlertsRequest alerts error", err);
    const errorMessage = `There was a ${errType} error while fetching your notification feed. Please refresh the page.`;
    dispatch(setAlertsError(errorMessage));
    setAlertsDict({alerts: [], isCaptured: true});
  }

  const handleResolvedAlertsError = (errType, err) => {
    console.log("AlertsRequest resolvedAlerts error", err);
    const errorMessage = `There was a ${errType} error while fetching your notification feed. Please refresh the page.`;
    dispatch(setResolvedAlertsError(errorMessage));
    setResolvedAlertsDict({alerts: [], isCaptured: true});
  }

  return postSetupComplete && !isCaptured ? (
    <>
      <DirectRequest
        requestArgs={alertsArgs}
        afterProcess={handleAlerts}
        handleError={(err) => handleAlertsError(Constants.requestErrorTypes.server, err)}
        handleCatchError={(err) => handleAlertsError(Constants.requestErrorTypes.network, err)}
      />
      <DirectRequest
        requestArgs={resolvedAlertsArgs}
        afterProcess={handleResolvedAlerts}
        handleError={(err) => handleResolvedAlertsError(Constants.requestErrorTypes.server, err)}
        handleCatchError={(err) => handleResolvedAlertsError(Constants.requestErrorTypes.network, err)}
      />
    </>
  ): null
}

const ActiveAlertsRequest = () => {
  const dispatch = useDispatch();

  const isRestricted = useSelector((state) => state.role.isRestricted);
  const restrictedWhitelist = useSelector((state) => state.role.restrictedWhitelist);

  const isCaptured = useSelector((state) => state.alerts.isCaptured);
  const postSetupComplete = useSelector((state) => state.role.postSetupComplete);

  const [alertsArgs, setAlertsArgs] = useState({
    url: Constants.SERVER_GET_RELEASED_ALERTS_URL
  });

  const handleAlerts = (res) => {
    if (res && res.alerts && res.alerts.length > 0) {
      const alerts = isRestricted ? res.alerts.filter(alert => restrictedWhitelist.includes(alert.developerAppUuid)) : res.alerts;
      const cleanedAlerts = sortAlerts(cleanAlerts(alerts));
      dispatch(setActiveAlerts({alerts: cleanedAlerts}));
    } else {
      dispatch(setActiveAlerts({alerts: []}));
    }
  };

  const handleAlertsError = (errType, err) => {
    console.log("AlertsRequest alerts error", err);
    const errorMessage = `There was a ${errType} error while fetching your notification feed. Please refresh the page.`;
    dispatch(setAlertsError(errorMessage));
    dispatch(setActiveAlerts({ alerts: [] }))
  }

  return postSetupComplete && !isCaptured ? (
    <DirectRequest
      requestArgs={alertsArgs}
      afterProcess={handleAlerts}
      handleError={(err) => handleAlertsError(Constants.requestErrorTypes.server, err)}
      handleCatchError={(err) => handleAlertsError(Constants.requestErrorTypes.network, err)}
    />
  ) : null
}

export const AlertsRequest = () => {
  const isReady = useSelector((state) => state.alerts.isReady);

  return (
    <>
      {!isReady && <InitialAlertsRequest />}
      {isReady && <ActiveAlertsRequest />}
    </>
  )
}

export default AlertsRequest