import { commandsSelectors, removeCommand } from "../../store/commandsSlice";
import { resetPreviewApp, setPreviewError } from "../../store/previewAppSlice";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AlertTitle from "@mui/material/AlertTitle";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import { setScript } from "../../store/appBuilderSlice";

const PreviewErrorAlertContent = ({ message }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <AlertTitle><strong>Preview error</strong> - close this alert to try again</AlertTitle>
      <Tooltip title={`Click to ${!expanded ? "expand" : "minimize"}`} disableHoverListener={message.length <= 200} followCursor>
        <Typography>Error message - <span onClick={() => setExpanded((expanded) => !expanded)}>{!expanded && message.length > 200 ? message.slice(0, 200) + "..." : message}</span></Typography>
      </Tooltip>
    </>
  )
};

const AppBuilderSSEErrorManager = () => {
  // watches preview command for error status and sets previewError if true
  const dispatch = useDispatch();
  const previewAppCommand = useSelector(state => state.previewApp.commandUuid);
  const previewCommand = useSelector((selectorState) =>
    commandsSelectors.selectById(selectorState, previewAppCommand)
  );

  // FUNCTION -> watches previewCommand for error status and sets previewError if true
  useEffect(() => {
    if (previewCommand) console.log("AppBuilder previewCommand ->", previewCommand);
    if (!previewCommand || previewCommand.commandState !== "Error") {
      // do nothing
    } else {
      // set preview error
      dispatch(setPreviewError({ error: { isErrored: true, message: previewCommand?.response || "Malformed server response" } }));
    }
  }, [previewCommand]);
  return null;
}

const AppBuilderPreviewErrorAlert = ({ resetPreview }) => {
  const previewError = useSelector(state => state.previewApp.error);
  const previewAppCommand = useSelector(state => state.previewApp.commandUuid);
  return !previewError.isErrored ? null : (
    <Grid
      item
      xs={12}
      sx={{
        position: "fixed",
        top: "0",
        left: "14rem",
        right: "1rem",
        zIndex: "99",
      }}
    >
      <Alert
        severity="error"
        action={<Button color="inherit" size="small" onClick={() => resetPreview(previewAppCommand)}>X</Button>}
      >
        <PreviewErrorAlertContent message={previewError.message} />
      </Alert>
    </Grid>
  )
}

export const AppBuilderErrorAlert = () => {
  const dispatch = useDispatch();

  const resetPreview = (previewAppCommand) => {
    dispatch(resetPreviewApp());
    dispatch(removeCommand(previewAppCommand));
    dispatch(setScript({ script: "" }));
  }

  return (
    <>
      <AppBuilderSSEErrorManager />
      <AppBuilderPreviewErrorAlert
        resetPreview={resetPreview}
      />
    </>
  )
}

export default AppBuilderErrorAlert;