import DirectRequest from "./DirectRequest";
import * as Constants from "../../Constants";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSandboxDestinations } from "../../store/appBuilderSlice";

const generateDestinationDict = (destinations) => {
  const output = {};
  destinations.forEach((destination) => {
    output[destination.name] = { isCaptured: false, destinations: [] };
  });
  output['generic'] = { isCaptured: false, destinations: [] };
  // console.log("generateDestinationDict -> output", output)
  return output;
}

const SandboxDestinationRequest = ({ integration, setDestinationsDict }) => {
  const [destinationArgs, setDestinationArgs] = useState({
    url: Constants.SERVER_DEVAPP_POST_GET_SANDBOX_DESTINATION_URL,
    method: "POST",
    body: JSON.stringify({
      source: integration,
    }),
  });

  const handleDestinations = (res) => {
    if (res && res.sandboxChoices) {
      setDestinationsDict((prevDict) => ({ ...prevDict, [integration]: { isCaptured: true, destinations: res.sandboxChoices }}));
    }
  }

  const handleDestinationsError = (err) => {
    console.warn(`error getting sandbox destinations for ${integration}`, err);
    setDestinationsDict((prevDict) => ({ ...prevDict, [integration]: { isCaptured: true, destinations: [] }}));
  }

  return (
    <DirectRequest
      requestArgs={destinationArgs}
      afterProcess={handleDestinations}
      handleError={handleDestinationsError}
      handleCatchError={handleDestinationsError}
    />
  )
}

export const PreviewAppSandboxDestinationsRequest = () => {
  const dispatch = useDispatch()
  const isSysAdmin = useSelector((state) => state.role.isSysAdmin);
  const sandboxDestinationsCaptured = useSelector((state) => state.appBuilder.sandboxDestinations);
  const [destinationsDict, setDestinationsDict] = useState(null);

  useEffect(() => {
    setDestinationsDict(generateDestinationDict(Constants.supported_integrations));
  }, [])

  useEffect(() => {
    // console.log("PreviewAppSandboxDestinationsRequest - destinationsDict", destinationsDict);
    if (destinationsDict) {
      let allCaptured = true;
      Object.keys(destinationsDict).forEach((integration) => {
        if (!destinationsDict[integration].isCaptured) allCaptured = false;
      });
      if (allCaptured) {
        // console.log("allCaptured -> destinationsDict:", destinationsDict);
        const allDestinations = {};
        Object.keys(destinationsDict).forEach((integration) => {
          allDestinations[integration] = destinationsDict[integration].destinations;
        });
        console.log("allDestinations processed, setting to appBuilderSlice:", allDestinations);
        dispatch(setSandboxDestinations(allDestinations));
      }
    }
  }, [destinationsDict]);

  // useEffect(() => {
  //   console.log("PreviewAppSandboxDestinationsRequest - sandboxDestinationsCaptured", sandboxDestinationsCaptured);
  // }, [sandboxDestinationsCaptured]);

  return (!isSysAdmin || sandboxDestinationsCaptured || !destinationsDict) ? null : (
    <>
      {Object.keys(destinationsDict).map((integration, index) => (
        <SandboxDestinationRequest integration={integration} setDestinationsDict={setDestinationsDict} key={index} />
      ))}
    </>
  )
}

export default PreviewAppSandboxDestinationsRequest;