import { createSlice } from '@reduxjs/toolkit';

export const summaryMetricsSlice = createSlice({
  name: "summaryMetrics",
  initialState: {
    summary: {},
    isCaptured: false,
  },
  reducers: {
    setSummaryMetrics: (state, action) => {
      state.summary = action.payload;
      state.isCaptured = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSummaryMetrics } = summaryMetricsSlice.actions;

export default summaryMetricsSlice.reducer;