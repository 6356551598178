import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const retrieving = "Retrieving details";
const retrievalError = "There was an error retrieving details, you're being redirected";

export const AppViewLoader = ({error, errorMessage}) => {
  return (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: "30vh" }}>
        <Grid item xs={12} style={{textAlign: "center", alignItems: "center"}}>
          <Typography sx={{marginBottom: "1rem"}}>{!error ? retrieving : errorMessage ? errorMessage : retrievalError}<span className="bouncing-dots"><span
            className="dot" style={{ marginLeft: "2px"}}>.</span><span className="dot" style={{ marginLeft: "2px"}}>.</span><span className="dot" style={{ marginLeft: "2px"}}>.</span></span></Typography>
          <div className="lds-grid-small">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export const LoadingBar = ({cancelAction, cancelText, cancelIcon}) => {
  return (
    <>
      <Grid
        container
        spacing={4}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <Grid item>
          <Button
            onClick={cancelAction}
            size="small"
            variant="contained"
            startIcon={cancelIcon}
          >
            {cancelText}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};