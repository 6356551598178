import React from 'react';

const LogoLoader = () => {
  return (
    <div id="logo_loader" style={{
      width: '100%',
      height: '200px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden'
    }}>
      <div style={{ 
        height: '200px',
        padding: '0 300px',
        position: 'relative',
        width: '100%'
      }}>
        <svg
          id="Layer_2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 132.27 178.42"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid meet"
          style={{ 
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <defs>
            <style>{`
              .cls-1 { fill: #0012b8; }
              .cls-2 { fill: #146cf1; }
              .cls-3 { fill: #0e24ee; }
              .cls-4 { fill: #59ceff; }
              .bg { fill: #ffffff; }
              .sibling { fill: #ffffff; }

              @keyframes rotateFadeRight {
                0% {
                  transform: translateX(0) rotate(0deg);
                  opacity: 1;
                }
                20% {
                  transform: translateX(300px) rotate(90deg);
                  opacity: 0;
                }
                50% {
                  transform: translateX(-300px) rotate(-90deg);
                  opacity: 0;
                }
                70% {
                  transform: translateX(0) rotate(0deg);
                  opacity: 1;
                }
                100% {
                  transform: translateX(0) rotate(0deg);
                  opacity: 1;
                }
              }

              .element-1 { /* Right pill */
                animation: rotateFadeRight 8s ease-in-out infinite;
                transform-origin: center;
                transform-box: fill-box;
              }

              .element-2 { /* Right circle group */
                animation: rotateFadeRight 8s ease-in-out infinite;
                animation-delay: 1s;
                transform-origin: center;
                transform-box: fill-box;
              }

              .element-3 { /* Middle pill */
                animation: rotateFadeRight 8s ease-in-out infinite;
                animation-delay: 2s;
                transform-origin: center;
                transform-box: fill-box;
              }

              .element-4 { /* Left circle group */
                animation: rotateFadeRight 8s ease-in-out infinite;
                animation-delay: 3s;
                transform-origin: center;
                transform-box: fill-box;
              }

              .element-5 { /* Left pill */
                animation: rotateFadeRight 8s ease-in-out infinite;
                animation-delay: 4s;
                transform-origin: center;
                transform-box: fill-box;
              }
            `}</style>
          </defs>
          <rect className="bg" width="132.27" height="178.42" />
          <g id="Layer_1-2">
            {/* Pills Layer */}
            <g>
              {/* Left pill */}
              <path
                className="cls-1 element-5"
                d="M0,16.25v123.11c0,8.96,7.27,16.23,16.23,16.23s16.23-7.27,16.23-16.23V16.25C32.46,7.29,25.19,0.02,16.23,0.02S0,7.29,0,16.25z"
              />

              {/* Middle pill */}
              <path
                className="cls-4 element-3"
                d="M49.9,39.08v123.11c0,8.96,7.27,16.23,16.23,16.23s16.24-7.27,16.24-16.23V39.08c0-8.96-7.27-16.23-16.24-16.23S49.9,30.12,49.9,39.08z"
              />

              {/* Right pill */}
              <path
                className="cls-2 element-1"
                d="M99.81,16.25v123.11c0,8.96,7.27,16.23,16.23,16.23s16.23-7.27,16.23-16.23V16.25c0-8.96-7.27-16.23-16.23-16.23S99.81,7.29,99.81,16.25z"
              />
            </g>

            {/* Circles Layer - Always on top */}
            <g>
              {/* Right circle group */}
              <g className="element-2">
                <circle className="sibling" cx="90.82" cy="59.05" r="18.74" />
                <circle className="cls-3" cx="90.82" cy="59.05" r="11.24" />
              </g>

              {/* Left circle group */}
              <g className="element-4">
                <circle className="sibling" cx="40.56" cy="118.9" r="18.74" />
                <circle className="cls-3" cx="40.56" cy="118.9" r="11.24" />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default LogoLoader;

// import React from 'react';

// const LogoLoader = () => {
//   return (
//     <svg
//       id="Layer_2"
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 132.27 178.42"
//       // width="132.27"
//       // height="178.42"
//       preserveAspectRatio="xMidYMid meet"
//       width="100%"
//       height="100%"
//     >
//       <defs>
//         <style>{`
//           .cls-1 { fill: #0012b8; }
//           .cls-2 { fill: #146cf1; }
//           .cls-3 { fill: #0e24ee; }
//           .cls-4 { fill: #59ceff; }
//           .bg { fill: #ffffff; }
//           .sibling { fill: #ffffff; }

//           @keyframes moveLeft {
//             0%, 8.33% {
//               transform: translateY(0);
//             }
//             25% {
//               transform: translateY(-59.85px);
//             }
//             25%, 66.67% {
//               transform: translateY(-59.85px);
//             }
//             83.33% {
//               transform: translateY(0);
//             }
//             83.33%, 100% {
//               transform: translateY(0);
//             }
//           }

//           @keyframes moveRight {
//             0%, 25% {
//               transform: translateY(0);
//             }
//             41.67% {
//               transform: translateY(59.85px);
//             }
//             41.67%, 83.33% {
//               transform: translateY(59.85px);
//             }
//             100% {
//               transform: translateY(0);
//             }
//           }

//           .animated-circle-left {
//             animation: moveLeft 6s infinite;
//           }

//           .animated-circle-right {
//             animation: moveRight 6s infinite;
//           }
//         `}</style>
//       </defs>
//       <rect className="bg" width="132.27" height="178.42" />
//       <g id="Layer_1-2">
//         {/* Left bar */}
//         <path
//           className="cls-1"
//           d="M0,16.25v123.11c0,8.96,7.27,16.23,16.23,16.23s16.23-7.27,16.23-16.23V16.25C32.46,7.29,25.19,0.02,16.23,0.02S0,7.29,0,16.25z"
//         />

//         {/* Middle bar */}
//         <path
//           className="cls-4"
//           d="M49.9,39.08v123.11c0,8.96,7.27,16.23,16.23,16.23s16.24-7.27,16.24-16.23V39.08c0-8.96-7.27-16.23-16.24-16.23S49.9,30.12,49.9,39.08z"
//         />

//         {/* Right bar */}
//         <path
//           className="cls-2"
//           d="M99.81,16.25v123.11c0,8.96,7.27,16.23,16.23,16.23s16.23-7.27,16.23-16.23V16.25c0-8.96-7.27-16.23-16.23-16.23S99.81,7.29,99.81,16.25z"
//         />

//         {/* Groups for circles and their siblings */}
//         <g className="animated-circle-right">
//           {/* Right circle sibling (larger, white) */}
//           <circle className="sibling" cx="90.82" cy="59.05" r="18.74" />
//           {/* Right circle (original) */}
//           <circle className="cls-3" cx="90.82" cy="59.05" r="11.24" />
//         </g>

//         <g className="animated-circle-left">
//           {/* Left circle sibling (larger, white) */}
//           <circle className="sibling" cx="40.56" cy="118.9" r="18.74" />
//           {/* Left circle (original) */}
//           <circle className="cls-3" cx="40.56" cy="118.9" r="11.24" />
//         </g>
//       </g>
//     </svg>
//   );
// };

// export default LogoLoader;
