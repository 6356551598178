import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import React from "react";
import {useSelector} from "react-redux";

const getLabel = (newValue, paramValue) => {
  if (newValue === null || newValue === paramValue) {
    return (
      <span>Value</span>
    )
  } else {
    return (
      <span>Value - <i>edited</i></span>
    )
  }
}

export const DropdownInput = ({ index, handleChange }) => {
  const theme = useTheme();
  const newValue = useSelector((state) => state.alertConfigsSysAdmin.alertConfigs[index].newValue);
  const paramValue = useSelector((state) => state.alertConfigsSysAdmin.alertConfigs[index].paramValue);
  const dropdownValueList = useSelector((state) => state.alertConfigsSysAdmin.alertConfigs[index].dropdownValueList);
  const handleDropdownSelection = (event) => {
    const selectedValue = event.target.value;
    handleChange(selectedValue, paramValue);
  }
  return (
    <FormControl fullWidth>
      <InputLabel id={"alert-config-option-label_" + index}>{getLabel(newValue, paramValue)}</InputLabel>
      <Select
        labelId={"alert-config-option-label_" + index}
        id={"alert-config-option_" + index}
        fullWidth
        label={getLabel(newValue, paramValue)}
        value={newValue !== null ? newValue : paramValue}
        onChange={handleDropdownSelection}
        sx={{ borderColor: theme.palette.primary.main, width: "100%" }}
      >
        {dropdownValueList.map((value, index) => {
          return (
            <MenuItem key={`${index}_${value}_option`} value={value}>
              {value}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default DropdownInput;