import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ReplayIcon from "@mui/icons-material/Replay";
import RemoveIcon from '@mui/icons-material/Remove';

export const TagInputs = ({ allTags, tags, setTags, selectedAppUuid, allApps }) => {
  const theme = useTheme();
  const handleTagChange = (value, index) => {
    const newTags = [...tags]
    newTags[index] = value
    setTags(newTags)
  }

  const handleTagSelect = (event, index) => {
    const selectedValue = event.target.value;
    console.log("Selected Value: ", selectedValue, " at index:", index);
    handleTagChange(selectedValue, index)
    event.target.value = '';//reset dropdown to empty selection
  }

  const handleTagReset = (index) => {
    const newTags = [...tags]
    newTags[index] = allApps.find(app => app.uuid === selectedAppUuid).tags[index] ? allApps.find(app => app.uuid === selectedAppUuid).tags[index] : ""
    setTags(newTags)
  }

  const removeTag = (index) => {
    const newTags = [...tags]
    newTags.splice(index, 1)
    setTags(newTags)
  }

  const addTag = () => {
    const newTags = [...tags]
    if (selectedAppUuid && allApps.find(app => app.uuid === selectedAppUuid).tags.length > tags.length) {
      newTags.push(allApps.find(app => app.uuid === selectedAppUuid).tags[tags.length])
    } else {
      newTags.push("")
    }
    setTags(newTags)
  }

  return selectedAppUuid ? (
    <Grid item xs={12} container spacing={2}>
      {tags.map((tag, index) => (
        <Grid item xs={12} key={index} container spacing={1} alignItems={"center"}>
          <Grid item xs={0.75}>
            Tag {index + 1}
          </Grid>
          <Grid item xs={8.25}>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                sx={{ backgroundColor: theme.palette.secondary.main, width: "100%" }}
                value={tag}
                variant="outlined"
                placeholder={"Enter a tag"}
                data-tut="reactour__description"
                onChange={(event) => handleTagChange(event.target.value, index)}
                InputProps={allApps.find(app => app.uuid === selectedAppUuid).tags[index] !== tags[index] ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <em>(edited{!allApps.find(app => app.uuid === selectedAppUuid).tags[index] ? " - new tag" : ""})</em>
                    </InputAdornment>
                  ),
                } : {}}
                error={tag === ""}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl sx={{ width: "100%" }}>
              <Select
                value="" // Always set to an empty string
                onChange={(event) => handleTagSelect(event, index)}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === '') {
                    return <span style={{ color: "grey" }}><em>Select a tag</em></span>;
                  }
                  return selected;
                }}
              >
                {allTags.map((tag, index) => {
                  return (
                    <MenuItem value={tag} key={index}>
                      {tag}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={0.5}>
            <IconButton
              onClick={() => handleTagReset(index)}
            >
              <ReplayIcon />
            </IconButton>
          </Grid>
          <Grid item xs={0.5}>
            <IconButton
              onClick={() => removeTag(index)}
            >
              <RemoveIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      {selectedAppUuid && (
        <Grid item xs={12} container spacing={1} alignItems={"center"} justifyContent={"flex-end"}>
          <Grid item>
            Add tag
          </Grid>
          <Grid item xs={0.5}>
            <IconButton
              onClick={() => addTag()}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  ) : null;
};

export default TagInputs;