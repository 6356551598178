import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import mixpanel from 'mixpanel-browser';
import {removeMixpanelEvent} from "../../store/mixpanelTrackSlice";
import { user_blacklist } from "../../Constants";

// NOTE: 'Signed In" is tracked independently in MixpanelSignIn.js - all other events should be dispatched to mixpanelTrackSlice for tracking here
const MixpanelTracker = ({isInternal}) => {
  const dispatch = useDispatch();
  const mixpanelEvents = useSelector((state) => state.mixpanelTrack.events);
  useEffect(() => {
    if (mixpanelEvents.length > 0) {
      if (!isInternal) {
        const event = JSON.parse(JSON.stringify(mixpanelEvents[0].event));
        const additionalProperties = JSON.parse(JSON.stringify(mixpanelEvents[0].additionalProperties));
        if (additionalProperties) {
          mixpanel.track(event, additionalProperties);
        } else {
          mixpanel.track(event);
        }
      }
      dispatch(removeMixpanelEvent());
    }
  }, [mixpanelEvents])
  return null;
}
export default function MixpanelTrack() {
  const mixpanelInitialized = useSelector((state) => state.mixpanel.initialized);
  const mixpanelIdentified = useSelector((state) => state.mixpanel.identified);
  const mixpanelSignedIn = useSelector((state) => state.mixpanelSignIn.hasSignedIn);
  const user = useSelector((state) => state.role.email);
  const isInternal = useSelector((state) => state.role.isInternal);
  return (
    <>
      {mixpanelInitialized && mixpanelIdentified && mixpanelSignedIn && user && (
        <MixpanelTracker isInternal={isInternal} />
      )}
    </>
  )
}