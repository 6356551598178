import { createSlice } from "@reduxjs/toolkit";

export const developerOptInSlice = createSlice({
  name: "developerOptIn",
  initialState: {
    hasOptedIn: false,
  },
  reducers: {
    setDeveloperOptIn: (state, action) => {
      state.hasOptedIn = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDeveloperOptIn } = developerOptInSlice.actions;

export default developerOptInSlice.reducer;