import React from "react";
import { useDispatch } from "react-redux";
import { addCommand } from "../../store/commandsSlice";
import GenericRequest from "./GenericRequest";

function AsyncRequest(props) {
	const dispatch = useDispatch();

	function processAsyncResponse(result) {
		// console.log(props.afterProcess);
		const commandUuid = result.data.commandUuid;
		if (commandUuid) {
			dispatch(
				addCommand(
					commandUuid,
					props.requestArgs.sourceName,
					props.requestArgs.commandType
				)
			);
			if (props.afterProcess) {
				props.afterProcess(result.data);
			}
		}
	}

	function processAsyncResponseException(result) {
		if (props.handleError) {
			props.handleError(result);
		}
	}

	return (
		<GenericRequest
			requestArgs={props.requestArgs}
			processResponse={processAsyncResponse}
			processException={processAsyncResponseException}
			{...props}
		/>
	);
}

export default AsyncRequest;
