import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TopBar from "../TopBar/TopBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DirectRequest from "../../API/requests/DirectRequest";
import { useTheme } from "@mui/material/styles";
import iso8601Timestamp from "../../helpers/iso8601Timestamp";
import * as Constants from "../../Constants";

const steps = [
  {
    content:
      "This page serves as a visual fallback in case of a user interface error - Please refresh the page to continue",
  },
];

export function ErrorFallback({ error, resetErrorBoundary }) {
  const theme = useTheme();
  const [errorLogArgs, setErrorLogArgs] = useState();
	const role = useSelector((state) => state.role);
  const isInternal = useSelector((state) => state.role.isInternal);
  const frontendVersion = useSelector(state => state.appState.frontendVersion);
  const backendVersion = useSelector(state => state.appState.backendVersion);
	let navigate = useNavigate();
	const navigateAndReload = () => {
		navigate("/");
		window.location.reload();
	};
  
  useEffect(() => {
    const error_stack = error.stack.replace(/(?:\r\n|\r|\n)/g, "<br/>");

    const requestArgs = {
      url: Constants.SERVER_SEND_EMAIL,
      method: "POST",
      body: JSON.stringify({
        "body": `Affected user: ${role.name}<br/>URL: ${window.location.href}<br/>Error name: ${error.name}<br/>Error message: ${error.message}<br/>Error cause: ${error.cause}<br/><br/>${error_stack}<br/><br/>Version: ${frontendVersion || ''} (${backendVersion || '0.0.000'})`,
        "subject": `Frontend ErrorBoundary triggered for ${role.name} (${window.location.origin} - ${iso8601Timestamp()})`,
        "from": "noreply@wiselayer.com",
        "toEmail": Constants.notificationList.join(","),
        "sendHTML": true
      })
    }
    if (process.env.NODE_ENV !== "development" && !isInternal) {
      setErrorLogArgs(requestArgs);
    } else {
      console.log(error.stack.toString());
    }
  }, [error]);

  const afterProcess = () => {};

  return (
    <Fragment>
      <DirectRequest requestArgs={errorLogArgs} afterProcess={afterProcess} />
      <TopBar steps={steps} />
      <Box
        sx={{
          mt: "3rem",
          ml: "4rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h3">An error occurred...</Typography>
        {error.message && (
          <>
            <Typography variant="h6" sx={{ mt: "4rem" }}>
              Error message:
            </Typography>
            <Typography sx={{ mt: "1rem", fontSize: "1rem", maxWidth: "60vw" }}>
              {error.message}
            </Typography>
          </>
        )}
        <Typography variant="h6" sx={{ mt: "4rem" }}>
          Please <strong>refresh</strong> the page or click{" "}
          <span
            style={{ cursor: "pointer", color: theme.palette.primary.main }}
            onClick={navigateAndReload}
          >
            here
          </span>{" "}
          to continue.
        </Typography>
      </Box>
    </Fragment>
  );
}
