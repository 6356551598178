import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

const customerSubsAdapter = createEntityAdapter({
  selectId: (customerSub) => customerSub.uuid,
});

export const customerSubsSlice = createSlice({
  name: "customerSubs",
  initialState: customerSubsAdapter.getInitialState(),
  reducers: {
    addCustomerSub: {
      reducer: customerSubsAdapter.addOne,
      prepare: (customerSubscriptionUuid) => {
        console.log("adding customerSub ", customerSubscriptionUuid)
        return {
          payload: {
            uuid: customerSubscriptionUuid,
            isSynced: false,
            isReady: false,
            isError: false,
            isListening: false,
          },
        };
      },
    },
    changeToSynced: {
      reducer: customerSubsAdapter.updateOne,
      prepare: (customerSubscriptionUuid) => {
        console.log("changing to synced for customerSub ", customerSubscriptionUuid)
        return {
          payload: {
            id: customerSubscriptionUuid,
            changes: {
              isSynced: true
            },
          },
        };
      },
    },
    changeToReady: {
      reducer: customerSubsAdapter.updateOne,
      prepare: (customerSubscriptionUuid) => {
        return {
          payload: {
            id: customerSubscriptionUuid,
            changes: {
              isReady: true
            },
          },
        };
      },
    },
    changeToError: {
      reducer: customerSubsAdapter.updateOne,
      prepare: (customerSubscriptionUuid) => {
        return {
          payload: {
            id: customerSubscriptionUuid,
            changes: {
              isError: true
            },
          },
        };
      },
    },
    changeToListening: {
      reducer: customerSubsAdapter.updateOne,
      prepare: (customerSubscriptionUuid) => {
        console.log("changing to listening for customerSub ", customerSubscriptionUuid)
        return {
          payload: {
            id: customerSubscriptionUuid,
            changes: {
              isListening: true
            },
          },
        };
      },
    },
    removeCustomerSub: {
      reducer: customerSubsAdapter.removeOne,
      prepare: (customerSubscriptionUuid) => {
        console.log("removing customerSub ", customerSubscriptionUuid);
        return {
          payload: customerSubscriptionUuid,
        };
      },
    },
  }
});

export const customerSubsSelectors = customerSubsAdapter.getSelectors((state) => {
  return state.customerSubs;
});

export const {
  addCustomerSub,
  changeToSynced,
  changeToReady,
  changeToError,
  changeToListening,
  removeCustomerSub
} = customerSubsSlice.actions;

export default customerSubsSlice.reducer;