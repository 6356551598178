import { createSlice } from '@reduxjs/toolkit';

export const alertsNotificationSlice = createSlice({
  name: "alertsNotification",
  initialState: {
    args: null,
    textSearches: [],
  },
  reducers: {
    setAlertNotificationArgs: (state, action) => {
      // console.log("setAlertNotificationArgs", action.payload)
      state.args = action.payload
    },
    resetAlertNotificationArgs: (state) => {
      // console.log("resetAlertNotificationArgs")
      state.args = null
    },
    setTextSearches: (state, action) => {
      // console.log("setTextSearches", action.payload)
      state.textSearches = [...state.textSearches, action.payload]
    },
    resetTextSearches: (state) => {
      // console.log("resetTextSearches")
      state.textSearches = []
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAlertNotificationArgs,
  resetAlertNotificationArgs,
  setTextSearches,
  resetTextSearches,
} = alertsNotificationSlice.actions;

export default alertsNotificationSlice.reducer;