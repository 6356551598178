import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import AddchartIcon from "@mui/icons-material/Addchart";
import new_logo from "../../assets/Wiselayer.png";
import Typography from "@mui/material/Typography";
import * as Constants from "../../Constants";
import Grid from "@mui/material/Grid";
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import RestoreIcon from '@mui/icons-material/Restore';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import TuneIcon from '@mui/icons-material/Tune';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from "@mui/material/IconButton";
import LogoutIcon from '@mui/icons-material/Logout';
import Tooltip from "@mui/material/Tooltip";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import InboxIcon from '@mui/icons-material/Inbox';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import EditIcon from '@mui/icons-material/Edit';
import FolderIcon from '@mui/icons-material/Folder';
import ReviewsIcon from '@mui/icons-material/Reviews';
import TocIcon from '@mui/icons-material/Toc';

import { NotificationFeedTab, LinkConfigAppTabs, SettingsTab, SysAdminTab } from "./HeaderTabs";

const drawerWidth = "14rem"; /*231*/

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  //justifyContent: 'flex-',
  padding: theme.spacing(0, 2),
  //backgroundColor: theme.palette.primary.light,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  borderRight: "1px solid " + theme.palette.border.light,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const StyledList = styled(List, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  padding: "0em",
  color: theme.typography.color,
  "&.selected": {
    backgroundColor: theme.palette.secondary.main,
    borderLeft: "4px solid " + theme.palette.border.main,
  },
  "& svg": {
    fill: theme.typography.color,
  },
}));

const BottomBar = styled("div")(({ theme }) => ({
  position: "fixed",
  bottom: "0em",
  padding: "1em",
  "& p": {
    fontSize: "16px",
  },
}));

const locations = [
  { label: "Alerts", icon: <NotificationsNoneIcon />, path: "/", subTabs: true,
    subTabsContent: [
      // note - labels here are not used - they are generated by NotificationFeedTabs based on selectTab
      { label: <Typography>Active: new + current ({<ThumbUpIcon/>})</Typography>, icon: <InboxIcon />, path: "/", selectTab: 'Main' },
      { label: "Unread only", icon: <MarkunreadMailboxIcon />, path: "/", selectTab: "Unread" },
      { label: "Previous: resolved in system", icon: <RestoreIcon />, path: "/", selectTab: "Resolved" },
      { label: <Typography>Removed ({<ThumbDownIcon/>})</Typography>, icon: <RemoveCircleOutlineIcon />, path: "/", selectTab: 'Irrelevant' },
    ],
    selectTab: 'Main'
  },
  // { label: "Full revenue flow", icon: <TocIcon />, path: "/full_revenue_flow", subTabs: false },
  { path: "LinkConfigAppTags" },
  { label: "Settings", icon: <SettingsIcon />, path: "/settings", subTabs: true,
    subTabsContent: [
      { label: "Account", icon: <AccountCircleIcon />, path: "/account" },
      { label: "Notifications (on/off)", icon: <TuneIcon />, path: "/notifications" },
      { label: "Integrations", icon: <DynamicFeedIcon />, path: "/integrations", admin: true },
      { label: "AI analyst messages", icon: <ReviewsIcon />, path: "/messages", admin: true }
    ],
    selectTab: 'Account'
  },
];

const sysAdminLocations = [
  { label: "SysAdmin", icon: <SupervisorAccountIcon />, path: "/sysadmin", subTabs: true,
    subTabsContent: [
      { label: "Company controls", icon: <SupervisedUserCircleIcon />, path: "/sysadmin_controls" },
      { label: "Dashboard builder", icon: <AddchartIcon />, path: "/build_dashboard" },
      { label: "Dashboard editor", icon: <EditIcon/>, path: "/edit_dashboard" },
    ],
    selectTab: "Company controls"
  },
];

export default function Header(props) {
  const [open, setOpen] = React.useState(true);
  const current_location = useLocation();
  const dispatch = useDispatch();
  const isSysAdmin = useSelector((state) => state.role.isSysAdmin);
  const isRestricted = useSelector((state) => state.role.isRestricted);
  const userName = useSelector((state) => state.role.name ? state.role.name : state.role.email);
  const companyDomain = useSelector((state) => state.role.companyDomain);
  const closeBanner = useSelector(state => state.role.closeBanner)

  const logout = (event) => {
    const logoutUrl = Constants.SERVER_LOGOUT_URL;
    dispatch({ type: "RESET" });
    localStorage.setItem("isLoggingOut", "true");
    if (localStorage.getItem("previewPercent")) {
      console.log("Header.js logging out -> Removing previewPercent and previewPercentTimestamp");
      localStorage.removeItem("previewPercent");
      localStorage.removeItem("previewPercentTimestamp");
    }
    window.open(logoutUrl, "_self");
  };

  return props.hide ? null : (
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        <Drawer
          variant="permanent"
          open={open}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <DrawerHeader>
            <RouterLink to="/">
              <img
                src={new_logo}
                alt="Wiselayer"
                style={closeBanner ? {
                  height: 70,
                  margin: "2em 0em",
                  display: !open ? "none" : "block",
                } : {
                  height: 70,
                  margin: "4em 0em",
                  display: !open ? "none" : "block",
                }}
              />
            </RouterLink>
          </DrawerHeader>
          {locations.map((location) => {
                return (
                <StyledList
                  key={location.label}
                  className={
                    location.path === current_location.pathname
                      ? "selected"
                      : ""
                  }
                >
                  {location.path === "/" && (
                    <NotificationFeedTab
                      to={location.path}
                      primary={location.label}
                      icon={location.icon}
                      subTabs={location.subTabs}
                      subTabsContent={location.subTabsContent}
                      selectTab={location.selectTab}
                    />
                  )}
                  {location.path === "LinkConfigAppTags" && (
                    <LinkConfigAppTabs/>
                  )}
                  {location.path === "/settings" && !isRestricted && (
                    <SettingsTab
                      to={location.path}
                      primary={location.label}
                      icon={location.icon}
                      subTabs={location.subTabs}
                      subTabsContent={location.subTabsContent}
                      selectTab={location.selectTab}
                    />
                  )}
                </StyledList>
              );
            })}
          {isSysAdmin && sysAdminLocations.map((location) => {
              return (
                <StyledList
                  key={location.label}
                  className={
                    location.path === current_location.pathname
                      ? "selected"
                      : ""
                  }
                >
                  <SysAdminTab
                    to={location.path}
                    primary={location.label}
                    icon={location.icon}
                    subTabs={location.subTabs}
                    subTabsContent={location.subTabsContent}
                    selectTab={location.selectTab}
                  />
                </StyledList>
              );
          })}
          <BottomBar>
            <Grid
              container
              spacing={2}
              alignItems={"center"}
              key={"login"}
              sx={{ marginTop: "0.5em" }}
            >
              <Grid item>
                <Tooltip title={"Logout"} enterDelay={200}>
                  <IconButton onClick={logout}>
                    <LogoutIcon/>
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                container
                sx={{ maxWidth: "10rem", minWidth: "10rem" }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{ maxWidth: "10rem", overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  <Typography variant="p">{userName}</Typography>
                </Grid>
                {companyDomain && (
                  <Grid
                    item
                    xs={12}
                    sx={{ maxWidth: "10rem", overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    <Typography variant="p">[{companyDomain}]</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </BottomBar>
        </Drawer>
      </Box>
    </React.Fragment>
  );
}
