import mixpanel from 'mixpanel-browser';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMixpanelIdentified } from "../../store/mixpanelSlice";
import { user_blacklist } from "../../Constants";

export default function MixpanelIdentify() {
  const dispatch = useDispatch();
  const mixpanelInitialized = useSelector((state) => state.mixpanel.initialized);
  const mixpanelIdentified = useSelector((state) => state.mixpanel.identified);
  const user = useSelector((state) => state.role.email);
  const isCaptured = useSelector((state) => state.role.isCaptured);
  const isInternal = useSelector((state) => state.role.isInternal);
  useEffect(() => {
    if (mixpanelInitialized && isCaptured && !mixpanelIdentified) {
      if (!isInternal/*user_blacklist[user]*/) {
        mixpanel.identify(user);
        mixpanel.people.set({
          "$email": user,
        })
        mixpanel.register({ "originUrl": window.location.origin });
      } else {
        // don't identify user
      }
      dispatch(setMixpanelIdentified(true))
    }
  }, [mixpanelInitialized, mixpanelIdentified, isCaptured, isInternal, user])
  return null;
}