import React from 'react';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsMore from "highcharts/highcharts-more";
import { useTheme } from '@mui/material/styles';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { SiteLanguage } from "../../../Constants"

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
NoDataToDisplay(Highcharts);

function FDBar(props) {
	const theme = useTheme();
	// const chartTheme = theme.palette.chartTheme;
	Highcharts.setOptions({
     	// colors: [chartTheme.chart1.color, chartTheme.chart2.color, chartTheme.chart3.color, chartTheme.chart4.color, chartTheme.chart5.color, chartTheme.chart6.color, chartTheme.chart7.color, chartTheme.chart8.color],
			xAxis: {
				labels: {
					style: {
						fontFamily: theme.typography.fontFamily,
						color: theme.typography.color,
						fontSize: "16px"
					}
				}
			},
			yAxis: {
				labels: {
					style: {
						fontFamily: theme.typography.fontFamily,
						color: theme.typography.color,
						fontSize: "16px"
					}
				}
			},
			legend: {
				itemStyle: {
					fontFamily: theme.typography.fontFamily,
					color: theme.typography.color,
					fontSize: "16px"
				}
			},
			plotOptions: {
				series: {
					dataLabels: {
						style: {
							fontFamily: theme.typography.fontFamily,
							fontSize: "16px"
						}
					}
				}
			},
			tooltip: {
				style: {
					fontFamily: theme.typography.fontFamily,
					color: theme.typography.color,
					fontSize: "14px"
				}
			},
			chart: {
				style: {
					fontFamily: theme.typography.fontFamily,
				}
			},
	});

	const formatter = (formatting) => {
		return (formatting && formatting.currency ? "$" : (formatting && formatting.prefix ? formatting.prefix : "")) + "{y:,." + (formatting && formatting.digits ? formatting.digits : (formatting.max ? formatting.max : 0)) + "f}" + (formatting && formatting.percent ? "%" : "") + (formatting && formatting.suffix ? formatting.suffix : "");
	}

	const tooltip_format = (formatting) => {
		return formatter(formatting).replace("{y:", "{point.y:");
	}

	const axis_format = (formatting) => {
		return formatter(formatting).replace("{y:", "{text:");
	}

	const label_formatter = (y, formatting) => {
    		let prefix = (formatting && formatting.currency ? "$" : (formatting && formatting.prefix ? formatting.prefix : ""));
    		let suffix = (formatting && formatting.percent ? "%" : "") + (formatting && formatting.suffix ? formatting.suffix : "");
    		let digits = (formatting && formatting.digits ? formatting.digits : (formatting.max ? formatting.max : 0));
    		let percent = (formatting && formatting.percent ? true : false);
    		let output;

    		if(percent) {
    			output = Highcharts.numberFormat(Math.abs(y) * 100, digits);
    		} else if (Math.abs(y) >= 1000000000) {
          		output = Highcharts.numberFormat(Math.abs(y) / 1000000000, digits) + 'B'
        	} else if (Math.abs(y) >= 1000000) {
          		output = Highcharts.numberFormat(Math.abs(y) / 1000000, digits) + 'M'
        	} else if (Math.abs(y) >= 1000) {
          		output = Highcharts.numberFormat(Math.abs(y) / 1000, digits) + 'K';
        	} else {
          		output = Highcharts.numberFormat(Math.abs(y), digits)
        	}

        	output = prefix + output + suffix;

        	if(y < 0) {
        		output = "(" + output + ")";
        	}

        	return output
	}

	let options = {
		lang: {
			noData: SiteLanguage.MissingData
		},
		chart: {
	        height: props.height !== undefined ? props.height : 400
	    },
			noData: {
				style: {
					fontWeight: theme.typography.p.fontWeight,
					fontSize: theme.typography.h6.fontSize,
					color: theme.typography.missingData.color,
				}
			},
	    title: {
	        text: null
	    },
		yAxis: {
	        min: props.min !== undefined ? props.min : null,
	        title: {
	            enabled: props.yAxisLabel !== undefined ? props.yAxisLabel : false,
	            text: props.yAxisLabel !== undefined ? props.yAxisLabel : false
	        },
	    	labels: {
	    		format: axis_format(props.formatting)
	    	},
	        gridLineWidth: 0.5
	    },
	    legend: {
	        enabled: props.stackLabels ? props.stackLabels : (props.legend ? props.legend : false)
	    },
	    credits: {
	    	enabled: false
	    },
	    tooltip: {
	    	enabled: props.tooltip !== undefined ? props.tooltip : true,
	    	pointFormat: "{series.name}: <b>" + tooltip_format(props.formatting) + "</b><br/>"
	    },
	    plotOptions: {
	    	series: {
	            dataLabels: {
	            	enabled: true,
	            	formatter: function() {
			        	let output = label_formatter(this.y, props.formatting);

			        	return output
			      	},
	            	style: {
	            		fontWeight: props.type === "waterfall" || (props.stackLabels && props.stackLabels === true) ? "bold" : "normal",
	            	}
	            },
	            dataSorting: {
			        enabled: props.sorting !== undefined ? props.sorting : false
			    }
	    	},
	    	bar: {
	            //stacking: 'normal',
	            dataLabels: {
	            	x: -5,
	            	align: "right",
	            	style: {
	            		stroke: "white",
	            	}
	            }
	    	},
	    	column: {
	    		pointPadding: 0.2,
            	borderWidth: 0,
            	stacking: props.stackLabels && props.stackLabels === true ? "normal" : undefined
	    	}
	    },
	    series: props.hasData ? props.series : [],
	};

	if(props.yAxis) {
		let existing = options.yAxis;
		options.yAxis = [];
		for(var i = 0; i < props.yAxis.length; i++) {
			let updated_json = {};

			if(props.yAxis[i].opposite === true) {
				// Automatically format the axes based on the formatting of the first series on that axes
				let series_format;
				let formatting;

				for(var j = 0; j < props.series.length; j++) {
					if(props.series[j].yAxis === 1) {
						series_format = props.series[j];
						formatting = {
							"max": series_format.max,
							"min": series_format.min,
							"percent": series_format.percent,
							"prefix": series_format.prefix,
							"suffix": series_format.suffix,
							"currency": series_format.currency
						}
						props.series[j].dataLabels = {
							formatter: function() {
								return label_formatter(this.y, formatting)
							}
						}
						props.series[j].tooltip = {
							pointFormat: "{series.name}: <b>" + tooltip_format(formatting) + "</b><br/>"
						} 
					}
				}
				let format_label = axis_format(formatting);
				let updated_json_labels = {
					"labels": {
						"format": format_label
					}
				}
				options.yAxis.push(Object.assign(updated_json, existing, props.yAxis[i], updated_json_labels));
			} else {
				options.yAxis.push(Object.assign(updated_json, existing, props.yAxis[i]));
			}
			
		}
	}

	if(props.categoryColumnLabels) {
		const fixedCategories = props.categoryColumnLabels.map(category => { return category });
		if(!props.xAxis) {
			options.xAxis = {};
		} else {
			options.xAxis = props.xAxis;
		}
		options.xAxis.categories = fixedCategories;
	} else if (props.categoryColumnName) {
		options.xAxis = props.xAxis
	}

	//console.log("FDBar options...", options);

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Typography variant={"h6"}>{props.title}</Typography>
			</Grid>
			<Grid item xs={12}>
				<HighchartsReact
				    highcharts={Highcharts}
				    options={options}
				  />
			</Grid>
	    </Grid>
	)
}

export default FDBar;