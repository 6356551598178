import React, { useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import { addSubscriptionUuid, removeSubscriptionUuid } from "../../store/initialSubscriptionsSlice";

const checkForInitialSubscription = (app, initialSubscriptions) => {
  return initialSubscriptions.includes(app.uuid);
}
export default function AppCardInitialSubscriptionButtonPicker({app}) {
  const dispatch = useDispatch();
  const initialSubscriptions = useSelector((state) => state.initialSubscriptions.subscriptionUuids);
  const handleSelection = () => {
    if (checkForInitialSubscription(app, initialSubscriptions)) {
      dispatch(removeSubscriptionUuid(app.uuid));
    } else {
      dispatch(addSubscriptionUuid(app.uuid));
    }
  }
  return (
    <Grid item xs={12}>
      <FormGroup>
        <FormControlLabel control={<Switch checked={checkForInitialSubscription(app, initialSubscriptions)} onChange={handleSelection} />} label={checkForInitialSubscription(app, initialSubscriptions) ? "Enabled" : "Disabled"} />
      </FormGroup>
    </Grid>
  )
}