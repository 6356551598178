import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import * as Constants from "../../Constants";
import {setPostSetupComplete} from "../../store/roleSlice";
import DirectRequest from "./DirectRequest";

export const PostSetupCompleteRequest = () => {
  const dispatch = useDispatch();
  const subscribedApps = useSelector((state) => state.appsSubscriptions.list);
  const postSetupComplete = useSelector((state) => state.role.postSetupComplete);
  const [postSetupCompleteArgs, setPostSetupCompleteArgs] = useState(null);
  // if !postSetupComplete will check all subscriptions for isSetupComplete and hit postSetupComplete if true
  useEffect(() => {
    if (!postSetupComplete && subscribedApps.length > 0 && subscribedApps.every(sub => sub.isSetupComplete)) {
      console.log("all subscriptions are setup complete, hitting post setup complete");
      setPostSetupCompleteArgs({ url: Constants.SERVER_GET_RUN_POST_SETUP_URL, method: "POST" })
    }
  }, [subscribedApps])

  const handlePostSetupComplete = (res) => {
    console.log("Post setup complete")
    dispatch(setPostSetupComplete());
  }

  return postSetupComplete ? null : (
    <DirectRequest
      requestArgs={postSetupCompleteArgs}
      afterProcess={handlePostSetupComplete}
      handleError={(err) => console.log("server error hitting post setup complete", err)}
      handleCatchError={(err) => console.log("client error hitting post setup complete", err)}
    />
  );
}

export default PostSetupCompleteRequest;