import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import * as Constants from "../../Constants";
import DirectRequest from "../../API/requests/DirectRequest";
import {setAlertNotifyUserEmail, setOptOutOfAlertSummaryEmail} from "../../store/roleSlice";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";

export const SummaryEmailOptIn = ({role, sysAdmin, refreshSelectedCompanyMetadata}) => {
  const dispatch = useDispatch()
  const [optOutArgs, setOptOutArgs] = useState(null)
  const handleChange = () => {
    const tempArgs = {
      url: sysAdmin
        ? Constants.SERVER_POST_SYSADMIN_OPT_OUT_ALERT_SUMMARY_EMAIL_URL + role.companyUuid
        : Constants.SERVER_POST_OPT_OUT_ALERT_SUMMARY_EMAIL_URL,
      method: "POST",
      body: JSON.stringify({ optOutOfAlertSummaryEmail: !role.optOutOfAlertSummaryEmail })
    }
    setOptOutArgs(tempArgs);
  }

  const handlePostOptOut = () => {
    if (sysAdmin) {
      refreshSelectedCompanyMetadata();
    } else {
      dispatch(setOptOutOfAlertSummaryEmail(!role.optOutOfAlertSummaryEmail));
    }
    setOptOutArgs(null);
  }

  return (
    <>
      <DirectRequest
        requestArgs={optOutArgs}
        afterProcess={handlePostOptOut}
        handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("error saving opt out of alert summary email")}
        handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("catch error saving opt out of alert summary email")}
      />
      <Grid item xs={12} container alignItems={"center"} spacing={1} sx={{ height: "65px"}}>
        <Grid item>
          Summary email status:
        </Grid>
        <Grid item>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch checked={!role.optOutOfAlertSummaryEmail} onChange={handleChange} />
              }
              label={!role.optOutOfAlertSummaryEmail ? "Enabled" : "Disabled"}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  )
}

export const SummaryEmailForm = ({role, sysAdmin, refreshSelectedCompanyMetadata}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [formState, setFormState] = useState({
    alertNotifyUserArgs: null,
    edit: false,
    input: ""
  })

  const handleInput = (e) => {
    setFormState(prevFormState => ({ ...prevFormState, input: e.target.value }))
  }

  const toggleEdit = () => {
    setFormState(prevFormState => ({ edit: !prevFormState.edit, input: "", alertNotifyUserArgs: null }))
  }

  function isValidEmail(email) {
    const regex = /.*@.*\..*/;
    return regex.test(email);
  }

  const handleSave = () => {
    const tempArgs = {
      url: sysAdmin ? Constants.SERVER_POST_SYSADMIN_ALERT_SUMMARY_EMAIL_URL + role.companyUuid : Constants.SERVER_POST_ALERT_SUMMARY_EMAIL_URL,
      method: "POST",
      body: JSON.stringify({ alertNotifyEmail: formState.input })
    }
    setFormState(prevFormState => ({ ...prevFormState, alertNotifyUserArgs: tempArgs }))
  }

  const handlePostSave = (res) => {
    const tempAlertNotifyUserEmail = JSON.parse(JSON.stringify(formState.input));
    if (sysAdmin) {
      refreshSelectedCompanyMetadata();
    } else {
      dispatch(setAlertNotifyUserEmail({ alertNotifyUserEmail: tempAlertNotifyUserEmail }));
    }
    setFormState({
      alertNotifyUserArgs: null,
      edit: false,
      input: ""
    })
  }

  return (
    <>
      <DirectRequest
        requestArgs={formState.alertNotifyUserArgs}
        afterProcess={handlePostSave}
        handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("error saving alert summary email")}
        handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("catch error saving alert summary email")}
      />
      <Grid item xs={12} container alignItems={"center"} spacing={1} sx={{ height: "65px"}}>
        <Grid item>
          <Typography variant={"p"}>Summary email recipient:&nbsp;&nbsp;<strong>{role.alertNotifyUserEmail}</strong></Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={toggleEdit} size={"small"}>
            <EditIcon sx={formState.edit ? { color: theme.palette.primary.main } : {}}/>
          </IconButton>
        </Grid>
      </Grid>
      {formState.edit && (
        <Grid item xs={12} container alignItems={"center"} spacing={1}>
          <Grid item>
            <Typography variant={"p"}>Update recipient:</Typography>
          </Grid>
          <Grid item>
            <TextField
              size={"small"}
              onChange={handleInput}
            />
          </Grid>
          <Grid item>
            <Button size={"small"} variant={"contained"} disabled={!isValidEmail(formState.input)} onClick={handleSave}>Save</Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export const Account = () => {
  const role = useSelector((state) => state.role);

  return (
    <Grid container width={"100%"} spacing={3}>
      <Grid item xs={12}>
        <Typography variant={"h4"}>Account</Typography>
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12} sx={{ height: "65px"}} container alignItems={"center"} spacing={1}>
          <Grid item>
            <Typography variant={"p"}>User email:&nbsp;&nbsp;<strong>{role.email}</strong></Typography>
          </Grid>
        </Grid>
        <SummaryEmailForm role={role} />
        <SummaryEmailOptIn role={role} />
      </Grid>
    </Grid>
  )
}

export default Account