import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import * as Constants from "../../Constants";
import {refreshAlertMessages, setAlertMessages} from "../../store/alertMessagesSlice";
import {
  refreshAlertMessages as refreshSysAdminAlertMessages,
  setAlertMessages as setSysAdminAlertMessages
} from "../../store/alertMessagesSysAdminSlice";
import DirectRequest from "./DirectRequest";
import convertISO8601ToMMDDYYYY from "../../helpers/convertISO8601ToMMDDYYYY";

const sortAlertMessages = (messages) => {
  return [...messages].sort((a, b) => {
    const aDate = convertISO8601ToMMDDYYYY(a.createdOn).split('/');
    const bDate = convertISO8601ToMMDDYYYY(b.createdOn).split('/');
    const dateCompare = bDate[2] - aDate[2] || bDate[0] - aDate[0] || bDate[1] - aDate[1];
    if (dateCompare !== 0) {
      return dateCompare;
    } else {
      const appNameCompare = a.appNameDisplay.localeCompare(b.appNameDisplay);
      if (appNameCompare !== 0) {
        return appNameCompare;
      } else {
        // If dates are the same, and appNameDisplay are same, sort by message
        const messageCompare = a.message.localeCompare(b.message);
        if (messageCompare !== 0) {
          return messageCompare;
        } else {
          // if messages are the same, compare uuid
          return a.uuid.localeCompare(b.uuid);
        }
      }
    }
  });
}

const GetAlertMessages = ({ sysAdmin }) => {
  const dispatch = useDispatch();

  const alertActiveMap = useSelector(
    (state) => sysAdmin ? state.alertMessagesSysAdmin.alertActiveMap : state.alertMessages.alertActiveMap
  );
  const isCaptured = useSelector(
    (state) => sysAdmin ? state.alertMessagesSysAdmin.isCaptured : state.alertMessages.isCaptured
  );
  const [alertMessagesArgs, setAlertMessagesArgs] = useState({ url: Constants.SERVER_GET_ALERT_MESSAGES_URL });

  const companyUuid = useSelector(state => state.alertsSysAdmin.company.uuid);
  const getSysAdminAlertMessagesArgs = () => {
    return {
      url: Constants.SERVER_SYSADMIN_POST_GET_ALERT_MESSAGES_URL,
      method: 'POST',
      body: JSON.stringify({
        companyUuid: companyUuid
      })
    }
  }

  const handleAlertMessages = (res) => {
    if (res) {
      // console.log("GetAlertMessages:", res);
      const alertMessages = [...res.alert_messages];
      alertMessages.forEach((message, index) => {
        message["resolved"] = !alertActiveMap[message.alertUuid];
        message["Id"] = index; // Add Id property for MUI DataGrid (FDGrid)
      })
      dispatch(sysAdmin ? setSysAdminAlertMessages(sortAlertMessages(alertMessages)) : setAlertMessages(sortAlertMessages(alertMessages)));
    }
  };

  return (alertActiveMap && !isCaptured) ? (
    <DirectRequest
      requestArgs={sysAdmin ? getSysAdminAlertMessagesArgs() : alertMessagesArgs}
      afterProcess={handleAlertMessages}
      handleError={(err) => console.log("AlertMessagesRequest error", err)}
      handleCatchError={(err) => console.log("AlertMessagesRequest catch error", err)}
    />
  ) : null;
}

const UpdateAlertMessages = ({ sysAdmin }) => {
  const dispatch = useDispatch();
  const updateAlertMessagesArgs = useSelector(
    state => sysAdmin ? state.alertMessagesSysAdmin.updateArgs : state.alertMessages.updateArgs
  );

  const handleSuccessfulUpdate = (res) => {
    if (res) {
      dispatch(sysAdmin ? refreshSysAdminAlertMessages() : refreshAlertMessages());
    }
  };

  return updateAlertMessagesArgs ? (
    <DirectRequest
      requestArgs={updateAlertMessagesArgs}
      afterProcess={handleSuccessfulUpdate}
      handleError={(err) => console.log("UpdateAlertMessages error", err)}
      handleCatchError={(err) => console.log("UpdateAlertMessages catch error", err)}
    />
  ) : null;
}

const UserAlertMessagesRequests = () => {
  const isAdmin = useSelector(state => state.role.isAdmin);
  return (
    <>
      {isAdmin && <GetAlertMessages sysAdmin={false} />}
      <UpdateAlertMessages sysAdmin={false} />
    </>
  )
}

const SysAdminAlertMessagesRequests = () => {
  const isSysAdmin = useSelector(state => state.role.isSysAdmin);
  return isSysAdmin ? (
    <>
      <GetAlertMessages sysAdmin={true} />
      <UpdateAlertMessages sysAdmin={true} />
    </>
  ) : null;
}

export const AlertMessagesRequest = () => {
  return (
    <>
      <UserAlertMessagesRequests/>
      <SysAdminAlertMessagesRequests/>
    </>
  )
}

export default AlertMessagesRequest;