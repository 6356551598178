import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import ImpactBar from "../../ImpactBar/ImpactBar";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from "react-redux";
import { getSourceTag } from "../TagEditor/TagEditor";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import * as Constants from "../../../Constants";
import DirectRequest from "../../../API/requests/DirectRequest";
import {refreshAppsSysAdmin} from "../../../store/appsSysAdminSlice";
import CircularProgress from "@mui/material/CircularProgress";
import {setIsDirty} from "../../../store/dashboardEditorSlice";

const SummaryCategoriesUpdateManager = ({ updateArgs, setUpdateArgs, setCurrentValue, newValue, setNewValue }) => {
  const dispatch = useDispatch();
  const [updateIndex, setUpdateIndex] = useState(0);

  const handleUpdateSuccess = () => {
    if (updateIndex < updateArgs.length - 1) {
      console.log(JSON.parse(updateArgs[updateIndex].body).developerAppUuid, " update successful")
      setUpdateIndex(updateIndex + 1)
    } else {
      console.log(JSON.parse(updateArgs[updateIndex].body).developerAppUuid, " update successful - all apps updated",)
      setUpdateArgs(null);
      dispatch(refreshAppsSysAdmin());
      setCurrentValue(newValue);
      setNewValue("");
    }
  }

  // reset updateIndex when updateArgs nulls after completion
  useEffect(() => {
    if (!updateArgs) {
      setUpdateIndex(0);
    }
  }, [updateArgs])

  return updateArgs ? (
    <DirectRequest
      requestArgs={updateArgs[updateIndex]}
      afterProcess={handleUpdateSuccess}
      handleError={(err) => console.log("server error updating app tags", err)}
      handleCatchError={(err) => console.log("client error updating app tags", err)}
    />
  ) : null;
}

const AppToUpdate = ({ appUuid, currentValue, divider }) => {
  const theme = useTheme();
  const allApps = useSelector((state) => state.appsSysAdmin.list);
  const app = allApps.find((app) => app.uuid === appUuid);
  return (
    <Grid container spacing={1}>
      {divider && <Grid item xs={12}><hr /></Grid>}
      <Grid item xs={12}>
        <Typography>{app.uuid}&nbsp;&nbsp;<strong>{app.fullName}</strong>&nbsp;&nbsp;<i>{getSourceTag(app.tags)}</i></Typography>
      </Grid>
      <Grid item xs={12} container>
        {app.tags.filter(tag => tag.includes("categories")).map((tag, index) => {
          const [identifier, summaryCategory, feedCategory] = tag.split("__");
          return (
            <Grid item xs={12} key={`${index}_${tag}`}>
              <Typography sx={{ color: "grey !important" }}>
                {identifier}__<span style={currentValue === summaryCategory ? { backgroundColor: theme.palette.primary.light, fontWeight: 500 } : {}}>{summaryCategory}</span>__{feedCategory}
              </Typography>
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

export const SummaryCategoriesEditor = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [currentValue, setCurrentValue] = useState("");
  const [newValue, setNewValue] = useState("");

  const allApps = useSelector((state) => state.appsSysAdmin.list);
  const [allCategories, setAllCategories] = useState([]);
  const [appsToUpdate, setAppsToUpdate] = useState([]);

  const [updateArgs, setUpdateArgs] = useState(null);
  const [updating, setUpdating] = useState(false);

  // Call generate display whenever currentValue changes
  useEffect(() => {
    const appsToUpdate = [];
    allApps.forEach(app => {
      const tags = app.tags;
      for (let i = 0; i < tags.length; i++) {
        if (tags[i].includes("categories")) {
          const category = tags[i].split("__")[1];
          if (category === currentValue) {
            appsToUpdate.push(app.uuid);
            break;
          }
        }
      }
    })
    console.log("apps to update: ", appsToUpdate);
    setAppsToUpdate(appsToUpdate);
  }, [currentValue, allApps]);

  // sets Select options for existing summary category selection
  useEffect(() => {
    const categories = [];
    allApps.forEach(app => {
      const tags = app.tags;
      for (let i = 0; i < tags.length; i++) {
        if (tags[i].includes("categories")) {
          const category = tags[i].split("__")[1];
          categories.push(category);
        }
      }
    })
    setAllCategories([...new Set(categories)].sort((a, b) => a.localeCompare(b)));
    setUpdating(false);
    dispatch(setIsDirty(false));
  }, [allApps])

  const handleCategorySelection = (event) => {
    const selectedValue = event.target.value;
    // console.log("selected category: ", selectedValue);
    // handleTagChange(selectedValue, index)
    setCurrentValue(selectedValue);
  }

  const handleNewValue = (event) => {
    // console.log("new input: ", event.target.value);
    setNewValue(event.target.value)
  }

  const handleUpdate = () => {
    setUpdating(true);
    const argsArray = [];
    appsToUpdate.forEach(appUuid => {
      const curTags = allApps.find(app => app.uuid === appUuid).tags;
      const newTags = [...curTags];
      newTags.forEach((tag, index) => {
        if (tag.includes("categories")) {
          const [identifier, summaryCategory, feedCategory] = tag.split("__");
          if (summaryCategory === currentValue) {
            newTags[index] = `${identifier}__${newValue}__${feedCategory}`;
          }
        }
      });
      const body = {
        developerAppUuid: appUuid,
        tags: newTags
      }
      const updateRequestArgs = {
        url: Constants.SERVER_SYSADMIN_UPDATE_DEVAPP_URL,
        method: "POST",
        body: JSON.stringify(body)
      }
      argsArray.push(updateRequestArgs);
    });
    setUpdateArgs(argsArray);
    dispatch(setIsDirty(true));
  }

  return (
    <>
      <SummaryCategoriesUpdateManager
        updateArgs={updateArgs}
        setUpdateArgs={setUpdateArgs}
        setCurrentValue={setCurrentValue}
        newValue={newValue}
        setNewValue={setNewValue}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ImpactBar
            component={
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      id="summary-category-current-select"
                      size={"small"}
                      value={currentValue}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === "") {
                          return <span style={{ color: "grey" }}><em>Select a current summary category</em></span>;
                        }
                        return selected;
                      }}
                      onChange={handleCategorySelection}
                      disabled={updating}
                    >
                      {allCategories.map((category) => {
                        return (
                          <MenuItem value={category} key={category}>
                            {category}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <NavigateNextIcon sx={{ color: theme.palette.primary.main, fontSize: "2rem", marginTop: "5px" }} />
                </Grid>
                <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      id="summary-category-new-input"
                      placeholder={"Enter a new summary category"}
                      size={"small"}
                      onChange={handleNewValue}
                      value={newValue}
                      sx={{
                        '& .MuiInputBase-input::placeholder': {
                          fontStyle: 'italic',
                        },
                      }}
                      disabled={updating}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button
                    variant={"contained"}
                    sx={{ borderRadius: "0px !important"}}
                    startIcon={updating ? <CircularProgress size={"1rem"}/> : <SendIcon />}
                    onClick={handleUpdate}
                    disabled={!currentValue || !newValue || updating}
                    size={"small"}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {appsToUpdate.map((appUuid, index) => (
              <Grid item xs={12} key={appUuid}>
                <AppToUpdate appUuid={appUuid} currentValue={currentValue} divider={index !== 0} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default SummaryCategoriesEditor;