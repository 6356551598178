import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import * as Constants from "../../Constants";
import DirectRequest from "./DirectRequest";
import {setAppsSysAdmin} from "../../store/appsSysAdminSlice";
import { sortApps } from "./AppsRequest";

const getAllFeedCategories = (apps) => {
  const feedCategories = [];
  apps.forEach((app) => {
    const categories = app.tags.filter((tag) => tag.includes("categories__"));
    if (categories.length === 0) console.log("sysAdmin getAllFeedCategories - app:", app.fullName, app.uuid, "has no categories tags");
    categories.forEach((category) => {
      const feed_category = category.split("__")[2]?.split("::") || [];
      feedCategories.push(feed_category);
    });
  });
  return [...new Set(feedCategories.flat().sort())];
}

export default function AppsRequestSysAdmin() {
  const dispatch = useDispatch();
  const isSysAdmin = useSelector((state) => state.role.isSysAdmin);
  const isCaptured = useSelector((state) => state.appsSysAdmin.isCaptured);
  const [appsSysAdminArgs, setAppsSysAdminArgs] = useState({ url: Constants.SERVER_DEV_APPS_URL });

  const handleAllApps = (res) => {
    if (res) {
      const filteredAlertsApps = res.filter((app) => app.tags.includes("fdAlert"));
      const sortedApps = sortApps(filteredAlertsApps);
      const feedCategories = getAllFeedCategories(sortedApps);
      console.warn("SysAdmin apps", sortedApps, "SysAdmin feedCategories", feedCategories);
      dispatch(setAppsSysAdmin({ list: sortedApps, feedCategories: feedCategories }));
    }
  };

  return (
    <>
      {isSysAdmin && !isCaptured && (<DirectRequest requestArgs={appsSysAdminArgs} afterProcess={handleAllApps}/>)}
    </>
  );
}