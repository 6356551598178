import { createSlice } from "@reduxjs/toolkit";

export const requestErrorsSlice = createSlice({
  name: "requestErrors",
  initialState: { requestErrors: [] },
  reducers: {
    addRequestError: (state, action) => {
      state.requestErrors = state.requestErrors.concat([action.payload]);
    },
    removeRequestError: (state, action) => {
      state.requestErrors = state.requestErrors.slice(1);
    },
  },
});

export const { addRequestError, removeRequestError } =
  requestErrorsSlice.actions;

export default requestErrorsSlice.reducer;
