import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {
  setCurrentType,
  messages_to_show,
  setMessagesToShow,
  create_alert_message_types,
  setCreateAlertMessage,
  validateCreateAlertMessageInput,
  getCreateAlertMessageInputLabel
} from "../../store/alertMessagesSysAdminSlice";
import {
  alertLink,
  appNameDisplay,
  createdBy,
  createdOn,
  message,
  actions,
  sysAdminActions
} from "../AlertMessages/AlertMessagesColumns";
import Grid from "@mui/material/Grid";
import AlertMessagesFilters from "../AlertMessages/AlertMessagesFilters";
import AlertMessagesTable from "../AlertMessages/AlertMessagesTable";
import * as Constants from "../../Constants";
import DirectRequest from "../../API/requests/DirectRequest";
import FDGrid from "../FDGrid/FDGrid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

const cleanMessages = (messages) => {
  return messages.map((message, index) => {
    return {
      ...message,  // Preserve original properties
      Id: index, // Add Id property
    };
  })
}

const sortByMostRecent = (generalAiMessages) => {
  return generalAiMessages.sort((a, b) => {
    return new Date(b.createdOn) - new Date(a.createdOn);
  })
}

const generalColumns = [
  {
    field: "createdOn",
    headerName: "Created On",
    width: 150,
    renderCell: (params) => {
      return params.value ? new Date(params.value)?.toLocaleString() : ""
    }
  },
  {
    field: "message",
    headerName: "Message",
    flex: 1
  },
  {
    field: "createdBy",
    headerName: "Created By",
    width: 250
  }
]

const SysAdminGeneralMessagesTable = () => {
  const selectedCompany = useSelector(state => state.alertsSysAdmin.company);
  const [generalMessagesArgs, setGeneralMessagesArgs] = useState(null)
  const [generalMessages, setGeneralMessages] = useState([])

  useEffect(() => {
    if (selectedCompany && selectedCompany.uuid) {
      if (selectedCompany.name) console.log("selected company", selectedCompany.name);
      setGeneralMessagesArgs({ url: Constants.SERVER_GET_SYSADMIN_GENERAL_AI_MESSAGES_URL + selectedCompany.uuid });
      setGeneralMessages([]);
    }
  }, [selectedCompany])

  const handleGeneralMessages = (res) => {
    if (res && res.messages) {
      console.log("General messages", res.messages)
      setGeneralMessages(cleanMessages(sortByMostRecent(res.messages)));
    }
  }

  return (
    <>
      <DirectRequest
        requestArgs={generalMessagesArgs}
        afterProcess={handleGeneralMessages}
        handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("error getting general messages", err)}
        handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("catch error getting general messages", err)}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FDGrid
            pageSize={100}
            rows={generalMessages}
            columns={generalColumns}
            getRowId={(row) => row.Id}
            autoHeight={true}
            localeText={{
              noRowsLabel: 'No general messages'
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

const CreateAlertMessageInput = ({type}) => {
  const dispatch = useDispatch();
  const company = useSelector(state => state.alertsSysAdmin.company);
  const isUpdating = useSelector(state => state.alertMessagesSysAdmin.updateArgs);
  const value = useSelector(state => state.alertMessagesSysAdmin.createAlertMessage[type]);
  const error = validateCreateAlertMessageInput(value, type, company);
  const isErrored = !!error;
  const handleChange = (event) => {
    const newValue = event.target.value;
    dispatch(setCreateAlertMessage({ type: type, value: newValue }))
  }
  return (
    <FormControl sx={{width:"100%"}}>
      <TextField
        label={getCreateAlertMessageInputLabel(type, error)}
        variant="outlined"
        value={value}
        onChange={handleChange}
        error={isErrored}
        disabled={isUpdating}
      />
    </FormControl>
  )
}

const CreateAlertMessage = () => {
  const creating = useSelector(state => state.alertMessagesSysAdmin.createAlertMessage.showForm)
  return creating ? (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        {/*  alertUuid here */}
        <CreateAlertMessageInput type={create_alert_message_types.alertUuid}/>
      </Grid>
      <Grid item xs={8}>
      {/*  message here */}
        <CreateAlertMessageInput type={create_alert_message_types.message}/>
      </Grid>
      <Grid item xs={4}>
      {/*  developerAppUuid here */}
        <CreateAlertMessageInput type={create_alert_message_types.developerAppUuid}/>
      </Grid>
      <Grid item xs={8}>
      {/*  alertLink here */}
        <CreateAlertMessageInput type={create_alert_message_types.alertLink}/>
      </Grid>
      <Grid item xs={4}>
        {/*  appNameDisplay */}
        <CreateAlertMessageInput type={create_alert_message_types.appNameDisplay}/>
      </Grid>
      <Grid item xs={4}>
      {/*  createdOn */}
        <CreateAlertMessageInput type={create_alert_message_types.createdOn}/>
      </Grid>
      <Grid item xs={4}>
      {/*  createdBy */}
        <CreateAlertMessageInput type={create_alert_message_types.createdBy}/>
      </Grid>
    </Grid>
  ) : null;
}

const SysAdminAlertMessagesTable = () => {
  const columns = [
    appNameDisplay(),
    message(),
    createdBy(),
    createdOn(),
    alertLink(true),
    actions(true),
    sysAdminActions()
  ]
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/*  impact bar with filters here*/}
        <AlertMessagesFilters sysAdmin={true} />
      </Grid>
      <Grid item xs={12}>
        {/*  table here */}
        <CreateAlertMessage/>
        <AlertMessagesTable sysAdmin={true} columns={columns} />
      </Grid>
    </Grid>
  )
}

const SysAdminAlertMessagesTablePicker = () => {
  const messagesToShow = useSelector(state => state.alertMessagesSysAdmin.messagesToShow);
  return messagesToShow === messages_to_show.ALERT ? (
    <SysAdminAlertMessagesTable/>
  ) : (
    <SysAdminGeneralMessagesTable/>
  );
}

export const SysAdminAlertMessages = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setCurrentType("All"))
      dispatch(setMessagesToShow(messages_to_show.ALERT))
    }
  }, []);

  return (
    <SysAdminAlertMessagesTablePicker/>
  )
}

export default SysAdminAlertMessages;