export const all_states = [
	'CA', 'TX', 'FL', 'NY', 'PA',
	'IL', 'OH', 'GA', 'NC', 'MI',
	'NJ', 'VA', 'WA', 'AZ', 'MA',
	'TN', 'IN', 'MO', 'MD', 'WI',
	'CO', 'MN', 'SC', 'AL', 'LA',
	'KY', 'OR', 'OK', 'CT', 'UT',
	'PR', 'IA', 'NV', 'AR', 'MI',
	'KS', 'NM', 'NE', 'ID', 'WV',
	'HI', 'NH', 'ME', 'RI', 'MT',
	'DE', 'SD', 'ND', 'AL', 'DC',
	'VT', 'WY', 'MS'];