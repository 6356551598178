import ImpactBar from "../ImpactBar/ImpactBar";
import React from "react";
import Grid from '@mui/material/Grid';
import { setCurrentType } from "../../store/alertMessagesSlice";
import {
  create_alert_message_types,
  setCurrentType as setSysAdminCurrentType,
  setUpdateAlertMessagesArgs as setSysAdminUpdateAlertMessagesArgs,
  validateCreateAlertMessage,
  resetCreateAlertMessage,
  setCreateAlertMessage
} from "../../store/alertMessagesSysAdminSlice";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import * as Constants from '../../Constants';
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import iso8601Timestamp from "../../helpers/iso8601Timestamp";
import {setAlertNotificationArgs} from "../../store/alertsNotificationSlice";

const generateAlertMessagesTypeSelectionNotificationArgs = (user, type) => {
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `<strong>${user}</strong> selected the <strong>${type}</strong> alert message type on ${window.location.href}<br/><br/>`,
      "subject": `${user} selected the ${type} alert message type on ${window.location.href} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}

const getAlertMessageCount = (feedCategory, alertMessagesFeedCategoryMap) => {
  return alertMessagesFeedCategoryMap[feedCategory] ? `(${alertMessagesFeedCategoryMap[feedCategory].length})` : null;
}

const AlertMessagesAlertTypeFilter = ({ sysAdmin }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector(state => state.role.name);
  const isInternal = useSelector(state => state.role.isInternal);
  const allFeedCats = useSelector(
    (state) => sysAdmin ? state.appsSysAdmin.feedCategories : state.appsCategories.feedCategories
  );
  const currentType = useSelector(
    (state) => sysAdmin ? state.alertMessagesSysAdmin.currentType : state.alertMessages.currentType
  );
  const alertMessagesFeedCategoryMap = useSelector(
    (state) => sysAdmin ? state.alertMessagesSysAdmin.alertMessagesFeedCategoryMap : state.alertMessages.alertMessagesFeedCategoryMap
  );
  const handleAlertTypeSelection = (event) => {
    // console.log("handleAlertTypeSelection", event, event.target.dataset.value)
    if (typeof event.target.dataset.value === "string") {
      dispatch(sysAdmin ? setSysAdminCurrentType(event.target.dataset.value) : setCurrentType(event.target.dataset.value));
      if (!isInternal && !sysAdmin) {
        const tempAlertMessagesTypeSelectionArgs = generateAlertMessagesTypeSelectionNotificationArgs(user, event.target.dataset.value);
        dispatch(setAlertNotificationArgs(tempAlertMessagesTypeSelectionArgs));
      }
    }
  }
  return (
    <FormControl size="small" sx={{ minWidth: "200px"}}>
      <InputLabel id="alert-type-select-label">Alert type</InputLabel>
      <Select
        labelId="alert-type-select-label"
        id="alert-type-select"
        value={currentType}
        variant="outlined"
        onClick={handleAlertTypeSelection}
        label={"Alert type"}
        sx={{ minWidth: "150px", borderColor: theme.palette.primary.main }}
      >
        <MenuItem value={"All"} key={"cat_all"}>All</MenuItem>
        {alertMessagesFeedCategoryMap && [...allFeedCats].sort((a, b) => {
          if (!alertMessagesFeedCategoryMap) return 0;
          const aCount = alertMessagesFeedCategoryMap[a] ? alertMessagesFeedCategoryMap[a].length : 0;
          const bCount = alertMessagesFeedCategoryMap[b] ? alertMessagesFeedCategoryMap[b].length : 0;
          const countCompare = bCount - aCount;
          return countCompare;
        }).map((cat, index) => {
          return (
            <MenuItem index={"cat-" + index} value={cat} key={`cat__${cat}__${index}`}>{cat} {getAlertMessageCount(cat, alertMessagesFeedCategoryMap)}</MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

const generateCreateAlertMessageSysAdminArgs = (formValues, companyUuid) => {
  const tempArgs = {
    url: Constants.SERVER_SYSADMIN_POST_ALERT_MESSAGE_URL,
    method: "POST",
    body: JSON.stringify({
      companyUuid: companyUuid,
      alertUuid: formValues[create_alert_message_types.alertUuid],
      developerAppUuid: formValues[create_alert_message_types.developerAppUuid],
      alertLink: formValues[create_alert_message_types.alertLink],
      createdOn: formValues[create_alert_message_types.createdOn],
      message: formValues[create_alert_message_types.message],
      createdBy: formValues[create_alert_message_types.createdBy],
      appNameDisplay: formValues[create_alert_message_types.appNameDisplay]
    })
  }
  return tempArgs;
}

const SaveAlertMessageButton = () => {
  const dispatch = useDispatch();
  const company = useSelector(state => state.alertsSysAdmin.company);
  const createAlertMessage = useSelector(state => state.alertMessagesSysAdmin.createAlertMessage);
  const enabled = validateCreateAlertMessage(createAlertMessage, company);
  const isUpdating = useSelector(state => state.alertMessagesSysAdmin.updateArgs);
  const handleCreateAlertMessage = () => {
    const createAlertMessageArgs = generateCreateAlertMessageSysAdminArgs(createAlertMessage, company.uuid);
    console.log("createAlertMessageArgs", createAlertMessageArgs);
    dispatch(setSysAdminUpdateAlertMessagesArgs(createAlertMessageArgs));
  }

  return (
    <Grid item>
      <Button
        color="primary"
        startIcon={isUpdating ? <CircularProgress size={"1rem"}/> : <SendIcon />}
        sx={{ borderRadius: "0px !important"}}
        variant="contained"
        size={"small"}
        onClick={handleCreateAlertMessage}
        disabled={isUpdating || !enabled}
      >
        Save
      </Button>
    </Grid>
  )
}

const CancelCreateButton = ({ handleToggle }) => {
  const isUpdating = useSelector(state => state.alertMessagesSysAdmin.updateArgs)
  return (
    <Grid item>
      <Button
        color="primary"
        startIcon={<RemoveIcon />}
        sx={{ borderRadius: "0px !important"}}
        variant="outlined"
        size={"small"}
        onClick={handleToggle}
        disabled={isUpdating}
      >
        Cancel
      </Button>
    </Grid>
  )
}

const CreateAlertMessageButton = ({ handleToggle, creating }) => {
  const companyUuid = useSelector(state => state.alertsSysAdmin.company.uuid);
  return (
    <Grid item>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        sx={{ borderRadius: "0px !important"}}
        variant="contained"
        size={"small"}
        onClick={handleToggle}
        disabled={!companyUuid || creating}
      >
        Add message
      </Button>
    </Grid>
  )
}

const CreateAlertMessageButtons = () => {
  const dispatch = useDispatch();
  const creating = useSelector(state => state.alertMessagesSysAdmin.createAlertMessage.showForm);
  const handleToggle = () => {
    if (creating) {
      dispatch(resetCreateAlertMessage());
    } else {
      dispatch(setCreateAlertMessage({ type: "showForm", value: true }));
    }
  }
  return (
    <>
      <CreateAlertMessageButton handleToggle={handleToggle} creating={creating}/>
      {creating && <CancelCreateButton handleToggle={handleToggle}/>}
      {creating && <SaveAlertMessageButton/>}
    </>
  )
}

const Filters = ({ sysAdmin }) => {
  return (
    <Grid container spacing={2} alignItems={"center"}>
      <Grid item>
        <AlertMessagesAlertTypeFilter sysAdmin={sysAdmin} />
      </Grid>
      {sysAdmin && <CreateAlertMessageButtons/>}
    </Grid>
  )
}

export const AlertMessagesFilters = ({ sysAdmin }) => {
  return (
    <ImpactBar
      component={
        <Filters sysAdmin={sysAdmin} />
      }
    />
  )
}

export default AlertMessagesFilters;