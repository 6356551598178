import { createSlice } from '@reduxjs/toolkit';

export const alertsSlice = createSlice({
  name: "alerts",
  initialState: {
    isCaptured: false,
    alerts: [],
    resolvedAlerts: [],
    isReady: false,
    alertsError: {
      isErrored: false,
      errorMessage: "",
    },
    resolvedAlertsError: {
      isErrored: false,
      errorMessage: "",
    },
  },
  reducers: {
    setAlerts: (state, action) => {
      state.alerts = action.payload.alerts
      state.resolvedAlerts =  action.payload.resolvedAlerts
      state.isCaptured = true
      state.isReady = true
    },
    refreshAlerts: (state, action) => {
      state.isCaptured = false
    },
    setActiveAlerts: (state, action) => {
      state.alerts = action.payload.alerts
      state.isCaptured = true
    },
    setAlertsError: (state, action) => {
      state.alertsError.isErrored = true
      state.alertsError.errorMessage = action.payload
    },
    setResolvedAlertsError: (state, action) => {
      state.resolvedAlertsError.isErrored = true
      state.resolvedAlertsError.errorMessage = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAlerts,
  refreshAlerts,
  setActiveAlerts,
  setAlertsError,
  setResolvedAlertsError,
} = alertsSlice.actions;

export default alertsSlice.reducer;