import { useState } from "react";
import DirectRequest from "./DirectRequest";
import {useDispatch, useSelector} from "react-redux";
import {
  refreshAppsSubscriptions,
  setAppsSubscriptions,
  resetAddSubscriptionArgs,
  resetCancelSubscriptionArgs,
  resetCustomerSubToRemove
} from "../../store/appsSubscriptionsSlice";
import * as Constants from "../../Constants";
import {removeCustomerSub} from "../../store/customerSubsSlice";

const GetAppsSubscriptions = () => {
  const dispatch = useDispatch();

  const isCaptured = useSelector((state) => state.appsSubscriptions.isCaptured);
  const [appsSubscriptionsArgs, setAppsSubscriptionsArgs] = useState({ url: Constants.SERVER_CUS_SUBBED_APPS_URL });
  const customerSubToRemove = useSelector((state) => state.appsSubscriptions.customerSubToRemove);

  const handleAppsSubscriptions = (res) => {
    if (res) {
      // console.log("GetAppsSubscriptions:", res);
      dispatch(setAppsSubscriptions(res));
      if (customerSubToRemove) {
        dispatch(resetCustomerSubToRemove());
        dispatch(removeCustomerSub(customerSubToRemove));
      }
    }
  };

  return isCaptured ? null : (
    <DirectRequest
      requestArgs={appsSubscriptionsArgs}
      afterProcess={handleAppsSubscriptions}
      handleError={(err) => console.log("AppsSubscriptionsRequest error", err)}
      handleCatchError={(err) => console.log("AppsSubscriptionsRequest catch error", err)}
    />
  );
}

const AddSubscriptionRequest = () => {
  const dispatch = useDispatch();
  const addSubscriptionArgs = useSelector((state) => state.appsSubscriptions.addSubscriptionArgs);

  const handleSubscriptionsUpdate = (res) => {
    console.log("AddAppsSubscriptions success", res);
    dispatch(resetAddSubscriptionArgs());
    dispatch(refreshAppsSubscriptions());
  }

  return addSubscriptionArgs ? (
    <DirectRequest
      requestArgs={addSubscriptionArgs}
      afterProcess={handleSubscriptionsUpdate}
      handleError={(err) => console.log("UpdateAppsSubscriptions error", err)}
      handleCatchError={(err) => console.log("UpdateAppsSubscriptions catch error", err)}
    />
  ) : null;
}

const CancelSubscription = () => {
  const dispatch = useDispatch();
  const cancelSubscriptionArgs = useSelector((state) => state.appsSubscriptions.cancelSubscriptionArgs);

  const handleSubscriptionsUpdate = (res) => {
    console.log("CancelSubscription success", res);
    dispatch(resetCancelSubscriptionArgs());
    dispatch(refreshAppsSubscriptions());
  }

  return cancelSubscriptionArgs ? (
    <DirectRequest
      requestArgs={cancelSubscriptionArgs}
      afterProcess={handleSubscriptionsUpdate}
      handleError={(err) => console.log("UpdateAppsSubscriptions error", err)}
      handleCatchError={(err) => console.log("UpdateAppsSubscriptions catch error", err)}
    />
  ) : null;
}

const CancelSubscriptionRequest = () => {
  return (
    <CancelSubscription/>
  )
}

const UpdateAppsSubscriptions = () => {
  return (
    <>
      <AddSubscriptionRequest/>
      <CancelSubscriptionRequest/>
    </>
  )
}


export const AppsSubscriptionsRequest = () => {
  return (
    <>
      <GetAppsSubscriptions />
      <UpdateAppsSubscriptions />
    </>
  )
}

export default AppsSubscriptionsRequest;