import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled, useTheme } from "@mui/material/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import * as Constants from "../../Constants";

import { v4 as uuidv4 } from "uuid";

function AppBar(props) {
	const navigate = useNavigate();
	const theme = useTheme();

	const handleChange = (event, newValue) => {
		if (props.changeActiveAppCategory) {
			props.changeActiveAppCategory(newValue);
		} else {
			props.changeActiveBuilder(newValue)
		}
	};

	const getTabs = () => {
		const tabs = [];

		tabs.push(getDescriptionTab());
		const domainTabs = mapDomains();
		if (domainTabs) {
			tabs.push(domainTabs);
		}
		if (props.developerCreate) {
			tabs.push(mapBuilders())
		}
		return tabs;
	};

	const getDescriptionTab = () => {
		return (
			<Tab
				disabled
				label={props?.developerCreate ? "Builder" : "Categories"}
				sx={{ color: theme.typography.color + " !important" }}
				key={0}
			/>
		);
	};

	const mapDomains = () => {
		if (props.appCategories) {
			return props.appCategories.map((appCategory, index) => (
				<Tab label={appCategory} value={appCategory} key={index + 1} />
			));
		}
	};

	const mapBuilders = () => {
		if (props.developerCreate) {
			return props.builders.map((builder, index) => (
				<Tab label={builder} value={builder} key={index + 1} />
			));
		}
	};

	const getMasterTabListElement = () => {
		if (!props.developerCreate) {
			return (
				<Tabs
					value={props.activeAppCategory ? props.activeAppCategory : null}
					onChange={handleChange}
					data-tut="reactour__domainbar"
				>
					{getTabs()}
				</Tabs>
			);
		} else {
			return (
				<Tabs
					value={props.activeBuilder ? props.activeBuilder : null}
					onChange={handleChange}
				>
					{getTabs()}
				</Tabs>
			)
		}
	};

	return getMasterTabListElement();
}

export default AppBar;
