export const filterRows = (rows, filters, hide_row_toggle) => {
  /*
  rows = {
    tableName: {
      data: array of arrays
      typeMap: {...}
    }
  }
   */
  // console.log("filterRows", "rows: ", rows, "filters: ", filters, "hide_row_toggle: ", hide_row_toggle);
  const rows_clone = JSON.parse(JSON.stringify(rows));
  const tablesToFilter = Object.entries(filters)
    .filter(([tableName, tableFilters]) => Object.keys(tableFilters).length > 0)
    .map(([tableName, tableFilters]) => tableName);
  // console.log("tablesToFilter", tablesToFilter)
  for (let i = 0; i < tablesToFilter.length; i++) {
    let table = tablesToFilter[i];
    // console.log(`table[${i}] = `, table);
    let new_rows = [rows[table].data[0]];
    for (let j = 1; j < rows[table].data.length; j++) {
      let good = true
      const tableFilters = filters[table];
      // console.log("tableFilters = ", tableFilters);
      for (const key in tableFilters) {
        if (key.includes("displayConfig")) {
          if (!hide_row_toggle) {
            continue;
          }
          // console.log("displayConfig key")
          // multi-key match required
          // displayConfig__configUuidA: { valueMap: { fieldNameA: valueA, fieldNameB: valueB }, displayConfigOptions: { hide_row: true } }
          const filterValueMap = tableFilters[key].valueMap;
          const displayConfigOptions = tableFilters[key].displayConfigOptions;
          // console.log("filterValueMap ", filterValueMap);
          // console.log("displayConfigOptions ", displayConfigOptions);
          const keys = Object.keys(filterValueMap);
          const rowValueMap = {};
          for (let k = 0; k < keys.length; k++) {
            const keyIndex = rows[table].data[0].indexOf(keys[k]);
            if (keyIndex === -1) {
              continue
            }
            rowValueMap[keys[k]] = rows[table].data[j][keyIndex];
          }
          if (keys.every((key) => filterValueMap[key] === rowValueMap[key])) {
            if (displayConfigOptions.hide_row) {
              good = false;
            }
          }
        } else {
          // console.log("not displayConfig key")
          const keyIndex = rows[table].data[0].indexOf(key); // we get the header row index for the key/field
          if(keyIndex === -1) {
            continue
          }
          // console.log("keyIndex", keyIndex)
          // now we do conditional logic for each filterType and modify 'good' accordingly
          if (
            tableFilters[key]["filterType"] === "year" ||
            tableFilters[key]["filterType"] === "month"
          ) {
            let date = new Date(rows[table].data[j][keyIndex]);
            if (
              (tableFilters[key]["filterType"] === "year" &&
                date.getFullYear() !== tableFilters[key]["target"]) ||
              (tableFilters[key]["filterType"] === "month" &&
                date.getMonth() !== tableFilters[key]["target"])
            ) {
              good = false;
            }
          } else if (tableFilters[key]["filterType"] === "regular") {
            if (rows[table].data[j][keyIndex] !== tableFilters[key]["target"]) {
              good = false;
            }
          } else if (tableFilters[key]["filterType"] === "search") {
            // console.log("search filter", tableFilters[key]);
            if (
              rows[table].data[j][keyIndex]
                .toLowerCase()
                .indexOf(tableFilters[key]["target"].toLowerCase()) === -1
            ) {
              good = false;
            }
          }
        }
      }
      if (good) { // if good - it goes in new_rows
        new_rows.push(rows[table].data[j]);
      }
    }
    // console.log(`all ${table} rows processed`, new_rows);
    // console.log("rows_clone", rows_clone);
    rows_clone[table].data = new_rows;
  }
  return rows_clone
  // Vik's OG filter logic:
  // 'tables' used to be 'allRows'
  // 'all_tables' was current setFilter's [sourceTable, ...additionalTables]
  // 'clone' was a deep copy of filters...
  // for(var j = 0; j < all_tables.length; j++) { // all_tables is all tables effected by the filter
  //   let table = all_tables[j];
  //   let new_rows = [allRows[table].data[0]]; // we start by constructing the header row
  //   for (var i = 1; i < allRows[table].data.length; i++) { // then we begin on row 1 (start of data rows)
  //     let good = true;
  //
  //     for (var key in clone) { // then we loop through each filter
  //       const keyIndex = allRows[table].data[0].indexOf(key); // we get the header row index for the key/field
  //       if(keyIndex == -1) {
  //         continue
  //       }
  //       // now we do conditional logic for each filterType and modify 'good' accordingly
  //       if (
  //         clone[key]["filterType"] === "year" ||
  //         clone[key]["filterType"] === "month"
  //       ) {
  //         let date = new Date(allRows[table].data[i][keyIndex]);
  //
  //         if (
  //           (clone[key]["filterType"] === "year" &&
  //             date.getFullYear() !== clone[key]["target"]) ||
  //           (clone[key]["filterType"] === "month" &&
  //             date.getMonth() !== clone[key]["target"])
  //         ) {
  //           good = false;
  //         }
  //       } else if (clone[key]["filterType"] === "regular") {
  //         if (allRows[table].data[i][keyIndex] !== clone[key]["target"]) {
  //           good = false;
  //         }
  //       } else if (clone[key]["filterType"] === "search") {
  //         if (
  //           allRows[table].data[i][keyIndex]
  //             .toLowerCase()
  //             .indexOf(clone[key]["target"].toLowerCase()) === -1
  //         ) {
  //           good = false;
  //         }
  //       }
  //     }
  //     if (good) { // if good - it goes in new_rows
  //       new_rows.push(allRows[table].data[i]);
  //     }
  //   }
  //   rows_clone[table].data = new_rows;
  // }
}

export const transformRows = (rows, transformations, dashboardJson, suppress_alert_toggle) => {
  // transformations will be keyed by table -> displayConfig__configUuid__configTableRowUuid: { valueMap: {}, displayConfigOptions: {}, objectName: "" }
  const rows_clone = JSON.parse(JSON.stringify(rows));
  const tablesToTransform = Object.entries(transformations)
    .filter(([tableName, tableTransforms]) => Object.keys(tableTransforms).length > 0)
    .map(([tableName, tableTransforms]) => tableName);
  for (let i = 0; i < tablesToTransform.length; i++) {
    const table = tablesToTransform[i];
    // console.log(`table[${i}] = `, table);
    const new_rows = [];
    for (let j = 0; j < rows[table].data.length; j++) {// start at index 0 -> may need to alter header row
      const statusTrackerConfig = Object.values(dashboardJson.components)
        .find((component) => component.type === "table" && component.sourceTable === table && component.statusTrackerConfig)?.statusTrackerConfig//transformationConfigs[table]?.statusTrackerConfig;// status tracker -> need to insert "_status_tracker"
      const suppressAlertConfig = Object.values(dashboardJson.components)
        .find((component) => component.type === "table" && component.sourceTable === table && component.suppressAlertConfig)?.suppressAlertConfig
      const new_row = [...rows[table].data[j]];
      if (j === 0) {
        if (statusTrackerConfig) {
          new_row.push("_status_tracker")// add status tracker column
        }
      } else {
        // first - insert _status_tracker defaultValue on every row
        // then check all keys - if it's a match - check
        let statusOverride = false;
        if (statusTrackerConfig) {
          let override = false;
          // check for overrideColName - if overrideColName value not falsey apply overrideColStatus and flip statusOverride otherwise defaultStatus
          const keyIndex = rows[table].data[0].indexOf(statusTrackerConfig.overrideColName);// find index from header row
          if (keyIndex !== -1) {
            override = !!new_row[keyIndex];
          }
          if (override) {
            new_row.push(statusTrackerConfig.overrideColStatus);// add status tracker override value
            statusOverride = true
          } else {
            new_row.push(statusTrackerConfig.defaultStatus)// add status tracker default value
          }
        }
        const tableTransforms = transformations[table];
        for (const key in tableTransforms) {
          const transformValueMap = tableTransforms[key].valueMap;
          const displayConfigOptions = tableTransforms[key].displayConfigOptions;
          if (!transformValueMap || !displayConfigOptions) {
            // do nothing - this means key is either "suppress_alert" or "status_tracker" placed to trigger tablesToTransform loop
          } else {
            const keys = Object.keys(transformValueMap);
            const rowValueMap = {};
            for (let k = 0; k < keys.length; k++) {
              const keyIndex = rows[table].data[0].indexOf(keys[k]);
              if (keyIndex === -1) {
                continue
              }
              rowValueMap[keys[k]] = rows[table].data[j][keyIndex];
            }
            if (keys.every((key) => transformValueMap[key] === rowValueMap[key])) {
              // we have a match - now check displayConfigOptions and statusTrackerConfig/suppressAlertConfig to adjust values as needed
              if (displayConfigOptions.suppress_alert && suppressAlertConfig && suppress_alert_toggle) {
                // zero out all alertFlagColList
                suppressAlertConfig.alertFlagColList.forEach(flagCol => {
                  const keyIndex = rows[table].data[0].indexOf(flagCol);// find index from header row
                  if (keyIndex !== -1) {
                    new_row[keyIndex] = 0;
                  }
                })
                if (suppressAlertConfig.alertMessageCol) {
                  const keyIndex = rows[table].data[0].indexOf(suppressAlertConfig.alertMessageCol);// find index from header row
                  if (keyIndex !== -1) {
                    new_row[keyIndex] = "";
                  }
                }
              }
              if (displayConfigOptions.status_tracker && statusTrackerConfig && !statusOverride) {
                // replace pushed value with displayConfigOptions.status
                new_row.pop();
                new_row.push(displayConfigOptions.status_tracker);
              }
            }
          }
        }
      }
      // console.log("new_row post processing", new_row);
      new_rows.push(new_row);
    }
    // console.log("transformRows " + table + " new_rows", new_rows);
    rows_clone[table].data = new_rows;
  }
  return rows_clone;
}

export const sortRows = (rows, sortRules, dashboardJson) => {
  /*
    sortRules: {
      tableNameA: sortConfig // should only be one per table
    }
   "sortConfig": [
                {
                    "colName": "_status_tracker",
                    "descending": false
                },
                {
                    "colName": "bill_amt",
                    "descending": true
                }
            ],
   */
  // console.log("sortRows start")
  const rows_clone = JSON.parse(JSON.stringify(rows));
  const tablesToSort = Object.keys(sortRules);
  for (let i = 0; i < tablesToSort.length; i++) {
    const table = tablesToSort[i]
    const sortConfig = sortRules[table];
    const statusTrackerConfig = Object.values(dashboardJson.components)
      .find((component) => component.type === "table" && component.sourceTable === table && component.statusTrackerConfig)?.statusTrackerConfig

    // Clone the rows to avoid mutating the original array
    const table_rows_clone = JSON.parse(JSON.stringify(rows[table].data));

    // Assume first row contains headers
    const columnRow = table_rows_clone[0];

    // Copy rows without the header
    const rowsToSort = table_rows_clone.slice(1);

    // Find the column indices based on sortConfig
    const sortConditions = sortConfig.map(config => {
      const colIndex = columnRow.indexOf(config.colName);
      return {
        colIndex,
        descending: config.descending,
        colName: config.colName
      };
    });

    // Sorting logic based on multiple rules
    rowsToSort.sort((a, b) => {
      for (const condition of sortConditions) {
        const { colIndex, descending, colName } = condition;
        const valueA = a[colIndex];
        const valueB = b[colIndex];
        const valueType = rows_clone[tablesToSort[i]].typeMap[colName];// TODO: may need to integrate valueType
        if (valueA == null || valueB == null) {
          return valueA == null ? 1 : -1;
        }

        // Apply numeric sorting or string sorting - TODO: may need to integrate valueType
        let comparison = 0;
        if (colName === "_status_tracker" && statusTrackerConfig) {
          // Use allowedStatusList to determine the order
          const statusList = statusTrackerConfig.allowedStatusList;

          const indexA = statusList.indexOf(valueA);
          const indexB = statusList.indexOf(valueB);

          // Handle any statuses not found in the allowedStatusList
          const rankA = indexA !== -1 ? indexA : statusList.length;
          const rankB = indexB !== -1 ? indexB : statusList.length;

          comparison = rankA - rankB;
        } else if (typeof valueA === 'number' && typeof valueB === 'number') {
          comparison = valueA - valueB;
        } else {
          comparison = valueA.toString().localeCompare(valueB.toString());
        }

        // If values are different, return comparison result
        if (comparison !== 0) {
          return descending ? -comparison : comparison;
        }
      }
      return 0; // if all rules yield equality, keep the original order
    });

    // set sorted data
    rows_clone[table].data = [columnRow, ...rowsToSort];
  }
  return rows_clone;
}

export const calculation = (calc, table, rows) => {
  let scalar = calc.scalar ? calc.scalar : 1;
  let result = 0;

  // console.log(calc.category == "__ALL");
  if (typeof calc === "number") {
    result = calc;
  }

  if (calc.value) {
    result = calc.value;
  } else if (calc.operation === "count") {
    // -1 added to it to account for header row
    result = rows[table].data
      .slice(1)
      .reduce(
        (accumulator, row) =>
          accumulator +
          (row[calc.xColumnIndex] === calc.category ||
          calc.category === "__ALL"
            ? 1
            : 0),
        0
      );
  } else if (calc.operation === "countif") {
    result = rows[table].data
      .slice(1)
      .reduce(
        (accumulator, row) =>
          accumulator +
          ((row[calc.xColumnIndex] === calc.category ||
            calc.category === "__ALL") &&
          row[calc.if_field] === calc.if_value
            ? 1
            : 0),
        0
      );
  } else if (calc.operation === "sum") {
    result = rows[table].data
      .slice(1)
      .filter(function(a) {
        return (!isNaN(Number(a[calc.field])))
      })
      .reduce(
        (accumulator, row) =>
          accumulator +
          (row[calc.xColumnIndex] === calc.category ||
          calc.category === "__ALL" &&
          !isNaN(Number(row[calc.field]))
            ? Number(row[calc.field])
            : 0),
        0
      );
  } else if (calc.operation === "sumif") {
    result = rows[table].data
      .slice(1)
      .filter(function(a) {
        return (!isNaN(Number(a[calc.field]))) && (isFinite(Number(a[calc.field])))
      })
      .reduce(
        (accumulator, row) =>
          accumulator +
          ((row[calc.xColumnIndex] === calc.category ||
            calc.category === "__ALL") &&
          row[calc.if_field] === calc.if_value
            ? Number(row[calc.field])
            : 0),
        0
      );
    //console.log(calc.category);
    //console.log(calc.if_field, calc.if_value, result);
    //console.log(rows[table].data);
  } else if (calc.operation === "addcols") {
    //console.log(calc)
    result = rows[table].data
      .slice(1)
      .filter(function(a) {
        return (!isNaN(Number(a[calc.field]))) && (isFinite(Number(a[calc.field])))
      })
      .reduce(
        (accumulator, row) =>
          accumulator +
          ((row[calc.xColumnIndex] === calc.category ||
            calc.category === "__ALL")
            ? Number(row[calc.field]) + Number(row[calc.agg_field])
            : 0),
        0
      );
    //console.log(calc.category);
    //console.log(calc.if_field, calc.if_value, result);
    //console.log(rows[table].data);
  } else if (calc.operation === "mean") {

    let length = rows[table].data
      .slice(1)
      .filter(function(a) {
        return (!isNaN(Number(a[calc.field]))) && (isFinite(Number(a[calc.field])))
      })
      .reduce(
        (accumulator, row) =>
          accumulator +
          (row[calc.xColumnIndex] === calc.category ||
          calc.category === "__ALL"
            ? 1
            : 0),
        0
      );

    result =
      rows[table].data
        .slice(1)
        .filter(function(a) {
          return (!isNaN(Number(a[calc.field]))) && (isFinite(Number(a[calc.field])))
        })
        .reduce(
          (accumulator, row) =>
            accumulator +
            (row[calc.xColumnIndex] === calc.category ||
            calc.category === "__ALL"
              ? Number(row[calc.field])
              : 0),
          0
        ) / length;
  } else if (calc.operation === "median") {
    let sorted = [...rows[table].data.slice(1)]
      .filter(function(a) {
        return (!isNaN(Number(a[calc.field]))) && (isFinite(Number(a[calc.field])))
      })
      .sort(function (a, b) {
        return a[calc.field] - b[calc.field];
      });

    if(sorted.length > 0) {
      let half = Math.floor(sorted.length / 2);
      result =
        sorted.length % 2
          ? Number(sorted[half][calc.field])
          : (Number(sorted[half - 1][calc.field]) + Number(sorted[half][calc.field])) / 2;
    } else {
      result = undefined;
    }
  } else if (calc.operation === "overlap_percent") {
    let result_overlap = rows[table].data
      .slice(1)
      .reduce(
        (accumulator, row) =>
          accumulator +
          (row[calc.field1] === calc.value1 &&
          row[calc.field2] === calc.value2
            ? 1
            : 0),
        0
      );
    result = rows[table].data
      .slice(1)
      .reduce(
        (accumulator, row) =>
          accumulator + (row[calc.field1] === calc.value1 ? 1 : 0),
        0
      );
    result = result_overlap / result;
  } else if (calc.operation === "aggregate") {
    result = rows[table].data
      .slice(1)
      .reduce(
        (accumulator, row) =>
          accumulator +
          (row[calc.agg_field] === calc.key ? Number(row[calc.agg_column]) : 0),
        0
      );
    if (result === 0 && calc.no_zero === true) {
      result = 0.00000001;
    }
  }

  return result * scalar;
};