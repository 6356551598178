import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ReplayIcon from "@mui/icons-material/Replay";
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from "react-redux";
import ListSubheader from "@mui/material/ListSubheader";

const dummySources = [
  {
    name: "quickbooks",
    sourceTables: [
      {
        uuid: "stab_012",
        name: "Table 1"
      },
      {
        uuid: "stab_345",
        name: "Table 2"
      },
      {
        uuid: "stab_678",
        name: "Table 3"
      },
      {
        uuid: "stab_901",
        name: "Table 4"
      },
      {
        uuid: "stab_234",
        name: "Table 5"
      },
      {
        uuid: "stab_567",
        name: "Table 6"
      },
      {
        uuid: "stab_890",
        name: "Table 7"
      },
      {
        uuid: "stab_112",
        name: "Table 8"
      },
      {
        uuid: "stab_223",
        name: "Table 9"
      },
    ]
  }
]

const getSelectRenderValue = (selectedUuid, sourceTables, requiredSourceTableUuids, selectedAppUuid, allApps, index) => {
  console.log(selectedUuid, index)
  const isNew = !allApps.find(app => app.uuid === selectedAppUuid).requiredSourceTables[index]
  const isEdited = allApps.find(app => app.uuid === selectedAppUuid).requiredSourceTables[index]?.uuid !== requiredSourceTableUuids[index]

  return (
    <span>
    {`${selectedUuid}`}&nbsp;&nbsp;
    <strong>{sourceTables[selectedUuid]?.tableName || "Unknown table"}</strong>
    <span style={{ color: "grey" }}>&nbsp;&nbsp;{sourceTables[selectedUuid]?.sourceName || "Unknown source"}&nbsp;&nbsp;{isNew ? <i>-&nbsp;&nbsp;new table</i> : isEdited ? <i>-&nbsp;&nbsp;edited</i> : ""}</span>
  </span>
  );//`${selectedUuid} - ${sourceTables[selectedUuid]?.tableName || "Unknown table"} - ${sourceTables[selectedUuid]?.sourceName || "Unknown source"}${isNew ? " - new table" : isEdited ? " - edited" : ""}`;
}

export const RequiredSourceTableInputs = ({ requiredSourceTableUuids, setRequiredSourceTableUuids, selectedAppUuid, allApps }) => {
  console.log("requiredSourceTableUuids:", requiredSourceTableUuids)
  const allSources = useSelector((state) => state.apps.sources)//dummySources//
  const getSourceTablesDict = (sources) => {
    const sourceTablesDict = {}
    sources.forEach(source => {
      source.sourceTables.forEach(table => {
        sourceTablesDict[table.uuid] = {
          sourceName: source.name,
          tableName: table.name
        }
      })
    })
    return sourceTablesDict
  }
  const sourceTables = getSourceTablesDict(allSources);
  const sortAllSources = (sources) => {
    const sourcesCopy = [...sources]
    // return sourcesCopy.sort((a, b) => a.name.localeCompare(b.name))
    // i need a basic alphabetical sort with 1 exception: 'generic' should always be first
    return sourcesCopy.sort((a, b) => {
      if (a.name === "generic") return -1
      if (b.name === "generic") return 1
      return a.name.localeCompare(b.name)
    })
  }
  const sortedAllSources = sortAllSources(allSources)
  // you should be able to remove a requiredSourceTableUuid
  // you should be able to add a requiredSourceTableUuid
  // - this is different from TagInputs because there will not be free text input - each will have to be selected from our available source tables
  const handleTableChange = (value, index) => {
    const newTableUuids = [...requiredSourceTableUuids]
    newTableUuids[index] = value
    setRequiredSourceTableUuids(newTableUuids)
  }

  const handleTableSelect = (event, index) => {
    const selectedValue = event.target.value;
    console.log("Selected Value: ", selectedValue, " at index:", index);
    handleTableChange(selectedValue, index)
    //event.target.value = '';//reset dropdown to empty selection
  }

  const handleTableReset = (index) => {
    const newTableUuids = [...requiredSourceTableUuids]
    newTableUuids[index] = allApps.find(app => app.uuid === selectedAppUuid).requiredSourceTables[index] ? allApps.find(app => app.uuid === selectedAppUuid).requiredSourceTables[index].uuid : ""
    setRequiredSourceTableUuids(newTableUuids)
  }

  const removeTable = (index) => {
    const newTableUuids = [...requiredSourceTableUuids]
    newTableUuids.splice(index, 1)
    setRequiredSourceTableUuids(newTableUuids)
  }

  const addTable = () => {
    const newTableUuids = [...requiredSourceTableUuids]
    if (selectedAppUuid && allApps.find(app => app.uuid === selectedAppUuid).requiredSourceTables.length > requiredSourceTableUuids.length) {
      newTableUuids.push(allApps.find(app => app.uuid === selectedAppUuid).requiredSourceTables[newTableUuids.length])
    } else {
      newTableUuids.push("")
    }
    setRequiredSourceTableUuids(newTableUuids)
  }


  return selectedAppUuid ? (
    <Grid item xs={12} container spacing={2}>
      {requiredSourceTableUuids.map((tableUuid, index) => (
        <Grid item xs={12} key={index} container spacing={1} alignItems={"center"}>
          <Grid item xs={0.75}>
            Table {index + 1}
          </Grid>
          <Grid item xs={10.25}>
            <FormControl sx={{ width: "100%" }}>
              <Select
                value={tableUuid} // Always set to an empty string
                onChange={(event) => handleTableSelect(event, index)}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === '') {
                    return <span style={{ color: "grey" }}><em>Select a table</em></span>;
                  }
                  return getSelectRenderValue(selected, sourceTables, requiredSourceTableUuids, selectedAppUuid, allApps, index)
                }}
                error={tableUuid === ""}
              >
                <MenuItem value="" sx={{ color: "grey !important", pointerEvents: "none" }}><em>Select a source table</em></MenuItem>
                {sortedAllSources.map((source) => {
                  const sortedSourceTables = [...source.sourceTables].sort((a, b) => a.name.localeCompare(b.name));
                  return [
                    <ListSubheader key={source.name + "_header"}>{source.name}</ListSubheader>,
                    ...sortedSourceTables.map((table, index) => (
                      <MenuItem
                        value={table.uuid}
                        key={source.name + "_table_" + index}
                        sx={requiredSourceTableUuids.includes(table.uuid) ? { color: "grey !important", pointerEvents: "none" } : {}}
                      >
                        {table.uuid}&nbsp;&nbsp;<strong>{table.name}</strong>
                      </MenuItem>
                    ))
                  ];
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={0.5}>
            <IconButton
              onClick={() => handleTableReset(index)}
            >
              <ReplayIcon />
            </IconButton>
          </Grid>
          <Grid item xs={0.5}>
            <IconButton
              onClick={() => removeTable(index)}
            >
              <RemoveIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      {selectedAppUuid && (
        <Grid item xs={12} container spacing={1} alignItems={"center"} justifyContent={"flex-end"}>
          <Grid item>
            Add table
          </Grid>
          <Grid item xs={0.5}>
            <IconButton
              onClick={() => addTable()}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  ) : null;
}

export default RequiredSourceTableInputs;