import { useState, useEffect } from "react";
import DirectRequest from "./DirectRequest";
import * as Constants from "../../Constants";
import { useDispatch, useSelector } from "react-redux";
import {cleanAlerts} from "./AlertsRequest";
import {setSysAdminAlerts, setSysAdminActiveAlerts } from "../../store/alertsSysAdminSlice";

export const sortSysAdminAlerts = (alerts) => {
  // sorts alerts by DATE -> MONEY SAVED -> APP NAME (aka: 'alert type') -> DESCRIPTION
  return [...alerts].sort((a, b) => {
    // sort by date
    const aDate = a.flagFirstOccurrenceTimestamp.split('/');
    const bDate = b.flagFirstOccurrenceTimestamp.split('/');
    const dateCompare = bDate[2] - aDate[2] || bDate[0] - aDate[0] || bDate[1] - aDate[1];

    if (dateCompare !== 0) {
      // If dates are different
      return dateCompare;
    } else {
      // If dates are the same, sort by moneySaved
      const moneySavedCompare = parseFloat(b.moneySaved) - parseFloat(a.moneySaved);
      if (moneySavedCompare !== 0) {
        return moneySavedCompare;
      } else {
        // If moneySaved amounts are the same, sort by appName
        const appNameCompare = a.appNameDisplay.localeCompare(b.appNameDisplay);
        if (appNameCompare !== 0) {
          // If appName are different
          return appNameCompare;
        } else {
        // If appName are the same, sort by flagDescription
          return a.flagDescription.localeCompare(b.flagDescription);
        }
      }
    }
  });
};

const InitialAlertsSysAdminRequest = () => {
  const dispatch = useDispatch();
  const isSysAdmin = useSelector((state) => state.role.isSysAdmin);
  const isCaptured = useSelector((state) => state.alertsSysAdmin.isCaptured);
  const companyUuid = useSelector((state) => state.alertsSysAdmin.company.uuid);

  const [alertsDict, setAlertsDict] = useState({
    alertsArgs: null,
    resolvedAlertsArgs: null,
    alerts: [],
    alertsCaptured: false,
    resolvedAlerts: [],
    resolvedAlertsCaptured: false
  });

  useEffect(() => {
    if (companyUuid && !isCaptured) {
      console.log("setting local AlertsSysAdminRequest args state")
      setAlertsDict((prevAlertsDict) => ({
        ...prevAlertsDict,
        alertsArgs: { url: Constants.SERVER_SYSADMIN_GET_COMPANY_ALERTS_URL + companyUuid },
        resolvedAlertsArgs: { url: Constants.SERVER_SYSADMIN_GET_COMPANY_RESOLVED_ALERTS_URL + companyUuid }
      }));
    }
  }, [companyUuid, isCaptured]);

  useEffect(() => {
    if (alertsDict.alertsCaptured && alertsDict.resolvedAlertsCaptured) {
      console.warn("sysAdmin alerts", alertsDict.alerts, "resolvedAlerts", alertsDict.resolvedAlerts);
      dispatch(setSysAdminAlerts({alerts: alertsDict.alerts, resolvedAlerts: alertsDict.resolvedAlerts}));
      // console.log("resetting local AlertsSysAdminRequest state");
      setAlertsDict({ alertsArgs: null, resolvedAlertsArgs: null, alerts: [], alertsCaptured: false, resolvedAlerts: [], resolvedAlertsCaptured: false });
    }
  }, [alertsDict]);

  const handleAlerts = (res) => {
    if (res && res.alerts && res.alerts.length > 0) {
      const cleanedAlerts = sortSysAdminAlerts(cleanAlerts(res.alerts));
      setAlertsDict((prevAlertsDict) => ({ ...prevAlertsDict, alerts: cleanedAlerts, alertsCaptured: true}));
    } else {
      setAlertsDict((prevAlertsDict) => ({ ...prevAlertsDict, alerts: [], alertsCaptured: true}));
    }
  }

  const handleResolvedAlerts = (res) => {
    if (res && res.alerts && res.alerts.length > 0) {
      const cleanedAlerts = sortSysAdminAlerts(cleanAlerts(res.alerts));
      setAlertsDict((prevAlertsDict) => ({ ...prevAlertsDict, resolvedAlerts: cleanedAlerts, resolvedAlertsCaptured: true}));
    } else {
      setAlertsDict((prevAlertsDict) => ({ ...prevAlertsDict, resolvedAlerts: [], resolvedAlertsCaptured: true}));
    }
  };

  // dummyAlerts logic for SysAdminAlertFeed visual testing
  // const dummyAlerts = useSelector((state) => state.dummyAlerts.alerts);
  // useEffect(() => {
  //   if (Constants.USE_DUMMY_DATA && !isCaptured) {
  //     const cleanedDummyAlerts = sortAlerts(cleanAlerts(dummyAlerts));
  //     const cleanedDummyResolvedAlerts = [];
  //     dispatch(setSysAdminAlerts({alerts: cleanedDummyAlerts, resolvedAlerts: cleanedDummyResolvedAlerts}));
  //   }
  // }, [isCaptured, dummyAlerts])

  return isCaptured || !isSysAdmin ? null : (
    <>
      <DirectRequest
        requestArgs={alertsDict.alertsArgs}
        afterProcess={handleAlerts}
        handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("AlertsRequest alerts error", err)}
        handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("AlertsRequest alerts catch error", err)}
      />
      <DirectRequest
        requestArgs={alertsDict.resolvedAlertsArgs}
        afterProcess={handleResolvedAlerts}
        handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("AlertsRequest resolvedAlerts error", err)}
        handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("AlertsRequest resolvedAlerts catch error", err)}
      />
    </>
  )
}

const ActiveAlertsSysAdminRequest = () => {
  const dispatch = useDispatch();
  const isSysAdmin = useSelector((state) => state.role.isSysAdmin);
  const isCaptured = useSelector((state) => state.alertsSysAdmin.isCaptured);
  const companyUuid = useSelector((state) => state.alertsSysAdmin.company.uuid);

  const [sysAdminAlertsArgs, setSysAdminAlertsArgs] = useState({ url: Constants.SERVER_SYSADMIN_GET_COMPANY_ALERTS_URL + companyUuid });

  const handleAlerts = (res) => {
    if (res && res.alerts && res.alerts.length > 0) {
      const cleanedAlerts = sortSysAdminAlerts(cleanAlerts(res.alerts));
      dispatch(setSysAdminActiveAlerts({alerts: cleanedAlerts}));
    } else {
      dispatch(setSysAdminActiveAlerts({alerts: []}));
    }
  }

  return (isCaptured || !isSysAdmin) ? null : (
    <DirectRequest
      requestArgs={sysAdminAlertsArgs}
      afterProcess={handleAlerts}
      handleError={(err) => console.log("AlertsRequest alerts error", err)}
      handleCatchError={(err) => console.log("AlertsRequest alerts catch error", err)}
    />

  )
};

export const AlertsSysAdminRequest = () => {
  const isSysAdmin = useSelector((state) => state.role.isSysAdmin);
  const isReady = useSelector((state) => state.alertsSysAdmin.isReady);

  return isSysAdmin ? (
    <>
      {!isReady && <InitialAlertsSysAdminRequest />}
      {isReady && <ActiveAlertsSysAdminRequest />}
    </>
  ) : null
};

export default AlertsSysAdminRequest;