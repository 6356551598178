import Grid from "@material-ui/core/Grid";
import { useSelector } from 'react-redux';
import Typography from "@mui/material/Typography";
import React from "react";
import { useTheme } from "@mui/material/styles";
import Config from "./Config";

const SelectedApp = () => {
  const theme = useTheme();
  const selectedAppUuid = useSelector(state => state.alertConfigsSysAdmin.selectedAppUuid);
  const appsSysAdmin = useSelector(state => state.appsSysAdmin.list);
  const app = appsSysAdmin.find(app => app.uuid === selectedAppUuid);
  return selectedAppUuid ? (
    <Grid item xs={12}>
      {/*  fullName, description, impact here */}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="p"><strong>{app.fullName}</strong></Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="p">{app.description}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="p" sx={{ color: theme.palette.primary.main }}><i>{app.tags.find((tag) => tag.includes("impact")).split("__")[1]}</i></Typography>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
}

const AlertConfigsLoader = () => {
  const selectedAppUuid = useSelector(state => state.alertConfigsSysAdmin.selectedAppUuid);
  const alertConfigsCaptured = useSelector(state => state.alertConfigsSysAdmin.alertConfigsCaptured);
  const isUpdating = useSelector(state => state.alertConfigsSysAdmin.isUpdating);
  return selectedAppUuid && !alertConfigsCaptured && !isUpdating ? (
    <Grid item xs={12}>
      <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: "5rem" }}>
        <Grid item xs={12} style={{textAlign: "center", alignItems: "center"}}>
          <Typography sx={{marginBottom: "1rem"}}>Getting alert configurations<span className="bouncing-dots"><span
            className="dot" style={{ marginLeft: "2px"}}>.</span><span className="dot" style={{ marginLeft: "2px"}}>.</span><span className="dot" style={{ marginLeft: "2px"}}>.</span></span></Typography>
          <div className="lds-grid-small">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
}

export const AlertConfigs = () => {
  const alertConfigs = useSelector(state => state.alertConfigsSysAdmin.alertConfigs);
  return (
    <Grid item xs={12} container spacing={3}>
      <SelectedApp/>
      <AlertConfigsLoader/>
      {alertConfigs.length > 0 && alertConfigs.map((config, index) => {
        return(
          <Config
            key={index}
            index={index}
          />
        )
      })}
    </Grid>
  )
}

export default AlertConfigs