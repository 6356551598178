import mixpanel from 'mixpanel-browser';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHasSignedIn } from "../../store/mixpanelSignInSlice";
import {user_blacklist} from "../../Constants";

export default function MixpanelSignIn() {
  const dispatch = useDispatch();
  const mixpanelInitialized = useSelector((state) => state.mixpanel.initialized);
  const mixpanelIdentified = useSelector((state) => state.mixpanel.identified);
  // const user = useSelector((state) => state.role.email);
  const isCaptured = useSelector((state) => state.role.isCaptured);
  const isInternal = useSelector((state) => state.role.isInternal);
  const mixpanelSignedIn = useSelector((state) => state.mixpanelSignIn.hasSignedIn);
  useEffect(() => {
    if (mixpanelInitialized && isCaptured && mixpanelIdentified && !mixpanelSignedIn) {
      // if (!user_blacklist[user]) {
      //   mixpanel.track("Signed In");
      // }
      if (!isInternal) {
        mixpanel.track("Signed In");
      }
      dispatch(setHasSignedIn(true));
    }
  }, [mixpanelInitialized, mixpanelIdentified, isCaptured, isInternal, mixpanelSignedIn]);
  return null;
}