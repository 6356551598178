import { createSlice } from "@reduxjs/toolkit";

export const emailNotificationSlice = createSlice({
  name: "emailNotification",
  initialState: {
    loginNotificationSent: false,
  },
  reducers: {
    setLoginNotificationSent: (state, action) => {
      // console.log("setLoginNotificationSent -> action.payload", action.payload);
      state.loginNotificationSent = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoginNotificationSent } = emailNotificationSlice.actions;

export default emailNotificationSlice.reducer;