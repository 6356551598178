import React, { useState, useEffect } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {useTheme} from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import { SiteLanguage } from "../../../Constants";
import { setFilters, defaultFilterPayload } from "../../../store/dashboardFiltersSlice";
import { useSelector, useDispatch } from "react-redux";

/*
TOGGLE HAS BEEN REMOVED FOR NOW - I couldn't find an example in a live dashboard - will confirm with Vik 8/29
 const trigger_toggle = (targets, linked_tables, label, event) => {
    if(props.toggle) {
      if(props.linked_variable && props.updateDashboardDict) {
        props.updateDashboardDict(props.linked_variable, label);
      }

      props.toggle(targets, linked_tables, event);
    }

    return true
  }
useEffect(() => {
    if(props.toggle && props.linked_variable) {
      props.updateDashboardDict(props.linked_variable, props.toggle_items[0].label);
    }
  }, []);
{props.type === "toggle" && <FormControl>
  <FormLabel id="demo-radio-buttons-group-label">
    {props.title}
  </FormLabel>
  <RadioGroup
    row={props.bool ? true : false}
    aria-labelledby="demo-radio-buttons-group-label"
    defaultValue={JSON.stringify(props.toggle_items[0].fields[0])}
    name="radio-buttons-group"
    size={"small"}
  >
    {props.toggle_items.map((item, index) =>
      (<FormControlLabel key={"label-" + index} value={JSON.stringify(item.fields[0])} control={<Radio />} label={item.label} onClick={trigger_toggle.bind(this, item.fields, props.linked_tables, item.label)} />)
    )}
  </RadioGroup>
</FormControl>}

props.hasData is no longer being watched - props.items comes in on first grid render before any filters are set
- props.items set locally to useState - which means it will have all initial options
- props.hasData should really only be applied to display elements like tables and charts - filtering inputs should not have to worry about it
{!props.hasData && (
          <FormControlLabel
            value={"All"}
            control={
              <Radio
                size={"small"}
                disableRipple
                sx={{
                  paddingLeft: '10px', // Align first Radio with FormLabel
                  paddingRight: '5px', // Bring label closer to Radio
                  '& .MuiSvgIcon-root': {
                    fontSize: 18, // Reduce icon size
                  },
                }}
              />
            }
            label={<Typography variant={"p"} sx={{ color: theme.typography.missingData.color }}>{SiteLanguage.MissingData}</Typography>}
            disabled={true}
          />
        )}
 */

function FDRadioGroupV2(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [items, setItems] = useState(props.items);
  const [isDate, setIsDate] = useState(props.date_config ? props.date_config.type : undefined);
  let field = props.field;
  const value = useSelector((state) => state.dashboardFilters.filtersInputs[props.objectName]?.value ?? props.flagFilterInput ?? (props.noAll ? props.items[0] : "All"))

  useEffect(() => {
    if(props.flagFilterComponentName !== undefined && props.flagFilterInput !== undefined) {
      dispatch(setFilters(defaultFilterPayload(props.objectName, field, props.flagFilterInput, isDate !== undefined ? isDate : "regular", props.sourceTable, props.additionalTables, undefined)))
    }
  }, [props.flagFilterComponentName, props.flagFilterInput]);

  const filter = (field, target, filterType, mainTable, additionalTables, event) => {
    dispatch(setFilters(defaultFilterPayload(props.objectName, field, target, filterType, mainTable, additionalTables)));
  }

  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label" sx={{ marginTop: "-6px" }}>
        {props.title}
      </FormLabel>
      <RadioGroup
        row={!!props.bool}
        aria-labelledby="demo-radio-buttons-group-label"
        value={value}
        name="radio-buttons-group"
        size={"small"}
        sx={{ marginTop: "-6px" }}
      >
        {!props.noAll && props.noAll !== true && (
          <FormControlLabel
            value={"All"}
            control={
              <Radio
                size={"small"}
                disableRipple
                sx={{
                  paddingLeft: '10px', // Align first Radio with FormLabel
                  paddingRight: "5px", // Bring label closer to Radio
                  '& .MuiSvgIcon-root': {
                    fontSize: 18, // Reduce icon size
                  },
                  '&:hover': {
                    backgroundColor: 'transparent', // Remove hover background
                  }
                }}
              />
            }
            label={"All"}
            onClick={filter.bind(this, field, "All", isDate !== undefined ? isDate : "regular", props.sourceTable, props.additionalTables)}
          />
        )}
        {items.map((item, index) => (
          <FormControlLabel
            key={"label-" + index}
            value={item}
            control={
              <Radio
                size={"small"}
                disableRipple
                sx={{
                  paddingRight: "5px", // Bring label closer to Radio
                  '& .MuiSvgIcon-root': {
                    fontSize: 18, // Reduce icon size
                  },
                  '&:hover': {
                    backgroundColor: 'transparent', // Remove hover background
                  }
                }}
              />
            }
            label={props.bool && props.bool === true ? (item === 1 ? "Y" : "N") : item}
            onClick={filter.bind(this, field, item, isDate !== undefined ? isDate : "regular", props.sourceTable, props.additionalTables)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default FDRadioGroupV2;