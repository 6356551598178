import TextField from '@mui/material/TextField';
import { updateConfigDisplayNote } from '../../../../../store/alertConfigsSysAdminSlice';
import { useDispatch, useSelector } from "react-redux";

const getDisplayNoteLabel = (newNote, paramDisplayNote) => {
  if (newNote === null) {
    return "Note"
  } else if (newNote !== paramDisplayNote) {
    return (
      <span>Note - <i>edited</i></span>
    )
  }
}

export const DisplayNoteInput = ({ index }) => {
  const paramDisplayNote = useSelector((state) => state.alertConfigsSysAdmin.alertConfigs[index].paramDisplayNote);
  const newNote = useSelector((state) => state.alertConfigsSysAdmin.alertConfigs[index].newNote);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    const newNote = event.target.value;
    if (newNote === paramDisplayNote) {
      dispatch(updateConfigDisplayNote({ newNote: null, index: index }));
    } else {
      dispatch(updateConfigDisplayNote({ newNote: newNote, index: index }));
    }
  }
  return (
    <TextField
      id="outlined-multiline-static"
      label={getDisplayNoteLabel(newNote, paramDisplayNote)}
      multiline
      rows={2}
      value={newNote !== null ? newNote : paramDisplayNote}
      onChange={handleChange}
      InputLabelProps={{
        shrink: true, // Always show the label above the input
      }}
      sx={{ width: "100%"}}
    />
  )
}

export default DisplayNoteInput