import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import store from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import logo from "./assets/Wiselayer.png";
import * as Constants from "./Constants";

async function enableMocking() {
  if (!Constants.USE_DUMMY_DATA) {
    return
  }

  const { worker } = await import('./mocks/browser')

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start()
}


let persistor = persistStore(store);

const IndexErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "10rem",
          }}
        >
          <img src={logo} alt="Wiselayer" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "4rem",
          }}
        >
          <p>
            An <strong>error</strong> has occurred...
          </p>
          <p style={{ fontSize: "larger" }}>
            Please <strong>refresh</strong> the page to continue.
          </p>
        </div>
      </div>
    </div>
  );
};

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <ErrorBoundary FallbackComponent={IndexErrorFallback}>
//           <App />
//         </ErrorBoundary>
//       </PersistGate>
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

enableMocking().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundary FallbackComponent={IndexErrorFallback}>
            <App />
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
})


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
