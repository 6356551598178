import { useSelector } from "react-redux";
import { Grid, Box } from "@mui/material";
import TocIcon from "@mui/icons-material/Toc";

const containerStyle = {
  backgroundColor: "white !important",
  borderRadius: "10px",
  padding: "20px",
  paddingLeft: "20px !important",
  paddingTop: "20px !important",
  position: "relative",
}

const marginBottomContainerStyle = {
  ...containerStyle,
  marginBottom: "8px"
}

const LinkConfigApp = ({ app, subscribedApps, length, index }) => {

  const getLinkConfigTitle = (tags) => {
    const linkConfigTag = tags.find(tag => tag.includes("link_config__"));
    return linkConfigTag.split("__")[1].split("_").join(" ");
  }

  const handleClick = () => {
    localStorage.setItem("dashboardState", JSON.stringify({
      customerSubscriptionUuid: subscribedApps.find((subbedApp) => subbedApp.developerAppUuid === app.uuid).uuid,
      viewApp: true,
      //flagFilterComponentName: row.flagFilterComponentName,
      //flagFilterInput: row.flagFilterInput,
      //thisAlert: row,
      thisDevApp: app,
      //companyUuid: companyUuid, // will be needed for sysadmin
      //currentTab: currentTab,
      //readyForCustomer: readyForCustomer
    }));
    window.open(`${window.location.origin}/redirect/${app.uuid}`, '_blank');
  }

  const getContainerStyle = () => {
    if (index !== length - 1) {
      return marginBottomContainerStyle
    } else {
      return containerStyle;
    }
  }

  return (
    <Grid 
      item 
      xs={12} 
      container 
      alignItems={"center"}
      sx={getContainerStyle()}
    >
      {/* Hover Box */}
      <Box
        sx={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          right: '10px',
          bottom: '10px',
          borderRadius: '10px',
          boxShadow: 'none',
          transition: 'box-shadow 0.2s ease-in-out',
          '&:hover': {
            boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.2)',
          },
          zIndex: 0, // Place behind the content
          cursor: "pointer",
        }}
        onClick={handleClick}
      />

      {/* Content */}
      <TocIcon sx={{ marginRight: "10px", fontSize: "32px" }} />
      <span 
        style={{ 
          fontSize: "1.25rem", 
          fontWeight: 650, 
          lineHeight: 1.6,
        }}
      >
        {getLinkConfigTitle(app.tags)}
      </span>
    </Grid>
  )
}

export const LinkConfigApps = () => {
  const postSetupComplete = useSelector(state => state.role.postSetupComplete); // def return null if !postSetupComplete
  const apps = useSelector(state => state.apps.filteredList);
  const subscribedApps = useSelector(state => state.appsSubscriptions.list);
  const linkConfigApps = apps.filter(app => {
    let linkConfigFlag = false;
    app.tags.forEach((tag) => {
      if (tag.includes("link_config__")) {
        linkConfigFlag = true
      }
    })
    let subscribedAppFlag;
    subscribedAppFlag = subscribedApps.some((subbedApp) => subbedApp.developerAppUuid === app.uuid);
    return linkConfigFlag && subscribedAppFlag;
  })
  return postSetupComplete && linkConfigApps?.length > 0 ? (
    <Grid container spacing={1} sx={{ width: "750px" }}>
      {linkConfigApps.map((app, index) => (
        <LinkConfigApp
          key={app.uuid + "_link"}
          app={app}
          subscribedApps={subscribedApps}
          length={linkConfigApps.length}
          index={index}
        />
      ))}
    </Grid>
  ) : null;
}

export default LinkConfigApps;