import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTool, setSelectedAppUuid, setIsDirty, resetModalState, resetDashboardEditor } from "../../../store/dashboardEditorSlice";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

export const UnsavedChangesModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showModal = useSelector((state) => state.dashboardEditor.modalState.open);
  const intendedPath = useSelector((state) => state.dashboardEditor.modalState.intendedPath);
  const intendedSelection = useSelector((state) => state.dashboardEditor.modalState.intendedSelection);
  const intendedTool = useSelector((state) => state.dashboardEditor.modalState.intendedTool);

  const handleCancel = () => {
    dispatch(resetModalState());
  }
  const handleContinue = () => {
    if (intendedPath) {
      navigate(intendedPath)
      dispatch(resetDashboardEditor())
    } else {
      if (intendedSelection) {
        dispatch(setSelectedAppUuid(intendedSelection));
      }
      if (intendedTool) {
        dispatch(setActiveTool(intendedTool));
      }
      dispatch(setIsDirty(false));
      dispatch(resetModalState());
    }
  }

  return (
    <Modal
      open={showModal}
    >
      <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", borderRadius: "10px", bgcolor: "background.paper", boxShadow: 24, p: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert severity={"warning"} sx={{ borderRadius: "10px" }}>You have unsaved changes. Are you sure you want to continue?</Alert>
          </Grid>
          <Grid item xs={6}>
            {/*<button onClick={handleCancel}>Cancel</button>*/}
            <Button variant={"outlined"} color={"error"} onClick={handleCancel} sx={{ width: "100%"}}>Cancel</Button>
          </Grid>
          <Grid item xs={6}>
            {/*<button onClick={handleContinue}>Continue</button>*/}
            <Button variant={"contained"} onClick={handleContinue} sx={{ width: "100%"}}>Continue</Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
};

export default UnsavedChangesModal;