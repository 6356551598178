import React, {useState, useEffect} from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { setFilters, defaultFilterPayload } from "../../../store/dashboardFiltersSlice";
import { useSelector, useDispatch } from "react-redux";

/*
OLD FDSelectGroup
<FormControl sx={{ minWidth: "50%", maxWidth: "calc(100% - 2rem)", display: "flex", flexDirection: "row", alignItems: "center" }} size="small">
	<FormLabel
		id="demo-simple-select-label"
		sx={{
			marginRight: "1rem",        // Add space between label and select
			whiteSpace: "nowrap",       // Prevent label from wrapping
		}}
		// sx={{
		// 	marginBottom: "0.5em",
		// }}
	>
		{props.title}
	</FormLabel>
	<Select
		labelId="demo-simple-select-label"
		id="demo-simple-select"
		value={value}
		onChange={handleChange}
		disabled={!props.hasData}
		sx={{ flex: 1 }}              // Allow the select to take up remaining space
	>
		{!props.hasData && <MenuItem value={"All"}><Typography variant={"p"}>{SiteLanguage.MissingData}</Typography></MenuItem>}
  	{props.hasData && !props.noAll && props.noAll !== true && <MenuItem value={value} onClick={filter.bind(this, field, "All", props.sourceTable, props.additionalTables)}>All</MenuItem>}
  	{props.hasData && items.map((item, index) =>
  		(<MenuItem key={index + item} value={item} onClick={filter.bind(this, field, item, props.sourceTable, props.additionalTables)}>{props.bool && props.bool === true ? (item === 1 ? "Y" : "N") : item}</MenuItem>)
  	)}
  </Select>
</FormControl>
*/

function FDSelectGroupV2(props) {
  const dispatch = useDispatch();

  const value = useSelector((state) =>
    state.dashboardFilters.filtersInputs[props.objectName]?.value ?? props.flagFilterInput ?? props.default ?? "All"
  );
  const [items, setItems] = useState(props.items);
  const [isDate, setIsDate] = useState(props.date_config ? props.date_config.type : undefined);
  let field = props.field;

  const filter = (field, target, table, additionalTables, event) => {
    dispatch(setFilters(defaultFilterPayload(props.objectName, field, target, isDate !== undefined ? isDate : "regular", table, additionalTables, event)))
  }

  useEffect(() => {
    if(props.flagFilterComponentName !== undefined && props.flagFilterInput !== undefined) {
      dispatch(setFilters(defaultFilterPayload(props.objectName, field, props.flagFilterInput, isDate !== undefined ? isDate : "regular", props.sourceTable, props.additionalTables, undefined)));
    } else {
      dispatch(setFilters(defaultFilterPayload(props.objectName, field, props.default ?? "All", isDate !== undefined ? isDate : "regular", props.sourceTable, props.additionalTables, undefined)))
    }
  }, [props.flagFilterComponentName, props.flagFilterInput]);

  return (
      <FormControl
        sx={{
          width: "100%",
        }}
      >
        <TextField
          select
          label={props.title}
          value={value}
          size="small"               			// Set the size to small for compactness
          sx={{										// Allow the select to take up remaining space
            '& .MuiInputBase-root': {
              padding: '4px 8px',        	// Shrink the padding inside the input
              fontSize: '0.875rem',      	// Reduce the font size
              minHeight: '32px',         	// Set a minimum height
              height: '32px',            	// Set a fixed height
            },
            '& .MuiSelect-select': {
              padding: '4px 8px',        	// Shrink padding inside the select
            },
            '& .MuiInputLabel-root': {
              fontSize: '0.875rem',       // Match the input font size
              lineHeight: '1.5',          // Adjust line height to prevent cutoff
              transform: 'translate(14px, 8px) scale(1)', // Adjust label position
            },
            '& .MuiInputLabel-shrink': {
              transform: 'translate(14px, -8px) scale(0.75)', // Adjust shrink position
            },
          }}
        >
          {!props.noAll && props.noAll !== true && (
            <MenuItem
              value={value}
              onClick={filter.bind(this, field, "All", props.sourceTable, props.additionalTables)}
            >
              All
            </MenuItem>
          )}
          {items.map((item, index) => (
              <MenuItem
                key={index + item}
                value={item}
                onClick={filter.bind(this, field, item, props.sourceTable, props.additionalTables)}
              >
                {props.bool && props.bool === true ? (item === 1 ? "Y" : "N") : item}
              </MenuItem>
            ))}
        </TextField>
      </FormControl>
  )
}

export default FDSelectGroupV2;