import { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Constants from "../../Constants";
import { setClusterState } from "../../store/clusterStateSlice";

function ListenForClusterState(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.sse) {
      return;
    }
    props.sse.addEventListener(Constants.CommandType.clusterState, function (m) {
      const clusterState = JSON.parse(m.data);
      dispatch(setClusterState({ state: clusterState.state }));
    });
  }, [props.sse]);

  return null;
}

export default ListenForClusterState;
