import { useState, useEffect, Fragment } from "react";
import ListenForDataSync from "./ListenForDataSync";
import ListenForAppRun from "./ListenForAppRun";
import DirectRequest from "../requests/DirectRequest";
import * as Constants from "../../Constants";
import PreviewAppSSEResponse from "./PreviewAppSSEResponse";
import ListenForCompleteDataSync from "./ListenForCompleteDataSync";
import ListenForClusterState from "./ListenForClusterState";

function SSE(props) {
  const [sse, setSSE] = useState();

  const [checkAuthentication, setCheckAuthentication] = useState();

  useEffect(() => {
    const newSSE = new EventSource(Constants.SERVER_SSE_URL, {
      withCredentials: true,
    });
    // console.log("new sse created");
    setSSE(newSSE);
  }, []);

  const createSSE = () => {
    if (sse?.readyState !== 2) return;
    const newSSE = new EventSource(Constants.SERVER_SSE_URL, {
      withCredentials: true,
    });
    // console.log("new sse created");
    setSSE(newSSE);
  };

  useEffect(() => {
    if (!sse) {
      return;
    }

    sse.onerror = (e) => {
      // console.log("sse error", e);
      setCheckAuthentication({});
      sse.close();
      return;
    };

    if (sse.readyState === 2) {
      setCheckAuthentication({});
      sse.close();
      return;
    }

    return () => {
      sse.close();
    };
  }, [sse]);

  return Constants.USE_DUMMY_DATA ? null : (
    <Fragment>
      <DirectRequest
        requestArgs={checkAuthentication}
        afterProcess={createSSE}
      />

      <PreviewAppSSEResponse sse={sse} />
      <ListenForDataSync sse={sse} />
      <ListenForAppRun sse={sse} />
      <ListenForCompleteDataSync sse={sse} />
      <ListenForClusterState sse={sse} />
    </Fragment>
  );
}

export default SSE;
