import React, {useState, useEffect} from 'react';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

function FDInfo(props) {

	return (
			<Grid container spacing={2}>
				<Grid item xs={12} className="table-label">
					<Alert severity="info" sx={{ marginRight: "2em" }} variant="outlined">
					  <AlertTitle>{props.title}</AlertTitle>
					  {props.items.map((item, index) => 
					  		(<React.Fragment key={"info-item-" + index}><Typography variant="p"><strong>{item.title}</strong>: {item.description}</Typography>{item.source && <Typography variant="p" onClick={() => {window.open(item.source); return true}} sx={{ "marginLeft": "2px", "textDecoration": "underline" }}>(source)</Typography>}<br/></React.Fragment>)
					  )}
					</Alert>
				</Grid>
			</Grid>
	)
}

export default FDInfo;