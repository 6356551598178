import { useSelector, useDispatch } from 'react-redux';
import {addCustomerSub, customerSubsSelectors, removeCustomerSub} from "../../store/customerSubsSlice";
import React, {useEffect} from "react";
import CustomerSubListenerRequest from "../../API/requests/CustomerSubListenerRequest";

const CustomerSubManager = ({ customerSubscription }) => {
  const dispatch = useDispatch();
  const customerSub = useSelector((state) => customerSubsSelectors.selectById(state, customerSubscription?.uuid));

  // create customerSub if no customerSub and subscription is not complete
  useEffect( () => {
    if (customerSub === undefined && customerSubscription && customerSubscription.uuid && !customerSubscription.isSetupComplete) {
      console.log("CustomerSubManager -- creating customerSub for ", customerSubscription.uuid);
      dispatch(addCustomerSub(customerSubscription.uuid));
    }
  }, [customerSub, customerSubscription]);

  // remove customerSub if customerSub and subscription is complete
  useEffect(() => {
    if (customerSub && customerSubscription.isSetupComplete) {
      dispatch(removeCustomerSub(customerSubscription.uuid));
    }
  }, [customerSub, customerSubscription])

  // trigger listener if customerSub and !isListening
  return customerSub && !customerSub.isListening ? (
    <CustomerSubListenerRequest
      customerSubscription={customerSubscription}
    />
  ) : null;
};

export const CustomerSubsManager = () => {
  const subscribedApps = useSelector((state) => state.appsSubscriptions.list);
  // console.log("subscribedApps", subscribedApps)
  return (
    <>
      {subscribedApps.length > 0 && subscribedApps.map((subscription) => {
        return <CustomerSubManager key={subscription.uuid} customerSubscription={subscription} />
      })}
    </>
  )
}

export default CustomerSubsManager;