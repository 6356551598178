import * as Constants from "../../../../Constants";
import iso8601Timestamp from "../../../../helpers/iso8601Timestamp";
import { config_options } from "../../../../store/dashboardConfigsSlice";

export const getPrimaryKeyValueMap = (row, primaryKeyFieldList) => {
  const tempPrimaryKeyValueMap = {};
  primaryKeyFieldList.forEach((primaryKey) => {
    tempPrimaryKeyValueMap[primaryKey] = row[primaryKey];
  })
  // console.log("tempPrimaryKeyValueMap", tempPrimaryKeyValueMap)
  return tempPrimaryKeyValueMap;
}

export const getLinkedTableForeignValueMaps = (row, primaryKeyFieldList, linkedTableForeignFieldMap) => {
  //Map<String, List<String>> linkedTableForeignFieldMap
  const orderedPrimaryValues = primaryKeyFieldList.map((primaryKey) => row[primaryKey]);
  const tempLinkedTableForeignValueMap = {};
  Object.entries(linkedTableForeignFieldMap).forEach(([tableName, foreignFieldMap]) => {
    const tempForeignValueMap = {};
    foreignFieldMap.forEach((field, index) => {
      tempForeignValueMap[field] = orderedPrimaryValues[index];
    })
    tempLinkedTableForeignValueMap[tableName] = tempForeignValueMap;
  })
  // console.log("tempLinkedTableForeignValueMap", tempLinkedTableForeignValueMap);
  return tempLinkedTableForeignValueMap;
}

const getDisplayConfigOptionValue = (configAction, newConfigValue) => {
  console.log("getDisplayConfigOptionValue - configAction", configAction, "newConfigValue", newConfigValue);
  if (configAction === config_options.create_entry) {
    const { entryUuid } = newConfigValue;
    return entryUuid;
  } else {
    return newConfigValue;
  }
}

export const generateDisplayConfigUpdateArgs = (companyUuid, newConfigValue, configAction, row, displayConfigUuid, primaryKeyFieldList, linkedTableForeignFieldMap) => {
  // TODO: needs to handle optional parameters if configAction === config_options.create_entry
  const tempBody = {
    displayConfigTableUuid: displayConfigUuid,
    primaryKeyValueMap: getPrimaryKeyValueMap(row, primaryKeyFieldList),
    displayConfigOptionName: configAction,
    displayConfigOptionValue: getDisplayConfigOptionValue(configAction, newConfigValue),// NO CONFIG ROW YET - means false, FLIP TO TRUE
  }
  if (linkedTableForeignFieldMap) {
    tempBody["linkedTableForeignValueMaps"] = getLinkedTableForeignValueMaps(row, primaryKeyFieldList, linkedTableForeignFieldMap);
  }
  if (configAction === config_options.create_entry) {
    const { rowConfigSetupInfo } = newConfigValue
    tempBody["rowConfigSetupInfo"] = rowConfigSetupInfo;
  }
  const tempUpdateArgs = {
    url: companyUuid ? Constants.SERVER_SYSADMIN_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROW_URL + companyUuid : Constants.SERVER_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROW_URL,// TODO - add
    method: "POST",
    body: JSON.stringify(tempBody)
  }
  return tempUpdateArgs;
}

const getNotificationBody = (newConfigValue, configAction, row, user, displayConfigUuid, devAppUuid) => {
  const rowInBody = Object.entries(row).map(([key, value]) => `${key}: ${value}<br/>`).join("");
  if (configAction === config_options.create_entry) {
    const { entryUuid } = newConfigValue;
    const deleted = entryUuid === "NOT_SET";
    return `<strong>${user}</strong> ${deleted ? "deleted entry" : entryUuid ? "updated entry " + entryUuid : "created entry"}<br/><br/>displayConfigUuid: ${displayConfigUuid}<br/><br/>appUuid: ${devAppUuid}<br/><br/>row:<br/><br/>${rowInBody}<br/>on ${window.location.origin}<br/><br/>`
  } else {
    return `<strong>${user}</strong> set ${configAction} to ${newConfigValue}<br/><br/>display config uuid: ${displayConfigUuid}<br/><br/>app uuid: ${devAppUuid}<br/><br/>row:<br/><br/>${rowInBody}<br/>on ${window.location.origin}<br/><br/>`
  }
}

const getNotificationSubject = (newConfigValue, configAction, user) => {
  if (configAction === config_options.create_entry) {
    const { entryUuid } = newConfigValue;
    const deleted = entryUuid === "NOT_SET";
    return `${user} ${deleted ? "deleted entry" : entryUuid ? "updated entry " + entryUuid : "created entry"} (${window.location.origin} - ${iso8601Timestamp()})`
  } else {
    return `${user} set ${configAction} to ${newConfigValue} (${window.location.origin} - ${iso8601Timestamp()})`
  }
}

export const generateDisplayConfigUpdateNotification = (newConfigValue, configAction, row, user, displayConfigUuid, devAppUuid) => {
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": getNotificationBody(newConfigValue, configAction, row, user, displayConfigUuid, devAppUuid),
      "subject": getNotificationSubject(newConfigValue, configAction, user),
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}