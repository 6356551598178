import { useState, useEffect } from "react";
import DirectRequest from "./DirectRequest";
import {useDispatch, useSelector} from "react-redux";
import { setAppsAndSources, setAppsError, setSourcesError } from "../../store/appsSlice";
import * as Constants from "../../Constants";

export const sortApps = (apps) => {
  return apps.sort((a, b) => a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase()));
}

const AppsRequest = () => {
  const dispatch = useDispatch();

  const isCaptured = useSelector((state) => state.apps.isCaptured);

  const [apps, setApps] = useState({ list: [], isCaptured: false });
  const [sources, setSources] = useState({ list: [], isCaptured: false });

  const [appsArgs, setAppsArgs] = useState({ url: Constants.SERVER_CUS_APPS_URL });
  const [sourcesArgs, setSourcesArgs] = useState({ url: Constants.SERVER_SOURCE_DEFINITIONS_URL });

  const handleApps = (res) => {
    if (res) {
      const filteredAlertsApps = res.filter((app) => app.tags.includes("fdAlert"));
      const sortedApps = sortApps(filteredAlertsApps);
      setApps({ list: sortedApps, isCaptured: true });
    }
  };

  const handleSources = (res) => {
    if (res) {
      const filteredSources = res.filter((source) => source.definitionOrigin === "fivetran");
      setSources({ list: filteredSources, isCaptured: true });
    }
  };

  const handleAppsError = (errType, err) => {
    const errorMessage = `There was a ${errType} error while fetching your notifications. Please refresh the page.`;
    setApps({ list: [], isCaptured: true });
    dispatch(setAppsError(errorMessage));
  };

  const handleSourcesError = (errType, err) => {
    const errorMessage = `There was a ${errType} error while fetching your sources. Please refresh the page.`;
    setSources({ list: [], isCaptured: true });
    dispatch(setSourcesError(errorMessage));
  }

  useEffect(() => {
    if (apps.isCaptured && sources.isCaptured) {
      console.log("all apps", apps.list);
      dispatch(setAppsAndSources({ list: apps.list, sources: sources.list }));
    }
  }, [apps, sources])

  useEffect(() => {
    if (isCaptured) {
      // console.log("resetting local AppsRequest state");
      setApps({ list: [], isCaptured: false });
      setSources({ list: [], isCaptured: false });
    }
  }, [isCaptured])

  return (
    <>
      {!isCaptured && (
        <>
          <DirectRequest
            requestArgs={appsArgs}
            afterProcess={handleApps}
            handleError={(err) => handleAppsError(Constants.requestErrorTypes.server, err)}
            handleCatchError={(err) => handleAppsError(Constants.requestErrorTypes.network, err)}
          />
          <DirectRequest
            requestArgs={sourcesArgs}
            afterProcess={handleSources}
            handleError={(err) => handleSourcesError(Constants.requestErrorTypes.server, err)}
            handleCatchError={(err) => handleSourcesError(Constants.requestErrorTypes.network, err)}
          />
        </>
      )}
    </>
  )
}

export default AppsRequest;