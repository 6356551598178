import {useSelector} from "react-redux";
import React, {useState, useEffect} from "react";
import * as Constants from "../../../Constants";
import DirectRequest from "../../../API/requests/DirectRequest";
import ImpactBar from "../../ImpactBar/ImpactBar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ReplayIcon from "@mui/icons-material/Replay";
import SendIcon from '@mui/icons-material/Send';
import SearchIcon from '@mui/icons-material/Search';
import Box from "@mui/material/Box";
import { CustomAppTooltip } from "../TagEditor/TagEditor";
import { SelectApp } from "../TagEditor/TagEditor";
import {refreshAppsSysAdmin} from "../../../store/appsSysAdminSlice";
import { useDispatch } from "react-redux";
import RequiredSourceTableInputs from "./RequiredSourceTableInputs";
import { setIsDirty } from "../../../store/dashboardEditorSlice";

const checkRequiredSourceTableUuids = (selectedAppUuid, allApps, requiredSourceTableUuids) => {
  // make sure the selectedAppUuid App's requiredSourceTables are NOT the same as requiredSourceTableUuids in order to send an update request
  // this func will return a boolean and is consumed by the update button's disabled prop - i.e. if true, the button is disabled
  if (!selectedAppUuid) return true;
  const currentApp = allApps.find(app => app.uuid === selectedAppUuid);
  if (!currentApp) return true;
  if (requiredSourceTableUuids.some(uuid => !uuid)) return true;
  return currentApp.requiredSourceTables.map(table => table.uuid).join("") === requiredSourceTableUuids.join("");
}

const SelectAppBar = ({ allApps, selectedAppUuid, handleReset, handleUpdate, requiredSourceTableUuids }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    let isDirty = false;
    if (selectedAppUuid) {
      const currentApp = allApps.find(app => app.uuid === selectedAppUuid);
      if (requiredSourceTableUuids.some(uuid => !uuid)) {
        isDirty = true;
      }
      if (currentApp) {
        if (currentApp.requiredSourceTables.map(table => table.uuid).join("") !== requiredSourceTableUuids.join("")) {
          isDirty = true;
        }
      }
    }
    dispatch(setIsDirty(isDirty));
  }, [selectedAppUuid, allApps, requiredSourceTableUuids])
  return (
    <Grid item xs={12}>
      <ImpactBar
        component={
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item>
              <SelectApp selectedAppUuid={selectedAppUuid} allApps={allApps} />
            </Grid>
            <Grid item>
              <Box sx={{height: "100%", display: "flex", alignItems: "center" }}>
                <CustomAppTooltip
                  title={selectedAppUuid ? <pre>{JSON.stringify(allApps.find(app => app.uuid === selectedAppUuid), null, 2)}</pre> : ""}
                >
                  <SearchIcon sx={!selectedAppUuid ? { fontSize: "2rem", fill: "grey" } : { fontSize: "2rem" }}/>
                </CustomAppTooltip>
              </Box>
            </Grid>
            <Grid item>
              <Button
                onClick={handleReset}
                sx={{ borderRadius: "0px !important"}}
                variant={"outlined"}
                startIcon={<ReplayIcon />}
                disabled={!selectedAppUuid}
              >
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant={"contained"}
                sx={{ borderRadius: "0px !important"}}
                startIcon={<SendIcon />}
                onClick={handleUpdate}
                disabled={checkRequiredSourceTableUuids(selectedAppUuid, allApps, requiredSourceTableUuids)}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        }
      />
    </Grid>
  )
}

export const RequiredSourceTableEditor = () => {
  const dispatch = useDispatch();
  const allApps = useSelector((state) => state.appsSysAdmin.list);//dummyApps//
  const selectedAppUuid = useSelector((state) => state.dashboardEditor.selectedAppUuid);
  const [requiredSourceTableUuids, setRequiredSourceTableUuids] = useState([]);

  const [updateAppTablesArgs, setUpdateAppTablesArgs] = useState(null);

  useEffect(() => {
    if (selectedAppUuid) setRequiredSourceTableUuids(JSON.parse(JSON.stringify(allApps.find((app) => app.uuid === selectedAppUuid).requiredSourceTables.map((table) => table.uuid))));
  }, [allApps, selectedAppUuid])

  const handleReset = () => {
    setRequiredSourceTableUuids(JSON.parse(JSON.stringify(allApps.find((app) => app.uuid === selectedAppUuid).requiredSourceTables.map((table) => table.uuid))));
  };

  const handleUpdate = () => {
    const body = {
      developerAppUuid: selectedAppUuid,
      requiredSourceTableUuids: requiredSourceTableUuids
    }
    const updateRequestArgs = {
      url: Constants.SERVER_SYSADMIN_UPDATE_DEVAPP_URL,
      method: "POST",
      body: JSON.stringify(body)
    }
    setUpdateAppTablesArgs(updateRequestArgs);
  }
  const refreshSysAdminApps = () => {
    dispatch(refreshAppsSysAdmin());
  }

  return (
    <>
      <DirectRequest
        requestArgs={updateAppTablesArgs}
        afterProcess={refreshSysAdminApps}
      />
      <Grid container spacing={2}>
        <SelectAppBar
          allApps={allApps}
          handleReset={handleReset}
          handleUpdate={handleUpdate}
          selectedAppUuid={selectedAppUuid}
          requiredSourceTableUuids={requiredSourceTableUuids}
        />
        <RequiredSourceTableInputs
          requiredSourceTableUuids={requiredSourceTableUuids}
          setRequiredSourceTableUuids={setRequiredSourceTableUuids}
          selectedAppUuid={selectedAppUuid}
          allApps={allApps}
        />
      </Grid>
    </>
  )
}

export default RequiredSourceTableEditor;