import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeCommand } from "../../store/commandsSlice";
import { setPreviewData } from "../../store/previewAppSlice";
import GenericDelayedResponseHandler from "./GenericDelayedResponseHandler";
import * as Constants from "../../Constants";

const generateAlertsFromTables = (data) => {
  if (!data || data.length <= 1) {
    // no alerts (only "columns" array at 0 index)
    return [];
  } else {
    const headers = data[0];
    const rows = data.slice(1);

    return rows.map(row => {
      let obj = {};
      headers.forEach((header, index) => {
        obj[header] = row[index];
      });
      return obj;
    });
  }
}

function PreviewAppSSEResponse(props) {
  const dispatch = useDispatch();
  const previewCommand = useSelector((state) => state.previewApp.commandUuid);

  function finishPreviewApp(response, commandUuid) {
    // TODO: what happens if an error is returned?
    if (previewCommand !== commandUuid) {
      dispatch(removeCommand(commandUuid));
      return;
    }
    const previewPayload = {};
    previewPayload.tables = response.response.tables;
    previewPayload.dashboardJson = response.response.dashboardJson;
    const previewAlerts = generateAlertsFromTables(response.response.tables.fdalerts?.data);
    console.log("PreviewAppSSEResponse -> previewAlerts", previewAlerts);
    previewPayload.alerts = previewAlerts;
    dispatch(setPreviewData(previewPayload));
    dispatch(removeCommand(commandUuid));
    // clear previewPercent and previewPercentTimestamp from localStorage
    if (localStorage.getItem("previewPercent")) {
      console.log("PreviewAppSSEResponse -> Removing previewPercent and previewPercentTimestamp")
      localStorage.removeItem("previewPercent");
      localStorage.removeItem("previewPercentTimestamp");
    }
  }

  return (
    <Fragment>
      <GenericDelayedResponseHandler
        sse={props.sse}
        handlerName={Constants.CommandType.runMarketplaceApp}
        finishResponse={finishPreviewApp}
      />
    </Fragment>
  );
}

export default PreviewAppSSEResponse;
