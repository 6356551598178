import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import {styled, useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import * as Constants from '../../../Constants';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CheckIcon from '@mui/icons-material/Check';
import Email_Logo from "../../../assets/logos/Email_Logo.png";
import PDF_Logo from "../../../assets/logos/PDF_Logo.png";

const StretchedArrow = styled(ArrowRightAltIcon)(({ scale }) => ({
  fontSize: 20,
  transform: scale, // Adjust these values to control horizontal (4) and vertical (1) scaling
  color: "rgba(0, 0, 0, 0.40)",
}));

const SmallArrow = styled(ArrowRightIcon)(() => ({
  fontSize: 20,
  color: "rgba(0, 0, 0, 0.40)",
}))

const getImg = (source) => {
  if (source === "email") {
    return {
      src: Email_Logo,
      alt: "Email logo"
    }
  }
  if (source === "pdf") {
    return {
      src: PDF_Logo,
      alt: "PDF logo"
    }
  }
  return {
    src: Constants.supported_integrations.find((potentialIntegration) => potentialIntegration.name === source).logo || null,
    alt: Constants.supported_integrations.find((potentialIntegration) => potentialIntegration.name === source).displayName + " logo" || "No source found"
  }
}

const innerGridStyle = { paddingTop: "0px !important", paddingLeft: "0px !important"}

const FlowElementTitle = ({ title, enabled, isXXSmallScreen }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Typography
        variant="p"
        sx={{
          fontSize: isXXSmallScreen ? ".5rem" : ".7rem",
          lineHeight: isXXSmallScreen ? ".7rem" : ".9rem",
          marginRight: "8px",
          marginLeft: "8px",
          textAlign: "center",
          filter: enabled ? 'grayscale(100%)' : 'grayscale(100%) opacity(30%)'
        }}
      >{title}</Typography>
    </Box>
  )
}

const FlowElementSource = ({ source, enabled, isXSmallScreen }) => {
  return (
    <Box sx={{
      height: '40px',
      display: 'flex',
      justifyContent: "center",
      alignItems: 'center',
      filter: enabled ? 'grayscale(100%)' : 'grayscale(100%) opacity(30%)'
    }}>
      <img
        src={getImg(source).src}
        alt={getImg(source).alt}
        style={{
          maxHeight: "100%",
          maxWidth: isXSmallScreen ? '40px' : '80px',
          objectFit: 'contain'
        }}
      />
    </Box>
  )
}

const FlowElementArrow = ({ isLargeScreen, isSmallScreen }) => {
  const getArrowScale = () => {
    if (isLargeScreen) return "scale(4, 1)";
    return "scale(2, 1)";
  };
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      {!isSmallScreen && <StretchedArrow scale={getArrowScale()}/>}
      {isSmallScreen && <SmallArrow/>}
    </Box>
  )
}

const FlowElementSubtext = ({ enabled, isXSmallScreen }) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      {enabled && (
        <CheckIcon
          sx={{
            fontSize: isXSmallScreen ? ".8rem" : "1.2rem",
          }}
        />
      )}
      {!enabled && (
        <Typography
          variant={"p"}
          sx={{
            marginLeft: "8px",
            marginRight: "8px",
            textAlign: "center",
            fontSize: isXSmallScreen ? "6px" : "10px",
            lineHeight: isXSmallScreen ? "8px" : "12px",
            fontWeight: 500,
            color: "grey !important"
          }}
        >Email WiseLayer to connect</Typography>
      )}
    </Box>
  )
}

export const FDFlow = (props) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up(1430));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1110));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down(900));
  const isXXSmallScreen = useMediaQuery(theme.breakpoints.down(770));

  const rows = props.step_list;

  const n = rows.length;
  const x = 12 / (n + (n - 1) / 8);
  const y = x / 8;

  return (
    <Grid container spacing={2} sx={{ width: "100%", marginBottom: "6px", marginTop: "2px", marginLeft: "0px", paddingBottom: "6px", paddingTop: "10px", backgroundColor: theme.palette.secondary.main, borderRadius: "10px" }}>
      {rows.map((row, index) => (
        <React.Fragment key={index + "_0"}>
          <Grid item xs={x} sx={innerGridStyle}>
            <FlowElementTitle
              title={row.data_type}
              enabled={row.enabled}
              isXXSmallScreen={isXXSmallScreen}
            />
          </Grid>
          {index < rows.length - 1 && (
            <Grid item xs={y} sx={innerGridStyle}>
              {/* Empty grid for spacing */}
            </Grid>
          )}
        </React.Fragment>
      ))}
      {rows.map((row, index) => (
        <React.Fragment key={index + "_1"}>
          <Grid item xs={x} sx={innerGridStyle}>
            <FlowElementSource
              source={row.data_source}
              enabled={row.enabled}
              isXSmallScreen={isXSmallScreen}
            />
          </Grid>
          {index < rows.length - 1 && (
            <Grid item xs={y} sx={innerGridStyle}>
              <FlowElementArrow
                isSmallScreen={isSmallScreen}
                isLargeScreen={isLargeScreen}
              />
            </Grid>
          )}
        </React.Fragment>
      ))}
      {rows.map((row, index) => (
        <React.Fragment key={index + "_2"}>
          <Grid item xs={x} sx={innerGridStyle}>
            <FlowElementSubtext
              enabled={row.enabled}
              isXSmallScreen={isXSmallScreen}
            />
          </Grid>
          {index < rows.length - 1 && (
            <Grid item xs={y} sx={innerGridStyle}>
              {/* Empty grid for spacing */}
            </Grid>
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default FDFlow;
