import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../Constants";

export const builders = ["Editor", "Upload"];

export const dataSources = ["generic"].concat(Constants.supported_integrations.map(integration => integration.name))//["generic", "brex", "netsuite_suiteanalytics", "quickbooks"];

export const initialState = {
  selectedFile: null,
  script: "",
  loading: false,
  activeBuilder: builders[0],
  activeDataSource: dataSources[0],
  sandboxDestination: "Default",
  sandboxDestinations: null,
};

export const appBuilderSlice = createSlice({
  name: "appBuilder",
  initialState,
  reducers: {
    setSandboxDestinations: (state, action) => {
      // component in resource wrapper gathers sandbox destinations (preview data sets) for each supported integration
      console.log("setSandboxDestinations -> action.payload", action.payload);
      state.sandboxDestinations = action.payload;
    },
    setActiveBuilder: (state, action) => {
      console.log("setActiveBuilder -> action.payload", action.payload);
      state.activeBuilder = action.payload.activeBuilder;
      state.selectedFile = null;
      state.script = "";
    },
    setScript: (state, action) => {
      console.log("setScript -> action.payload", action.payload);
      state.script = action.payload.script;
    },
    setFile: (state, action) => {
      console.log("setFile -> action.payload", action.payload);
      state.selectedFile = action.payload.selectedFile;
    },
    setActiveDataSource: (state, action) => {
      console.log("setActiveDataSource -> action.payload", action.payload);
      state.activeDataSource = action.payload.activeDataSource;
      state.sandboxDestination = "Default";
    },
    setSandboxDestination: (state, action) => {
      console.log("setSandboxDestination -> action.payload", action.payload);
      state.sandboxDestination = action.payload.sandboxDestination;
    },
  },
});

export const {
  setSandboxDestinations,
  setActiveBuilder,
  setScript,
  setFile,
  setActiveDataSource,
  setSandboxDestination,
} = appBuilderSlice.actions;
export default appBuilderSlice.reducer;
