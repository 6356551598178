import React from 'react';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import { useTheme } from '@mui/material/styles';
import {SiteLanguage} from "../../../Constants";
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';

HighchartsExporting(Highcharts);
NoDataToDisplay(Highcharts);

function FDLine(props) {
	const theme = useTheme();
	const chartTheme = theme.palette.chartTheme;
	Highcharts.setOptions({
		colors: props.colors
			? props.colors
			: [chartTheme.chart1.color, chartTheme.chart2.color, chartTheme.chart3.color, chartTheme.chart4.color, chartTheme.chart5.color, chartTheme.chart6.color, chartTheme.chart7.color, chartTheme.chart8.color],
		xAxis: {
			labels: {
				style: {
					fontFamily: theme.typography.fontFamily,
					color: theme.typography.color,
				}
			}
		},
		yAxis: {
			labels: {
				style: {
					fontFamily: theme.typography.fontFamily,
					color: theme.typography.color,
				}
			}
		},
		legend: {
			itemStyle: {
				fontFamily: theme.typography.fontFamily,
				color: theme.typography.color,
			}
		},
		plotOptions: {
			series: {
				dataLabels: {
					style: {
						fontFamily: theme.typography.fontFamily,
						color: theme.typography.color,
					}
				}
			}
		},
		tooltip: {
			style: {
				fontFamily: theme.typography.fontFamily,
				color: theme.typography.color,
			}
		},
	});

	const formatter = (props.formatting && props.formatting.currency ? "$" : "") + "{y:,." + (props.formatting && props.formatting.digits ? props.formatting.digits : 0) + "f}" + (props.formatting && props.formatting.percent ? "%" : "");

	const options = {
	    chart: {
	        height: props.height !== undefined ? props.height : 400
	    },
	    title: {
	        text: null
	    },
	    xAxis: {
	       	title: {
	            enabled: props.yAxisLabel !== undefined ? props.yAxisLabel : false,
	            text: props.yAxisLabel !== undefined ? props.yAxisLabel : false
	        }
	    },
	    yAxis: {
	        min: props.min !== undefined ? props.min : null,
	        title: {
	            enabled: props.yAxisLabel !== undefined ? props.yAxisLabel : false,
	            text: props.yAxisLabel !== undefined ? props.yAxisLabel : false
	        },
	    	labels: {
	    		format: formatter.replace("{y:", "{text:")
	    	},
	        gridLineWidth: 0.5
	    },
	    legend: {
	        enabled: false
	    },
	    credits: {
	    	enabled: false
	    },
	    tooltip: {
	    	enabled: props.tooltip !== undefined ? props.tooltip : true,
	    	pointFormat: "{series.name}: <b>" + formatter.replace("{y:", "{point.y:") + "</b><br/>"
	    },
	    plotOptions: {
	    	series: {
	    		color: theme.palette.primary.main,
	            dataLabels: {
	            	enabled: true,
	            	format: formatter,
	            	style: {
	            		fontWeight: 100
	            	}
	            },
	            dataSorting: {
			        enabled: props.sorting !== undefined ? props.sorting : false
			    }
	    	},
	    	line: {
	    		label: {
	    			connectorAllowed: false,
	    		},
	    		pointStart: props.start ? props.start : 1000
	    	},
	    },
	    series: props.hasData ? props.series : [],
			lang: {
				noData: SiteLanguage.MissingData
			},
			noData: {
				style: {
					fontWeight: theme.typography.p.fontWeight,
					fontSize: theme.typography.h6.fontSize,
					color: theme.typography.missingData.color,
				}
			},
	};

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Typography variant="h6">{props.title}</Typography>
			</Grid>
			<Grid item xs={12}>
				<HighchartsReact
				    highcharts={Highcharts}
				    options={options}
				  />
			</Grid>
	    </Grid>
	)
}

export default FDLine;