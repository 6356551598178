import { createSlice } from '@reduxjs/toolkit';
//activeTools: ["Tags", "Script", "Source Tables"],
export const dashboardEditorSlice = createSlice({
  name: "dashboardEditor",
  initialState: {
    activeTool: "Tags",
    selectedAppUuid: null,
    isDirty: false,
    modalState: {
      open: false,
      intendedPath: null,
      intendedSelection: null,
      intendedTool: null
    }
  },
  reducers: {
    setActiveTool: (state, action) => {
      console.log("setActiveTool - action.payload:", action.payload)
      state.activeTool = action.payload;
    },
    setSelectedAppUuid: (state, action) => {
      console.log("setSelectedAppUuid - action.payload:", action.payload)
      state.selectedAppUuid = action.payload;
    },
    setIsDirty: (state, action) => {
      console.log("setIsDirty - action.payload:", action.payload)
      state.isDirty = action.payload;
    },
    setModalState: (state, action) => {
      console.log("setModalState - action.payload:", action.payload)
      state.modalState.open = action.payload.open;
      if (action.payload.intendedPath) {
        state.modalState.intendedPath = action.payload.intendedPath;
      }
      if (action.payload.intendedSelection) {
        state.modalState.intendedSelection = action.payload.intendedSelection;
      }
      if (action.payload.intendedTool) {
        state.modalState.intendedTool = action.payload.intendedTool;
      }
    },
    resetModalState: (state) => {
      state.modalState = {
        open: false,
        intendedPath: null,
        intendedSelection: null,
        intendedTool: null
      }
    },
    resetDashboardEditor: (state) => {
      state.activeTool = "Tags";
      state.selectedAppUuid = null;
      state.isDirty = false;
      state.modalState = {
        open: false,
        intendedPath: null,
        intendedSelection: null,
        intendedTool: null
      }
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setActiveTool,
  setSelectedAppUuid,
  setIsDirty,
  setModalState,
  resetModalState,
  resetDashboardEditor
} = dashboardEditorSlice.actions;

export default dashboardEditorSlice.reducer;