import { createSlice } from "@reduxjs/toolkit";

export const mixpanelTrackSlice = createSlice({
  name: "mixpanelTrack",
  initialState: { events: [] },
  reducers: {
    addMixpanelEvent: (state, action) => {
      /*
      sample payload:
      { event: "Viewed App", additionalProperties: { uuid: "1234" } }
      */
      state.events = state.events.concat([action.payload]);
    },
    removeMixpanelEvent: (state, action) => {
      state.events = state.events.slice(1);
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMixpanelEvent, removeMixpanelEvent } = mixpanelTrackSlice.actions;

export default mixpanelTrackSlice.reducer;