import {useDispatch, useSelector} from "react-redux";
import {setDisplayConfigsToggleState, displayConfigTogglePayload} from "../../../../store/dashboardFiltersSlice";
import { config_options} from "../../../../store/dashboardConfigsSlice";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, {useMemo} from "react";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import {dashboardConfigsSelectors, resetConfig} from "../../../../store/dashboardConfigsSlice";
import * as Constants from "../../../../Constants";
import {setAlertNotificationArgs} from "../../../../store/alertsNotificationSlice";
import iso8601Timestamp from "../../../../helpers/iso8601Timestamp";
import Tooltip from "@mui/material/Tooltip";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const iconButtonStyle = {
  padding: "2px",
  marginLeft: "12px"
}

const getTooltipTitle = (displayConfigOption) => {
  if (displayConfigOption === config_options.suppress_alert) {
    return "Reset suppressed alerts";
  } else if (displayConfigOption === config_options.hide_row) {
    return "Reset hidden rows";
  } else {
    return "";
  }
}

const generateConfigResetNotification = (user, displayConfigUuid, displayConfigTableRows, devAppUuid) => {
  const rowUuidsInBody = displayConfigTableRows.map((row) => `${row.uuid}<br/>`).join("");
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `<strong>${user}</strong> reset display config ${displayConfigUuid}<br/><br/>reset display config table row uuids:<br/>${rowUuidsInBody}<br/>app: ${devAppUuid}<br/><br/>on ${window.location.origin}<br/><br/>`,
      "subject": `${user} reset display config ${displayConfigUuid} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}

const DisplayConfigResetButton = ({ displayConfigUuid, displayConfigOption, disableActions }) => {
  const dispatch = useDispatch();
  const dashboardUuid = useSelector(state => state.dashboard.uuid);
  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector((state) => state.role.isInternal);
  const displayConfigTableRows = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.displayConfigTableRows);
  const companyUuid = useSelector(state => state.dashboard.companyUuid);
  const handleReset = () => {
    if (displayConfigTableRows.length === 0) {
      return;
    }
    // create array of args for each displayConfigTableRow - set all displayConfigOption false in payloads
    // - need to modify displayConfigEntities to have resetArgs which will be array of reset args - needs to hook up to new ResetDisplayConfigRequest
    const tempResetArgsArray = [];
    displayConfigTableRows.forEach((displayConfigTableRow) => {
      const tempBody = {
        displayConfigTableUuid: displayConfigUuid,
        primaryKeyValueMap: displayConfigTableRow.primaryKeyValueMap,
        displayConfigOptionName: displayConfigOption,
        displayConfigOptionValue: false,// NO CONFIG ROW YET - means false, FLIP TO TRUE
      }
      if (displayConfigTableRow.linkedTableForeignValueMaps) {
        tempBody["linkedTableForeignValueMaps"] = displayConfigTableRow.linkedTableForeignValueMaps;
      }
      const tempUpdateArgs = {
        url: companyUuid ? Constants.SERVER_SYSADMIN_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROW_URL + companyUuid : Constants.SERVER_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROW_URL,// TODO - add
        method: "POST",
        body: JSON.stringify(tempBody)
      }
      tempResetArgsArray.push(tempUpdateArgs);
    })
    if (!isInternal && !companyUuid) {// companyUuid means sysAdmin viewing dashboard
      // console.log("configResetNotification", configUpdateNotification);
      const configResetNotification = generateConfigResetNotification(user, displayConfigUuid, displayConfigTableRows, dashboardUuid)
      dispatch(setAlertNotificationArgs(configResetNotification));
    }
    dispatch(resetConfig(displayConfigUuid, tempResetArgsArray));
  }
  return (displayConfigOption !== config_options.status_tracker && displayConfigOption !== config_options.create_entry && displayConfigOption !== config_options.send_email) ? (
    <Tooltip title={getTooltipTitle(displayConfigOption)}>
      <IconButton
        onClick={handleReset}
        sx={iconButtonStyle}
        disabled={disableActions}
      >
        <SettingsBackupRestoreIcon/>
      </IconButton>
    </Tooltip>
  ) : null;
}

const getLanguage = (displayConfigOption) => {
  // const toggleLanguage = toggleState ? "Show" : "Hide"
  if (displayConfigOption === config_options.hide_row) {
    return "Show hidden rows"
  } else if (displayConfigOption === config_options.suppress_alert) {
    return "Show suppressed alerts"
  }
}

const DisplayConfigToggle = ({ displayConfigOption, disableActions }) => {
  const dispatch = useDispatch();
  const toggleState = useSelector(state => state.dashboardFilters.displayConfigsToggleState[displayConfigOption]);
  // console.log("DisplayConfigToggle - displayConfigOption:", displayConfigOption, " toggleState:", toggleState);
  const handleToggle = () => {
    dispatch(setDisplayConfigsToggleState(displayConfigTogglePayload(displayConfigOption, !toggleState)))
  }

  return (displayConfigOption !== config_options.status_tracker && displayConfigOption !== config_options.create_entry && displayConfigOption !== config_options.send_email) ? (
    <FormControl>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormLabel style={{ marginRight: '2px', pointerEvents: 'none', color: "rgba(0, 0, 0, 0.6)" }}>
          {getLanguage(displayConfigOption)}
        </FormLabel>
        <Switch
          checked={!toggleState}
          onChange={handleToggle}
          size="small"
          disabled={disableActions}
        />
      </div>
    </FormControl>
  ) : null;
}

export const DisplayConfigToggles = ({ displayConfigUuid, displayConfigActionList, displayConfigSysAdminActionList, disableActions }) => {
  const companyUuid = useSelector(state => state.dashboard.companyUuid);
  // if sysAdminConfigAction && disableActions -> preview
  // if sysAdmin && companyUuid -> live sysadmin viewing
  const showSysAdminToggles = useMemo(() => {
    return !!(companyUuid || disableActions);
  }, [disableActions, companyUuid]);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {displayConfigActionList?.map((displayConfigOption, index) => {
        return (
          <DisplayConfigToggle key={"display_config_toggle_" + index} displayConfigOption={displayConfigOption} disableActions={disableActions}/>
        )
      })}
      {displayConfigActionList?.map((displayConfigOption, index) => {
        return (
          <DisplayConfigResetButton key={"display_config_reset_button_" + index} displayConfigOption={displayConfigOption} disableActions={disableActions} displayConfigUuid={displayConfigUuid}/>
        )
      })}
      {showSysAdminToggles && displayConfigSysAdminActionList?.map((displayConfigOption, index) => {
        return (
          <DisplayConfigToggle key={"display_config_sys_admin_toggle_" + index} displayConfigOption={displayConfigOption} disableActions={disableActions}/>
        )
      })}
      {showSysAdminToggles && displayConfigSysAdminActionList?.map((displayConfigOption, index) => {
        return (
          <DisplayConfigResetButton key={"display_config_reset_sys_admin_button_" + index} displayConfigOption={displayConfigOption} disableActions={disableActions} displayConfigUuid={displayConfigUuid}/>
        )
      })}
    </div>
  );
}

export default DisplayConfigToggles;