import * as Constants from "../../Constants";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import PushPinIcon from '@mui/icons-material/PushPin';
import ArchiveIcon from '@mui/icons-material/Archive';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Papa from 'papaparse';
import iso8601Timestamp from "../../helpers/iso8601Timestamp";

export const update_types = {
  RELEVANT: "RELEVANT",
  IRRELEVANT: "IRRELEVANT",
  PIN: "PIN",
  ARCHIVE: "ARCHIVE",
  AI: "AI",
}

/*
ALERT FORMAT:
    String developerAppUuid;
    String appName;
    String alertName;
    String flagSeverity;
    String flagDescription;
    String flagRowId;
    String flagFirstOccurrenceTimestamp; // mm/dd/yyyy

    Boolean flagAiRelevant;
    Boolean flagAiIrrelevant;
    String flagAiMessage;
    Boolean flagPin;
    Boolean flagArchive;
    Boolean hasBeenNotified;

    INTERNAL SERVER PROPERTY (will have separate endpoint for SysAdminAlertControls only):
    Boolean flagOverrideAndShowAnyway; (sysAdmin can hit endpoint to turn this on - will reset alert state from user perspective)
 */

export const generateAlertUpdateArgs = (row, updateType, message, companyUuid) => {
  const updateArgs = {
    url: companyUuid ? Constants.SERVER_SYSADMIN_POST_COMPANY_ALERT_UPDATE_URL + companyUuid : Constants.SERVER_USER_POST_ALERT_UPDATE_URL,
    method: "POST",
  }
  const genericUpdateBody = {
    uuid: row.uuid
  }
  if (updateType === update_types.RELEVANT) {
    // toggle relevant on or toggle relevant off + pin === false
    genericUpdateBody.flagAiRelevantStatus = row.flagAiRelevantStatus === "RELEVANT" ? "NOT_SET" : "RELEVANT";
    // Nov 1 - flagAiMessage no longer sent with relevant update
    // genericUpdateBody.flagAiMessage = companyUuid ? `SysAdmin: ${message ? message : "No message provided"}` : message ? message : null;
    genericUpdateBody.flagPin = false;
    // console.log("update args RELEVANT body", genericUpdateBody);
    updateArgs.body = JSON.stringify(genericUpdateBody);
  } else if (updateType === update_types.IRRELEVANT) {
    // toggle irrelevant on (w/ optional message) or toggle irrelevant off (set message to null) + archive/pin === false
    genericUpdateBody.flagAiRelevantStatus = row.flagAiRelevantStatus === "IRRELEVANT" ? "NOT_SET" : "IRRELEVANT";
    genericUpdateBody.flagAiMessage = message ? message : null;
    genericUpdateBody.flagPin = false;
    // console.log("update args IRRELEVANT body", genericUpdateBody);
    updateArgs.body = JSON.stringify(genericUpdateBody);
  } else if (updateType === update_types.PIN) {
    // toggle pin on or off
    genericUpdateBody.flagPin = !row.flagPin;
    // console.log("alert update args PIN body", genericUpdateBody);
    updateArgs.body = JSON.stringify(genericUpdateBody);
  } else if (updateType === update_types.ARCHIVE) {
    // Nov 1 - ARCHIVE currently not in use
    // toggle archive on or off + pin === false
    genericUpdateBody.flagArchive = !row.flagArchive;
    genericUpdateBody.flagPin = false;
    // console.log("alert update args ARCHIVE body", genericUpdateBody);
    updateArgs.body = JSON.stringify(genericUpdateBody);
  } else if (updateType === update_types.AI) {
    genericUpdateBody.flagAiMessage = message;
    genericUpdateBody.flagAiRelevantStatus = row.flagAiRelevantStatus;
    updateArgs.body = JSON.stringify(genericUpdateBody);
  }
  return updateArgs;
}

export const generateAlertMessagesUpdateArgs = (row, message, user) => {
  const tempArgs = {
    url: Constants.SERVER_POST_ALERT_MESSAGE_URL,
    method: "POST",
    body: JSON.stringify({
      alertUuid: row.uuid,
      developerAppUuid: row.developerAppUuid,
      alertLink: `${window.location.origin}/redirect/alert_message/${row.developerAppUuid}/${row.uuid}`,
      createdOn: iso8601Timestamp(),
      message: message,
      createdBy: user,
      appNameDisplay: row.appNameDisplay
    })
  }
  return tempArgs;
}

export const generateAlertUpdateNotificationArgs = (row, updateType, user) => {
  const sysAdminLink = `${window.location.origin}/redirect/${row.developerAppUuid}/${row.uuid}/${row.companyUuid}`
  const getSubjectText = (row, updateType) => {
    if (updateType === update_types.RELEVANT) {
      return `Alert ${row.flagAiRelevantStatus === "RELEVANT" ? "unread" : "read"}`;
    } else if (updateType === update_types.IRRELEVANT) {
      return `Alert ${row.flagAiRelevantStatus === "IRRELEVANT" ? "unremoved" : "removed"}`;
    }
  }
  const getUpdateText = (row, updateType) => {
    if (updateType === update_types.RELEVANT) {
      return `marked ${row.uuid} as ${row.flagAiRelevantStatus === "RELEVANT" ? "unread" : "read"}`;
    } else if (updateType === update_types.IRRELEVANT) {
      return `marked ${row.uuid} as ${row.flagAiRelevantStatus === "IRRELEVANT" ? "unremoved" : "removed"}`;
    }
  }
  const notificationArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `<strong>${user}</strong> ${getUpdateText(row, updateType)} on ${window.location.origin}<br/><br/>Alert:<br/>${row.appNameDisplay}<br/>${row.flagDescription}<br/><br/>SysAdmin Link:<br/>${sysAdminLink}<br/><br/>`,
      "subject": `${getSubjectText(row, updateType)} by ${user} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    }),
  }
  return notificationArgs;
}

export const generateAiMessageNotificationArgs = (row, message, user) => {
  const sysAdminLink = `${window.location.origin}/redirect/${row.developerAppUuid}/${row.uuid}/${row.companyUuid}`
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `Alert AI message from <strong>${user}</strong> on ${window.location.origin}<br/><br/>Alert:<br/>${row.appNameDisplay}<br/>${row.flagDescription}<br/><br/>Message:<br/>${message}<br/><br/>SysAdmin Link:<br/>${sysAdminLink}<br/><br/>`,
      "subject": `Alert AI message from ${user} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}

export const generateGeneralAiMessageNotificationArgs = (currentDate, currentType, currentTab, message, user) => {
  const currentTabMap = {
    'Main': 'Inbox',
    'Unread': 'Unread',
    'Resolved': 'Previous',
    'Irrelevant': 'Removed'
  }
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `General AI message from <strong>${user}</strong> on ${window.location.origin}<br/><br/>Discovered filter: ${currentDate || "Not applied"}<br/>Alert type: ${currentType || "All"}<br/>Tab (<i>Inbox, Unread, Previous, Removed</i>): ${currentTabMap[currentTab]}<br/><br/>Message:<br/>${message}<br/>`,
      "subject": `General AI message from ${user} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}

export const generateAiFixNotificationArgs = (row, user) => {
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `AI Fix request from <strong>${user}</strong> on ${window.location.origin}<br/><br/>Alert:<br/>${row.appNameDisplay}<br/>${row.flagDescription}<br/><br/>`,
      "subject": `AI Fix request from ${user} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}

export const generateAlertEmailNotificationArgs = (row, user) => {
  const sysAdminLink = `${window.location.origin}/redirect/${row.developerAppUuid}/${row.uuid}/${row.companyUuid}`
  const alertEmailArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `<strong>${user}</strong> emailed an alert on ${window.location.origin}<br/><br/>Alert:<br/>${row.appNameDisplay}<br/>${row.flagDescription}<br/><br/>Emailed Link:<br/>${window.location.origin}/redirect/${row.developerAppUuid}/${row.uuid}<br/><br/>SysAdmin Link:<br/>${sysAdminLink}<br/><br/>`,
      "subject": `Alert emailed by ${user} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return alertEmailArgs;
}

const csvToHtmlTable = (csv) => {
  // Use PapaParse to parse the CSV data
  const { data } = Papa.parse(csv, { skipEmptyLines: true });

  let html = '<table style="border-collapse: collapse; width: 100%; font-size: 10px;">';

  data.forEach((row, index) => {
    html += '<tr>';

    row.forEach((column) => {
      const cellStyle = `max-width: 400px; word-wrap: break-word; border: 1px solid grey; padding: 5px; ${index === 0 ? 'text-align: left;' : ''}`;
      const tag = index === 0 ? 'th' : 'td';
      html += `<${tag} style="${cellStyle}">${column.trim()}</${tag}>`;
    });

    html += '</tr>';
  });

  html += '</table>';
  return html;
}

export const generateExportNotificationArgs = (fileName, user, exportedCsv) => {
  // console.log("exportedCsv", exportedCsv);
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `<strong>${user}</strong> exported <strong>${fileName}</strong> from ${window.location.href}<br/><br/>${csvToHtmlTable(exportedCsv)}<br/><br/>`,
      "subject": `${user} exported ${fileName} from ${window.location.href} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}

export const generatePrimaryFeedToggleNotificationArgs = (value, user) => {
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `<strong>${user}</strong> toggled the <strong>${value}</strong> filter on ${window.location.href}<br/><br/>`,
      "subject": `${user} toggled the ${value} filter on ${window.location.href} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}

export const generateAlertTypeFilterNotificationArgs = (feedCategory, user) => {
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `<strong>${user}</strong> filtered by <strong>${feedCategory}</strong> alert type on ${window.location.href}<br/><br/>`,
      "subject": `${user} filtered by ${feedCategory} alert type on ${window.location.href} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}

export const generateDescriptionFilterNotificationArgs = (filterTextArray, user) => {
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `<strong>${user}</strong> searched by:<br/>${filterTextArray.map(text => `${text}<br/>`).join('')}<br/>`,
      "subject": `${user} searched by description on ${window.location.href} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}

export const generateAlertsTabSelectionNotificationArgs = (tab, user) => {
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `<strong>${user}</strong> selected the <strong>${tab}</strong> tab on ${window.location.href}<br/><br/>`,
      "subject": `${user} selected the ${tab} tab on ${window.location.href} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}

export const generateSettingsTabSelectionNotificationArgs = (path, user) => {
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `<strong>${user}</strong> selected the <strong>${path}</strong> tab on ${window.location.href}<br/><br/>`,
      "subject": `${user} selected the ${path} tab on ${window.location.href} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}

export const generateOpenedSummaryModalNotificationArgs = (user) => {
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `<strong>${user}</strong> opened the summary modal on ${window.location.href}<br/><br/>`,
      "subject": `${user} opened the summary modal on ${window.location.href} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}

export const generateSummaryCategorySelectedNotificationArgs = (user, category) => {
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `<strong>${user}</strong> selected the <strong>${category}</strong> summary modal category on ${window.location.href}<br/><br/>`,
      "subject": `${user} selected the ${category} summary modal category on ${window.location.href} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}

export const generateAlertFeedSelectedNotificationArgs = (user, feedCategory) => {
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `<strong>${user}</strong> selected the <strong>${feedCategory}</strong> summary modal feed category on ${window.location.href}<br/><br/>`,
      "subject": `${user} selected the ${feedCategory} summary modal feed category on ${window.location.href} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}

export const generateSysAdminAlertOverrideArgs = (row, companyUuid) => {
  console.log("SysAdmin alert override -- alert:", row);
  return {
    url: Constants.SERVER_SYSADMIN_POST_COMPANY_ALERT_OVERRIDE_URL + companyUuid,
    method: "POST",
    body: JSON.stringify({
      uuid: row.uuid,
      flagOverrideAndShowAnyway: true,
    }),
  }
};

export const generateSysAdminAlertReadyForCustomerArgs = (row, companyUuid) => {
  console.log("SysAdmin alert ready for customer -- alert:", row);
  return {
    url: Constants.SERVER_SYSADMIN_POST_COMPANY_ALERT_RELEASE_URL + companyUuid,
    method: "POST",
    body: JSON.stringify({
      uuid: row.uuid,
      readyForCustomer: !row.readyForCustomer,
    }),
  }
}

export const generateSysAdminAlertToPrimaryFeedArgs = (row, companyUuid) => {
  console.log("SysAdmin alert to primary feed -- alert:", row);
  return {
    url: Constants.SERVER_SYSADMIN_POST_COMPANY_ALERT_PRIMARY_FEED_URL + companyUuid,
    method: "POST",
    body: JSON.stringify({
      uuid: row.uuid,
      inPrimaryFeed: !row.inPrimaryFeed,
    }),
  }
}

export const generateSysAdminAlertMessageArgs = (row, message, companyUuid) => {
  return {
    url: Constants.SERVER_SYSADMIN_POST_COMPANY_ALERT_UPDATE_URL + companyUuid,
    method: "POST",
    body: JSON.stringify({
      uuid: row.uuid,
      flagAiMessageSysadmin: message,
      flagAiRelevantStatus: row.flagAiRelevantStatus
    })
  }
}

export const generateDummyAlertUpdateDispatch = (row, updateType, message) => {
  const genericUpdateBody = {
    developerAppUuid: row.developerAppUuid,
    alertName: row.alertName,
    flagRowId: row.flagRowId,
  }
  if (updateType === update_types.RELEVANT) {
    // toggle relevant on or toggle relevant off + pin === false
    genericUpdateBody.flagAiRelevantStatus = row.flagAiRelevantStatus === "RELEVANT" ? "NOT_SET" : "RELEVANT";
    // Nov 1 - flagAiMessage no longer sent with relevant update
    // genericUpdateBody.flagAiMessage = message ? message : null;
    genericUpdateBody.flagPin = false;
  } else if (updateType === update_types.IRRELEVANT) {
    // toggle irrelevant on (w/ optional message) or toggle irrelevant off (and set message to null) + archive/pin === false
    genericUpdateBody.flagAiRelevantStatus = row.flagAiRelevantStatus === "IRRELEVANT" ? "NOT_SET" : "IRRELEVANT";
    genericUpdateBody.flagAiMessage = message ? message : null;
    genericUpdateBody.flagPin = false;
  } else if (updateType === update_types.PIN) {
    // toggle pin on or off
    genericUpdateBody.flagPin = !row.flagPin;
  } else if (updateType === update_types.ARCHIVE) {
    // toggle archive on or off + pin === false
    genericUpdateBody.flagArchive = !row.flagArchive;
    genericUpdateBody.flagPin = false;
  } else if (updateType === update_types.AI) {
    genericUpdateBody.flagAiMessage = message;
  }
  console.log("dummy alert update dispatch", genericUpdateBody)
  return genericUpdateBody;
};

// TODO: mixpanel events
/*
SAMPLE:
dispatch(
				addMixpanelEvent({
					event: "Not Relevant",
					additionalProperties: {
						"developerAppUuid": row.developerAppUuid,
						"appName": row.appName,
						"alertName": row.alertName,
						"flagSeverity": row.flagSeverity,
						"flagDescription": row.flagDescription,
						"flagRowId": row.flagRowId,
						"flagTimestamp": row.flagTimestamp,
						"flagOverride": true,
						"flagRelevant": row.flagRelevant,
						"hasBeenNotified": row.hasBeenNotified,
						"flagOverrideMessage": message ? message : null,
						"companyUuid": companyUuid,
					}
				})
			);
 */
export const generateMixpanelEvent = (row, updateType, message) => {
  //dispatch(addMixpanelEvent({ event: "Started Subscription", additionalProperties: { "uuid": app.uuid }}));
  const payload = {
    additionalProperties: {
      "developerAppUuid": row.developerAppUuid,
      "appNameDisplay": row.appNameDisplay,
      "flagRowId:": row.flagRowId,
      "uuid": row.uuid,
      "flagDescription": row.flagDescription,
    }
  }
  if (updateType === update_types.RELEVANT) {
    if (row.flagAiRelevantStatus !== "RELEVANT") {
      // payload.additionalProperties.flagAiMessage = message ? message : null;
      payload.event = "Alert Acknowledged";
    }
  } else if (updateType === update_types.IRRELEVANT) {
    if (row.flagAiRelevantStatus !== "IRRELEVANT") {
      payload.additionalProperties.flagAiMessage = message ? message : null;
      payload.event = "Alert Removed";
    }
  } else if (updateType === update_types.AI) {
    payload.additionalProperties.flagAiMessage = message;
    payload.event = "Alert AI Message";
  }
  return payload;
};

export const generateMixpanelViewAlertEvent = (row) => {
  return {
    event: "View Alert",
    additionalProperties: {
      "developerAppUuid": row.developerAppUuid,
      "appNameDisplay": row.appNameDisplay,
      "flagDescription": row.flagDescription,
      "flagRowId": row.flagRowId,
      "uuid": row.uuid,
      "flagAiRelevantStatus": row.flagAiRelevantStatus,
    }
  }
}

export const MainTooltipTitle = () => {
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <span style={{visibility: "hidden"}}>i</span>
      <span style={{marginRight: "4px"}}>Unread + read (</span>
      <CheckCircleOutlineIcon fontSize={"inherit"} sx={{marginRight: "4px"}}/>
      <span>)</span>
      {/*<span style={{ marginRight: "5px" }}>&</span>*/}
      {/*<PushPinIcon fontSize={"inherit"}/>*/}
      <span style={{visibility: "hidden"}}>i</span>
    </div>
  )
}

export const ArchivedTooltipTitle = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <span style={{ visibility: "hidden"}}>i</span>
      <CheckCircleOutlineIcon fontSize={"inherit"} sx={{ marginRight: "5px" }}/>
      <span style={{ marginRight: "5px" }}>with</span>
      <ArchiveIcon fontSize={"inherit"}/>
      <span style={{ visibility: "hidden"}}>i</span>
    </div>
  )
}

export const IrrelevantTooltipTitle = () => {
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <span style={{visibility: "hidden"}}>i</span>
      <span style={{marginRight: "4px"}}>(</span>
      <VisibilityOffIcon fontSize={"inherit"} sx={{marginRight: "4px"}}/>
      <span>)</span>
      <span style={{visibility: "hidden"}}>i</span>
    </div>
  )
}

export const getRowClassNameForAlertFeed = (row, currentTab, sysAdmin, preview) => {
  if (!preview) {
    if (sysAdmin && row.readyForCustomer) {
      return "alert--active"
    } else if (!sysAdmin && row.flagAiRelevantStatus === "RELEVANT" && (currentTab === "Primary" || currentTab === "Main")) {
      return "alert--active"
    } else if (!sysAdmin && row.flagAiRelevantStatus === "NOT_SET" && (currentTab === "Primary" || currentTab === "Main" || currentTab === "Unread")) {
      return "alert--new"
    } else {
      return "alert--inactive"
    }
  } else {
    return "alert--inactive"
  }
}

export const getFontWeightForRenderCell = (row, currentTab, sysAdmin, preview) => {
  if (!preview && !sysAdmin && row.flagAiRelevantStatus === "NOT_SET" && (currentTab === "Primary" || currentTab === "Main" || currentTab === "Unread")) {
    return 600
  } else {
    return "inherit"
  }
}

export const getMoneySaved = (value) => {
  if (!value || value <= 1) {
    return "$";
  } else if (value <= 10) {
    return "$$";
  } else if (value <= 100) {
    return "$$$";
  } else {
    return "$$$$";
  }
}

const toFixedWithCommas = (num) => {
  if (!num) {
    // Handle null, undefined
    const fallbackNum = 0.00
    return fallbackNum.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  } else {
    return num.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }
}

export const formatMoneySaved = (primaryFeed, sysAdmin, moneySaved) => {
  if (sysAdmin) {
    return moneySaved;
  } else {
    return primaryFeed ? `$${toFixedWithCommas(moneySaved)}` : `${getMoneySaved(moneySaved)}`;
  }
}
