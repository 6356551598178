/*
this guy is only for user - isAccrualsUser / module app users will not be sysadmin 

he needs to find the app witht he passed moduleTag from their filteredApps list

then he needs to create the necessary state to pass on to <Dashboard/>
*/
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import { AppViewLoader, LoadingBar } from "../Loaders/AppViewLoader";
import * as Constants from "../../Constants";
import LogoutIcon from '@mui/icons-material/Logout';
import { setAlertNotificationArgs } from "../../store/alertsNotificationSlice";
import iso8601Timestamp from "../../helpers/iso8601Timestamp";

const Loader = ({ moduleTag, error }) => {
  const dispatch = useDispatch();
  const cancelAction = () => {
    const logoutUrl = Constants.SERVER_LOGOUT_URL;
    dispatch({ type: "RESET" });
    localStorage.setItem("isLoggingOut", "true");
    window.open(logoutUrl, "_self");
  }
  const cancelText = "Logout"
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <LoadingBar cancelAction={cancelAction} cancelText={cancelText} cancelIcon={<LogoutIcon/>} />
      </Grid>
      <Grid item xs={12}>
        <AppViewLoader error={error.isErrored} errorMessage={error.message}/>
      </Grid>
    </Grid>
  )
}

const Getter = ({ moduleTag, setError }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apps = useSelector(state => state.apps.filteredList);
  const subscribedApps = useSelector(state => state.appsSubscriptions.list);

  const moduleApp = apps.find((app) => app.tags.includes(moduleTag));
  const moduleAppSubscriptionUuid = subscribedApps.find(sub => sub.developerAppUuid === moduleApp?.uuid)?.uuid;

  const user = useSelector(state => state.role.name);
  const isInternal = useSelector(state => state.role.isInternal);
  useEffect(() => {
    if (!moduleApp || !moduleAppSubscriptionUuid) {
      setError({
        isErrored: true,
        message: `You do not have access to this resource - the team has been notified and a fix is in the works`
      })
      if (!isInternal) {
        // send message to team that username is missing app from filtered list or not subscribed
        const tempNotificationArgs = {
          url: Constants.SERVER_SEND_EMAIL,
          method: "POST",
          body: JSON.stringify({
            "body": `<strong>${user}</strong> doesn't have access to the ${moduleTag} app on ${window.location.origin}<br/><br/>`,
            "subject": `${user} doesn't have access to the ${moduleTag} app (${window.location.origin} - ${iso8601Timestamp()})`,
            "from": "noreply@wiselayer.com",
            "toEmail": Constants.notificationList.join(","),
            "sendHTML": true
          })
        }
        dispatch(setAlertNotificationArgs(tempNotificationArgs));
      }
    } else {
      const dashboardState = {
        thisDevApp: moduleApp,
        uuid: moduleApp.uuid,
        viewApp: true,
        customerSubscriptionUuid: moduleAppSubscriptionUuid,
        moduleApp: true
      };
      const dashboardName = moduleTag.split("__")[1];
      console.log("navigating to accruals -> dashboardState", dashboardState);
      // setTimeout(() => {
      //   navigate(`/dashboard/${dashboardName}`, { state: dashboardState });
      // }, 50000)
      navigate(`/dashboard/${dashboardName}`, { state: dashboardState });
    }
  }, [moduleApp, moduleAppSubscriptionUuid, moduleTag, user, isInternal, navigate]);

  return null;
}

export const DashboardGetter = ({ moduleTag }) => {
  const [error, setError] = useState({
    isErrored: false,
    message: ""
  })
  return (
    <>
      <Loader moduleTag={moduleTag} error={error} />
      <Getter moduleTag={moduleTag} setError={setError} />
    </>
  )
}

export default DashboardGetter;