import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setFilteredList } from "../../store/appsSlice";

const FilteredAppsManager = () => {
  const dispatch = useDispatch();

  const isRestricted = useSelector((state) => state.role.isRestricted);
  const restrictedWhitelist = useSelector((state) => state.role.restrictedWhitelist);

  const apps = useSelector((state) => state.apps.list);
  const sources = useSelector((state) => state.apps.sources);
  const appsAndSourceCaptured = useSelector((state) => state.apps.isCaptured);

  const connectedIntegrations = useSelector((state) => state.integrationMetadata.integrations);
  const connectedIntegrationsCaptured = useSelector((state) => state.integrationMetadata.isCaptured);

  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (appsAndSourceCaptured && connectedIntegrationsCaptured) {
      // console.log("ready to calc filteredApps")
      setReady(true);
    }
  }, [appsAndSourceCaptured, connectedIntegrationsCaptured])

  useEffect(() => {
    if (ready) {
      // console.log("calculating filteredApps")
      /*
      connectedSources look like this:
       {
            "connected": true,
            "fivetranConnectorId": "guidance_hygienic",
            "sourceName": "quickbooks", <----------------------- this is the source name - filter sources by these
            "uuid": "ftco_C4NW27YD83PRSMPWW1L2M7D7H"
        }

      apps look like this:
        {
            ...,
            "requiredSourceTables": [
              {
                ...,
                "uuid": "stab_3K2TL0M0Q7624O92S4683E9T9"
              },
              { ... },
              ...
            ]
        }

      sources look like this:
        {
          ...,
          "name": "quickbooks",
          "sourceTables": [
            {
              ...,
              "uuid": "stab_3K2TL0M0Q7624O92S4683E9T9"
            },
            { ... },
            ...
          ]
        }

      */
      const connectedSourceNames = connectedIntegrations.map((integration) => integration.sourceName).concat(["generic"]);// all connected sources + "generic";
      const connectedSources = sources.filter((source) => connectedSourceNames.includes(source.name));
      const connectedSourceTablesArrays = [];
      connectedSources.forEach((source) => {
        connectedSourceTablesArrays.push(source.sourceTables.map((sourceTable) => sourceTable.uuid));
      })
      const connectedSourceTables = connectedSourceTablesArrays.flat();
      // console.log("connectedSourceTables:", connectedSourceTables);
      const filteredApps = apps.filter((app) => {
        const requiredSourceTables = app.requiredSourceTables.map((requiredSourceTable) => requiredSourceTable.uuid);
        // console.log(`${app.fullName} requiredSourceTables:`, requiredSourceTables);
        return requiredSourceTables.every(requiredSourceTable => connectedSourceTables.includes(requiredSourceTable));
      });
      // console.log("filteredApps:", filteredApps);

      if (!isRestricted) { // regular user - display all viable apps
        dispatch(setFilteredList(filteredApps));
      } else { // restricted user - display only apps in restrictedWhitelist
        const restrictedFilteredApps = filteredApps.filter((app) => restrictedWhitelist.includes(app.uuid));
        dispatch(setFilteredList(restrictedFilteredApps));
      }
    }
  }, [apps, sources, connectedIntegrations, ready, restrictedWhitelist, isRestricted])


  return null;
}

export default FilteredAppsManager;