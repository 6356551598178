import { useSelector } from "react-redux";
import DirectRequest from "./DirectRequest";
import * as Constants from "../../Constants";
import { useState } from "react";

/*
NOTE: 12/18 - The backend Databricks service always takes a while on the first request - this "warm up" request will ensure a sysadmin viewing runs gets an immediate response
 */
export const DatabricksRunsWarmUpRequest = () => {
  const sysAdmin = useSelector((state) => state.role.isSysAdmin);
  const companyUuid = useSelector((state) => state.role.companyUuid);

  const [jobRunsArgs, setJobRunsArgs] = useState({ url: Constants.SERVER_SYSADMIN_POST_GET_DATABRICKS_RUNS_URL + companyUuid, method: "POST", body: JSON.stringify({})  });

  return (
    sysAdmin ? (
      <DirectRequest
        requestArgs={jobRunsArgs}
        handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("JobRunsRequest error", err)}
        handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("JobRunsRequest catch error", err)}
      />
    ) : null
  )
}

export default DatabricksRunsWarmUpRequest;