import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from "react-redux";
import { setShowModal } from "../../store/summaryNotificationsModalSlice";
import Box from '@mui/material/Box';
import CloseIcon from "@mui/icons-material/Close";
import {getDataPointsToday} from "../AlertFeed/SummaryMetricsBox";
import { useState, useEffect } from "react";
import SummaryOverview from "./SummaryOverview";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import LinkConfigApps from './LinkConfigApps';

const SummaryPrimaryFeedIndicator = () => {
  const primaryFeed = useSelector((state) => state.alertsTabs.primaryFeed);

  return (
    <Grid item xs={12} container justifyContent={"center"}>
      <Typography><i>Summary for<strong>{primaryFeed ? " HIGHEST VALUE ONLY " : " ALL "}</strong>alerts</i></Typography>
    </Grid>
  )
}

export const SummaryNotificationsModal = () => {
  const dispatch = useDispatch()
  const postSetupComplete = useSelector((state) => state.role.postSetupComplete);
  const showModal = useSelector((state) => state.summaryNotificationsModal.showModal);

  const summaryMetrics = useSelector((state) => state.summaryMetrics.summary);
  const isCaptured = useSelector((state) => state.summaryMetrics.isCaptured);

  const [selectedCategory, setSelectedCategory] = useState(null)

  const handleClose = () => {
    dispatch(setShowModal(false));
  }

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };
    window.addEventListener('keyup', handleKeyDown);
    return () => {
      window.removeEventListener('keyup', handleKeyDown);
    };
  }, [])

  return postSetupComplete ? (
    <Modal open={showModal} id={"summary_modal"}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          maxHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "auto" // enable scrolling if content is too wide/tall
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Grid container spacing={1} sx={{ width: "750px" }}>
            {/* LinkConfigApps */}
            <LinkConfigApps/>
            {/* padding elements */}
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>
            {/* summary modal */}
            <Grid
              container
              spacing={1}
              sx={{
                backgroundColor: "white !important",
                borderRadius: "10px",
                width: "750px",
                position: "relative",
                padding: "20px"
              }}
            >
              <IconButton
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                }}
              >
                <CloseIcon />
              </IconButton>
              <Grid item xs={12} container justifyContent={"center"}>
                <Typography sx={{ fontSize: "1.7rem", lineHeight: 1.6, fontWeight: 650 }}>Profitability optimized by Wise AI</Typography>
              </Grid>
              {isCaptured && (
                <Grid item xs={12} container justifyContent={"center"}>
                  <Typography variant={"p"}><i><strong>{getDataPointsToday(summaryMetrics)}</strong> data points analyzed (past 24 hr)</i></Typography>
                </Grid>
              )}
              <SummaryPrimaryFeedIndicator/>
              <Grid id={"summary_overview"} item xs={12} container spacing={2} justifyContent={"center"} sx={{ marginTop: "0.15rem", marginLeft: "0px !important", paddingLeft: "0px !important" }}>
                <SummaryOverview selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}/>
              </Grid>
            </Grid>
          </Grid>
        </ClickAwayListener>
      </Box>
    </Modal>
  ) : null;
}

export default SummaryNotificationsModal;