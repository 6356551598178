import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import DirectRequest from "../../API/requests/DirectRequest";
import Grid from "@mui/material/Grid";
import * as Constants from "../../Constants";
import FDGrid from "../FDGrid/FDGrid";
import { dummy_apps } from "../../store/dummyData/dummy_apps";
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import { cleanDatabricksRuns, StartTime, RunStatus } from "./SysAdminDatabricksRuns";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from '@mui/icons-material/Refresh';
import Typography from "@mui/material/Typography";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Box from "@mui/material/Box";
import { CustomAppTooltip } from "../AppBuilder/TagEditor/TagEditor";
/*
last alert sync res
{
    "alertSyncEventMap": {
        "dapp_C2AYQ5EXS0MAD4J2CXHDYH447": {
            "runId": 17134,
            "parentRunId": 17133,
            "companyId": "comp_86FOF91CZHPW88SRGR9TYISRB",
            "objectId": "dapp_C2AYQ5EXS0MAD4J2CXHDYH447",
            "externalPartner": "unknown",
            "type": "alerts_sync",
            "attributes": {
                "active": {
                    "to_delete": 0,
                    "to_insert": 0,
                    "to_update": 296
                },
                "status": "SUCCESS",
                "syncId": 1707329177161,
                "resolved": {
                    "to_delete": 0,
                    "to_insert": 0,
                    "to_update": 0
                }
            },
            "state": "completed",
            "createdBy": "system_async",
            "createdOn": "2024-02-07T18:06:18.934+00:00"
        },
        ...
    }
}
new db runs res
{
    "databricksJobRunInfoList": {
        "dapp_C2AYQ5EXS0MAD4J2CXHDYH447": {
            "jobId": 700876788559,
            "runId": 549280706234755,
            "startTime": 1707329108759,
            "jobUrl": "https://dbc-7da6d969-096f.cloud.databricks.com/?o=3103134358146978#job/700876788559",
            "runUrl": "https://dbc-7da6d969-096f.cloud.databricks.com/?o=3103134358146978#job/700876788559/run/549280706234755",
            "jobName": "dapp_c2ayq5exs0mad4j2cxhdyh447__notebook",
            "runDuration": 74884,
            "runStatus": "SUCCESS",
            "companyUuid": "comp_86FOF91CZHPW88SRGR9TYISRB",
            "developerAppUuid": "dapp_C2AYQ5EXS0MAD4J2CXHDYH447",
            "alertRowCount": 296,
            "otherTablesRowCount": 296,
            "runParameters": {
                "job_id": "700876788559",
                "run_id": "549280706234755",
                "schemaPath": "awsdev_catalog.awsdev_awsdev_testsand_yisrb_quickbooks",
                "webhookUrl": "https://dev-use1.fruitiondata.net/databricks/public/webhook",
                "customerUuid": "comp_86fof91czhpw88srgr9tyisrb",
                "parent_run_id": "{{parent_run_id}}",
                "developerAppUuid": "dapp_c2ayq5exs0mad4j2cxhdyh447"
            },
            "createdOn": "2024-02-07T18:07:31.412+00:00",
            "createdBy": "jack@awsdev_testsandbox.com"
        },
        ...
    }
}
*/
const cleanApps = (apps) => {
  return apps.map((app, index) => (
    {
      ...app,
      Id: index,
    }
  ))
}

const SelectedCompanyFilteredAppsManager = ({ selectedCompany, setRows, setAvailableAppUuids }) => {
  const sysAdminApps = useSelector((state) => state.appsSysAdmin.list);
  const sources = useSelector((state) => state.apps.sources);
  const [selectedCompanyIntegrationMetadataArgs, setSelectedCompanyIntegrationMetadataArgs] = useState();

  useEffect(() => {
    if (selectedCompany && selectedCompany.uuid) {
      setSelectedCompanyIntegrationMetadataArgs({ url: Constants.SERVER_GET_SYSADMIN_INTEGRATION_METADATA_URL + selectedCompany.uuid });
      setRows([])
      setAvailableAppUuids([])
    }
  }, [selectedCompany])

  const handleSelectedCompanyIntegrationMetadata = (res) => {
    console.log(selectedCompany, "integration metadata:", res.connectors)
    if (res && res.connectors) {
      // logic copied/modified from FilteredAppsManager
      // const connectedSourceNames = res.connectors.map((connector) => connector.service);
      const connectedSourceNames = res.connectors.map((connector) => connector.service).concat(["generic"]);// all connected sources + "generic";
      // console.log("handleSelectedCompanyIntegrationMetadata -> connectedSourceNames", connectedSourceNames);
      const connectedSources = sources.filter((source) => connectedSourceNames.includes(source.name));
      const connectedSourceTablesArrays = [];
      connectedSources.forEach((source) => {
        connectedSourceTablesArrays.push(source.sourceTables.map((sourceTable) => sourceTable.uuid));
      })
      const connectedSourceTables = connectedSourceTablesArrays.flat();
      // console.log("connectedSourceTables:", connectedSourceTables);
      const filteredApps = sysAdminApps.filter((app) => {
        const requiredSourceTables = app.requiredSourceTables.map((requiredSourceTable) => requiredSourceTable.uuid);
        // console.log(`${app.fullName} requiredSourceTables:`, requiredSourceTables);
        return requiredSourceTables.every(requiredSourceTable => connectedSourceTables.includes(requiredSourceTable));
      });
      const filteredAppsUuids = filteredApps.map((app) => app.uuid);
      console.log("filteredAppsUuids", filteredAppsUuids);
      setRows(cleanApps([...filteredApps, ...sysAdminApps.filter((app) => !filteredAppsUuids.includes(app.uuid))]));
      setAvailableAppUuids(filteredAppsUuids);
    }
  }

  return (
    <>
      <DirectRequest requestArgs={selectedCompanyIntegrationMetadataArgs} afterProcess={handleSelectedCompanyIntegrationMetadata}/>
    </>
  )
}

// sortSubscribedApps and it's sysAdminApps/selectedCompanySubscribedApps useEffect where not good for UX - apps would resort on subscribe/unsubscribe
/*sortSubscribedApps(JSON.parse(JSON.stringify(sysAdminApps)), selectedCompanySubscribedApps.map((sub) => sub.developerAppUuid))*/
const sortSubscribedApps = (apps, subscribedAppsUuids) => {
  return apps.sort((a, b) => {
    if (subscribedAppsUuids.includes(a.uuid) && !subscribedAppsUuids.includes(b.uuid)) {
      return -1;
    } else if (!subscribedAppsUuids.includes(a.uuid) && subscribedAppsUuids.includes(b.uuid)) {
      return 1;
    } else {
      return 0;
    }
  })
}

const syncIsAfterRun = (startTime, createdOn) => {
  if (startTime == null || createdOn == null) {
    return false; // Return false if either value is null or undefined
  }
  // Convert startTime from epoch time in milliseconds to a Date object
  const startTimeDate = new Date(startTime);
  // Convert createdOn from an ISO 8601 string to a Date object
  const createdOnDate = new Date(createdOn);
  // Check if either Date object is invalid
  if (isNaN(startTimeDate.getTime()) || isNaN(createdOnDate.getTime())) {
    return false; // Return false if either date is invalid
  }
  // Return true if createdOnDate is after startTimeDate, false otherwise
  return createdOnDate > startTimeDate;
}

const getRowClassNameForSysAdminSubscribedApps = (app, subscribedApps, runs, syncs) => {
  let rowClassName = "";
  if (runs && syncs) {
    // there is an object for runs and an object for syncs - good to compare
    if (subscribedApps.map((subscribedApp) => subscribedApp.developerAppUuid).includes(app.uuid)) {
      // if subscribed find most recent run and sync for this devapp
      const mostRecentRun = runs[app.uuid];
      const mostRecentSync = syncs[app.uuid];
      if (mostRecentRun && mostRecentSync) {
        // only apply className if mostRecentRun and mostRecentSync are both defined
        const syncAfterRun = syncIsAfterRun(mostRecentRun.startTime, mostRecentSync.createdOn);
        if (!syncAfterRun) {
          // now I need some additional checks:
          // 1. if mostRecentRun.runStatus === "RUNNING" do not apply className
          // 2. if mostRecentRun.startTime is less than 15 minutes ago do not apply className
          if (mostRecentRun.runStatus !== "RUNNING" && (new Date().getTime() - mostRecentRun.startTime) > 900000) {
            rowClassName = "sysadmin-subscribed-apps-sync-before-run";
          }
        }
      }
    }
  }
  return rowClassName;
}

const getTooltip = (tags) => {
  return (
    <>
      <Grid container spacing={1}>
        {tags.map((tag, index) => {
          return (
            <Fragment key={index}>
              {index !== 0 && (
                <Grid item xs={12}>
                  {/* purely for spacing between tags */}
                </Grid>
              )}
              <Grid item xs={12}>
                {tag}
              </Grid>
            </Fragment>
          )
        })}
      </Grid>
    </>
  )
}

const TagsStack = ({ icon, tags }) => {
  return (
    <>
      <Tooltip title={getTooltip(tags)} followCursor>
        {icon}
      </Tooltip>
    </>
  );
}

const checkIfSubscribed = (subscribedApps, app) => {
  const subscribedAppUuids = subscribedApps.map((subscribedApp) => subscribedApp.developerAppUuid);
  return subscribedAppUuids.includes(app.uuid);
}

const SubscriptionSwitch = ({ companyUuid, subscribedApps, app, refreshSubscribedApps, availableAppUuids }) => {
  const [enabled, setEnabled] = useState(false);
  const [subscriptionArgs, setSubscriptionArgs] = useState();

  useEffect(() => {
    setEnabled(checkIfSubscribed(subscribedApps, app));
  }, [subscribedApps, app])

  const handleStartSubscription = () => {
    console.log("starting subscription for developer app", app.uuid);
    const tempSubscriptionArgs = {
      url: Constants.SERVER_SYSADMIN_CUS_APP_BUY_URL + companyUuid,
      method: "POST",
      body: JSON.stringify({
        "developerAppUuid": app.uuid,
        "stripeSubscriptionUuid": null,
        "isTrial": false,
        "isActive": true
      })
    }
    setSubscriptionArgs(tempSubscriptionArgs)
  }

  const handleCancelSubscription = () => {
    console.log("cancelling subscription for developer app", app.uuid);
    const customerSubscriptionUuid = subscribedApps.find((subscribedApp) => subscribedApp.developerAppUuid === app.uuid).uuid;
    const tempSubscriptionArgs = {
      url: Constants.SERVER_SYSADMIN_CUS_APP_CANCEL_URL + companyUuid,
      method: "POST",
      body: JSON.stringify({
        "customerSubscriptionUuid": customerSubscriptionUuid
      })
    }
    setSubscriptionArgs(tempSubscriptionArgs)
  }

  const handleChange = () => {
    if (!enabled) {
      handleStartSubscription();
    } else {
      handleCancelSubscription();
    }
  }

  const handleAfterSubscriptionAction = (res) => {
    console.log("success - refreshing subscribedApps");
    refreshSubscribedApps();
  }

  return (
    <>
      <DirectRequest
        requestArgs={subscriptionArgs}
        afterProcess={handleAfterSubscriptionAction}
        handleError={(err) => console.log("error creating subscription for developer app", app.uuid)}
        handleCatchError={(err) => console.log("catch error creating subscription for developer app", app.uuid)}
      />
      <Grid container sx={{ width: "100%" }} justifyContent={"center"}>
        <Grid item sx={{ marginLeft: "20px"}}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch checked={enabled} onChange={handleChange} disabled={!companyUuid || !availableAppUuids.includes(app.uuid)} />
              }
              label={enabled ? "Enabled" : "Disabled"}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  )
}

const LastDatabricksRun = ({ runs, developerAppUuid, subscribedApps }) => {
  // if subscribed find most recent run for this devapp
  if (runs && subscribedApps.map((subscribedApp) => subscribedApp.developerAppUuid).includes(developerAppUuid)) {
    const mostRecentRun = runs[developerAppUuid];
    if (mostRecentRun) {
      return (
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={6}>
            <StartTime startTime={mostRecentRun.startTime} runUrl={mostRecentRun.runUrl}/>
          </Grid>
          <Grid item xs={6}>
            <RunStatus runStatus={mostRecentRun.runStatus} />
          </Grid>
        </Grid>
      )
    } else {
      return null;
    }
  } else return null;
}

const LastAlertSync = ({ developerAppUuid, subscribedApps, syncs }) => {
  if (syncs && subscribedApps.map((subscribedApp) => subscribedApp.developerAppUuid).includes(developerAppUuid)) {
    const mostRecentSync = syncs[developerAppUuid];
    if (mostRecentSync) {
      return (
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={6}>
            <Tooltip title={mostRecentSync.attributes.status === "SUCCESS" ? "" : mostRecentSync.attributes.message ? mostRecentSync.attributes.message : "No message provided"}>
              <Typography>{new Date(mostRecentSync.createdOn).toLocaleString()}</Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={6}>
            <Typography component="div" noWrap sx={{ display: "flex", alignItems: "center" }}>
              {mostRecentSync.attributes.status === "SUCCESS" ? <CheckCircleOutlineIcon sx={{color: "green"}}/> : <HighlightOffIcon sx={{color: "#9c0606"}}/>}
              <Box component="span" sx={{ marginLeft: "6px"}}>
                {mostRecentSync.attributes.status}
              </Box>
            </Typography>
          </Grid>
        </Grid>
      )
    } else {
      return null;
    }
  } else return null;
}

export const SysAdminSubscribedApps = ({selectedCompany}) => {
  const [selectedCompanySubscribedApps, setSelectedCompanySubscribedApps] = useState([]);
  const [selectedCompanySubscribedAppsArgs, setSelectedCompanySubscribedAppsArgs] = useState();

  const [rows, setRows] = useState([]);
  const [availableAppUuids, setAvailableAppUuids] = useState([]);

  const [databricksRunsArgs, setDatabricksRunsArgs] = useState();
  const [databricksRuns, setDatabricksRuns] = useState();// becomes object

  const [alertSyncsArgs, setAlertSyncsArgs] = useState();
  const [alertSyncs, setAlertSyncs] = useState();// becomes object

  useEffect(() => {
    if (selectedCompany && selectedCompany.uuid) {
      if (selectedCompany.name) console.log("selected company", selectedCompany.name);
      setSelectedCompanySubscribedAppsArgs({ url: Constants.SERVER_SYSADMIN_CUS_SUBBED_APPS_URL + selectedCompany.uuid });
      setSelectedCompanySubscribedApps([])
      setDatabricksRuns()
      setAlertSyncs()
    }
  }, [selectedCompany])

  const refreshSubscribedApps = () => {
    if (selectedCompany && selectedCompany.uuid) {
      setSelectedCompanySubscribedAppsArgs({ url: Constants.SERVER_SYSADMIN_CUS_SUBBED_APPS_URL + selectedCompany.uuid });
    }
  }

  const refreshDatabricksRuns = () => {
    if (selectedCompany && selectedCompany.uuid) {
      setDatabricksRunsArgs({ url: Constants.SERVER_SYSADMIN_POST_GET_DATABRICKS_RUNS_BY_APP_URL + selectedCompany.uuid, method: "POST", body: JSON.stringify({}) })
    }
  }

  const refreshAlertSyncs = () => {
    if (selectedCompany && selectedCompany.uuid) {
      setAlertSyncsArgs({ url: Constants.SERVER_SYSADMIN_POST_GET_ALERT_SYNCS_URL + selectedCompany.uuid, method: "POST", body: JSON.stringify({}) })
    }
  }

  const refreshDatabricksRunsAndAlertSyncs = () => {
    refreshDatabricksRuns();
    refreshAlertSyncs();
  }

  const handleSelectedCompanySubscribedApps = (res) => {
    console.warn("selected company subscriptions:", res);
    if (res) {
      setSelectedCompanySubscribedApps(res);
      refreshDatabricksRunsAndAlertSyncs()
    }
  }

  const handleDatabricksRuns = (res) => {
    console.log("handleDatabricksRuns -> res", res)
    if (res && res.databricksJobRunInfoList) {
      setDatabricksRuns(res.databricksJobRunInfoList);
    }
  }

  const handleAlertSyncs = (res) => {
    console.log("handleAlertSyncs -> res", res);
    if (res && res.alertSyncEventMap) {
      setAlertSyncs(res.alertSyncEventMap);
    }
  }

  const columns = [
    {
      "field": "name",
      "headerName": "App name",
      "width": 350,
      "sortable": false,
      renderCell: (params) => {
        const app = params.row;
        return (
          `${app.name} - ${app.fullName}`
        )
      }
    },
    {
      "field": "uuid",
      "headerName": "App uuid",
      "width": 100,
      "sortable": false,
    },
    {
      "field": "tags",
      "headerName": "",
      "width": 50,
      "renderCell": (params) => {
        const app = params.row;
        // <TagsStack icon={<SearchIcon />} tags={tags} />
        return (
          <CustomAppTooltip
            title={app ? <pre>{JSON.stringify(app, null, 2)}</pre> : ""}
          >
            <SearchIcon />
          </CustomAppTooltip>
        )
      },
      "headerAlign": "center",
      "align": "center",
      "sortable": false,
    },
    {
      "field": "actions",
      "headerName": "Enable / Disable",
      "width": 200,
      "align": "center",
      "headerAlign": "center",
      "renderCell": (params) => {
        return (
          <SubscriptionSwitch
            companyUuid={selectedCompany?.uuid}
            subscribedApps={selectedCompanySubscribedApps}
            app={params.row}
            refreshSubscribedApps={refreshSubscribedApps}
            availableAppUuids={availableAppUuids}
          />
        )
      },
      "sortable": false,
    },
    {
      "field": "mostRecentDatabricksRun",
      "headerName": "Last Databricks run",
      "width": 300,
      "renderHeader": (params) => {
        return (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <span style={{ fontWeight: 480 }}>{params.colDef.headerName}</span><IconButton onClick={refreshDatabricksRunsAndAlertSyncs}><RefreshIcon/></IconButton>
          </div>
        )
      },
      "renderCell": (params) => {
        return (
          <LastDatabricksRun runs={databricksRuns} developerAppUuid={params.row.uuid} subscribedApps={selectedCompanySubscribedApps}/>
        )
      },
      "sortable": false,
    },
    {
      "field": "mostRecentAppAlertSync",
      "headerName": "Last alert sync",
      "width": 300,
      "renderHeader": (params) => {
        return (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <span style={{ fontWeight: 480 }}>{params.colDef.headerName}</span><IconButton onClick={refreshDatabricksRunsAndAlertSyncs}><RefreshIcon/></IconButton>
          </div>
        )
      },
      "renderCell": (params) => {
        return (
          <LastAlertSync syncs={alertSyncs} developerAppUuid={params.row.uuid} subscribedApps={selectedCompanySubscribedApps}/>
        )
      },
      "sortable": false,
    }
  ]

  useEffect(() => {
    if (Constants.USE_DUMMY_DATA) {
      setRows(cleanApps(dummy_apps))
    }
  }, [])

  return (
    <>
      <DirectRequest requestArgs={selectedCompanySubscribedAppsArgs} afterProcess={handleSelectedCompanySubscribedApps}/>
      <DirectRequest requestArgs={databricksRunsArgs} afterProcess={handleDatabricksRuns}/>
      <DirectRequest requestArgs={alertSyncsArgs} afterProcess={handleAlertSyncs}/>
      <SelectedCompanyFilteredAppsManager selectedCompany={selectedCompany} setRows={setRows} setAvailableAppUuids={setAvailableAppUuids}/>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FDGrid
            pageSize={100}
            rows={rows}
            columns={columns}
            getRowId={(row) => row.Id}
            autoHeight={true}
            localeText={{
              noRowsLabel: 'No subscribed apps'
            }}
            getRowClassName={(params) => getRowClassNameForSysAdminSubscribedApps(params.row, selectedCompanySubscribedApps, databricksRuns, alertSyncs)}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default SysAdminSubscribedApps;