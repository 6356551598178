import { useState } from "react";
import DirectRequest from "./DirectRequest";
import * as Constants from "../../Constants";
import { useDispatch } from "react-redux";
import { setAlertSyncs } from "../../store/alertSyncsSlice";

export const AlertSyncsRequest = () => {
  const dispatch = useDispatch();
  const [alertSyncsArgs, setAlertSyncsArgs] = useState({
    url: Constants.SERVER_GET_ALERT_SYNCS_BY_APP_URL
  });
  const handleAlertSyncs = (res) => {
    if (res && res.alertSyncEventMap) {
      dispatch(setAlertSyncs(res.alertSyncEventMap));
    } else {
      console.log("malformed alert syncs response")
    }
  };
  return (
    <DirectRequest
      requestArgs={alertSyncsArgs}
      afterProcess={handleAlertSyncs}
      handleError={(err) => console.log("server error getting alert syncs:", err)}
      handleCatchError={(err) => console.log("client error getting alert syncs", err)}
    />
  )
};

export default AlertSyncsRequest;