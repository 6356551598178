import React, { useState } from "react";
import { useDispatch } from "react-redux";
import DirectRequest from "./DirectRequest";
import { setIntegrations, setIntegrationsError } from "../../store/integrationMetadataSlice";
import * as Constants from "../../Constants";

export default function IntegrationMetadataRequest() {
  const dispatch = useDispatch()
  const [integrationMetadataArgs, setIntegrationMetadataArgs] = useState({ url: Constants.SERVER_GET_INTEGRATION_METADATA_URL });

  const handleIntegrationMetadata = (res) => {
    if (res) {
      console.log("integration metadata", res);
      dispatch(setIntegrations(res));
    }
  }

  const handleIntegrationMetadataError = (errType, err) => {
    console.log("integration metadata error", err);
    const errorMessage = `There was a ${errType} error while fetching your connected systems. Please refresh the page.`
    dispatch(setIntegrationsError(errorMessage));
    dispatch(setIntegrations([]))
  }

  return (
    <>
      <DirectRequest
        requestArgs={integrationMetadataArgs}
        afterProcess={handleIntegrationMetadata}
        handleError={(err) => !Constants.USE_DUMMY_DATA && handleIntegrationMetadataError(Constants.requestErrorTypes.server, err)}
        handleCatchError={(err) => !Constants.USE_DUMMY_DATA && handleIntegrationMetadataError(Constants.requestErrorTypes.network, err)}
      />
    </>
  )
}