import { useDispatch, useSelector } from "react-redux";
import { setAlertActiveMap, setAlertMessagesFeedCategoryMap } from "../../store/alertMessagesSlice";
import {
  setAlertActiveMap as setSysAdminAlertActiveMap,
  setAlertMessagesFeedCategoryMap as setSysAdminAlertMessagesFeedCategoryMap,
  resetAlertMessagesSysAdminState
} from "../../store/alertMessagesSysAdminSlice";
import {useEffect} from "react";

const AlertMessagesCompanyWatcher = () => {
  const dispatch = useDispatch();
  const selectedCompany = useSelector(state => state.alertsSysAdmin.company);
  useEffect(() => {
    if (selectedCompany && selectedCompany.uuid) {
      // if (selectedCompany.name) console.log("AlertMessagesCompanyWatcher - selected company", selectedCompany.name);
      dispatch(resetAlertMessagesSysAdminState());
    }
  }, [selectedCompany])
  return null;
}

const GenerateAlertActiveMap = ({ sysAdmin }) => {
  const dispatch = useDispatch();
  const alerts = useSelector(
    (state) => sysAdmin ? state.alertsSysAdmin.alerts : state.alerts.alerts
  );
  const activeMap = {};
  alerts.forEach(alert => {
    activeMap[alert.uuid] = true;
  });
  dispatch(sysAdmin ? setSysAdminAlertActiveMap(activeMap) : setAlertActiveMap(activeMap));
  return null;
}

const AlertActiveGenerator = ({ sysAdmin }) => {
  const alertsReady = useSelector(
    (state) => sysAdmin ? state.alertsSysAdmin.isReady : state.alerts.isReady
  );
  // console.log("AlertFeedCategoryGenerator - alertsReady = ", alertsReady);
  return alertsReady ? (<GenerateAlertActiveMap sysAdmin={sysAdmin} />) : null;
}

const AlertActiveManager = ({ sysAdmin }) => {
  const alertActiveMap = useSelector(
    (state) => sysAdmin ? state.alertMessagesSysAdmin.alertActiveMap : state.alertMessages.alertActiveMap
  );
  return !alertActiveMap ? (<AlertActiveGenerator sysAdmin={sysAdmin} />) : null;
}

const GenerateAlertMessageFeedCats = ({ sysAdmin }) => {
  const dispatch = useDispatch();
  const alertMessages = useSelector(
    state => sysAdmin ? state.alertMessagesSysAdmin.list : state.alertMessages.list
  );
  const alertMessagesFeedCategoryMap = {};
  alertMessages.forEach(message => {
    const feedCategory = message.appNameDisplay;
    if (!feedCategory) {
      // do nothing
    } else if (alertMessagesFeedCategoryMap[feedCategory]) {
      alertMessagesFeedCategoryMap[feedCategory].push(message.uuid);
    } else {
      alertMessagesFeedCategoryMap[feedCategory] = [message.uuid];
    }
  });
  dispatch(sysAdmin ? setSysAdminAlertMessagesFeedCategoryMap(alertMessagesFeedCategoryMap) : setAlertMessagesFeedCategoryMap(alertMessagesFeedCategoryMap));
  return null;
}

const AdminAlertMessagesCategoryManager = () => {
  const isAdmin = useSelector(state => state.role.isAdmin);
  return isAdmin ? (
    <>
      <AlertActiveManager sysAdmin={false}/>
      <GenerateAlertMessageFeedCats sysAdmin={false}/>
    </>
  ) : null;
}

const SysAdminAlertMessagesCategoryManager = () => {
  const isSysAdmin = useSelector(state => state.role.isSysAdmin);
  return isSysAdmin ? (
    <>
      <AlertActiveManager sysAdmin={true} />
      <GenerateAlertMessageFeedCats sysAdmin={true} />
      <AlertMessagesCompanyWatcher/>
    </>
  ) : null;
}

export const AlertMessagesCategoryManager = () => {
  return (
    <>
      <AdminAlertMessagesCategoryManager/>
      <SysAdminAlertMessagesCategoryManager/>
    </>
  );
}

export default AlertMessagesCategoryManager;