import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericCommandHandler from "./GenericCommandHandler";
import DirectRequest from "../requests/DirectRequest";
import * as Constants from "../../Constants";
import {
  changeToError,
  removeCommand,
  commandsSelectors,
} from "../../store/commandsSlice";

function GenericDelayedResponseHandler(props) {
  const dispatch = useDispatch();
  const [commandResponseArgs, setCommandResponseArgs] = useState();
  const [commandUuid, setCommandUuid] = useState();
  const command = useSelector((selectorState) =>
    commandsSelectors.selectById(selectorState, commandUuid)
  );

  useEffect(() => {
    if (commandUuid) {
      const requestArgs = {
        url: Constants.SERVER_COMMAND_RESPONSES_URL,
        method: "POST",
        body: JSON.stringify(Array(commandUuid)),
      };
      setCommandResponseArgs(requestArgs);
    }
  }, [commandUuid]);

  function handleDelayedResponse(incomingCommandUuid) {
    setCommandUuid(incomingCommandUuid);
  }

  function processCommandResponse(data) {
    if (!data || !data[commandUuid]) {
      // Server response is bad
      dispatch(
        changeToError(
          commandUuid,
          "Malformed server response for " +
            props.handlerName +
            " command " +
            commandUuid
        )
      );
      return;
    }
    const response = data[commandUuid];
    props.finishResponse(response, commandUuid);
  }

  const handleCommandResponseError = () => {
    console.log(
      "SSE -> GenericDelayedResponseHandler DirectRequest handleError triggered for " +
        props.handlerName +
        " command " +
        commandUuid
    );
    dispatch(
      changeToError(
        commandUuid,
        "Failed to retrieve server response for " +
          props.handlerName +
          " command " +
          commandUuid
      )
    );
  };

  const handleCommandResponseCatchError = () => {
    console.log(
      "SSE -> GenericDelayedResponseHandler DirectRequest handleCatchError triggered for " +
        props.handlerName +
        " command " +
        commandUuid
    );
    dispatch(
      changeToError(
        commandUuid,
        "Failed to connect with server to retrieve response for " +
          props.handlerName +
          " command " +
          commandUuid
      )
    );
  };

  return (
    <Fragment>
      <DirectRequest
        requestArgs={commandResponseArgs}
        afterProcess={processCommandResponse}
        handleError={handleCommandResponseError}
        handleCatchError={handleCommandResponseCatchError}
      />
      <GenericCommandHandler
        sse={props.sse}
        handlerName={props.handlerName}
        finishHandler={handleDelayedResponse}
      />
    </Fragment>
  );
}

export default GenericDelayedResponseHandler;
