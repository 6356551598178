import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCommand, removeCommand } from "../../store/commandsSlice";
import { changeToSynced } from "../../store/customerSubsSlice";
import AsyncRequest from "../requests/AsyncRequest";
import GenericDelayedResponseHandler from "./GenericDelayedResponseHandler";
import * as Constants from "../../Constants";
import { checkForSync } from "../../helpers/syncStatusHelpers";

function ListenForDataSync(props) {
  const dispatch = useDispatch();
  const [listenForAppRunArgs, setListenForAppRunArgs] = useState();
  const [listenForCompleteDataSyncArgs, setListenForCompleteDataSyncArgs] = useState();
  const hasSyncedAtLeastOnce = useSelector((state) => state.syncStatus.hasSyncedAtLeastOnce);

  function finishListenForDataSync(response, commandUuid) {
    // TODO: what happens if an error is returned?
    if (response.status === "succeeded") {
      const customerSubscriptionUuid = response.response.customerSubscriptionUuid;
      // console.log("sync completed for " + customerSubscriptionUuid);
      const tempArgs = {
        url: Constants.SERVER_LISTEN_FOR_APP_RUN_URL,
        method: "POST",
        body: JSON.stringify({
          customerSubscriptionUuid: customerSubscriptionUuid
        })
      }
      dispatch(changeToSynced(customerSubscriptionUuid));
      if (!checkForSync(hasSyncedAtLeastOnce)) {
        const completeDataSyncArgs = {
          url: Constants.SERVER_LISTEN_FOR_COMPLETE_DATA_SYNC_URL,
          method: "POST",
          body: JSON.stringify({
            sourceName: "quickbooks",
          }),
          commandType: Constants.CommandType.listenForCompleteDataSync,
        };
        setListenForCompleteDataSyncArgs(completeDataSyncArgs);
      }
      setListenForAppRunArgs(tempArgs);
      dispatch(removeCommand(commandUuid));
    }
  }

  function afterListenForAppRunStart(res) {
    // Just wait
  }

  const handleListenError = (data) => {
    // Todo: implement this
    console.log("Error occurred while waiting for app to run");
    console.log(data);
  }

  const handleListenCatchError = (error) => {
    // Todo: implement this
    console.log("Error occurred while communicating with server");
    console.log(error);
  }

  const afterListenForCompleteDataSyncStart = (res) => {
    // Just wait
  }
  const handleCompleteListenError = (data) => {
    console.log("Error occurred while waiting for complete data sync");
    console.log(data);
  }
  const handleCompleteListenCatchError = (error) => {
    console.log("Error occurred while communicating with server for complete data sync");
    console.log(error);
  }

  return (
    <Fragment>
      <AsyncRequest
        requestArgs={listenForAppRunArgs}
        afterProcess={afterListenForAppRunStart}
        handleError={handleListenError}
        handleCatchError={handleListenCatchError}
      />
      {/* ▼ silenced for fivetran integration */}
      {/*<AsyncRequest*/}
      {/*  requestArgs={listenForCompleteDataSyncArgs}*/}
      {/*  afterProcess={afterListenForCompleteDataSyncStart}*/}
      {/*  handleError={handleCompleteListenError}*/}
      {/*  handleCatchError={handleCompleteListenCatchError}*/}
      {/*/>*/}
      <GenericDelayedResponseHandler
        sse={props.sse}
        handlerName={Constants.CommandType.listenForDataSync}
        finishResponse={finishListenForDataSync}
      />
    </Fragment>
  );
}

export default ListenForDataSync;
