import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import FDGrid from "../FDGrid/FDGrid";

export const AlertMessagesTable = ({ sysAdmin, columns }) => {
  const [rows, setRows] = useState([]);
  const alertMessages = useSelector(
    (state) => sysAdmin ? state.alertMessagesSysAdmin.list : state.alertMessages.list
  );
  const currentType = useSelector(
    (state) => sysAdmin ? state.alertMessagesSysAdmin.currentType : state.alertMessages.currentType
  );
  useEffect(() => {
    if (currentType === "All") {
      setRows(alertMessages)
    } else {
      setRows(alertMessages.filter((message) => message.appNameDisplay === currentType))
    }
  }, [alertMessages, currentType])
  return (
    <FDGrid
      pageSize={100}
      rows={rows}
      columns={columns}
      getRowId={(row) => row.uuid}
      autoHeight={true}
      localeText={{
        noRowsLabel: "No messages"
      }}
    />
  )
}

export default AlertMessagesTable;