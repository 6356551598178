import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import * as Constants from "../Constants";

// Store: Array of Command objects
// Command object:
//    commandUuid (unique ID of command)
//    sourceName (name of corresponding source)
//    commandState (status of command, one of PENDING/READY/ERROR)
//    commandType (action being performed)
//    response (contents of response)
//    createdOn (timestamp)

const commandsAdapter = createEntityAdapter({
  selectId: (command) => command.commandUuid,
});

export const commandsSlice = createSlice({
  name: "commands",
  initialState: commandsAdapter.getInitialState(),
  reducers: {
    addCommand: {
      reducer: commandsAdapter.addOne,
      prepare: (commandUuid, sourceName, commandType) => {
        const createdOn = new Date().getTime();
        return {
          payload: {
            commandUuid: commandUuid,
            sourceName: sourceName,
            commandState: Constants.CommandState.Pending,
            commandType: commandType,
            response: "",
            createdOn: createdOn,
          },
        };
      },
    },
    removeCommand: commandsAdapter.removeOne,
    //changeToReady: {
    //  reducer: commandsAdapter.updateOne,
    //  prepare: (commandUuid) => {
    //    return {
    //      payload: {
    //        id: commandUuid,
    //        changes: {
    //          commandState: Constants.CommandState.Ready
    //        }
    //      }
    //    }
    //  }
    //},
    changeToReady: {
      reducer: commandsAdapter.updateOne,
      prepare: (commandUuid, response) => {
        return {
          payload: {
            id: commandUuid,
            changes: {
              commandState: Constants.CommandState.Ready,
              response: JSON.stringify(response),
            },
          },
        };
      },
    },
    changeToError: {
      reducer: commandsAdapter.updateOne,
      prepare: (commandUuid, error) => {
        return {
          payload: {
            id: commandUuid,
            changes: {
              commandState: Constants.CommandState.Error,
              response: JSON.stringify(error),
            },
          },
        };
      },
    },
  },
});

export const commandsSelectors = commandsAdapter.getSelectors((state) => {
  return state.commands;
});

export const { addCommand, removeCommand, changeToReady, changeToError } =
  commandsSlice.actions;

export default commandsSlice.reducer;
