import { createSlice } from "@reduxjs/toolkit";

export const mixpanelSignInSlice = createSlice({
  name: "mixpanelSignIn",
  initialState: {
    hasSignedIn: false,
  },
  reducers: {
    setHasSignedIn: (state, action) => {
      state.hasSignedIn = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setHasSignedIn } = mixpanelSignInSlice.actions;

export default mixpanelSignInSlice.reducer;