import { createSlice } from '@reduxjs/toolkit';

export const appsSysAdminSlice = createSlice({
  name: "appsSysAdmin",
  initialState: {
    list: [],
    feedCategories: [],
    isCaptured: false,
  },
  reducers: {
    setAppsSysAdmin: (state, action) => {
      state.list = action.payload.list;
      state.feedCategories = action.payload.feedCategories;
      state.isCaptured = true;
    },
    refreshAppsSysAdmin: (state) => {
      state.isCaptured = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAppsSysAdmin, refreshAppsSysAdmin } = appsSysAdminSlice.actions;

export default appsSysAdminSlice.reducer;
